import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PurchaseOrderDetailsQueryVariables = Types.Exact<{
  uniqueOrderId: Types.Scalars['String']['input'];
}>;


export type PurchaseOrderDetailsQuery = { __typename?: 'RootQueryType', purchaseOrderDetailsReport?: { __typename?: 'PurchaseOrderDetailsReport', metrics: Array<{ __typename?: 'PurchaseOrderDetailsReportMetrics', acceptedFillRate: any, acceptedQuantity: number, customerPo?: string | null, dc?: string | null, deliveredQuantity: number, description?: string | null, externalId?: string | null, id?: number | null, reason?: string | null, submittedFillRate: any, submittedQuantity: number, upc?: string | null }> } | null };


export const PurchaseOrderDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"purchaseOrderDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uniqueOrderId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"purchaseOrderDetailsReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"uniqueOrderId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uniqueOrderId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"metrics"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"acceptedQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"customerPo"}},{"kind":"Field","name":{"kind":"Name","value":"dc"}},{"kind":"Field","name":{"kind":"Name","value":"deliveredQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"submittedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"submittedQuantity"}},{"kind":"Field","name":{"kind":"Name","value":"upc"}}]}}]}}]}}]} as unknown as DocumentNode<PurchaseOrderDetailsQuery, PurchaseOrderDetailsQueryVariables>;