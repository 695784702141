import React, { useRef } from 'react';
import { UpsertVendorMarketInput } from 'graphql/__generated__/types';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  composeValidators,
  getMaxLengthValidation,
  validateRequired
} from 'common/helpers/validationHelper';
import { CountryCodes, CurrencyCodes } from 'common/constants';
import { FormApi } from 'final-form';
import { useMutation, useQuery } from '@apollo/client';
import { EditVendorMarketInitDataDocument } from '../../gql/__generated__/editVendorMarketInitData.query';
import { UpsertVendorMarketDocument } from '../../gql/__generated__/upsertVendorMatket.mutation';
import LoaderSpinner from 'components/LoaderSpinner';
import { Form } from 'react-final-form';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { App } from 'ant5';

interface EditVendorMarketFormProps {
  vendorMarket?: UpsertVendorMarketInput;
  onClose: (id?: string) => void;
}

export const EditRetailerChannelModal = ({ onClose, vendorMarket }: EditVendorMarketFormProps) => {
  const { message } = App.useApp();
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const { data: initData, loading } = useQuery(EditVendorMarketInitDataDocument, {
    skip: !vendorMarket
  });
  const [upsertVendorMarket] = useMutation(UpsertVendorMarketDocument, {
    refetchQueries: ['vendorMarkets', 'businessUnitsAndVendorMarkets']
  });

  if (loading && !initData) return <LoaderSpinner />;

  const submitForm = async (values: any, form: FormApi<any, any>) => {
    try {
      const { data } = await upsertVendorMarket({
        variables: {
          input: values
        }
      });
      form.reset();
      form.resetFieldState('vendorId');
      form.resetFieldState('countryCode');
      form.resetFieldState('currencyCode');
      form.resetFieldState('externalId');
      form.resetFieldState('name');
      message.success(`Retailer Channel successfully ${vendorMarket?.id ? 'updated' : 'added'}.`);
      onClose(data?.upsertVendorMarket?.vendorMarket?.id);
    } catch (e) {
      console.log({ e });
      message.error(`Error happened during Retailer Channel saving. ${e.message}`);
    }
  };

  return (
    <FullScreenEditingModal
      title={`${vendorMarket?.id ? 'Edit' : 'Add'} Retailer Channel`}
      open={!!vendorMarket}
      onClose={() => onClose()}
      buttons={
        <>
          <AlloyButton onClick={() => onClose()} type="secondary">
            Cancel
          </AlloyButton>
          <AlloyButton
            ref={addButtonRef}
            htmlType="submit"
            type="primary"
            onClick={() => {
              document
                ?.getElementById('edit-retailer-channel-modal-form')
                ?.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
            data-testid="retailer-channel-edit-save-button"
          >
            {vendorMarket?.id ? 'Save' : 'Add'}
          </AlloyButton>
        </>
      }
    >
      <Form
        initialValues={{
          name: vendorMarket?.name || '',
          vendorId: vendorMarket?.vendorId || null,
          externalId: vendorMarket?.externalId || null,
          id: vendorMarket?.id || null,
          countryCode: vendorMarket?.countryCode || '',
          currencyCode: vendorMarket?.currencyCode || '',
          parentVendorMarketId: vendorMarket?.parentVendorMarketId
        }}
        mutators={{
          setVendorId: (args, state, utils) => {
            utils.changeValue(state, 'vendorId', () => args[0]);
          }
        }}
        onSubmit={submitForm}
        render={({ handleSubmit, form }) => (
          <form
            id="edit-retailer-channel-modal-form"
            onSubmit={(event) => {
              const promise = handleSubmit(event);
              promise &&
                promise.then(() => {
                  // do nothing
                });
              return promise;
            }}
          >
            <AlloyFormField
              component="input"
              name="externalId"
              validate={validateRequired}
              required
              fieldProps={{
                title: 'External ID'
              }}
              data-testid="retailer-channel-edit-externel-id-field"
            />
            <AlloyFormField
              component="input"
              name="name"
              validate={validateRequired}
              required
              fieldProps={{
                title: 'Name'
              }}
              data-testid="retailer-channel-edit-name-field"
            />
            <AlloyFormField
              component="select"
              name="countryCode"
              validate={composeValidators([validateRequired, getMaxLengthValidation(2)])}
              required
              fieldProps={{
                title: 'Country Code',
                options: Object.keys(CountryCodes).map((countryCode) => ({
                  value: countryCode,
                  label: countryCode
                }))
              }}
              data-testid="retailer-channel-edit-country-code-field"
            />
            <AlloyFormField
              component="select"
              name="currencyCode"
              validate={composeValidators([validateRequired, getMaxLengthValidation(3)])}
              required
              fieldProps={{
                title: 'Currency Code',
                options: Object.keys(CurrencyCodes).map((countryCode) => ({
                  value: countryCode,
                  label: countryCode
                }))
              }}
              data-testid="retailer-channel-edit-currency-code-field"
            />
            <AlloyFormField
              component="select"
              name="vendorId"
              validate={validateRequired}
              required
              fieldProps={{
                title: 'Retailer',
                options: getNodesFromEdges(initData?.vendors).map((vendor) => ({
                  value: vendor.id,
                  label: vendor.name
                }))
              }}
              data-testid="retailer-channel-edit-retailer-field"
            />
            <AlloyFormField
              component="select"
              name="parentVendorMarketId"
              fieldProps={{
                title: 'Parent Retailer Channel',
                options: getNodesFromEdges(initData?.vendorMarkets).map((vendorMarket) => ({
                  value: vendorMarket.id,
                  label: vendorMarket.name
                }))
              }}
              data-testid="retailer-channel-edit-parent-vendor-market-field"
            />
          </form>
        )}
      />
    </FullScreenEditingModal>
  );
};
