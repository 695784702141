import { StatChangeIndicatorProps } from 'components/ui/StatChangeIndicator/StatChangeIndicator';

export function calculateChange(
  actualPeriodValue: number | undefined,
  comparisonPeriodValue: number | undefined,
  shouldShowChange: boolean,
  isMoreBetter: boolean,
  valueFormatter: (string: string) => string
): StatChangeIndicatorProps | undefined {
  if (actualPeriodValue === undefined || comparisonPeriodValue === undefined) return undefined;

  const diff = actualPeriodValue - comparisonPeriodValue;

  if (shouldShowChange && !Number.isNaN(diff)) {
    const isPositive = diff > 0 ? isMoreBetter : diff < 0 ? !isMoreBetter : undefined;

    return {
      value: valueFormatter(Math.abs(diff).toString()),
      change: diff > 0 ? ('increase' as const) : diff < 0 ? ('decrease' as const) : undefined,
      isPositive: isPositive
    };
  }

  // Return undefined if conditions are not met
  return undefined;
}

export type ChargebackGroupType = 'gross' | 'net' | 'waived/approved' | 'pending';

export const getFilterByChargebackGroupType = (type: ChargebackGroupType) => {
  const waivedOrApproved = [
    'Reversed',
    'Grace Period - Notification Only',
    'Grace Waived - Notification Only',
    'Waived',
    'Waived - Notification Only',
    'Dispute Approved'
  ].map((x) => x.toLowerCase());

  const pending = ['In Dispute', 'Disputed Needs More Info'].map((x) => x.toLowerCase());

  return <T extends { status?: string | null }>(item: T) => {
    const status = (item.status || '').toLowerCase();

    if (type === 'gross') return true;
    if (type === 'net') return !waivedOrApproved.includes(status);
    if (type === 'waived/approved') return waivedOrApproved.includes(status);
    if (type === 'pending') return pending.includes(status);

    return true;
  };
};
