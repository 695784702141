import React, { forwardRef } from 'react';
import { Checkbox as AntCheckbox, CheckboxProps, CheckboxRef } from 'ant5';

const AlloyCheckbox = forwardRef<CheckboxRef, CheckboxProps>(({ ...otherProps }, ref) => {
  return <AntCheckbox {...otherProps} ref={ref} />;
}) as React.ForwardRefExoticComponent<CheckboxProps & React.RefAttributes<CheckboxRef>> & {
  Group: typeof AntCheckbox.Group;
};

if (process.env.NODE_ENV !== 'production') {
  AlloyCheckbox.displayName = 'AlloyCheckbox';
}

AlloyCheckbox.Group = AntCheckbox.Group;

export { AlloyCheckbox };
export type { CheckboxProps, CheckboxRef };
