import React from 'react';
import s from './ColumnSplitTooltip.module.scss';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';

interface ColumnSplitTooltipProps {
  items: string[] | undefined;
  splitToLines?: boolean;
  loading?: boolean;
}

export const ColumnSplitTooltip = ({ items, loading, splitToLines }: ColumnSplitTooltipProps) => {
  const getItem = (key: string, text: string, last: boolean) =>
    splitToLines ? (
      <div key={key}>{text}</div>
    ) : (
      <span key={key}>
        {text}
        {!last ? ', ' : ''}
      </span>
    );

  return (
    <>
      {items && items?.length > 0 ? (
        items.length > 1 ? (
          <AlloyTooltip
            overlayStyle={{ maxWidth: '500px' }}
            placement="topLeft"
            title={
              !loading ? (
                items.length < 10 ? (
                  items.map((rdd, index) =>
                    index > 0 ? getItem(`${rdd}_${index}`, rdd, index === items.length - 1) : null
                  )
                ) : (
                  items
                    .slice(0, 10)
                    .map((rdd, index) =>
                      getItem(`${rdd}_${index}`, rdd, index === items.length - 1)
                    )
                )
              ) : (
                <AlloySpin />
              )
            }
          >
            {items.slice(0, 1)} <span className={s.splitTooltip}>+{items.length - 1}</span>
          </AlloyTooltip>
        ) : (
          items.slice(0, 1)
        )
      ) : (
        ''
      )}
    </>
  );
};
