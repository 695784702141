export const CATALOG_PRODUCT_ASSORTMENT = 'schip_assortment_v3_management_for_tp';
export const PO_DETAILS_DSD_CONDITIONAL_COLUMNS = 'schip_po_details_dsd_conditional_columns'; //This is specific to TP - should be a permanent FF until we no longer need it

// Added: Aug 6 2024
// This FF is used to switch on/off using new queries for the PO table and the PO summary on the PO page (new query provides new search abilities and has a lot of changes in filters)
export const PO_PAGE_NEW_SEARCH_FF = 'schip_po_page_new_search';

// Used to hide the entire Event Tracker feature, added 8.15.2024
export const FE_EVENT_TRACKER = 'fe_events_tracker';

// Forecast Planning, added: 1.10.2024
export const FE_FORECAST_PLANNING_MENU_ENABLED = 'fe_forecast_planning_menu';

// Moved here at 03.10.2024
export const FE_STO_DASHBOARD = 'fe_sto_dashboard';
export const FE_DEMAND_VISIBILITY = 'fe_demand_visibility';

// 20.08.2024
export const FE_PRE_SOURCE = 'fe_pre_source';

// January 2024
export const RECONCILIATION_FF = 'fe_reconciliation_phase_two';
// April 2024
export const RECONCILIATION_FF_BUTTON = 'fe_reconciliation_phase_two_reconcile_button_visible';

// Januray 2024
export const FE_INVENTORY_OPTIMIZATION = 'fe_inventory_optimisation';

// May 2024
export const DC_FACILITY_MANAGEMENT_FF = 'schip_dc_facility_management';

// October 16, 2024
export const WHSE_ORDER_PROCESSING_PAGE = 'schip_whse_order_processing_page';

// November 15, 2024
export const WHSE_TRUCK_BUILDER_PAGE = 'schip_whse_truck_builder_page';

// November 20, 2024
export const FE_CHARGEBACKS = 'schip_chargebacks';
