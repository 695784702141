import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import s from './IntegrationsTab.module.scss';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloySwitch } from 'components/ui/AlloySwitch/AlloySwitch';
import { AlloyTable } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCollapse } from 'components/ui/AlloyCollapse/AlloyCollapse';
import { useEnumValue } from 'common/useEnumValue';
import { AvailableCarrierCodes } from 'common/constants';
import {
  composeValidators,
  parseIntValue,
  validateEmail,
  validateEmails,
  validateRequired
} from 'common/helpers/validationHelper';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { ChannelAdvisorProfilesDocument } from 'pages/TradingPartnersPage/gql/__generated__/channelAdvisorProfiles.query';
import { useForm } from 'react-final-form';
import { Condition } from '../../../../AssortmentPage/components/EditTradingPartnerAssortment/components/FormFieldComponent/FormFieldComponent';
import { DeleteOutlined } from '@ant-design/icons';
import { FieldArray } from 'react-final-form-arrays';
import { TradingPartnerDefaultConfigsDocument } from 'pages/TradingPartnersPage/gql/__generated__/tradingPartnerDefaultConfigs.query';
import { FormApi } from 'final-form';
import { TradingPartner } from '../../TradingPartnerViewDrawer/TradingPartnerViewDrawer';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';

interface IntegrationsTabProps {
  tradingPartner?: TradingPartner;
  setUseConfig: (integrationName: string | undefined, value: boolean, form: FormApi) => void;
  upserting: boolean;
}

const columns = [
  {
    title: 'Type',
    dataIndex: 'title'
  },
  AlloyTable.EXPAND_COLUMN
];

export const IntegrationsTab = ({
  tradingPartner,
  setUseConfig,
  upserting
}: IntegrationsTabProps) => {
  const { data, loading } = useQuery(ChannelAdvisorProfilesDocument, {});

  const { data: defaultConfigsData } = useQuery(TradingPartnerDefaultConfigsDocument);

  const form = useForm();
  const { enumValues: ochConfigRegionValues, loading: ochConfigRegionValuesLoading } =
    useEnumValue('OchRegion');

  const { enumValues: unitOfMeasureValues, loading: unitOfMeasureValuesLoading } =
    useEnumValue('UnitOfMeasure');

  const { enumValues: productIdQualifierValues, loading: productIdQualifierValuesLoading } =
    useEnumValue('ProductIdQualifier');

  const {
    enumValues: internalProductIdQualifierValues,
    loading: internalProductIdQualifierValuesLoading
  } = useEnumValue('InternalProductIdQualifier');

  const {
    enumValues: ctt01TargetSegmentCountValues,
    loading: ctt01TargetSegmentCountValuesLoading
  } = useEnumValue('Edi856Ctt01TargetSegmentCountOptions');

  const {
    enumValues: isaInterchangeUsageIndicatorValues,
    loading: isaInterchangeUsageIndicatorValuesLoading
  } = useEnumValue('IsaInterchangeUsageIndicator');

  const { enumValues: ediFormatValues, loading: ediFormatValuesLoading } =
    useEnumValue('EdiFormat');

  const { enumValues: shippingSlaValues, loading: shippingSlaValuesLoading } =
    useEnumValue('ShippingSla');

  const {
    enumValues: invoiceConfigTermsOfSaleBasisDateCodeValues,
    loading: invoiceConfigTermsOfSaleBasisDateCodeValuesLoading
  } = useEnumValue('InvoiceTermsOfSaleBasisDateCode');

  const {
    enumValues: invoiceConfigTermsOfSalePaymentTypeValues,
    loading: invoiceConfigTermsOfSalePaymentTypeValuesLoading
  } = useEnumValue('InvoiceTermsOfSalePaymentType');

  const [integrations, setIntegration] = useState<{
    [name: string]: {
      description: string;
      show: boolean;
      children?: { [name: string]: { description: string; show: boolean } };
    };
  }>({
    channelAdvisorConfig: {
      description: 'Channel Advisor Config',
      show: !!tradingPartner?.channelAdvisorConfig
    },
    ochConfig: {
      description: 'OCH Config',
      show: !!tradingPartner?.ochConfig
    },
    pepdirectConfig: {
      description: 'PEPDirect Config',
      show: !!tradingPartner?.pepdirectConfig
    },
    shippingConfig: {
      description: 'Shipping Config',
      show: !!tradingPartner?.shippingConfig
    },
    ediMessageConfiguration: {
      description: 'EDI message config',
      show: !!tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig,
      children: {
        purchaseOrderCustomization850: {
          description: 'Purchase Order (850)',
          show: !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi850Config
        },
        purchaseOrderAckCustomization855: {
          description: 'Purchase Order Acknowledgements (855)',
          show: !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
        },
        purchaseOrderCustomization856: {
          description: 'ASN (856)',
          show: !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
        },
        purchaseOrderCustomization810: {
          description: 'Invoice (810)',
          show: !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
        }
      }
    },
    ediConfigExternal: {
      description: 'EDI Config External',
      show: !!tradingPartner?.ediConfigExternal
    },
    ediConfigInternal: {
      description: 'EDI Config Internal ',
      show: !!tradingPartner?.ediConfigInternal
    },
    emailConfigSalesOrderExport: {
      description: 'Email Config Sales Order Export',
      show: !!tradingPartner?.emailConfigSalesOrderExport
    },
    emailConfigSalesOrderRelease: {
      description: 'Email Config Sales Order Release',
      show: !!tradingPartner?.emailConfigSalesOrderRelease
    },
    internalAlertConfig: {
      description: 'Internal Alert Config',
      show: !!tradingPartner?.internalAlertConfig
    },
    internalAlertEmailConfig: {
      description: 'Internal Alert Email Config',
      show: !!tradingPartner?.internalAlertEmailConfig
    },
    amazonSellingPartnerConfig: {
      description: 'Amazon Selling Partner Config',
      show: !!tradingPartner?.amazonSellingPartnerConfig
    },
    samsClubConfig: {
      description: "Sam's Club Config",
      show: !!tradingPartner?.samsClubConfig
    },
    invoiceConfig: {
      description: 'Invoice Config',
      show: !!tradingPartner?.invoiceConfig
    }
  });

  const shippingConfigForm = (
    <AlloyRow gutter={8}>
      <AlloyCol span={12}>
        <AlloyFormField
          component="switch"
          data-testid="tp-shipping-config-routing-enabled-switch"
          name="shippingConfigRoutingEnabled"
          fieldProps={{
            title: 'Routing Enabled',
            className: s.custom_switch
          }}
        />
        <AlloyFormField
          component="inputNumber"
          data-testid="tp-shipping-config-minimal-pickup-days-number-input"
          name="shippingConfigMinimalPickupDays"
          fieldProps={{
            title: 'Minimal Pickup Days',
            parser: parseIntValue
          }}
          required
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="switch"
          data-testid="tp-shipping-config-tendering-enabled-switch"
          name="shippingConfigTenderingEnabled"
          fieldProps={{
            title: 'Tendering Enabled',
            className: s.custom_switch
          }}
        />
        <AlloyFormField
          component="inputNumber"
          data-testid="tp-shipping-config-maximal-pickup-days-number-input"
          name="shippingConfigMaximalPickupDays"
          fieldProps={{
            title: 'Maximal Pickup Days',
            parser: parseIntValue
          }}
          required
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="select"
          data-test-id="tp-shipping-config-sla-select"
          name="shippingConfigSla"
          fieldProps={{
            loading: shippingSlaValuesLoading,
            title: 'Shipping SLA',
            options: (shippingSlaValues || []).map((sla) => ({
              value: sla,
              label: sla
            }))
          }}
        />
      </AlloyCol>
      <AlloyCol span={24}>
        <AlloyFormField
          component="select"
          data-testid="tp-shipping-config-available-carrier-codes-select"
          name="shippingConfigAvailableCarrierCodes"
          fieldProps={{
            title: 'Available Carrier Codes',
            options: Object.keys(AvailableCarrierCodes).map((code) => ({
              value: code,
              label: code
            })),
            mode: 'tags',
            showArrow: false
          }}
        />
      </AlloyCol>
    </AlloyRow>
  );

  const validateIfSelected = (integrationName: string, validator: any) => {
    return integrations[integrationName]?.show && !upserting ? validator : undefined;
  };

  const ochConfigForm = (
    <AlloyRow gutter={8}>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-och-config-scope-input"
          name="ochConfigScope"
          fieldProps={{
            title: 'Scope'
          }}
          required
          validate={validateIfSelected('ochConfig', validateRequired)}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="inputNumber"
          data-testid="tp-och-config-source-system-id-number-input"
          name="ochConfigSourceSystemId"
          fieldProps={{
            title: 'Source System ID',
            parser: parseIntValue
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="select"
          data-testid="tp-och-config-region-select"
          name="ochConfigRegion"
          fieldProps={{
            loading: ochConfigRegionValuesLoading,
            title: 'Region',
            options: (ochConfigRegionValues || []).map((region: string) => ({
              value: region,
              label: region
            }))
          }}
        />
      </AlloyCol>
    </AlloyRow>
  );

  const channelAdvisorConfigForm = (
    <AlloyRow gutter={8}>
      <AlloyCol span={12}>
        <AlloyFormField
          component="inputNumber"
          data-testid="tp-channel-advisor-config-site-id-number-input"
          name="channelAdvisorConfigSiteId"
          fieldProps={{
            title: 'Site ID',
            parser: parseIntValue
          }}
          required
          validate={validateIfSelected('channelAdvisorConfig', validateRequired)}
        />
        <AlloyFormField
          component="select"
          data-testid="tp-channel-advisor-config-profile-id-select"
          name="channelAdvisorConfigProfileId"
          fieldProps={{
            loading: loading,
            title: 'Profile ID',
            options: (data?.channelAdvisorProfiles || []).map(({ accountName, profileId }) => ({
              value: String(profileId), //NOTE: this shows undefined here, as in staging, but do we want this?
              label: String(accountName)
            }))
          }}
          required
          validate={validateIfSelected('channelAdvisorConfig', validateRequired)}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-channel-advisor-config-special-instructions-prefix-input"
          name="channelAdvisorConfigSpecialInstructionsPrefix"
          fieldProps={{
            title: 'Special Instructions Prefix'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-channel-advisor-config-site-name-input"
          name="channelAdvisorConfigSiteName"
          fieldProps={{
            title: 'Site Name'
          }}
          required
          validate={validateIfSelected('channelAdvisorConfig', validateRequired)}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-channel-advisor-config-private-notes-input"
          name="channelAdvisorConfigPrivateNotes"
          fieldProps={{
            title: 'Private Notes'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-channel-advisor-config-special-instructions-postfix-input"
          name="channelAdvisorConfigSpecialInstructionsPostfix"
          fieldProps={{
            title: 'Special Instructions Postfix'
          }}
        />
      </AlloyCol>
    </AlloyRow>
  );

  const pepdirectConfigForm = (
    <div>
      <AlloyFormField
        component="input"
        data-testid="tp-pepdirect-config-apple-id-input"
        name="pepdirectConfigAppId"
        fieldProps={{
          title: 'App ID'
        }}
        required
        validate={validateIfSelected('pepdirectConfig', validateRequired)}
      />
    </div>
  );

  const emailSettings = (configName: string) => (
    <AlloyRow gutter={8}>
      <AlloyCol span={24}>
        <AlloyFormField
          component="switch"
          data-testid="tp-email-settings-switch"
          name={`${configName}Active`}
          fieldProps={{
            title: 'Active',
            className: s.custom_switch
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-email-settings-email-from-address-input"
          name={`${configName}EmailFromAddress`}
          fieldProps={{
            title: 'Email From Address'
          }}
          required
          validate={validateIfSelected(
            configName,
            composeValidators([validateRequired, validateEmail])
          )}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-email-settings-email-from-name-input"
          name={`${configName}EmailFromName`}
          fieldProps={{
            title: 'Email From Name'
          }}
        />
      </AlloyCol>
      <AlloyCol span={24}>
        <AlloyFormField
          component="select"
          data-testid="tp-email-settings-email-to-list-select"
          name={`${configName}EmailToList`}
          fieldProps={{
            title: 'Email To List',
            maxTagCount: undefined,
            options: [],
            open: false,
            showArrow: false,
            mode: 'tags'
          }}
          validate={validateEmails}
        />
      </AlloyCol>
    </AlloyRow>
  );

  const emailConfigSalesOrderRelease = emailSettings('emailConfigSalesOrderRelease');

  const emailConfigSalesOrderExport = emailSettings('emailConfigSalesOrderExport');

  const conditionalFields = (integrationName: string, prefix: string) => {
    const customSelectList: { value: number; label: string; disabled: boolean }[] = [];

    for (let i = 1; i <= 20; i++) {
      customSelectList.push({
        value: i,
        label: `${prefix}${i}`,
        disabled: (form.getFieldState(integrationName)?.value || [])
          .map(
            (customElement: { position: number; inputTextNull: boolean; inputText: string }) =>
              customElement?.position
          )
          .some((element1: number) => i === element1)
      });
    }

    return (
      <FieldArray name={`${integrationName}`}>
        {({ fields }) => (
          <>
            <AlloyCol span={12}>
              <AlloyButton
                data-testid="integration-edi-custom-add-button"
                className={s.integration_edi_custom_add_button}
                onClick={() => fields.push(undefined)}
                disabled={form.getFieldState(`${integrationName}Switch`)?.value === true}
              >
                Add Option
              </AlloyButton>
            </AlloyCol>
            <Condition when={`${integrationName}Switch`} is={false}>
              <AlloyCol span={24}>
                {fields.map((name, index) => (
                  <AlloyRow key={index} gutter={10}>
                    <AlloyCol span={10}>
                      <AlloyFormField
                        component="select"
                        data-testid="tp-conditional-fields-position-select"
                        name={`${name}.position`}
                        fieldProps={{
                          loading: loading,
                          title: 'Position',
                          options: customSelectList,
                          filterSort: undefined
                        }}
                      />
                    </AlloyCol>
                    <AlloyCol span={10}>
                      <AlloyFormField
                        component="input"
                        data-testid="tp-conditional-fields-input-input"
                        key={
                          `${name}.inputText` + form.getFieldState(`${name}.inputTextNull`)?.value
                        }
                        name={`${name}.inputText`}
                        fieldProps={{
                          title: 'Input',
                          disabled:
                            !form.getFieldState(`${name}.position`)?.value ||
                            form.getFieldState(`${name}.inputTextNull`)?.value
                        }}
                        required={!form.getFieldState(`${name}.inputTextNull`)?.value}
                      />
                    </AlloyCol>
                    <AlloyCol span={2}>
                      <AlloyFormField
                        component="checkbox"
                        data-testid="tp-conditional-fields-empty-checkbox"
                        name={`${name}.inputTextNull`}
                        fieldProps={{
                          title: 'Empty',
                          className: s.integration_edi_custom_checkbox,
                          disabled: !form.getFieldState(`${name}.position`)?.value
                        }}
                        hideInfo="WITH_MARGING"
                      />
                    </AlloyCol>
                    <AlloyCol span={2}>
                      <AlloyButton
                        data-testid="integration-edi-custom-delete-button"
                        className={s.integration_edi_custom_delete_button}
                        icon={<DeleteOutlined />}
                        onClick={() => fields.remove(index)}
                      />
                    </AlloyCol>
                  </AlloyRow>
                ))}
              </AlloyCol>
            </Condition>
          </>
        )}
      </FieldArray>
    );
  };

  const ediMsgConfig = () => {
    const renderChildren = (integration: string) => {
      switch (integration) {
        case 'purchaseOrderCustomization850':
          return integrations.ediMessageConfiguration.children &&
            integrations.ediMessageConfiguration.children['purchaseOrderCustomization850'].show
            ? purchaseOrderCustomization850Config
            : null;
        case 'purchaseOrderAckCustomization855':
          return integrations.ediMessageConfiguration.children &&
            integrations.ediMessageConfiguration?.children['purchaseOrderAckCustomization855']?.show
            ? purchaseOrderAckCustomization855Config
            : null;
        case 'purchaseOrderCustomization856':
          return integrations.ediMessageConfiguration.children &&
            integrations.ediMessageConfiguration?.children['purchaseOrderCustomization856']?.show
            ? purchaseOrderCustomization856Config
            : null;
        case 'purchaseOrderCustomization810':
          return integrations.ediMessageConfiguration.children &&
            integrations.ediMessageConfiguration?.children['purchaseOrderCustomization810']?.show
            ? purchaseOrderCustomization810Config
            : null;
        default:
          return null;
      }
    };

    const integrationChildrenKeys =
      integrations?.ediMessageConfiguration?.children &&
      Object.keys(integrations?.ediMessageConfiguration?.children);

    return (
      <>
        <AlloyRow className={s.title_row}>Global settings</AlloyRow>
        <AlloyRow gutter={8}>
          <AlloyCol span={12}>
            <AlloyFormField
              component="select"
              data-testid={'ediGlobalConfigExtQualifier'}
              name={'ediMessageConfigurationExternalProductId'}
              fieldProps={{
                loading: productIdQualifierValuesLoading,
                title: 'External Product ID qualifier',
                options: (productIdQualifierValues || []).map((qualifier: string) => ({
                  value: qualifier,
                  label: qualifier
                }))
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigGsLocalCode'}
              name={'ediMessageConfigurationGsLocalCode'}
              fieldProps={{
                title: 'GS Local Code'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigIsaLocalId'}
              name={'ediMessageConfigurationIsaLocalId'}
              fieldProps={{
                title: 'ISA Local ID'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigIsaRemoteId'}
              name={'ediMessageConfigurationIsaRemoteId'}
              fieldProps={{
                title: 'ISA Remote ID'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigIsaComponentSeparator'}
              name={'ediMessageConfigurationIsaComponentElementSeparator'}
              fieldProps={{
                title: 'ISA Component Element Separator'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigIsaRepetitionSeparator'}
              name={'ediMessageConfigurationIsaRepetitionSeparator'}
              fieldProps={{
                title: 'ISA Repetition Separator'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigSupplierId'}
              name={'ediMessageConfigurationSupplierId'}
              fieldProps={{
                title: 'Supplier ID'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="select"
              data-testid={'ediGlobalConfigFormat'}
              name={'ediMessageConfigurationFormat'}
              fieldProps={{
                loading: ediFormatValuesLoading,
                title: 'Format',
                options: (ediFormatValues || []).map((format) => ({
                  value: format,
                  label: format
                }))
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
          </AlloyCol>
          <AlloyCol span={12}>
            <AlloyFormField
              component="select"
              data-testid={'ediGlobalConfigIntQualifier'}
              name={'ediMessageConfigurationInternalProductId'}
              fieldProps={{
                loading: internalProductIdQualifierValuesLoading,
                title: 'Internal Product ID qualifier',
                options: (internalProductIdQualifierValues || []).map((qualifier) => ({
                  value: qualifier,
                  label: qualifier
                }))
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigGsRemoteCode'}
              name={'ediMessageConfigurationGsRemoteCode'}
              fieldProps={{
                title: 'GS Remote Code'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigIsaLocalQualifier'}
              name={'ediMessageConfigurationIsaLocalQualifier'}
              fieldProps={{
                title: 'ISA Local Qualifier'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigIsaRemoteQualifier'}
              name={'ediMessageConfigurationIsaRemoteQualifier'}
              fieldProps={{
                title: 'ISA Remote Qualifier'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigIsaIntControlVer'}
              name={'ediMessageConfigurationIsaInterchangeControlVersion'}
              fieldProps={{
                title: 'ISA Interchange Control Version Number'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="select"
              data-testid={'ediGlobalConfigIsaIntUsageInd'}
              name={'ediMessageConfigurationIsaInterchangeUsageIndicator'}
              fieldProps={{
                loading: isaInterchangeUsageIndicatorValuesLoading,
                title: 'Interchange Usage Indicator',
                options: (isaInterchangeUsageIndicatorValues || []).map((indicator) => ({
                  value: indicator,
                  label: indicator
                }))
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigSupplierIdPath'}
              name={'ediMessageConfigurationSupplierIdPath'}
              fieldProps={{
                title: 'Supplier ID Path'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <AlloyFormField
              component="input"
              data-testid={'ediGlobalConfigWebhookEndpoint'}
              name={'ediMessageConfigurationWebhookEndpoint'}
              fieldProps={{
                title: 'Webhook Endpoint'
              }}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
          </AlloyCol>
        </AlloyRow>
        {integrationChildrenKeys ? (
          <AlloyCollapse
            bordered={false}
            expandIconPosition={'end'}
            className={s.edi_message_config_panel}
            defaultActiveKey={integrationChildrenKeys.filter(
              (integrationName) =>
                integrations.ediMessageConfiguration.children &&
                integrations.ediMessageConfiguration.children[integrationName].show
            )}
            expandIcon={({ isActive, header }) => (
              <AlloySwitch
                checked={isActive}
                onChange={(checked) => {
                  const targetKey =
                    integrationChildrenKeys.find(
                      (integrationName) =>
                        integrations.ediMessageConfiguration.children &&
                        integrations.ediMessageConfiguration.children[integrationName]
                          .description === header
                    ) || '';
                  integrations.ediMessageConfiguration.children &&
                    setIntegration({
                      ...integrations,
                      ediMessageConfiguration: {
                        ...integrations.ediMessageConfiguration,
                        children: {
                          ...integrations.ediMessageConfiguration?.children,
                          [targetKey]: {
                            ...integrations.ediMessageConfiguration?.children[targetKey],
                            show: checked
                          }
                        }
                      }
                    });
                  setUseConfig(targetKey, checked, form);
                }}
              />
            )}
          >
            {integrationChildrenKeys &&
              integrationChildrenKeys.map((integration) => (
                <AlloyCollapse.Panel
                  key={integration}
                  header={
                    integrations.ediMessageConfiguration?.children &&
                    integrations.ediMessageConfiguration.children[integration].description
                  }
                  className={s.ediMessageConfiguration_panel}
                >
                  {renderChildren(integration)}
                </AlloyCollapse.Panel>
              ))}
          </AlloyCollapse>
        ) : null}
      </>
    );
  };

  const purchaseOrderCustomization850Config = (
    <>
      <AlloyRow className={s.title_row}>EDI customizations</AlloyRow>
      <AlloyRow gutter={8}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            data-testid={'edi850ConfigShipTo'}
            name={'purchaseOrderCustomization850ConfigShipTo'}
            fieldProps={{
              title: 'Ship-To/Supplier info segment'
            }}
          />
        </AlloyCol>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi850ConfigProductUnitMeasurement'}
            name={'purchaseOrderCustomization850ConfigProductUnit'}
            fieldProps={{
              loading: unitOfMeasureValuesLoading,
              title: 'Product unit of measurement',
              options: (unitOfMeasureValues || []).map((unit: string) => ({
                value: unit,
                label: unit
              }))
            }}
            required
            validate={validateIfSelected('purchaseOrderCustomization850', validateRequired)}
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyRow className={s.title_row}>Business logic</AlloyRow>
      <AlloyRow gutter={8}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi850ConfigPassUpcToBu'}
            name={'purchaseOrderCustomization850ConfigPassUpc'}
            fieldProps={{
              loading: loading,
              title: 'Pass UPC through to BU',
              options: [
                { value: JSON.parse('true'), label: 'Yes' },
                { value: JSON.parse('false'), label: 'No' }
              ]
            }}
            required
            validate={validateIfSelected('purchaseOrderCustomization850', validateRequired)}
          />
        </AlloyCol>
      </AlloyRow>
    </>
  );

  const purchaseOrderAckCustomization855Config = (
    <>
      <AlloyRow className={s.title_row}>EDI customizations</AlloyRow>
      <AlloyRow gutter={8}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi855ConfigAdditionalItemsSwitch'}
            name={'purchaseOrderAckCustomization855ConfigCustomSwitch'}
            fieldProps={{
              loading: loading,
              title: 'PO1 - Additional item(s) added',
              options: [
                { value: JSON.parse('true'), label: 'Ignore' },
                { value: JSON.parse('false'), label: 'Custom' }
              ],
              allowClear: false
            }}
          />
        </AlloyCol>
        {/* TODO: broken Add Option button that should be disabled */}
        {conditionalFields('purchaseOrderAckCustomization855ConfigCustom', 'PO10')}

        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            data-testid={'edi855ConfigItemNotAvailable'}
            name="purchaseOrderAckCustomization855ConfigItemNotAvailInput"
            fieldProps={{
              title: 'MTX02 Item not available'
            }}
          />
        </AlloyCol>
        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            data-testid={'edi855ConfigItemInvalid'}
            name="purchaseOrderAckCustomization855ConfigItemInvalidInput"
            fieldProps={{
              title: 'MTX02 Item invalid/rejected'
            }}
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyRow className={s.title_row}>Business Logic</AlloyRow>
      <AlloyRow>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi855ConfigMatchOrig850'}
            name="purchaseOrderAckCustomization855ConfigMatch"
            fieldProps={{
              loading: loading,
              title: 'Match PO101 sequence to original 850',
              options: [
                { value: JSON.parse('true'), label: 'Yes' },
                { value: JSON.parse('false'), label: 'No' }
              ]
            }}
            required
            validate={validateIfSelected('purchaseOrderAckCustomization855', validateRequired)}
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyRow>
        <AlloyCol span={24}>
          <AlloyFormField
            component="checkbox"
            data-testid="tp-po-ack-customization-855-config-release-document-checkbox"
            name="purchaseOrderAckCustomization855ConfigReleaseDocument"
            fieldProps={{
              title: 'Release document to Trading Partner'
            }}
            inline="after"
          />
        </AlloyCol>
      </AlloyRow>
    </>
  );

  const purchaseOrderCustomization856Config = (
    <>
      <AlloyRow className={s.title_row}>EDI customizations</AlloyRow>
      <AlloyRow gutter={8}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi856ConfigAdditionalItemsSwitch'}
            name={'purchaseOrderCustomization856ConfigCustomSwitch'}
            fieldProps={{
              loading: loading,
              title: 'LIN - Additional item(s) added',
              options: [
                { value: JSON.parse('true'), label: 'Ignore' },
                { value: JSON.parse('false'), label: 'Custom' }
              ],
              allowClear: false
            }}
          />
        </AlloyCol>

        {/* TODO: broken Add Option button that should be disabled */}
        {conditionalFields('purchaseOrderCustomization856ConfigCustom', 'LIN0')}
      </AlloyRow>

      <AlloyRow className={s.title_row}>Business Logic</AlloyRow>
      <AlloyRow gutter={8}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi856ConfigMatchToOrigBu'}
            name="purchaseOrderCustomization856ConfigMatch"
            fieldProps={{
              loading: loading,
              title: 'Match LIN01 Sequence to original 850',
              options: [
                { value: JSON.parse('true'), label: 'Yes' },
                { value: JSON.parse('false'), label: 'No' }
              ]
            }}
            required
            validate={validateIfSelected('purchaseOrderCustomization856', validateRequired)}
          />
        </AlloyCol>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi856ConfigPassShipToFromBu'}
            name="purchaseOrderCustomization856ConfigPassShipTo"
            fieldProps={{
              loading: loading,
              title: 'Pass through Ship-to from BU',
              options: [
                { value: JSON.parse('true'), label: 'Yes' },
                { value: JSON.parse('false'), label: 'No' }
              ]
            }}
            required
            validate={validateIfSelected('purchaseOrderCustomization856', validateRequired)}
          />
        </AlloyCol>
        <AlloyCol span={24}>
          <AlloyFormField
            component="checkbox"
            data-testid="tp-po-customization-856-config-release-document-checkbox"
            name="purchaseOrderCustomization856ConfigReleaseDocument"
            fieldProps={{
              title: 'Release document to Trading Partner'
            }}
            inline="after"
          />
        </AlloyCol>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi856ConfigPassShipToFromBu'}
            name="purchaseOrderCustomization856ConfigCttCountMatch"
            fieldProps={{
              loading: ctt01TargetSegmentCountValuesLoading,
              title: 'CTT01 Number of Line Items Count Match',
              options: (ctt01TargetSegmentCountValues || []).map((segment) => ({
                value: segment,
                label: segment
              }))
            }}
            required
            validate={validateIfSelected('purchaseOrderCustomization856', validateRequired)}
          />
        </AlloyCol>
      </AlloyRow>
    </>
  );

  const purchaseOrderCustomization810Config = (
    <>
      <AlloyRow className={s.title_row}>EDI customizations</AlloyRow>
      <AlloyRow gutter={8}>
        <AlloyCol span={12}>
          {/* TODO: fix this optional text issue */}
          <AlloyFormField
            component="input"
            data-testid={'edi810ConfigRecName'}
            key={
              'purchaseOrderCustomization810ConfigResName' +
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
            name="purchaseOrderCustomization810ConfigResName"
            fieldProps={{
              title: 'N1 Remittance: Recipient Name'
            }}
            required={
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            } //TODO: showing as (Optional)
          />
        </AlloyCol>
        <AlloyCol span={12}>
          {/* TODO: fix this optional text issue */}
          <AlloyFormField
            component="input"
            data-testid={'edi810ConfigAddress'}
            key={
              'purchaseOrderCustomization810ConfigResAddress' +
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
            name="purchaseOrderCustomization810ConfigResAddress"
            fieldProps={{
              title: 'N3 Remittance: Address'
            }}
            required={
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            } //TODO: showing as (Optional)
          />
        </AlloyCol>
        <AlloyCol span={12}>
          {/* TODO: fix this optional text issue */}
          <AlloyFormField
            component="input"
            data-testid={'edi810ConfigCityZipCountry'}
            key={
              'purchaseOrderCustomization810ConfigResCityZip' +
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
            name="purchaseOrderCustomization810ConfigResCityZip"
            fieldProps={{
              title: 'N4 Remittance: City/State/Zip/Country'
            }}
            required={
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            } //TODO: showing as (Optional) and wrapping line
          />
        </AlloyCol>
      </AlloyRow>
      <AlloyRow gutter={8}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi810ConfigAdditionalItemsSwitch'}
            name={'purchaseOrderCustomization810ConfigCustomSwitch'}
            fieldProps={{
              loading: loading,
              title: 'IT1 - Additional Item(s) added',
              options: [
                { value: JSON.parse('true'), label: 'Ignore' },
                { value: JSON.parse('false'), label: 'Custom' }
              ],
              allowClear: false
            }}
          />
        </AlloyCol>

        {/* TODO: broken Add Option button that should be disabled */}
        {conditionalFields('purchaseOrderCustomization810ConfigCustom', 'IT10')}
      </AlloyRow>

      <AlloyRow gutter={8}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi810ConfigMatchOrig850'}
            name="purchaseOrderCustomization810ConfigMatch"
            fieldProps={{
              loading: loading,
              title: 'Match IT101 Sequence to original 850',
              options: [
                { value: JSON.parse('false'), label: 'No' },
                { value: JSON.parse('true'), label: 'Yes' }
              ]
            }}
            required
            validate={validateIfSelected('purchaseOrderCustomization810', validateRequired)}
          />
        </AlloyCol>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi810ConfigPassAddressFromBu'}
            name="purchaseOrderCustomization810ConfigPassRemAddress"
            fieldProps={{
              loading: loading,
              title: 'Pass remittance address from BU',
              options: [
                { value: JSON.parse('false'), label: 'No' },
                { value: JSON.parse('true'), label: 'Yes' }
              ]
            }}
            required
            validate={validateIfSelected('purchaseOrderCustomization810', validateRequired)}
          />
        </AlloyCol>
        <AlloyCol span={12}>
          <AlloyFormField
            component="select"
            data-testid={'edi810ConfigPassBillToFromBu'}
            name="purchaseOrderCustomization810ConfigPassBillToAddress"
            fieldProps={{
              loading: loading,
              title: 'Pass through bill-to address from BU',
              options: [
                { value: JSON.parse('false'), label: 'No' },
                { value: JSON.parse('true'), label: 'Yes' }
              ]
            }}
            hideInfo="WITH_MARGING"
            required
            validate={validateIfSelected('purchaseOrderCustomization810', validateRequired)}
          />
          {!form.getFieldState('purchaseOrderCustomization810ConfigPassBillToAddress')?.value ? (
            <div className={s.subfield_text}>Ship-to config address will be used</div>
          ) : null}
        </AlloyCol>
      </AlloyRow>
      <AlloyRow>
        <AlloyCol span={24}>
          <AlloyFormField
            component="checkbox"
            data-testid="tp-po-customization-810-config-release-document-checkbox"
            name="purchaseOrderCustomization810ConfigReleaseDocument"
            fieldProps={{
              title: 'Release document to Trading Partner'
            }}
            inline="after"
          />
        </AlloyCol>
      </AlloyRow>
    </>
  );

  const ediConfig = (configName: string) => (
    <AlloyRow gutter={8}>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-gs-local-code-input"
          name={`${configName}GsLocalCode`}
          fieldProps={{
            title: 'GS Local Code'
          }}
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-isa-local-id-input"
          name={`${configName}IsaLocalId`}
          fieldProps={{
            title: 'ISA Local ID'
          }}
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-isa-remote-id-input"
          name={`${configName}IsaRemoteId`}
          fieldProps={{
            title: 'ISA Remote ID'
          }}
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-isa-component-element-separator-input"
          name={`${configName}IsaComponentElementSeparator`}
          fieldProps={{
            title: 'ISA Component Element Separator'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-isa-repetition-separator-input"
          name={`${configName}IsaRepetitionSeparator`}
          fieldProps={{
            title: 'ISA Repetition Separator'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-supplier-id-input"
          name={`${configName}SupplierId`}
          fieldProps={{
            title: 'Supplier ID'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-format-input"
          name={`${configName}Format`}
          fieldProps={{
            title: 'Format'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-gs-remote-code-input"
          name={`${configName}GsRemoteCode`}
          fieldProps={{
            title: 'GS Remote Code'
          }}
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-isa-local-qualifier-input"
          name={`${configName}IsaLocalQualifier`}
          fieldProps={{
            title: 'ISA Local Qualifier'
          }}
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-isa-remote-qualifier-input"
          name={`${configName}IsaRemoteQualifier`}
          fieldProps={{
            title: 'ISA Remote Qualifier'
          }}
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-isa-interchange-control-version-number-input"
          name={`${configName}IsaInterchangeControlVersionNumber`}
          fieldProps={{
            title: 'ISA Interchange Control Version Number'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-interchange-usage-indicator-input"
          name={`${configName}InterchangeUsageIndicator`}
          fieldProps={{
            title: 'Interchange Usage Indicator'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-supplier-id-path-input"
          name={`${configName}SupplierIdPath`}
          fieldProps={{
            title: 'Supplier ID Path'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-edi-config-webhook-endpoint-input"
          name={`${configName}WebhookEndpoint`}
          fieldProps={{
            title: 'Webhook Endpoint'
          }}
        />
      </AlloyCol>
    </AlloyRow>
  );

  const ediConfigExternal = ediConfig('ediConfigExternal');

  const ediConfigInternal = ediConfig('ediConfigInternal');

  const internalAlertEmailConfig = emailSettings('internalAlertEmailConfig');

  const internalAlertConfig = (
    <AlloyRow>
      <AlloyCol span={24}>
        <AlloyFormField
          component="switch"
          data-testid="tp-internal-alert-config-send-duplicate-order-items-alert-switch"
          name="internalAlertConfigSendDuplicateOrderItemsAlert"
          fieldProps={{
            title: 'Send Duplicate Order Items Alert',
            className: s.custom_switch
          }}
        />
        <AlloyFormField
          component="switch"
          data-testid="tp-internal-alert-config-send-invalid-order-items-alert-switch"
          name="internalAlertConfigSendInvalidOrderItemsAlert"
          fieldProps={{
            title: 'Send Invalid Order Items Alert',
            className: s.custom_switch
          }}
        />
        <AlloyFormField
          component="switch"
          data-testid="tp-internal-alert-config-send-late-orders-alert-switch"
          name="internalAlertConfigSendLateOrdersAlert"
          fieldProps={{
            title: 'Send Late Orders Alert',
            className: s.custom_switch
          }}
        />
      </AlloyCol>
    </AlloyRow>
  );

  const amazonSellingPartnerConfig = (
    <AlloyRow gutter={8}>
      <AlloyCol span={24}>
        <AlloyFormField
          component="input"
          data-testid="tp-amazon-selling-partner-config-access-key-id-input"
          name="amazonSellingPartnerConfigAccessKeyId"
          fieldProps={{
            title: 'Access Key ID',
            placeholder:
              defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
                ?.accessKeyId || undefined
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-amazon-selling-partner-config-client-id-input"
          name="amazonSellingPartnerConfigClientId"
          fieldProps={{
            title: 'Client ID',
            placeholder:
              defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
                ?.clientId || undefined
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-amazon-selling-partner-config-region-input"
          name="amazonSellingPartnerConfigRegion"
          fieldProps={{
            title: 'Region',
            placeholder:
              defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
                ?.region || undefined
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-amazon-selling-partner-config-write-api-site-input"
          name="amazonSellingPartnerConfigWriteApiSite"
          fieldProps={{
            title: 'Write API Site',
            placeholder:
              defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
                ?.writeApiSite || undefined
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-amazon-selling-partner-config-read-api-site-input"
          name="amazonSellingPartnerConfigReadApiSite"
          fieldProps={{
            title: 'Read API Site',
            placeholder:
              defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
                ?.readApiSite || undefined
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-amazon-selling-partner-config-access-token-site-input"
          name="amazonSellingPartnerConfigAccessTokenSite"
          fieldProps={{
            title: 'Access Token Site',
            placeholder:
              defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
                ?.accessTokenSite || undefined
          }}
        />
        <PasswordField
          title="Client Secret"
          fieldName="amazonSellingPartnerConfigClientSecret"
          editFieldName="amazonSellingPartnerConfigEditClientSecret"
          upserting={upserting}
          defaultValue={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.clientSecret
          }
        />
        <PasswordField
          title="Refresh Token"
          fieldName="amazonSellingPartnerConfigRefreshToken"
          editFieldName="amazonSellingPartnerConfigEditRefreshToken"
          upserting={upserting}
          defaultValue={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.refreshToken
          }
        />
        <PasswordField
          title="Secret Access Key"
          fieldName="amazonSellingPartnerConfigSecretAccessKey"
          editFieldName="amazonSellingPartnerConfigEditSecretAccessKey"
          upserting={upserting}
          defaultValue={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.secretAccessKey
          }
        />
      </AlloyCol>
    </AlloyRow>
  );

  const samsClubConfig = (
    <AlloyRow gutter={8}>
      <AlloyCol span={24}>
        <AlloyFormField
          component="input"
          data-testid="tp-sams-club-config-client-id-input"
          name="samsClubConfigClientId"
          fieldProps={{
            title: 'Client ID',
            placeholder:
              defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultSamsClubConfig?.clientId ||
              undefined
          }}
        />
        <PasswordField
          title="Client Secret"
          fieldName="samsClubConfigClientSecret"
          editFieldName="samsClubConfigEditClientSecret"
          upserting={upserting}
          defaultValue={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultSamsClubConfig?.clientSecret
          }
        />
      </AlloyCol>
    </AlloyRow>
  );

  const invoiceConfig = (
    <AlloyRow gutter={8}>
      {/* Bill To */}
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-bill-to-name-input"
          name="invoiceConfigBillToName"
          fieldProps={{
            title: 'Bill To Name'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-bill-to-address-input"
          name="invoiceConfigBillToAddress"
          fieldProps={{
            title: 'Bill To Address'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-bill-to-party-id-input"
          name="invoiceConfigBillToPartyId"
          fieldProps={{
            title: 'Bill To Party ID'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-bill-to-city-name-input"
          name="invoiceConfigBillToCityName"
          fieldProps={{
            title: 'Bill To City Name'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-bill-to-state-input"
          name="invoiceConfigBillToState"
          fieldProps={{
            title: 'Bill To State'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-bill-to-country-code-input"
          name="invoiceConfigBillToCountryCode"
          fieldProps={{
            title: 'Bill To Country Code'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-bill-to-postal-code-input"
          name="invoiceConfigBillToPostalCode"
          fieldProps={{
            title: 'Bill To Postal Code'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-bill-to-tax-registration-number-input"
          name="invoiceConfigBillToTaxRegistrationNumber"
          fieldProps={{
            title: 'Bill To Tax Registration Number'
          }}
        />
      </AlloyCol>

      {/* Remittance */}
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-remittance-name-input"
          name="invoiceConfigRemittanceName"
          fieldProps={{
            title: 'Remittance Name'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-remittance-address-input"
          name="invoiceConfigRemittanceAddress"
          fieldProps={{
            title: 'Remittance Address'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-remittance-party-id-input"
          name="invoiceConfigRemittancePartyId"
          fieldProps={{
            title: 'Remittance Party ID'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-remittance-city-name-input"
          name="invoiceConfigRemittanceCityName"
          fieldProps={{
            title: 'Remittance City Name'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-remittance-state-input"
          name="invoiceConfigRemittanceState"
          fieldProps={{
            title: 'Remittance State'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-remittance-country-code-input"
          name="invoiceConfigRemittanceCountryCode"
          fieldProps={{
            title: 'Remittance Country Code'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-remittance-postal-code-input"
          name="invoiceConfigRemittancePostalCode"
          fieldProps={{
            title: 'Remittance Postal Code'
          }}
        />
        <AlloyFormField
          component="input"
          data-testid="tp-invoice-config-remittance-tax-registration-number-input"
          name="invoiceConfigRemittanceTaxRegistrationNumber"
          fieldProps={{
            title: 'Remittance Tax Registration Number'
          }}
        />
      </AlloyCol>

      {/* Terms of Sale */}
      <AlloyCol span={12}>
        <AlloyFormField
          component="select"
          data-testid="tp-invoice-config-terms-of-sale-basis-date-code-select"
          name="invoiceConfigTermsOfSaleBasisDateCode"
          fieldProps={{
            loading: invoiceConfigTermsOfSaleBasisDateCodeValuesLoading,
            title: 'Terms of Sale Basis Date Code',
            options: (invoiceConfigTermsOfSaleBasisDateCodeValues || []).map(
              (termsOfSaleBasisDateCode) => ({
                value: termsOfSaleBasisDateCode,
                label: termsOfSaleBasisDateCode
              })
            )
          }}
        />
        <AlloyFormField
          component="inputNumber"
          data-testid="tp-invoice-config-terms-of-sale-discount-percent-number-input"
          name="invoiceConfigTermsOfSaleDiscountPercent"
          fieldProps={{
            title: 'Terms of Sale Discount Percent'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="inputNumber"
          data-testid="tp-invoice-config-terms-of-sale-discount-days-due-number-input"
          name="invoiceConfigTermsOfSaleDiscountDaysDue"
          fieldProps={{
            title: 'Terms of Sale Discount Days Due'
          }}
        />
        <AlloyFormField
          component="inputNumber"
          data-testid="tp-invoice-config-terms-of-sale-net-days-number-input"
          name="invoiceConfigTermsOfSaleNetDays"
          fieldProps={{
            title: 'Terms of Sale Net Days'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="select"
          data-testid="tp-invoice-config-terms-of-sale-payment-type-select"
          name="invoiceConfigTermsOfSalePaymentType"
          fieldProps={{
            loading: invoiceConfigTermsOfSalePaymentTypeValuesLoading,
            title: 'Terms of Sale Payment Type',
            options: (invoiceConfigTermsOfSalePaymentTypeValues || []).map(
              (termsOfSalePaymentType) => ({
                value: termsOfSalePaymentType,
                label: termsOfSalePaymentType
              })
            )
          }}
        />
      </AlloyCol>
    </AlloyRow>
  );

  return (
    <>
      <AlloyTable
        rowKey={(row) => row.name}
        columns={columns}
        dataSource={Object.keys(integrations).map((integration) => ({
          title: integrations[integration].description,
          name: integration
        }))}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => {
            switch (record.name) {
              case 'channelAdvisorConfig':
                return integrations['channelAdvisorConfig'].show ? channelAdvisorConfigForm : null;
              case 'ochConfig':
                return integrations['ochConfig'].show ? ochConfigForm : null;
              case 'pepdirectConfig':
                return integrations['pepdirectConfig'].show ? pepdirectConfigForm : null;
              case 'shippingConfig':
                return integrations['shippingConfig'].show ? shippingConfigForm : null;
              case 'ediMessageConfiguration':
                return integrations['ediMessageConfiguration'].show ? ediMsgConfig() : null;
              case 'ediConfigInternal':
                return integrations[record.name].show ? ediConfigInternal : null;
              case 'ediConfigExternal':
                return integrations[record.name].show ? ediConfigExternal : null;
              case 'emailConfigSalesOrderRelease':
                return integrations[record.name].show ? emailConfigSalesOrderRelease : null;
              case 'emailConfigSalesOrderExport':
                return integrations[record.name].show ? emailConfigSalesOrderExport : null;
              case 'internalAlertEmailConfig':
                return integrations[record.name].show ? internalAlertEmailConfig : null;
              case 'internalAlertConfig':
                return integrations[record.name].show ? internalAlertConfig : null;
              case 'amazonSellingPartnerConfig':
                return integrations[record.name].show ? amazonSellingPartnerConfig : null;
              case 'samsClubConfig':
                return integrations[record.name].show ? samsClubConfig : null;
              case 'invoiceConfig':
                return integrations['invoiceConfig'].show ? invoiceConfig : null;
              default:
                return null;
            }
          },
          expandedRowKeys: Object.keys(integrations).reduce<string[]>((result, integration) => {
            if (integrations[integration].show) result.push(integration);
            return result;
          }, []),
          expandIcon: ({ expanded, record }) => (
            <AlloySwitch
              checked={expanded}
              onChange={(checked) => {
                setIntegration({
                  ...integrations,
                  [record.name]: { ...integrations[record.name], show: checked }
                });
                setUseConfig(record.name, checked, form);
              }}
            />
          )
        }}
      />
    </>
  );
};
