import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { validateRequired } from 'common/helpers/validationHelper';
import React from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import s from './CustomizationForm.module.scss';
import { useEnumValue } from '@/src/common/useEnumValue';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';

export const CustomizationForm = ({ upserting }: { upserting: boolean }) => {
  const form = useForm();

  const { enumValues: fieldTypeValues, loading: fieldTypeValuesLoading } = useEnumValue(
    'CustomizationHeaderFieldType'
  );

  return (
    <div>
      <FieldArray name="customizationHeaders">
        {({ fields }) => (
          <>
            <AlloyRow className={s.customization_fields_title}>
              Customization fields
              <AlloyButton
                shape="circle"
                type="text"
                onClick={() => {
                  fields.push({ field: '', title: '', fieldType: 'TEXT' });
                }}
              >
                <PlusOutlined />
              </AlloyButton>
            </AlloyRow>
            <AlloyRow
              style={{
                display: !form.getFieldState('customizationHeaders')?.value ? 'none' : undefined
              }}
            >
              {fields.map((name, index) => (
                <div key={index} className={s.customization_field_item}>
                  <div className={s.customization_priority}>{index + 1}</div>
                  <AlloyFormField
                    component="input"
                    name={`${name}.field`}
                    fieldProps={{
                      title: 'Field'
                    }}
                    required
                    validate={upserting ? validateRequired : undefined}
                  />
                  <AlloyFormField
                    component="input"
                    name={`${name}.title`}
                    fieldProps={{
                      title: 'Title'
                    }}
                    required
                    validate={upserting ? validateRequired : undefined}
                  />

                  <AlloyFormField
                    component="select"
                    name={`${name}.fieldType`}
                    data-testid="dc-customization-type"
                    fieldProps={{
                      title: 'Type',
                      loading: fieldTypeValuesLoading,
                      options: (fieldTypeValues || []).map((type) => ({
                        value: type,
                        label: type
                      }))
                    }}
                    required
                    validate={upserting ? validateRequired : undefined}
                  />

                  <AlloyButton
                    shape="circle"
                    type="text"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <CloseOutlined />
                  </AlloyButton>
                </div>
              ))}
            </AlloyRow>
          </>
        )}
      </FieldArray>
    </div>
  );
};
