import React from 'react';
import s from './AlloyProgressBar.module.scss';
import clsx from 'clsx';

type AlloyProgressBarProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  className?: string;
  progress: number;
};

const AlloyProgressBar: React.FC<AlloyProgressBarProps> = ({ progress, className, ...rest }) => {
  const progressClamped = Math.min(100, Math.max(0, progress));

  return (
    <div {...rest} className={clsx(s.progressBar, className)}>
      <div className={s.progressBarFill} style={{ width: `${progressClamped}%` }}></div>
    </div>
  );
};

export default AlloyProgressBar;
