export function transformToTitleCase(sentence: string, exceptions: string[]): string {
  const exceptionsSet = new Set(exceptions);

  return sentence
    .split(' ')
    .map((word) => {
      // Check if the word is an exception or not fully uppercase
      if (exceptionsSet.has(word) || word !== word.toUpperCase()) {
        return word;
      }
      // Convert to 'Title Case'
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}

export const WORD_EXCEPTIONS = ['FLNA', 'PBC', 'S&F', 'RTE'];
