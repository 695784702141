import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProductForecastTableQueryVariables = Types.Exact<{
  filters: Types.ProductForecastTableFilters;
}>;


export type ProductForecastTableQuery = { __typename?: 'RootQueryType', productForecastTable: Array<{ __typename?: 'ProductForecastTableEntry', forecastName: Types.ProductForecastName, values: Array<{ __typename?: 'ProductForecastSingleWeekValue', value?: any | null, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', week: number, year: number } }> }> };


export const ProductForecastTableDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"productForecastTable"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProductForecastTableFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"productForecastTable"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forecastName"}},{"kind":"Field","name":{"kind":"Name","value":"values"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"week"}},{"kind":"Field","name":{"kind":"Name","value":"year"}}]}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]} as unknown as DocumentNode<ProductForecastTableQuery, ProductForecastTableQueryVariables>;