import React, { useMemo } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useQueryParam, withDefault, ArrayParam, StringParam } from 'use-query-params';
import s from './ForecastPlanning.module.scss';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import bookmarkAddIcon from 'assets/icons/bookmark_add.svg';
import clsx from 'clsx';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { SearchOutlined } from '@ant-design/icons';
import { ProductCard } from './components/ProductCard/ProductCard';
import { ProductDetails } from './components/ProductDetails/ProductDetails';
import { ForecastTable } from './components/ForecastTable/ForecastTable';
import { useQuery } from '@apollo/client';
import { ProductForecastScorecardDocument } from './gql/__generated__/productForecastScorecard.query';
import { getCurrentPeriod } from 'components/PeriodCalendar/helpers';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { safeNumberComparator } from 'common/helpers/comparators';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { notEmpty } from 'common/helpers/notEmpty';
import { ForecastProduct } from './types';
import { AvailableProductFiltersDocument } from './gql/__generated__/availableProductFilters.query';
import { transformToTitleCase, WORD_EXCEPTIONS } from './helpers';

const CURRENT_PERIOD = getCurrentPeriod(true);

const sortingOptions = ['By Material ID'];

const createProductFilter = (searchTerms: string[]) => {
  return (product: ForecastProduct) => {
    if (searchTerms.length === 0) return true;
    return searchTerms.some(
      (term) =>
        product.sapMaterialId.includes(term) ||
        product.asin.some((asin) => asin.includes(term)) ||
        product.productDescription.includes(term)
    );
  };
};

export const ForecastPlanning = () => {
  const filters = useQuery(AvailableProductFiltersDocument);

  const [bus, setBus] = useQueryParam('bus', withDefault(ArrayParam, ['all']));
  const [vendorCodes, setVendorCodes] = useQueryParam(
    'vendorCodes',
    withDefault(ArrayParam, ['all'])
  );
  const [brands, setBrands] = useQueryParam('brands', withDefault(ArrayParam, ['all']));
  // TODO: search on fe !!!
  const [searchTerm, setSearchTerm] = useQueryParam('search', withDefault(ArrayParam, []));
  const nonEmptySearch = searchTerm.filter(notEmpty) as string[];

  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, sortingOptions[0]));
  // TODO: select 1st automatically?
  const [selected, setSelected] = useQueryParam('selected', withDefault(StringParam, ''));

  const businessUnitsOptions = useMemo(
    () => filters.data?.availableProductFilters?.businessUnits || [],
    [filters.data?.availableProductFilters?.businessUnits]
  );
  const vendorCodesOptions = useMemo(
    () => filters.data?.availableProductFilters?.vendorCodes || [],
    [filters.data?.availableProductFilters?.vendorCodes]
  );
  const brandCodesOptions = useMemo(
    () => filters.data?.availableProductFilters?.brands || [],
    [filters.data?.availableProductFilters?.brands]
  );

  const businessUnitCodes = useDeepCompareMemo(
    // empty array = all values
    () => getValueWithAll(bus as string[], []),
    [bus]
  );

  const vendorCodeCodes = useDeepCompareMemo(
    // empty array = all values
    () => getValueWithAll(vendorCodes as string[], []),
    [vendorCodes]
  );
  const brandCodes = useDeepCompareMemo(
    // empty array = all values
    () => getValueWithAll(brands as string[], []),
    [brands]
  );

  const productCards = useQuery(ProductForecastScorecardDocument, {
    variables: {
      filters: {
        fiscalCalendarWeek: {
          year: CURRENT_PERIOD.year,
          week: CURRENT_PERIOD.week,
          period: CURRENT_PERIOD.period
        },
        brands: brandCodes,
        vendorCodes: vendorCodeCodes,
        businessUnits: businessUnitCodes
      }
    },
    // TODO: auto-select "initial" value
    onCompleted: (data) => {
      if (!selected || !data?.productForecastScorecard.find((x) => x.sapMaterialId === selected)) {
        const sortedData = (data?.productForecastScorecard || []).sort((a, b) =>
          safeNumberComparator(parseInt(a.sapMaterialId), parseInt(b.sapMaterialId))
        );
        if (sortedData.length > 0) {
          setSelected(sortedData[0].sapMaterialId);
        }
      }
    }
  });

  const allProducts = useMemo(
    () => productCards.data?.productForecastScorecard || [],
    [productCards.data?.productForecastScorecard]
  );

  const filteredProducts = useMemo(
    () =>
      (productCards.data?.productForecastScorecard || [])
        .filter(createProductFilter(nonEmptySearch))
        .sort((a, b) => safeNumberComparator(parseInt(a.sapMaterialId), parseInt(b.sapMaterialId))),
    [nonEmptySearch, productCards.data?.productForecastScorecard]
  );

  const selectedProduct = useMemo(
    () => allProducts.find((x) => x.sapMaterialId === selected),
    [allProducts, selected]
  );

  if (productCards.error) return <ErrorDisplay error={productCards.error} />;

  return (
    <div>
      <div className={s.header}>
        <PageHeader className={s.title}>Forecast</PageHeader>
        <div className={s.page_filters}>
          <AlloySelect
            loading={filters.loading}
            className={clsx(s.select, bus.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={bus}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], bus as string[], setBus);
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL BUs', value: 'all' },
              ...businessUnitsOptions.map((bu) => ({
                label: transformToTitleCase(bu, WORD_EXCEPTIONS),
                value: bu
              }))
            ]}
          />{' '}
          <AlloySelect
            loading={filters.loading}
            className={clsx(s.select, vendorCodes.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={vendorCodes}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], vendorCodes as string[], setVendorCodes);
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL Vendor Codes', value: 'all' },
              ...vendorCodesOptions.map((vendorCode) => ({
                label: vendorCode,
                value: vendorCode
              }))
            ]}
          />{' '}
          <AlloySelect
            loading={filters.loading}
            className={clsx(s.select, brands.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={brands}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], brands as string[], setBrands);
            }}
            style={{ width: '220px' }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL Brands', value: 'all' },
              ...brandCodesOptions.map((brand) => ({
                label: transformToTitleCase(brand, WORD_EXCEPTIONS),
                value: brand
              }))
            ]}
          />
          <AlloyButton size="large" icon={<img src={bookmarkAddIcon} alt="Add bookmark" />} />
          <AlloyButton size="large" type="secondary">
            Export
          </AlloyButton>
        </div>
      </div>
      <div className={s.main}>
        <AlloySpin spinning={productCards.loading}>
          <div className={s.product_list_wrapper}>
            <div className={s.inputs_wrapper}>
              <div className={s.search_wrapper}>
                <MultipleValuesInput
                  placeholder="Search"
                  value={
                    nonEmptySearch
                      ? typeof nonEmptySearch === 'string'
                        ? [nonEmptySearch]
                        : nonEmptySearch
                      : []
                  }
                  onChange={setSearchTerm}
                  allowClear={true}
                  prefix={<SearchOutlined width="14px" height="14px" />}
                  splitInputValue={/[^0-9a-zA-Z-]+/g}
                />
              </div>
              <div className={s.sorting_container}>
                <AlloySelect
                  options={sortingOptions.map((label) => ({ label, value: label }))}
                  value={sort}
                  onChange={(value) => setSort(value)}
                />
                <div className={s.amount}>{filteredProducts.length}</div>
              </div>
            </div>
            {/* TODO: Virtualize it */}
            <div className={s.product_list}>
              {filteredProducts.map((product) => (
                <ProductCard
                  key={product.sapMaterialId}
                  product={product}
                  isSelected={selected === product.sapMaterialId}
                  setSelected={setSelected}
                />
              ))}
            </div>
          </div>
        </AlloySpin>
        <div className={s.details}>
          {selectedProduct && <ProductDetails product={selectedProduct} />}
          {selectedProduct && (
            <ForecastTable sapMaterialId={selectedProduct.sapMaterialId} vendorCodes={[]} />
          )}
        </div>
      </div>
    </div>
  );
};
