import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AllowDistributionCentersForTradingPartnerQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type AllowDistributionCentersForTradingPartnerQuery = { __typename?: 'RootQueryType', tradingPartner?: { __typename?: 'TradingPartner', id: string, retailerDeliveryDestinations: Array<{ __typename?: 'RetailerDeliveryDestination', id: string, allowedDistributionCenters: Array<{ __typename?: 'AllowedDistributionCenter', id: string, distributionCenter?: { __typename?: 'DistributionCenter', id: string } | null }> }> } | null };


export const AllowDistributionCentersForTradingPartnerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"allowDistributionCentersForTradingPartner"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tradingPartner"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"retailerDeliveryDestinations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"allowedDistributionCenters"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenter"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<AllowDistributionCentersForTradingPartnerQuery, AllowDistributionCentersForTradingPartnerQueryVariables>;