import { validateRequired } from 'common/helpers/validationHelper';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import {
  DistributionCenter,
  ExtractedFormField,
  FormField
} from 'pages/AssortmentPage/AssortmentPage';
import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { EditFormValues, ShipToWithAlloyDcs } from '../EditAssortmentModal';
import { uniq } from 'lodash-es';

export const DistributionCentedForCatalogProductField = ({
  formField,
  distributionCenters,
  shipTosWithAlloyDcs
}: {
  formField: ExtractedFormField | FormField;
  distributionCenters: DistributionCenter[];
  shipTosWithAlloyDcs: ShipToWithAlloyDcs[];
}) => {
  const form = useForm();

  const [alloyDcsToSelect, setAlloyDcToSelect] = useState(distributionCenters);

  return (
    <>
      <AlloyFormField
        component="select"
        data-testid="aa-edit-modal-distribution-centers-select"
        name="distributionCenterIds"
        required={formField.required}
        validate={formField.required ? validateRequired : undefined}
        fieldProps={{
          title: formField.name,
          options: alloyDcsToSelect.map(({ id, name, code }) => ({
            value: id,
            label: `${name} ${code}`
          })),
          mode: 'multiple'
        }}
      />
      <OnChange name="retailerDeliveryDestinationIds">
        {(value: string[]) => {
          const selectedShipTos = [
            ...(form.getState().values as EditFormValues).vendorFlexLocations,
            ...value
          ];

          const allowedDcs = uniq(
            shipTosWithAlloyDcs
              .filter((shipTo) => selectedShipTos.includes(shipTo.id))
              .flatMap((shipTo) =>
                shipTo.allowedDistributionCenters.map((dc) => dc.distributionCenter?.id)
              )
          );

          const dcsToSelect = distributionCenters.filter((dc) => allowedDcs.includes(dc.id));

          form.mutators.setDistributionCenterIds(
            form
              .getState()
              .values.distributionCenterIds.filter((dcId: string) => allowedDcs.includes(dcId))
          );
          setAlloyDcToSelect(dcsToSelect);
        }}
      </OnChange>
      <OnChange name="vendorFlexLocations">
        {(value: string[]) => {
          const selectedShipTos = [
            ...(form.getState().values as EditFormValues).retailerDeliveryDestinationIds,
            ...value
          ];

          const allowedDcs = uniq(
            shipTosWithAlloyDcs
              .filter((shipTo) => selectedShipTos.includes(shipTo.id))
              .flatMap((shipTo) =>
                shipTo.allowedDistributionCenters.map((dc) => dc.distributionCenter?.id)
              )
          );

          const dcsToSelect = distributionCenters.filter((dc) => allowedDcs.includes(dc.id));

          form.mutators.setDistributionCenterIds(
            form
              .getState()
              .values.distributionCenterIds.filter((dcId: string) => allowedDcs.includes(dcId))
          );
          setAlloyDcToSelect(dcsToSelect);
        }}
      </OnChange>
    </>
  );
};
