import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type WhseOpenOrderDetailsQueryVariables = Types.Exact<{
  orderNumber: Types.Scalars['String']['input'];
}>;


export type WhseOpenOrderDetailsQuery = { __typename?: 'RootQueryType', whseOpenOrderDetails?: { __typename?: 'OrderDetails', customerPo?: string | null, orderNumber: string, orderedDate?: string | null, shipTo?: string | null, suggestedRdd?: string | null, totalUnits?: number | null, totalWeights?: number | null, materials?: Array<{ __typename?: 'OrderDetailsMaterials', description?: string | null, inventory?: number | null, materialId: string, status: Types.WarehouseOrderStatus, totalWeight?: number | null, unitsOrdered?: number | null } | null> | null } | null };


export const WhseOpenOrderDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"whseOpenOrderDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orderNumber"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"whseOpenOrderDetails"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orderNumber"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orderNumber"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customerPo"}},{"kind":"Field","name":{"kind":"Name","value":"materials"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"inventory"}},{"kind":"Field","name":{"kind":"Name","value":"materialId"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"totalWeight"}},{"kind":"Field","name":{"kind":"Name","value":"unitsOrdered"}}]}},{"kind":"Field","name":{"kind":"Name","value":"orderNumber"}},{"kind":"Field","name":{"kind":"Name","value":"orderedDate"}},{"kind":"Field","name":{"kind":"Name","value":"shipTo"}},{"kind":"Field","name":{"kind":"Name","value":"suggestedRdd"}},{"kind":"Field","name":{"kind":"Name","value":"totalUnits"}},{"kind":"Field","name":{"kind":"Name","value":"totalWeights"}}]}}]}}]} as unknown as DocumentNode<WhseOpenOrderDetailsQuery, WhseOpenOrderDetailsQueryVariables>;