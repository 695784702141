import React, { Dispatch, ReactNode, useState } from 'react';
import { InferNodeType } from 'common/helpers/mappingHelper';
import { useQuery } from '@apollo/client';
import { EventTrackerOptionsDocument } from './components/EditEventDetails/gql/__generated__/options.query';
import { EventTrackerOptionsQuery } from './components/EditEventDetails/gql/__generated__/options.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';

type ProviderProps = {
  children: ReactNode;
};

export type VendorMarket = InferNodeType<EventTrackerOptionsQuery, 'vendorMarkets'>;
export type BusinessUnit = InferNodeType<EventTrackerOptionsQuery, 'businessUnits'>;

interface EventTrackerContextData {
  businessUnits: BusinessUnit[];
  vendorMarkets: VendorMarket[];
}

export const EventTrackerContext = React.createContext({} as EventTrackerContextData);

export const EventTrackerProvider = (props: ProviderProps) => {
  const { data } = useQuery(EventTrackerOptionsDocument, {
    variables: { first: 10000 }
  });

  return (
    <EventTrackerContext.Provider
      value={{
        businessUnits: data?.businessUnits ? getNodesFromEdges(data?.businessUnits) : [],
        vendorMarkets: data?.vendorMarkets ? getNodesFromEdges(data?.vendorMarkets) : []
      }}
    >
      {props.children}
    </EventTrackerContext.Provider>
  );
};
