import React, { useContext, useState } from 'react';
import s from './EditEventWatchlist.module.scss';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyUpload } from 'components/ui/AlloyUpload/AlloyUpload';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { EventTrackerContext } from 'pages/EventTracker/EventTracker.context';
import { parse } from 'papaparse';
import { encode } from 'js-base64';
import moment from 'moment';
import { useForm, useFormState } from 'react-final-form';

interface FileWithDate extends File {
  lastModifiedDate: Date;
}

export const parseWatchlistData = (
  data: any
): {
  purchaseOrders: string[];
  externalIds: string[];
  contents: string;
  error?: string;
} => {
  const purchaseOrders = [];
  const externalIds = [];
  let error;

  for (const row of data) {
    if (row['Purchase Orders']) purchaseOrders.push(row['Purchase Orders']);
    if (row['External IDs']) externalIds.push(row['External IDs']);
    if (
      !Object.keys(row).includes('Purchase Orders') &&
      !Object.keys(row).includes('External IDs')
    ) {
      console.error('file bad');
      error =
        'The selected file is not formatted correctly or is missing data. The column header should be either "Purchase Orders" or "External IDs"';
    }
  }

  if (!purchaseOrders.length && !externalIds.length) {
    error = 'The file appears to be empty';
  }

  if (error) return { error, purchaseOrders: [], externalIds: [], contents: '' };

  let contents = 'Purchase Orders,External IDs\n';
  const length =
    purchaseOrders.length > externalIds.length ? purchaseOrders.length : externalIds.length;
  for (let i = 0; i < length; i++) {
    if (purchaseOrders[i]) contents += purchaseOrders[i];
    contents += ',';
    if (externalIds[i]) contents += externalIds[i];
    if (i < length - 1) contents += '\n';
  }

  return {
    purchaseOrders,
    externalIds,
    contents
  };
};

export const EditEventWatchlist = () => {
  const [watchListError, setWatchListError] = useState<string>();
  const form = useForm();
  const formState = useFormState();

  const parseData = (file: FileWithDate) => {
    parse<{ [name: string]: string }>(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const { data } = results;
        const { contents, purchaseOrders, externalIds, error } = parseWatchlistData(data);

        if (error) {
          form.change('watchlistFile', undefined);
          setWatchListError(error);
          return;
        }

        form.change('watchlistFile', {
          contents: encode(contents),
          filename: file.name,
          lastModified: file.lastModifiedDate,
          purchaseOrders,
          externalIds,
          updated: true
        });

        setWatchListError('');
      }
    });
  };

  return (
    <div className={s.event_watchlist}>
      <div className={s.upload_prompt}>
        <div className={s.label}>
          Add <b>PO List</b> and/or <b>External ID</b> list
        </div>

        <AlloyUpload
          showUploadList={false}
          accept={'.xls,.xlsx,.csv'}
          maxCount={1}
          customRequest={({ onSuccess }) => {
            if (onSuccess) onSuccess('ok');
          }}
          onChange={(upload) => parseData(upload.file.originFileObj as FileWithDate)}
        >
          <AlloyButton type="primary">Upload</AlloyButton>
        </AlloyUpload>
      </div>

      {!!watchListError && <div className={s.error}>{watchListError}</div>}

      {!!formState.values.watchlistFile && (
        <div className={s.filelist}>
          <div className={s.header}>
            <div>File name</div>
            <div>Remove</div>
          </div>

          <div className={s.file}>
            <div className={s.left}>
              <div className={s.icon}>
                <FileOutlined />
              </div>
              <div className={s.name_group}>
                <div className={s.name}>{formState.values.watchlistFile?.filename}</div>
              </div>
            </div>
            <div className={s.right}>
              {/* <div className={s.download_link}>Download</div> */}
              <div className={s.icon}>
                <DeleteOutlined
                  onClick={() => {
                    form.change('watchlistFile', undefined);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={s.template_wrapper}>
        <div>Don't have a template?</div>
        <div className={s.links}>
          <div className={s.file}>
            <FileOutlined className={s.icon} />
            <CSVLink
              filename={`watchlist.csv`}
              headers={['Purchase Orders', 'External IDs']}
              data={''}
            >
              <span>Watchlist template</span>
            </CSVLink>
          </div>
        </div>
      </div>
    </div>
  );
};
