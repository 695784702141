import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CheckScenarioNameQueryVariables = Types.Exact<{
  scenarioName?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CheckScenarioNameQuery = { __typename?: 'RootQueryType', getScenariosList?: Array<{ __typename?: 'Scenario', id: string, scenarioName: string } | null> | null };

export type GetGtin14sQueryVariables = Types.Exact<{
  gtin14?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
}>;


export type GetGtin14sQuery = { __typename?: 'RootQueryType', getGtin14s?: Array<{ __typename?: 'Gtin14Product', id: string, gtin14: string } | null> | null };


export const CheckScenarioNameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"checkScenarioName"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scenarioName"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getScenariosList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"scenarioName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scenarioName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"scenarioName"}}]}}]}}]} as unknown as DocumentNode<CheckScenarioNameQuery, CheckScenarioNameQueryVariables>;
export const GetGtin14sDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetGtin14s"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"gtin14"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getGtin14s"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"gtin14"},"value":{"kind":"Variable","name":{"kind":"Name","value":"gtin14"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"gtin14"}}]}}]}}]} as unknown as DocumentNode<GetGtin14sQuery, GetGtin14sQueryVariables>;