import { App } from 'ant5';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import s from './CutMaterialModal.module.scss';
import { AlloyDivider } from 'components/ui/AlloyDivider/AlloyDivider';
import { MaterialOrderWithMaterialId } from '../MaterialsView';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { parseIntValue, validateRequired } from 'common/helpers/validationHelper';
import { useMutation } from '@apollo/client';
import { UpsertWarehouseCutDocument } from './gql/__generated__/upsertWarehouseCut.mutation';
import { WhseOpenOrdersProcessedDocument } from 'pages/WarehouseOrderProcessingPage/gql/__generated__/whseOpenOrdersProcessed.query';
import { WhseMaterialsProcessedDocument } from '../gql/__generated__/whseMaterialsProcessed.query';

interface CutMaterialsForm {
  cutReason: string;
  cutUnits: number;
  materialNumber: string;
  orderNumber: string;
}

export const CutMaterialModal = ({
  selectedMaterialToCut,
  isModalVisible,
  setIsModalVisible,
  resetSelection
}: {
  selectedMaterialToCut: MaterialOrderWithMaterialId;
  isModalVisible: boolean;
  setIsModalVisible: (shown: boolean) => void;
  resetSelection: () => void;
}) => {
  const { message } = App.useApp();

  const [cutMaterialsAction, { loading }] = useMutation(UpsertWarehouseCutDocument, {
    onCompleted: (data) => {
      message.success(data.upsertWarehouseCut?.message);
      resetSelection();
      setIsModalVisible(false);
    },
    onError: (error) => {
      message.error(`Could not move materials to cut list: ${error}`);
    },
    refetchQueries: [WhseOpenOrdersProcessedDocument, WhseMaterialsProcessedDocument]
  });

  const initialValues: CutMaterialsForm = useMemo(
    () => ({
      cutReason: '',
      cutUnits: 0,
      materialNumber: selectedMaterialToCut.materialId,
      orderNumber: selectedMaterialToCut.orderNumber
    }),
    [selectedMaterialToCut]
  );

  const onSubmit = async (values: CutMaterialsForm) => {
    await cutMaterialsAction({
      variables: {
        input: {
          cutReason: values.cutReason || '',
          cutUnits: values.cutUnits || 0,
          materialNumber: values.materialNumber,
          orderNumber: values.orderNumber
        }
      }
    });
  };

  const validateCutsNumberInput = (value: number) => {
    const maxValue = selectedMaterialToCut.unitsOrdered || 0;

    if (value === null || value === undefined) {
      return 'Should not be empty';
    }

    if (value !== undefined && value !== null && value <= 0) {
      return 'Must be greater than 0';
    }

    if (value && !!maxValue && value > maxValue) {
      return `Can't exceed ${maxValue}`;
    }
  };

  return (
    <Form<CutMaterialsForm>
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setCutUnits: (args, state, utils) => {
          utils.changeValue(state, 'cutUnits', () => args[0]);
        }
      }}
      render={({ handleSubmit, form }) => (
        <AlloyModal
          open={isModalVisible}
          title="Cut Order"
          afterOpenChange={(visible) => {
            if (visible) {
              form.restart();
            }
          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          okText="Submit"
          okButtonProps={{
            onClick: () => handleSubmit(),
            disabled: form.getState().invalid || loading
          }}
          width={496}
        >
          <AlloySpin spinning={false}>
            <AlloyDivider />
            <form onSubmit={handleSubmit}>
              <div className={s.cut_quantity_container}>
                <AlloyFormField
                  component="inputNumber"
                  data-testid="cut-quantity-field"
                  name="cutUnits"
                  fieldProps={{
                    title: 'Cut quantity',
                    className: s.cut_quantity_field,
                    min: 0,
                    parser: parseIntValue,
                    type: 'number'
                  }}
                  required
                  validate={(value) => validateCutsNumberInput(value)}
                ></AlloyFormField>

                <AlloyButton
                  data-testid="cut-all-button"
                  onClick={() => form.mutators.setCutUnits(selectedMaterialToCut.unitsOrdered)}
                >
                  Cut all
                </AlloyButton>
              </div>

              <AlloyFormField
                component="input"
                data-testid="cut-reason-field"
                name="cutReason"
                fieldProps={{
                  title: 'Cut reason'
                }}
                required
                validate={validateRequired}
              ></AlloyFormField>

              <div data-testid="submit-cut-warning">
                {`Once you click Submit, the cut action can't be undone. Please review carefully before proceeding.`}
              </div>
            </form>
          </AlloySpin>
        </AlloyModal>
      )}
    />
  );
};
