import React, { ReactNode, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';
import type { Location } from 'history';

type ProviderProps = {
  children: ReactNode;
};

interface FunctionalLocation extends Location {
  goTo: (path: string) => void;
  string: string;
}

interface AppHistory {
  pathState: PathState;
  pathHistory: Location[];
  getPathState: (path: string) => Location;
  goTo: (path: string) => void;
  getProximalLocation: (paths: [string, ...string[]]) => FunctionalLocation;
  getLink: (path: string) => FunctionalLocation;
}

interface PathState {
  [index: string]: Location;
}

export const AppHistoryContext = React.createContext({} as AppHistory);

export const AppHistoryContextProvider = (props: ProviderProps) => {
  const pathState = useRef<PathState>({});
  const pathHistory = useRef<Location[]>([]);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    pathHistory.current.unshift({ ...location });
    if (pathHistory.current.length > 30) pathHistory.current.length = 30;
    pathState.current = { ...pathState.current, [location.pathname]: { ...location } };
  }, [location]);

  const getPathState = (path: string) => {
    return pathState.current[path] ?? { pathname: path };
  };

  //navigate to the given path, and reinstate the url params last used at that path if available
  const goTo = (path: string) => {
    history.push(getPathState(path));
  };

  //provides location data including a string value that can be used in a Link element
  const getLink = (path: string) => {
    const location = getPathState(path);

    return functionalLocation(location);
  };

  //navigate to the most recent of the paths provided
  const getProximalLocation = (paths: [string, ...string[]] = ['/']) => {
    let proximalPath: Partial<Location> = { pathname: paths[0] || '/' };
    pathHistory.current.some((location: Location) => {
      if (paths.includes(location.pathname)) {
        proximalPath = location;
        return true;
      }
    });

    return functionalLocation(proximalPath);
  };

  const functionalLocation = (location: Partial<Location>) => {
    return {
      ...location,
      goTo: () => goTo(location.pathname || '/'),
      string:
        location.pathname && location.search
          ? `${location.pathname}${location.search}`
          : location.pathname && location.state
            ? `${location.pathname}?${stringify(location.state as Record<string, undefined>)}`
            : location.pathname
    } as FunctionalLocation;
  };

  return (
    <AppHistoryContext.Provider
      value={{
        pathState: pathState.current,
        pathHistory: pathHistory.current,
        getPathState,
        goTo,
        getProximalLocation,
        getLink
      }}
    >
      {props.children}
    </AppHistoryContext.Provider>
  );
};
