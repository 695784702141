import type { SorterResult } from 'components/ui/AlloyTable/AlloyTable';

/**
 * Naive implementation of sorter using AntDesign's table sorter
 * Does not work with multiple sorting yet
 */
export const sortTableDataWithTableSorter = <T>(data: T[], sorter?: SorterResult<T>): T[] => {
  if (!sorter || typeof sorter?.column?.sorter !== 'function') return data;
  const sorterFunction = sorter?.column?.sorter;
  return [...data].sort((a, b) =>
    sorter.order === 'ascend' ? sorterFunction(a, b) : sorterFunction(b, a)
  );
};

/**
 * Naive implementation of client-side pagination
 */
export function paginate<T>(array: T[], page: number, size: number): T[] {
  const start = (page - 1) * size;
  const end = start + size;
  return array.slice(start, end);
}
