import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import React from 'react';
import s from './WarehouseTruckBuilderPage.module.scss';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { JsonParam, useQueryParam, withDefault } from 'use-query-params';
import { SearchOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import WhsTruckBuilderOrders from './WhsTruckBuilderOrders';

export type DisplayMode = 'orders' | 'truckloads' | 'completed';
const DEFAULT_TAB: DisplayMode = 'orders';

type WarehouseTruckBuilderTab = {
  value: DisplayMode;
  label: React.ReactNode;
  disabled?: boolean;
};
const ORDERS_TAB: WarehouseTruckBuilderTab = {
  value: 'orders',
  label: 'Orders',
  disabled: false
};
const TRUCKLOADS_TAB: WarehouseTruckBuilderTab = {
  value: 'truckloads',
  label: 'Truckloads',
  disabled: false
};
const COMPLETED_TAB: WarehouseTruckBuilderTab = {
  value: 'completed',
  label: 'Completed',
  disabled: false
};
const tabs = [ORDERS_TAB, TRUCKLOADS_TAB, COMPLETED_TAB];

const WarehouseTruckBuilderPage: React.FC = () => {
  const [mode, setMode] = useQueryParam<DisplayMode>('mode', withDefault(JsonParam, DEFAULT_TAB));

  return (
    <AlloySpin spinning={false}>
      <div className={s.header}>
        <div data-testid="page-title" className={s.page_title}>
          <PageHeader className={s.page_title}>Warehouse Truck Builder</PageHeader>
        </div>
      </div>

      <div className={s.page_toggle}>
        <AlloySegmented
          options={tabs}
          value={mode as DisplayMode}
          onChange={(value: DisplayMode) => setMode(value)}
        />
      </div>

      <div data-testid="table-tools-container" className={s.table_tools_container}>
        <div data-testid="search-and-filters-container" className={s.left}>
          <div className={s.search_bar}>
            <AlloyInput
              placeholder="Search"
              allowClear={true}
              prefix={<SearchOutlined width="14px" height="14px" />}
              disabled={true}
            />
          </div>
          <AlloySelect
            className={clsx(s.dc_select, s.select)}
            placeholder="Filter"
            allowClear
            options={[
              {
                key: 'all',
                value: 'all',
                label: 'All'
              }
            ]}
            disabled={true}
          />
        </div>
      </div>

      {mode === 'orders' && <WhsTruckBuilderOrders />}
    </AlloySpin>
  );
};

export default WarehouseTruckBuilderPage;
