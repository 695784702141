import React from 'react';
import s from './DrawerTitle.module.scss';
import editIcon from 'assets/icons/editing/edit_icon.svg';
import crossIcon from 'assets/icons/editing/cross_icon.svg';
import backIcon from 'assets/icons/editing/back.svg';
import clsx from 'clsx';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

export const DrawerTitle = ({
  title,
  onEdit,
  onCancel,
  onBack,
  loading,
  disableEditing
}: {
  title: string | React.ReactNode;
  onEdit?: () => void;
  onCancel: () => void;
  onBack?: () => void;
  loading?: boolean;
  disableEditing?: boolean;
}) => {
  return (
    <div className={clsx(s.drawer_title, { [s.with_back]: !!onBack })}>
      {onBack && (
        <AlloyButton
          icon={<img alt="" src={backIcon} />}
          type="text"
          onClick={onBack}
          className={s.back}
        />
      )}
      <div className={s.title}>{title}</div>
      <div className={s.buttons}>
        {onEdit && (
          <>
            <AlloyButton
              icon={<img alt="" data-testid="entity-drawer-edit-button" src={editIcon} />}
              type="text"
              onClick={onEdit}
              disabled={loading || disableEditing}
              className={s.edit_button}
            />
            <div className={s.divider} />
          </>
        )}
        <AlloyButton icon={<img alt="" src={crossIcon} />} type="text" onClick={onCancel} />
      </div>
    </div>
  );
};
