import { ActiveType } from 'common/constants';
import React, { useState, useMemo } from 'react';
import { Filter, FilterData, LocationUpdate, ShipToFilterName } from 'common/interfaces';
import { useLazyQuery } from '@apollo/client';
import {
  AdvancedFilterComponent,
  getInitialFilters
} from 'components/AdvancedFilter/AdvancedFilterComponent';
import { parse } from 'query-string';
import { useHistory } from 'react-router-dom';
import { EXCLUDE_PREFIX } from 'common/helpers/advancedFiltersHelper';
import { BusinessUnitsDocument } from './gql/__generated__/businessUnits.query';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  RddAdvancedFilters,
  RddTpIdsCondition,
  RddVmIdsCondition
} from 'graphql/__generated__/types';
import { notEmpty } from 'common/helpers/notEmpty';
import { CondOperator } from 'graphql/__generated__/enums';
import { VendorMarketsAdvancedFiltersShipToPageDocument } from './gql/__generated__/vendorMarketsAdvancedFiltersShipToPage.query';
import { TradingPartnersAdvancedFiltersShipToPageDocument } from './gql/__generated__/tradingPartnersAdvancedFiltersShipToPage.query';

interface AdvancedFiltersProps {
  onApplyAdvancedFilter: (update: LocationUpdate) => void;
  setShowAdvancedFilters: (v: boolean) => void;
  showAdvancedFilters: boolean;
}

export const searchStringToQueryFilter = (search: string) => {
  const locationSearchProps = parse(search);
  const { retailerChannels, tradingPartners, active, businessUnits } = locationSearchProps;

  const rddVmIdsConditions = [] as RddVmIdsCondition[];
  const { includeVendorMarketIds, excludeVendorMarketIds } = (
    typeof retailerChannels === 'string' ? [retailerChannels] : retailerChannels || []
  ).reduce(
    (
      result: {
        includeVendorMarketIds: string[];
        excludeVendorMarketIds: string[];
      },
      value: string
    ) => {
      value.startsWith(EXCLUDE_PREFIX)
        ? result.excludeVendorMarketIds.push(value.substr(EXCLUDE_PREFIX.length))
        : result.includeVendorMarketIds.push(value);
      return result;
    },
    {
      includeVendorMarketIds: [] as string[],
      excludeVendorMarketIds: [] as string[]
    }
  );
  if (includeVendorMarketIds.length > 0) {
    rddVmIdsConditions.push({
      operator: 'INCLUDE',
      value: includeVendorMarketIds
    });
  }
  if (excludeVendorMarketIds.length > 0) {
    rddVmIdsConditions.push({
      operator: 'EXCLUDE',
      value: excludeVendorMarketIds
    });
  }

  const rddTpIdsConditions = [] as RddTpIdsCondition[];
  const { includeTradingPartnerIds, excludeTradingPartnerIds } = (
    typeof tradingPartners === 'string' ? [tradingPartners] : tradingPartners || []
  ).reduce(
    (
      result: {
        includeTradingPartnerIds: string[];
        excludeTradingPartnerIds: string[];
      },
      value: string
    ) => {
      value.startsWith(EXCLUDE_PREFIX)
        ? result.excludeTradingPartnerIds.push(value.substr(EXCLUDE_PREFIX.length))
        : result.includeTradingPartnerIds.push(value);
      return result;
    },
    {
      includeTradingPartnerIds: [] as string[],
      excludeTradingPartnerIds: [] as string[]
    }
  );
  if (includeTradingPartnerIds.length > 0) {
    rddTpIdsConditions.push({
      operator: 'INCLUDE',
      value: includeTradingPartnerIds
    });
  }
  if (excludeTradingPartnerIds.length > 0) {
    rddTpIdsConditions.push({
      operator: 'EXCLUDE',
      value: excludeTradingPartnerIds
    });
  }

  const filterData = {} as { active?: boolean; businessUnits?: string[] };
  if (active !== null && active !== undefined) {
    filterData.active = active === 'ACTIVE';
  }
  if (businessUnits && businessUnits.length > 0) {
    filterData.businessUnits = typeof businessUnits === 'string' ? [businessUnits] : businessUnits;
  }

  const advancedFilterData: RddAdvancedFilters = {
    rddVmIdsConditions,
    rddTpIdsConditions
  };

  return {
    filterData: filterData,
    advancedFilterData
  };
};

export interface QueryShipToFilters {
  filterData?: {
    active?: boolean;
    businessUnits?: string[];
  };
  advancedFilterData?: RddAdvancedFilters;
}

const defaultFilters = {
  before: null,
  after: null,
  tradingPartners: null,
  retailerChannels: null,
  businessUnits: null,
  active: null,
  searchLike: null
};

export const queryFilterToFilters = (queryFilter: QueryShipToFilters) => {
  const filters = [] as Filter<ShipToFilterName>[];

  if (queryFilter.filterData?.businessUnits)
    filters.push({ name: 'businessUnits', value: queryFilter.filterData.businessUnits });

  const tradingPartnersMap = new Map<string, string[]>();
  queryFilter.advancedFilterData?.rddTpIdsConditions?.forEach((condition) => {
    if (!condition?.operator) return;
    const optionValue = tradingPartnersMap.get(condition.operator);
    const value = [...(optionValue || []), ...(condition?.value || []).filter(notEmpty)];
    tradingPartnersMap.set(condition.operator, value);
  });
  tradingPartnersMap.forEach((value, key) => {
    filters.push({
      name: 'tradingPartners',
      value: value,
      option: key
    });
  });

  const retailerChannelsMap = new Map<string, string[]>();
  queryFilter.advancedFilterData?.rddVmIdsConditions?.forEach((condition) => {
    if (!condition?.operator) return;
    const optionValue = retailerChannelsMap.get(condition.operator);
    const value = [...(optionValue || []), ...(condition?.value || []).filter(notEmpty)];
    retailerChannelsMap.set(condition.operator, value);
  });
  retailerChannelsMap.forEach((value, key) => {
    filters.push({
      name: 'retailerChannels',
      value: value,
      option: key
    });
  });

  if (queryFilter.filterData?.active !== null && queryFilter.filterData?.active !== undefined) {
    filters.push({
      name: 'active',
      value: queryFilter.filterData.active ? 'ACTIVE' : 'INACTIVE'
    });
  }

  return filters;
};

export const AdvancedFilters = ({
  onApplyAdvancedFilter,
  showAdvancedFilters,
  setShowAdvancedFilters
}: AdvancedFiltersProps) => {
  const history = useHistory();

  const [currentFilters, setCurrentFilters] = useState<Filter<ShipToFilterName>[]>(
    getInitialFilters(searchStringToQueryFilter(history.location.search), queryFilterToFilters)
  );

  const [loadTradingPartners, { loading: tradingPartnersLoading, data: tradingPartnersData }] =
    useLazyQuery(TradingPartnersAdvancedFiltersShipToPageDocument, {
      variables: {
        first: 10000
      }
    });
  const tradingPartnerList = useMemo(
    () => getNodesFromEdges(tradingPartnersData?.tradingPartners),
    [tradingPartnersData?.tradingPartners]
  );

  const [loadVendorMarkets, { loading: vendorMarketsLoading, data: vendorMarketsData }] =
    useLazyQuery(VendorMarketsAdvancedFiltersShipToPageDocument, {
      variables: {
        first: 100000
      }
    });
  const vendorMarkerList = useMemo(
    () => getNodesFromEdges(vendorMarketsData?.vendorMarkets),
    [vendorMarketsData?.vendorMarkets]
  );

  const [loadBusinessUnits, { loading: businessUnitsLoading, data: businessUnitsData }] =
    useLazyQuery(BusinessUnitsDocument, {});
  const businessUnitsList = useMemo(
    () => getNodesFromEdges(businessUnitsData?.businessUnits),
    [businessUnitsData?.businessUnits]
  );

  const filters: FilterData<ShipToFilterName>[] = [
    {
      name: 'tradingPartners',
      title: 'Trading Partners',
      type: 'string',
      additionalOptions: [CondOperator.EXCLUDE, CondOperator.INCLUDE],
      multiple: true,
      values: tradingPartnerList.map((tradingPartner) => ({
        name: tradingPartner?.id,
        title: tradingPartner?.name
      })),
      lazyLoading: true
    },
    {
      name: 'retailerChannels',
      title: 'Retailer Channels',
      type: 'string',
      additionalOptions: [CondOperator.EXCLUDE, CondOperator.INCLUDE],
      multiple: true,
      values: vendorMarkerList.map((vendorMarket) => ({
        name: vendorMarket.id,
        title: vendorMarket.name
      })),
      lazyLoading: true
    },
    {
      name: 'businessUnits',
      title: 'Business Units',
      type: 'string',
      multiple: true,
      values: businessUnitsList.map((businessUnit) => ({
        name: businessUnit.code,
        title: businessUnit.name
      })),
      lazyLoading: true
    },
    {
      name: 'active',
      title: 'Active',
      type: 'string',
      multiple: false,
      values: ActiveType.map((activeType) => ({
        name: activeType.name,
        title: activeType.description
      })),
      lazyLoading: false
    }
  ];

  const onFilterValuesFocus = (fieldName: string) => {
    if (fieldName === 'tradingPartners' && !tradingPartnersLoading && !tradingPartnersData)
      loadTradingPartners();
    if (fieldName === 'retailerChannels' && !vendorMarketsLoading && !vendorMarketsData)
      loadVendorMarkets();
    if (fieldName === 'businessUnits' && !businessUnitsLoading && !businessUnitsData)
      loadBusinessUnits();
  };

  const onApplyAdvancedFilterWithDropToDefault = (update: LocationUpdate) => {
    onApplyAdvancedFilter({
      ...parse(history.location.search),
      ...defaultFilters,
      ...update
    });
  };

  const loading = useMemo(() => {
    const result = [];
    if (tradingPartnersLoading) {
      result.push('tradingPartners');
    }
    if (vendorMarketsLoading) {
      result.push('vendorMarkets');
    }
    return result;
  }, [tradingPartnersLoading, vendorMarketsLoading]);

  return (
    <AdvancedFilterComponent
      loading={loading}
      filters={filters}
      onApplyAdvancedFilter={onApplyAdvancedFilterWithDropToDefault}
      searchStringToQueryFilter={searchStringToQueryFilter}
      queryFilterToFilters={queryFilterToFilters}
      onFilterValuesFocus={onFilterValuesFocus}
      loadLazyLoadingData={() => {
        if (!vendorMarkerList) {
          loadVendorMarkets();
        }
        if (!tradingPartnerList) {
          loadTradingPartners();
        }
        if (!businessUnitsList) {
          loadBusinessUnits();
        }
      }}
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
      showAdvancedFilters={showAdvancedFilters}
      setShowAdvancedFilters={setShowAdvancedFilters}
      size="large"
    />
  );
};
