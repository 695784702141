import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import s from './MaterialsView.module.scss';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { App, Empty } from 'ant5';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import {
  WhseMaterialsProcessedDocument,
  WhseMaterialsProcessedQuery
} from './gql/__generated__/whseMaterialsProcessed.query';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { StringParam, useQueryParam } from 'use-query-params';
import { MaterialSortColumn, SortOrderDirection } from 'graphql/__generated__/types';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { notEmpty } from 'common/helpers/notEmpty';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { formatStatus } from '../../helpers';
import { ExpandButton } from 'components/ui/ExpandButton/ExpandButton';
import Icon from '@ant-design/icons';
import scissorsIcon from 'assets/icons/warehouseOrderProcessing/scissors_icon.svg';
import CheckmarkIcon from 'assets/icons/warehouseOrderProcessing/checkmark_icon.svg?react';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { CutMaterialModal } from './CutMaterialModal/CutMaterialModal';
import { ApproveWarehouseOpenOrderDocument } from './gql/__generated__/approveWarehouseOpenOrder.mutation';
import { WhseOpenOrdersProcessedDocument } from 'pages/WarehouseOrderProcessingPage/gql/__generated__/whseOpenOrdersProcessed.query';
import { EMPTY } from 'common/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { SearchField } from 'components/Common/SearchField/SearchField';
import { parse } from 'query-string';

interface MaterialOrderIdCellProps {
  materialOrderNumber: string;
  record: MaterialOrderWithMaterialId;
}

const MaterialOrderIdCell = ({ materialOrderNumber, record }: MaterialOrderIdCellProps) => {
  const routerLocation = useLocation();
  const materialOrderScrollTo = useMemo(
    () => routerLocation.state?.materialOrderNumber,
    [routerLocation]
  );

  useEffect(() => {
    if (record.orderNumber === materialOrderScrollTo) {
      const elementToScroll = document.getElementById(
        `material-order-number-${record.orderNumber}`
      );

      if (elementToScroll) {
        elementToScroll?.scrollIntoView({ block: 'center' });
      }
    }
  }, [materialOrderScrollTo, record.orderNumber]);

  return (
    <div
      data-testid="material-order-number-cell-container"
      className={s.material_order_number_cell_container}
    >
      <div
        data-testid={`material-order-number-${materialOrderNumber}`}
        id={`material-order-number-${record.orderNumber}`}
      >
        {materialOrderNumber}
      </div>
    </div>
  );
};

export const getMaterialsPageColumns = () => {};

type WhseMaterialsProcessed = InferNodeType<WhseMaterialsProcessedQuery, 'whseMaterialsProcessed'>;

export type MaterialOrder = NonNullable<NonNullable<WhseMaterialsProcessed['orders']>[number]>;

export type MaterialOrderWithMaterialId = MaterialOrder & { materialId: string };

// const MATERIALS_DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[3]; //TODO: are we interested in pagination? it's not in the design

const getRowKey = (record: WhseMaterialsProcessed) => record.materialId;
const getExpandedRowKey = (order: MaterialOrder) => order.orderNumber;

const TABLE_WIDTH = 1590;

export const MaterialsView = () => {
  const { message } = App.useApp();
  const routerLocation = useLocation();

  const [materialsSortColumn, setMaterialsSortColumn] = useQueryParam(
    'material_column',
    StringParam
  );
  const [materialsSortOrder, setMaterialsSortOrder] = useQueryParam('material_order', StringParam);
  const [search, setSearch] = useQueryParam('material_search', StringParam);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [selectedMaterialToCut, setSelectedMaterialToCut] = useState<MaterialOrderWithMaterialId>();
  const [showCutOrderModal, setShowCutOrderModal] = useState(false);

  // On page load/render, call the whseMaterialsProcessed query
  const { data, loading, error } = useQuery(WhseMaterialsProcessedDocument, {
    variables: {
      first: 100000,
      sort: {
        column: materialsSortColumn as MaterialSortColumn,
        direction: materialsSortOrder as SortOrderDirection
      },
      searchLike: search
    }
  });

  const materialsProcessedList = useMemo(
    () => getNodesFromEdges(data?.whseMaterialsProcessed),
    [data]
  );

  const [approveWarehouseOrder, { loading: approveWarehouseOrderLoading }] = useMutation(
    ApproveWarehouseOpenOrderDocument,
    {
      onCompleted: (data) => {
        message.success(data.approveWarehouseOpenOrder?.message);
      },
      onError: (error) => {
        message.error(`Could not approve warehouse order: ${error}`);
      },
      refetchQueries: [WhseOpenOrdersProcessedDocument, WhseMaterialsProcessedDocument]
    }
  );

  useEffect(() => {
    if (!materialsSortColumn || !materialsSortOrder) {
      setMaterialsSortColumn('MATERIAL_ID');
      setMaterialsSortOrder('DESC');
    }
  }, [setMaterialsSortColumn, setMaterialsSortOrder, materialsSortColumn, materialsSortOrder]);

  const handleTableSorting = (column: string, order: string) => {
    setMaterialsSortColumn(column);
    setMaterialsSortOrder(order);
  };

  const handleSearch = useCallback(
    (value: string) => {
      if (search !== (value || undefined)) {
        setSearch(value || undefined);
      }
    },
    [search, setSearch]
  );

  const handleApproveClick = async (values: MaterialOrderWithMaterialId) => {
    await approveWarehouseOrder({
      variables: {
        input: {
          materialNumber: values.materialId,
          orderNumber: values.orderNumber
        }
      }
    });
  };

  const history = useHistory();

  const routeChange = (id: string) => {
    history.replace({
      ...routerLocation,
      state: { materialOrderNumber: id }
    });
    history.push({
      pathname: `/material-order-details/${id}`,
      state: parse(history.location.search)
    });
  };

  const EmptyCell = () => {};

  const materialsTableColumns: ColumnsType<WhseMaterialsProcessed> = [
    {
      title: 'Material Id',
      key: 'MATERIAL_ID',
      width: '175px',
      render: (_, record) => record.materialId,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'MATERIAL_ID'),
      sorter: true
    },
    {
      title: 'Description',
      key: 'DESCRIPTION',
      render: (_, record) => record.description,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'DESCRIPTION'),
      sorter: true
    },
    {
      title: 'Status',
      key: 'STATUS',
      width: '148px',
      render: (_, record) => formatStatus(record.status),
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'STATUS'),
      sorter: true
    },
    {
      title: 'Units Ordered',
      key: 'UNITS_ORDERED',
      width: '148px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.unitsOrdered ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'UNITS_ORDERED'),
      sorter: true
    },
    {
      title: 'Inventory',
      key: 'INVENTORY',
      width: '148px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.inventory ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'INVENTORY'),
      sorter: true
    },
    {
      title: 'Discrepancy',
      key: 'DISCREPANCY',
      width: '148px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.discrepancy ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(materialsSortColumn, materialsSortOrder, 'DISCREPANCY'),
      sorter: true
    },
    {
      title: 'Order Number',
      key: 'ORDER_NUMBER',
      width: '175px',
      align: 'left',
      render: (_, record) => {
        if (record.orders && record.orders.length) {
          return (
            <ListOfItemsColumn
              items={record.orders.map((order) => order?.orderNumber || '') || []}
              splitToLines
            />
          );
        } else {
          return null;
        }
      }
    },
    { title: '', key: 'cutAndApproveFakeColumn', width: 112, render: EmptyCell }
  ];

  const FAKE_COLUMN_VARIABLE_WIDTH = {
    title: '',
    key: 'expanderVariable',
    render: EmptyCell
  };

  const FAKE_COLUMN_MED_WIDTH = {
    title: '',
    key: 'expanderMed',
    width: 175,
    render: EmptyCell
  };

  const FAKE_COLUMN_SML_WIDTH = {
    title: '',
    key: 'expanderSml',
    width: 148,
    render: EmptyCell
  };

  const materialsExpandedRowColumns: ColumnsType<MaterialOrderWithMaterialId> = [
    FAKE_COLUMN_MED_WIDTH,
    FAKE_COLUMN_VARIABLE_WIDTH,
    {
      key: 'STATUS',
      width: '148px',
      render: (_, record) => formatStatus(record.status)
    },
    {
      key: 'UNITS_ORDERED',
      width: '148px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.unitsOrdered ?? EMPTY}</span>
    },
    FAKE_COLUMN_SML_WIDTH,
    FAKE_COLUMN_SML_WIDTH,
    {
      key: 'ORDER_NUMBER',
      width: '175px',
      align: 'left',
      render: (_, record) => (
        <MaterialOrderIdCell materialOrderNumber={record?.orderNumber || ''} record={record} />
      )
    },
    {
      width: '40px',
      render: (_, record) => {
        return (
          <AlloyButton
            data-testid="cut_button"
            type="text"
            icon={<img src={scissorsIcon} alt="cut button" />}
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedMaterialToCut(record);
              toggleCutMaterialModalVisibility();
            }}
          />
        );
      }
    },
    {
      width: '40px',
      render: (_, record) => {
        return (
          <AlloyButton
            loading={approveWarehouseOrderLoading}
            data-testid="approve_order_button"
            className={s.approve_order_button}
            type="text"
            size="small"
            icon={<Icon component={() => <CheckmarkIcon />} />}
            disabled={record.status === 'READY_TO_SHIP'} //TODO: maybe we want a tooltip when it is disabled, stating why?
            onClick={(e) => {
              e.stopPropagation();
              if (record.status !== 'READY_TO_SHIP') {
                return handleApproveClick(record);
              }
            }}
          />
        );
      }
    }
  ];

  const currentPageOrdersExpandableKeys = useMemo(() => {
    return materialsProcessedList.map(getRowKey);
  }, [materialsProcessedList]);

  const areAllKeysExpandedOnThePage = useMemo(
    () => currentPageOrdersExpandableKeys.every((material) => expandedRowKeys.includes(material)),
    [expandedRowKeys, currentPageOrdersExpandableKeys]
  );

  const toggleCutMaterialModalVisibility = () => setShowCutOrderModal(!showCutOrderModal);

  const EmptyMaterialsTableText = (
    <div className={s.materials_table_empty_state_title}>No materials found</div>
  );

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <div data-testid="search-and-filters-container" className={s.search_and_filters_container}>
        <div className={s.search_bar}>
          {/* TODO: in future use MultipleValuesInput to accommodate search for material_id AND order_number - will need a BE change to use searchTerms on a filters field */}
          <SearchField
            data-testid="material-view-search"
            loading={loading}
            placeholder="Search by Material ID"
            handleSearch={handleSearch}
            value={search ?? ''}
          />
        </div>
        <AlloySelect
          className={s.select}
          placeholder="Filter"
          allowClear
          options={[
            {
              key: 'all',
              value: 'all',
              label: 'All'
            }
          ]}
          disabled={true} //TODO: filter to be implemented in a future ticket
        />
      </div>

      <div className={s.quantity_display_container}>
        <QuantityDisplay
          count={data?.whseMaterialsProcessed?.totalCount || 0}
          titleSingular={'Material'}
          titleMultiple={'Materials'}
        />
      </div>
      <AlloyTable
        data-testid="materials-processed-table"
        className={s.materials_processed_table}
        loading={loading}
        scroll={{ x: TABLE_WIDTH }}
        sticky
        pagination={false}
        columns={materialsTableColumns.filter(notEmpty)}
        dataSource={materialsProcessedList}
        expandable={{
          columnTitle: currentPageOrdersExpandableKeys.length > 0 && (
            <ExpandButton
              expanded={areAllKeysExpandedOnThePage}
              onClick={(expanded) => {
                return expanded
                  ? setExpandedRowKeys(
                      expandedRowKeys.filter((x) => !currentPageOrdersExpandableKeys.includes(x))
                    )
                  : setExpandedRowKeys([
                      ...new Set([...currentPageOrdersExpandableKeys, ...expandedRowKeys])
                    ]);
              }}
              collapseMessage="Collapse all"
              expandMessage="Expand all"
            />
          ),
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: (record) => (
            <AlloyTable //TODO: table header color change if possible
              loading={loading}
              data-testid="expanded-row-materials-table"
              className={s.expanded_row_materials_table}
              columns={materialsExpandedRowColumns.filter(notEmpty)}
              dataSource={record.orders?.filter(notEmpty).map((order) => {
                const orderWithMaterialId = {
                  materialId: record.materialId,
                  ...order
                };
                return orderWithMaterialId;
              })}
              pagination={false}
              rowKey={getExpandedRowKey}
              showHeader={false}
              onRow={({ materialId, orderNumber }: MaterialOrderWithMaterialId) => {
                return {
                  onClick: (e) => {
                    e.preventDefault();
                    if (!(e.target as HTMLElement).classList.contains('ant5-btn-icon')) {
                      routeChange(orderNumber);
                    }
                  },
                  'data-testid': `material-row-${materialId}`
                };
              }}
            />
          ),
          onExpandedRowsChange: (rows) => setExpandedRowKeys(rows as string[]),
          rowExpandable: ({ orders }) => !!orders
        }}
        onChange={(_pagination, _filters, sorter) => {
          const column =
            String((sorter as SorterResult<WhseMaterialsProcessed>).columnKey) || 'MATERIAL_ID';
          const order =
            (sorter as SorterResult<WhseMaterialsProcessed>).order === 'ascend' ||
            !(sorter as SorterResult<WhseMaterialsProcessed>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column, order);
        }}
        rowKey={getRowKey}
        locale={{
          emptyText: (
            <Empty
              data-testid="materials-table-empty-state"
              className={s.materials_table_empty_state}
              description={EmptyMaterialsTableText}
            />
          )
        }}
      />
      {showCutOrderModal && selectedMaterialToCut && (
        <CutMaterialModal
          selectedMaterialToCut={selectedMaterialToCut}
          isModalVisible={showCutOrderModal}
          setIsModalVisible={setShowCutOrderModal}
          resetSelection={() => setSelectedMaterialToCut(undefined)}
        />
      )}
    </>
  );
};
