import React, { useEffect, useMemo, useState } from 'react';
import s from './Graph.module.scss';
import { FiscalCalendarWeekInputWithWeekOnly } from 'components/PeriodCalendar/helpers';
import { groupBy } from 'lodash-es';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import { useQuery } from '@apollo/client';
import {
  ChargebacksReportDetailsByPeriodForGraphDocument,
  ChargebacksReportDetailsByPeriodForGraphQuery
} from './gql/__generated__/chargebacksReportDetailsByPeriodForGraph.query';
import currency from 'currency.js';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
  CartesianGrid,
  LegendProps,
  TooltipProps,
  Label
} from 'recharts';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { getColorWithApproximation } from 'common/helpers/palette';
import { notEmpty } from 'common/helpers/notEmpty';
import clsx from 'clsx';
import { ChargebackDimension } from 'graphql/__generated__/types';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import {
  formatCurrency,
  stringifyBackendValue,
  stringifyYearWeek
} from 'pages/Chargebacks/helpers';
import { periodWeekComparator, safeLocaleCompare } from 'common/helpers/comparators';
import { useChargebacksFiltersFromQueryParam } from '../Filters/hooks';
import { filterChargebacksData } from '../Filters/helpers';
import { useDeepCompareEffect } from 'use-deep-compare';

type ChargebackReportByPeriod =
  ChargebacksReportDetailsByPeriodForGraphQuery['chargebackDetailsReportsByPeriod'][number];

// TODO: fetch from BE?
const DEFAULT_BUS = ['FLNA', 'Gatorade', 'PBC', 'Quaker'];

const AXIS_COLOR = '#B1B1B1';
const __name__ = '__name__';

type AvailableDimensions = keyof Pick<
  ChargebackReportByPeriod,
  'distributionCenter' | 'issueType' | 'status'
>;

const dimensionToField: { [K in ChargebackDimension]?: AvailableDimensions } = {
  DISTRIBUTION_CENTER: 'distributionCenter',
  ISSUE_TYPE: 'issueType',
  STATUS: 'status'
} as const;

const dimensions: { label: string; value: ChargebackDimension }[] = [
  {
    label: 'Distribution Center',
    value: 'DISTRIBUTION_CENTER'
  },
  {
    label: 'Issue Type',
    value: 'ISSUE_TYPE'
  },
  {
    label: 'Status',
    value: 'STATUS'
  }
] as const;

export const Graph = ({
  fiscalCalendarWeeks
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
}) => {
  const [selectedFieldDimensions] = useChargebacksFiltersFromQueryParam();

  const [disabledSeries, setDisabledSeries] = useState<string[]>([]);
  const [hovered, setHovered] = useState('');

  const handleLegendClick = (dataKey: string) => {
    if (disabledSeries.includes(dataKey)) {
      setDisabledSeries(disabledSeries.filter((el) => el !== dataKey));
    } else {
      setDisabledSeries((prev) => [...prev, dataKey]);
    }
  };

  const [dimension, setDimension] = useQueryParam(
    'graph_dimension',
    withDefault(StringParam, 'ISSUE_TYPE')
  );
  const selectedField = useMemo(
    () => dimensionToField[dimension as ChargebackDimension] || 'issueType',
    [dimension]
  );

  const chargebacksGraphs = useQuery(ChargebacksReportDetailsByPeriodForGraphDocument, {
    variables: {
      // Fetch everything upfront – at least no fetching later. TODO: decide if it's better to do (BU + (currently selected split)) + split from filters
      dimensions: [
        'BUSINESS_UNIT',
        'ISSUE_TYPE',
        'STATUS',
        'SUB_TYPE_OF_THE_NON_COMPLIANCE',
        'TRADING_PARTNER',
        'DISTRIBUTION_CENTER'
      ],
      filters: {
        fiscalCalendarWeeks,
        countryCode: 'US'
      }
    }
  });

  const [bu, setBu] = useQueryParam('graph_bu', withDefault(StringParam, 'all'));

  useDeepCompareEffect(
    () => setDisabledSeries([]),
    [fiscalCalendarWeeks, dimension, selectedFieldDimensions.filters]
  );

  const allBusSelected = bu === 'all';

  const { graphData, isGraphEmpty, series, areMultipleYearsSelected } = useMemo(() => {
    const fiscalData = chargebacksGraphs.data?.chargebackDetailsReportsByPeriod || [];

    const areMultipleYearsSelected = (fiscalCalendarWeeks || []).some(
      (x) => x.year !== fiscalCalendarWeeks?.[0]?.year
    );

    const filteredByBuData = fiscalData.filter(
      (x) => allBusSelected || (x.businessUnit || '').toLocaleLowerCase() === bu.toLocaleLowerCase()
    );

    const filteredData = filterChargebacksData(filteredByBuData, selectedFieldDimensions.filters, [
      'businessUnit' // We are NOT filtering by business unit – we want to show all of them, always.
    ]);

    const allReasons = [...new Set(fiscalData.map((x) => x[selectedField]).filter(notEmpty))].sort(
      (a, b) => safeLocaleCompare(a, b)
    );

    const legendColors: { [key: string]: string } = {};
    allReasons.forEach((x, idx) => {
      legendColors[x] = getColorWithApproximation(idx, allReasons.length)?.color;
    });

    // We are reversing series so it matches alphabetical ordering, where first entry is on top
    // and last is on the bottom
    const series = [...new Set(filteredData.map((x) => x[selectedField]).filter(notEmpty))]
      .map((name: string) => ({
        name,
        color: legendColors[name]
      }))
      .sort((a, b) => safeLocaleCompare(a.name, b.name))
      .reverse();

    const groupedData = groupBy(filteredData, (x) =>
      allBusSelected
        ? x.businessUnit
        : stringifyYearWeek(x.fiscalCalendarWeek, areMultipleYearsSelected)
    );

    const calculateGraphData = (name: string, items: ChargebackReportByPeriod[]) => {
      const result: { [key: string]: number } = {};
      items.forEach((item) => {
        const field = item[selectedField];
        if (field) {
          result[field] = currency(result[field] || 0).add(item.financialCharge).value;
        }
      });
      return { ...result, [__name__]: name };
    };

    const unsortedGraphData =
      bu === 'all'
        ? DEFAULT_BUS.map((unit) => calculateGraphData(unit, groupedData[unit] || []))
        : fiscalCalendarWeeks.map((weekYear) => {
            const name = stringifyYearWeek(weekYear, areMultipleYearsSelected);
            return calculateGraphData(name, groupedData[name] || []);
          });

    const graphData = unsortedGraphData.sort((a, b) =>
      allBusSelected
        ? safeLocaleCompare(a[__name__], b[__name__])
        : periodWeekComparator(a[__name__], b[__name__])
    );

    // Each object in graph has "__name__", but everything else is optional
    const isGraphEmpty = !graphData.some((obj) => Object.keys(obj).length > 1);

    return { graphData, series, areMultipleYearsSelected, isGraphEmpty };
  }, [
    chargebacksGraphs.data?.chargebackDetailsReportsByPeriod,
    fiscalCalendarWeeks,
    selectedFieldDimensions.filters,
    bu,
    allBusSelected,
    selectedField
  ]);

  return (
    <div>
      <div className={s.graph_top}>
        <h2 className={s.title}>
          Chargebacks by{' '}
          <AlloySelect
            value={dimension}
            onChange={(value) => {
              setDimension(value);
            }}
            variant="borderless"
            dropdownStyle={{ minWidth: '150px', fontWeight: 'bold' }}
            options={dimensions}
            className={s.borderlessSelect}
          />
        </h2>
        <div className={s.selector}>
          <AlloySegmented
            value={bu}
            onChange={(value) => setBu(value)}
            options={[
              {
                label: 'All BUs',
                value: 'all'
              },
              ...DEFAULT_BUS.map((bu) => ({
                label: bu,
                value: bu
              }))
            ]}
          />
        </div>
      </div>
      <AlloySpin spinning={chargebacksGraphs.loading}>
        <div style={{ width: '100%', height: '360px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 0,
                right: 0,
                left: 20,
                bottom: allBusSelected ? 5 : areMultipleYearsSelected ? 75 : 25
              }}
              maxBarSize={320}
            >
              <CartesianGrid horizontal={true} vertical={false} stroke="#dcdcdc" strokeWidth={1} />
              <XAxis
                dataKey={__name__}
                angle={allBusSelected ? 0 : -90}
                textAnchor={allBusSelected ? 'middle' : 'end'}
                dx={allBusSelected ? 0 : -5}
                tickLine={false}
                stroke={AXIS_COLOR}
                tick={{ fill: 'black' }}
              >
                {/* Not sure if it's the best approach but this way we see the graph all the time */}
                {!chargebacksGraphs.loading && (chargebacksGraphs.error || isGraphEmpty) ? (
                  <Label
                    value={
                      chargebacksGraphs.error
                        ? 'Could not load data due to an error'
                        : 'No data available'
                    }
                    position="center"
                    style={{ transform: `translate(0px, -160px)` }}
                  />
                ) : (
                  <></>
                )}
              </XAxis>
              <YAxis
                stroke={AXIS_COLOR}
                tickLine={false}
                tickFormatter={(label) => formatCurrency(label, label < 100 && label !== 0 ? 1 : 0)}
                padding={{ top: 20 }}
                scale="linear"
              />
              <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(5,151,242, 0.2)' }} />
              <Legend
                verticalAlign="top"
                content={
                  <CustomLegend handleLegendClick={handleLegendClick} setHovered={setHovered} />
                }
              />
              {series.map(({ name, color }) => (
                <Bar
                  key={name}
                  dataKey={name}
                  stackId="a"
                  fill={color}
                  isAnimationActive={false}
                  hide={disabledSeries.includes(name)}
                  // Unfortunately currently it is not possible to pass testId, so we use className for that.
                  className={clsx(s.bar, `test_id_${name}_bar_chart`)}
                  opacity={name === hovered ? 0.5 : 1}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </AlloySpin>
    </div>
  );
};

const CustomLegend = (
  props: LegendProps & {
    handleLegendClick: (name: string) => void;
    setHovered: (value: string) => void;
  }
) => {
  const { payload, handleLegendClick, setHovered } = props;
  if (!payload) return <></>;

  return (
    <div className={s.legend}>
      {/* We reverse the array so order matches the "barchart" order */}
      {payload.reverse().map((entry, index) => (
        <button
          key={`item-${index}`}
          className={clsx(s.item, { [s.not_active]: entry.inactive })}
          onClick={() => handleLegendClick(entry.value)}
          onMouseEnter={() => setHovered(entry.value)}
          onMouseLeave={() => setHovered('')}
        >
          <div className={s.color} style={{ backgroundColor: entry.color }} />
          {stringifyBackendValue(entry.value)}
        </button>
      ))}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, '__name__'>) => {
  if (!(active && payload && payload.length)) return null;

  const total = payload
    .map((x) => x.value)
    .filter(notEmpty)
    .reduce((acc, val) => {
      return currency(acc).add(val);
    }, currency(0));

  return (
    <div className={s.tooltip}>
      <div className={s.value}>
        {label}: {formatCurrency(total)}
      </div>
      {[...payload].reverse().map((entry) => (
        <div key={`item-${entry.name}`} className={s.item}>
          <div className={s.color} style={{ backgroundColor: entry.color }} />
          {stringifyBackendValue(entry.name)}:{' '}
          <span className={s.value}>{formatCurrency(entry.value)}</span>
        </div>
      ))}
    </div>
  );
};
