import React, { useCallback, useMemo } from 'react';
import s from './EditAssortmentModal.module.scss';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyTabs } from 'components/ui/AlloyTabs/AlloyTabs';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { concat, merge, uniq, upperCase } from 'lodash-es';
import { ModalAction } from 'common/constants';
import { FormApi } from 'final-form';
import { FormFieldComponentForCatalogProduct } from './components/FormFieldComponentForCatalogProduct';
import {
  DEFAULT_FORM_FIELDS_ORDER_ON_CATALOG_PRODUCTS_FORM,
  SPECIAL_ASSORTMENT_FORM_FIELD,
  isForCatalogProductMultipleUpc,
  sorterBySlugs
} from 'pages/AssortmentPage/fieldsHelper';
import { ShipToField } from '../EditTradingPartnerAssortment/components/ShipToField/ShipToField';
import { SettingOutlined } from '@ant-design/icons';
import {
  AssortmentConfig,
  DistributionCenter,
  RetailerDeliveryDestination
} from 'pages/AssortmentPage/AssortmentPage';
import { ProductType } from 'graphql/__generated__/types';
import { UpsertTradingPartnerActiveAssortmentMutationVariables } from 'pages/AssortmentPage/gql/__generated__/upsertTradingPartnerActiveAssortment.mutation';
import { PrioritizedList } from 'components/PrioritizedList/PrioritizedList';
import { v4 as uuidv4 } from 'uuid';
import { RetailerProductExternalIdType } from 'graphql/__generated__/types';
import { TradingPartnerAssortmentFullData } from '../ProductDetailsModal/ProductDetailsDrawer';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { VendorProduct } from '../EditTradingPartnerAssortment/components/ExternalIdField';
import { ActiveAssortmentProductType } from 'graphql/__generated__/enums';
import { CatalogProduct } from './components/CatalogProductIdentifierField';
import { TradingPartnerForCatalogProductField } from './components/TradingPartnerForCatalogProductField';
import { convertStringToNumber } from 'common/helpers/stringsConverter';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { useQuery } from '@apollo/client';
import {
  AllowDistributionCentersForTradingPartnerDocument,
  AllowDistributionCentersForTradingPartnerQuery
} from 'pages/AssortmentPage/gql/__generated__/allowDistributionCentersForTradingPartner.query';

const DELIVERY_TYPE_MAP: {
  [index: string]: string;
} = {
  DSD: 'DIRECT_STORE_DELIVERY',
  ECOMM: 'ECOMM_DELIVERY',
  WHD: 'WAREHOUSE_DELIVERY'
};

export type EditFormSubstitution =
  UpsertTradingPartnerActiveAssortmentMutationVariables['input']['substitutions'][number] & {
    key: string;
  };

export type EditFormValues = UpsertTradingPartnerActiveAssortmentMutationVariables['input'] & {
  vendorFlex: boolean;
  vendorFlexLocations: string[];
  multipleUpc: boolean;
  substitutions: EditFormSubstitution[];
};

const getModalAction = (retailerProductId: string | undefined) =>
  retailerProductId ? ModalAction.EDIT : ModalAction.ADD;

const getBaseItem = (
  itemInfo: TradingPartnerAssortmentFullData | undefined,
  predefinedValues: Partial<TradingPartnerAssortmentFullData> | undefined
): TradingPartnerAssortmentFullData => {
  return merge(
    merge({ retailerDeliveryDestinationIds: [], substitutions: [{ inventory: [] }] }, itemInfo),
    predefinedValues
  );
};

interface EditTradingPartnerAssortmentByConfigProps {
  visible: boolean;
  onCancel: () => void;
  retailerProduct: TradingPartnerAssortmentFullData | undefined;
  upsertTradingPartnerAssortment: (
    action: ModalAction,
    params: UpsertTradingPartnerActiveAssortmentMutationVariables['input']
  ) => void;
  tradingPartnerDistributionCenters: DistributionCenter[];
  defaultShipTo?: string[];
  predefinedValues?: Partial<TradingPartnerAssortmentFullData>;
  vendorId: string | undefined;
  tradingPartner: {
    id: string;
    name?: string | null;
    deliveryType?: string | null;
    retailerDeliveryDestinations: RetailerDeliveryDestination[];
  };
  assortmentConfig: AssortmentConfig;
  vendorMarketId: string;
  retailerProductExternalIdType?: RetailerProductExternalIdType;
  changeAddingFlowToUpdate: (retailerProduct: TradingPartnerAssortmentFullData) => void;
}

export type ShipToWithAlloyDcs = NonNullable<
  AllowDistributionCentersForTradingPartnerQuery['tradingPartner']
>['retailerDeliveryDestinations'][number];

export interface DeliveryDestination {
  id: string;
  name: string | null | undefined;
  externalId: string | null | undefined;
  specialAssortment: boolean;
}

export const fillFormWithCatalogProduct = (
  product: CatalogProduct,
  form: FormApi,
  tabKey: string,
  fillExternalId: boolean,
  assortmentConfig: AssortmentConfig
) => {
  form.getFieldState(`${tabKey}.productCasesPerLayer`)?.change(product.casesPerLayer);
  form.getFieldState(`${tabKey}.productCasesPerPallet`)?.change(product.casesPerPallet);
  form.getFieldState(`${tabKey}.productDepth`)?.change(convertStringToNumber(product.depth));
  form
    .getFieldState(`${tabKey}.productGrossWeight`)
    ?.change(convertStringToNumber(product.grossWeight));
  form.getFieldState(`${tabKey}.productHeight`)?.change(convertStringToNumber(product.height));
  form.getFieldState(`${tabKey}.productLayersPerPallet`)?.change(product.layersPerPallet);
  form.getFieldState(`${tabKey}.productOracleInvenId`)?.change(product.oracleInvenId);
  form.getFieldState(`${tabKey}.productShipsInOwnContainer`)?.change(product.shipsInOwnContainer);
  form.getFieldState(`${tabKey}.productWidth`)?.change(convertStringToNumber(product.width));
  form.getFieldState(`${tabKey}.productName`)?.change(product.name);
  form
    .getFieldState(`${tabKey}.productProductType`)
    ?.change(product.productType ? upperCase(product.productType) : undefined);

  // This fields should be reseted after automatically filling not to provide additional searching
  if (assortmentConfig.fields.some((field) => field.slug === 'SAP_MATERIAL_ID')) {
    form.mutators.setValueByFillingForm(product.sapMaterialId, `${tabKey}.productSapMaterialId`);
  }
  if (assortmentConfig.fields.some((field) => field.slug === 'UPC')) {
    form.mutators.setValueByFillingForm(product.gtin12, `${tabKey}.productUpc`);
  }
  if (assortmentConfig.fields.some((field) => field.slug === 'GTIN14')) {
    form.mutators.setValueByFillingForm(product.gtin14, `${tabKey}.productGtin14`);
  }

  if (fillExternalId && product.externalId) {
    form.mutators.setValueByFillingForm(product.externalId, 'vendorProductExternalId');
  }
};

export const EditAssortmentModal = ({
  visible,
  onCancel,
  retailerProduct,
  tradingPartner,
  tradingPartnerDistributionCenters,
  upsertTradingPartnerAssortment,
  predefinedValues,
  defaultShipTo,
  assortmentConfig,
  vendorId,
  vendorMarketId,
  retailerProductExternalIdType,
  changeAddingFlowToUpdate
}: EditTradingPartnerAssortmentByConfigProps) => {
  const { data, loading } = useQuery(AllowDistributionCentersForTradingPartnerDocument, {
    variables: {
      id: tradingPartner.id
    }
  });

  const formFields = (
    assortmentConfig.specialAssortment
      ? [...assortmentConfig.fields, SPECIAL_ASSORTMENT_FORM_FIELD]
      : [...assortmentConfig.fields]
  ).sort(sorterBySlugs(DEFAULT_FORM_FIELDS_ORDER_ON_CATALOG_PRODUCTS_FORM));

  const editingItem = useMemo<TradingPartnerAssortmentFullData | undefined>(
    () => getBaseItem(retailerProduct, predefinedValues),
    [predefinedValues, retailerProduct]
  );

  const action = useMemo(
    () => getModalAction(editingItem?.retailerProductId),
    [editingItem?.retailerProductId]
  );

  const collectedRdds = useMemo(
    () =>
      (tradingPartner.retailerDeliveryDestinations || [])
        .filter((rdd) => rdd?.active)
        .map((rdd) => ({
          id: rdd?.id,
          name: rdd?.name,
          externalId: rdd?.externalId,
          specialAssortment: rdd?.specialAssortment
        })),
    [tradingPartner.retailerDeliveryDestinations]
  );

  const changeToUpdate = useCallback(
    (item: VendorProduct) => {
      changeAddingFlowToUpdate(
        getBaseItem(
          {
            rddIds: [],
            retailerProductId: item.id,
            retailerProductExternalId: item.externalId,
            substitutions: item.substitutionProducts?.map((substitution) => {
              return {
                inventory: [],
                casesPerLayer: substitution.product.casesPerLayer,
                casesPerPallet: substitution.product.quantityPerPallet,
                currency: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.currency,
                depth: substitution.product.depth,
                grossWeight: substitution.product.weightInPounds,
                grossWeightInKg: substitution.product.weightInKilograms,
                height: substitution.product.height,
                inventoryReserve: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.inventoryReserve,
                layersPerPallet: substitution.product.layersPerPallet,
                moqMinimum: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.moqMinimum,
                moqUnitOfMeasure: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.moqUnitOfMeasure,
                name: substitution.product.name,
                oracleInvenId: substitution.product.oracleInvenId,
                price: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.price,
                requiredOrderUnitOfMeasure: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.requiredOrderUnitOfMeasure,
                sapMaterialId: substitution.product.sapMaterialId,
                shipsInOwnContainer: substitution.product.shipsInOwnContainer,
                toCaseQuantity: item.toCaseQuantity,
                upc: substitution.product.upc,
                width: substitution.product.width,
                productType: substitution.product.productType
                  ? (upperCase(substitution.product.productType) as ProductType)
                  : undefined,
                activeDcIds: []
              };
            }),
            updatedAt: undefined
          },
          undefined
        )
      );
    },
    [changeAddingFlowToUpdate, tradingPartner.id]
  );

  const submitForm = (values: EditFormValues) => {
    const { multipleUpc } = values;

    // cause of DESC-11037 we should save only 1 upc if multiple_upc or use_multiple_substitutions not true
    const preparedSubstitutions = (
      multipleUpc ? values.substitutions : values.substitutions.slice(0, 1)
    ).map((multipleValue) => ({
      productCasesPerPallet: multipleValue.productCasesPerPallet,
      productDepth: multipleValue?.productDepth,
      productGrossWeight: multipleValue.productGrossWeight,
      productHeight: multipleValue?.productHeight,
      productName: multipleValue.productName,
      productSapMaterialId: multipleValue.productSapMaterialId,
      productUpc: multipleValue?.productUpc,
      productWidth: multipleValue.productWidth,
      productShipsInOwnContainer: multipleValue.productShipsInOwnContainer,
      productMoqUnitOfMeasure: multipleValue.productMoqUnitOfMeasure,
      productPrice: multipleValue.productPrice,
      productProductType: multipleValue.productProductType,
      productMoqMinimum: multipleValue.productMoqMinimum,
      productRequiredOrderUnitOfMeasure: multipleValue.productRequiredOrderUnitOfMeasure,
      productCurrency: multipleValue.productCurrency,
      productCasesPerLayer: multipleValue.productCasesPerLayer,
      productLayersPerPallet: multipleValue.productLayersPerPallet,
      productOracleInvenId: multipleValue.productOracleInvenId,
      productInventoryReserve: multipleValue.productInventoryReserve,
      productGtin14: multipleValue.productGtin14
    }));

    const inputVariables = {
      distributionCenterIds: values.distributionCenterIds,
      reprocessImpactedPurchaseOrders: true,
      type: DELIVERY_TYPE_MAP[tradingPartner?.deliveryType ?? ''] as ActiveAssortmentProductType,
      vendorProductExternalId: assortmentConfig.fields.find((field) => field.slug === 'EXTERNAL_ID')
        ? values.vendorProductExternalId
        : (values.substitutions[0].productUpc ?? ''),
      retailerDeliveryDestinationIds:
        values.vendorFlex && values.vendorFlexLocations.length > 0
          ? values.retailerDeliveryDestinationIds.includes('all')
            ? concat(
                collectedRdds?.filter((rdd) => !rdd.specialAssortment)?.map(({ id }) => id),
                values.vendorFlexLocations
              )
            : concat(values.retailerDeliveryDestinationIds, values.vendorFlexLocations)
          : values.retailerDeliveryDestinationIds.includes('all')
            ? collectedRdds?.filter((rdd) => !rdd.specialAssortment)?.map(({ id }) => id)
            : values.retailerDeliveryDestinationIds,
      substitutions: preparedSubstitutions,
      vendorProductId: action === ModalAction.ADD ? null : editingItem?.retailerProductId,
      tradingPartnerId: values.tradingPartnerId,
      vendorProductToCaseQuantity: values?.vendorProductToCaseQuantity,
      vendorProductDtcInventoryReserve: values.vendorProductDtcInventoryReserve,
      vendorMarketId: vendorMarketId
    };

    upsertTradingPartnerAssortment(action, inputVariables);
    onCancel();
  };

  const fields = (form: FormApi<EditFormValues>) => {
    if (assortmentConfig.multipleUpc || assortmentConfig.useMultipleSubstitutions) {
      // 2 columns, second is for multiple upc
      return (
        <>
          <AlloyCol span={12} className={s.group}>
            <TradingPartnerForCatalogProductField tradingPartner={tradingPartner} />
            <ShipToField
              deliveryDestinations={collectedRdds}
              formFieldName="retailerDeliveryDestinationIds"
            />
            {formFields.map(
              (formField) =>
                !isForCatalogProductMultipleUpc(formField.slug) && (
                  <FormFieldComponentForCatalogProduct
                    tabKey={'substitutions[0]'}
                    key={formField.slug}
                    vendorId={vendorId || ''}
                    action={action}
                    changeToUpdate={changeToUpdate}
                    formField={formField}
                    assortmentConfig={assortmentConfig}
                    distributionCenters={tradingPartnerDistributionCenters}
                    deliveryDestinations={collectedRdds}
                    fillFormWithCatalogProduct={(...args) =>
                      fillFormWithCatalogProduct(...args, assortmentConfig)
                    }
                    retailerProductExternalIdType={retailerProductExternalIdType}
                    shipTosWithAlloyDcs={data?.tradingPartner?.retailerDeliveryDestinations ?? []}
                  />
                )
            )}
          </AlloyCol>
          <AlloyCol span={12} className={s.group}>
            <FieldArray name="substitutions">
              {({ fields }) => {
                const items = fields.map((name, index) => {
                  return {
                    label: (
                      <span data-testid={`aa-edit-modal-upc-tab-${index + 1}`}>UPC{index + 1}</span>
                    ),
                    key: index.toString(),
                    closable: false,
                    children: (
                      <>
                        {formFields.map(
                          (formField) =>
                            isForCatalogProductMultipleUpc(formField.slug) && (
                              <FormFieldComponentForCatalogProduct
                                vendorId={vendorId || ''}
                                action={action}
                                changeToUpdate={changeToUpdate}
                                key={`${name}_${formField.slug}`}
                                formField={formField}
                                assortmentConfig={assortmentConfig}
                                distributionCenters={tradingPartnerDistributionCenters}
                                deliveryDestinations={collectedRdds}
                                tabKey={name}
                                fillFormWithCatalogProduct={(...args) =>
                                  fillFormWithCatalogProduct(...args, assortmentConfig)
                                }
                                retailerProductExternalIdType={retailerProductExternalIdType}
                                shipTosWithAlloyDcs={
                                  data?.tradingPartner?.retailerDeliveryDestinations ?? []
                                }
                              />
                            )
                        )}
                      </>
                    )
                  };
                });

                items.push({
                  label: (
                    <SettingOutlined
                      data-testid={`aa-edit-modal-upc-tab-settings`}
                      style={{ marginRight: 0 }}
                    />
                  ),
                  key: 'settings',
                  closable: false,
                  children: (
                    <FieldArray name="substitutions">
                      {({ fields }) => (
                        <div className={s.upc_settings_container}>
                          <p className={s.subtitle}>UPC settings list</p>
                          <PrioritizedList<
                            EditFormSubstitution & { priority: number; active: boolean }
                          >
                            selected={fields.value.map((value, index) => ({
                              ...value,
                              priority: index + 1,
                              active: true
                            }))}
                            onChange={(items) => form.mutators.updateMultipleUpcs(items)}
                            data-testid="edit-aa-multiple-upcs-priority-list"
                            itemBodyRender={(item) => (
                              <div className={s.card_content}>
                                <div>
                                  <div>UPC {item.priority}</div>
                                  <div>{item.productUpc}</div>
                                </div>
                                {/* here should be an active/inactive switch, when this ability will be implemented on BE */}
                              </div>
                            )}
                            itemKey={(item) => item.key}
                            preventRemovalLastElement
                          />
                        </div>
                      )}
                    </FieldArray>
                  )
                });

                return (
                  <AlloyTabs
                    type="editable-card"
                    onEdit={() => fields.push({ key: uuidv4() })}
                    className={s.tabs}
                    items={items}
                  />
                );
              }}
            </FieldArray>
          </AlloyCol>
        </>
      );
    } else if (formFields.length > 10) {
      // 2 columns
      return (
        <>
          <AlloyCol span={12} className={s.group}>
            <TradingPartnerForCatalogProductField tradingPartner={tradingPartner} />
            <ShipToField
              deliveryDestinations={collectedRdds}
              formFieldName="retailerDeliveryDestinationIds"
            />
            {formFields.map((formField, index) =>
              index < formFields.length / 2 ? (
                <FormFieldComponentForCatalogProduct
                  key={formField.slug}
                  tabKey={'substitutions[0]'}
                  vendorId={vendorId || ''}
                  action={action}
                  changeToUpdate={changeToUpdate}
                  formField={formField}
                  assortmentConfig={assortmentConfig}
                  distributionCenters={tradingPartnerDistributionCenters}
                  deliveryDestinations={collectedRdds}
                  fillFormWithCatalogProduct={(...args) =>
                    fillFormWithCatalogProduct(...args, assortmentConfig)
                  }
                  retailerProductExternalIdType={retailerProductExternalIdType}
                  shipTosWithAlloyDcs={data?.tradingPartner?.retailerDeliveryDestinations ?? []}
                />
              ) : null
            )}
          </AlloyCol>
          <AlloyCol span={12} className={s.group}>
            {formFields.map((formField, index) =>
              index >= formFields.length / 2 ? (
                <FormFieldComponentForCatalogProduct
                  key={formField.slug}
                  tabKey={'substitutions[0]'}
                  vendorId={vendorId || ''}
                  action={action}
                  changeToUpdate={changeToUpdate}
                  formField={formField}
                  assortmentConfig={assortmentConfig}
                  distributionCenters={tradingPartnerDistributionCenters}
                  deliveryDestinations={collectedRdds}
                  fillFormWithCatalogProduct={(...args) =>
                    fillFormWithCatalogProduct(...args, assortmentConfig)
                  }
                  retailerProductExternalIdType={retailerProductExternalIdType}
                  shipTosWithAlloyDcs={data?.tradingPartner?.retailerDeliveryDestinations ?? []}
                />
              ) : null
            )}
          </AlloyCol>
        </>
      );
    } else {
      // 1 column
      return (
        <AlloyCol span={24} className={s.group}>
          <TradingPartnerForCatalogProductField tradingPartner={tradingPartner} />
          <ShipToField
            deliveryDestinations={collectedRdds}
            formFieldName="retailerDeliveryDestinationIds"
          />
          {formFields.map((formField) => (
            <FormFieldComponentForCatalogProduct
              key={formField.slug}
              tabKey={'substitutions[0]'}
              vendorId={vendorId || ''}
              action={action}
              changeToUpdate={changeToUpdate}
              formField={formField}
              assortmentConfig={assortmentConfig}
              distributionCenters={tradingPartnerDistributionCenters}
              deliveryDestinations={collectedRdds}
              fillFormWithCatalogProduct={(...args) =>
                fillFormWithCatalogProduct(...args, assortmentConfig)
              }
              retailerProductExternalIdType={retailerProductExternalIdType}
              shipTosWithAlloyDcs={data?.tradingPartner?.retailerDeliveryDestinations ?? []}
            />
          ))}
        </AlloyCol>
      );
    }
  };

  return (
    <FullScreenEditingModal
      data-testid={`aa-edit-modal-${editingItem?.retailerProductId || 'new'}`}
      title={
        action === ModalAction.ADD ? (
          <span data-testid={`aa-edit-modal-add-title`}>Add Product to Assortment</span>
        ) : (
          <span data-testid={`aa-edit-modal-edit-title`}>Edit active assortment item</span>
        )
      }
      containerClassName={s.editing_area}
      open={visible}
      onClose={onCancel}
      buttons={
        <>
          <AlloyButton
            data-testid="aa-edit-modal-cancel-button"
            type="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </AlloyButton>
          <AlloyButton
            type="primary"
            data-testid={`aa-edit-modal-${
              action === ModalAction.ADD ? 'add-product' : 'update'
            }-button`}
            onClick={() => {
              document
                ?.getElementById('edit-retailer-product-modal-form')
                ?.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
          >
            {action === ModalAction.ADD ? 'Add Product' : 'Update'}
          </AlloyButton>
        </>
      }
    >
      <AlloySpin spinning={loading}>
        <Form<EditFormValues>
          initialValues={
            {
              multipleUpc:
                assortmentConfig.multipleUpc || assortmentConfig.useMultipleSubstitutions,
              vendorFlex:
                tradingPartner.retailerDeliveryDestinations.filter((rdd) => {
                  return editingItem?.rddIds?.includes(rdd.id) && rdd.specialAssortment;
                }).length > 0,
              distributionCenterIds:
                editingItem?.dcIds ??
                uniq(
                  (editingItem?.substitutions[0]?.inventory || []).reduce(
                    (result, inv) => (inv.active ? [...result, inv.distributionCenterId] : result),
                    [] as string[]
                  )
                ),
              vendorFlexLocations: tradingPartner.retailerDeliveryDestinations
                .filter((rdd) => editingItem?.rddIds?.includes(rdd.id) && rdd.specialAssortment)
                .map((location) => location.id),
              vendorProductExternalId: editingItem?.retailerProductExternalId,
              tradingPartnerId: tradingPartner.id || undefined,
              retailerDeliveryDestinationIds: defaultShipTo
                ? defaultShipTo
                : tradingPartner.retailerDeliveryDestinations
                    ?.filter(
                      (rdd) =>
                        editingItem?.rddIds?.includes(rdd.id) &&
                        !rdd.specialAssortment &&
                        rdd.active
                    )
                    ?.sort((rdd1, rdd2) => (rdd1.name || '').localeCompare(rdd2.name || ''))
                    ?.map(({ id }) => id),
              substitutions: editingItem?.substitutions.map((product) => {
                return {
                  productName: product.name,
                  productDepth: product?.depth ?? undefined,
                  productGrossWeight: product?.grossWeight ?? undefined,
                  productHeight: product?.height ?? undefined,
                  productCasesPerPallet: product?.casesPerPallet ?? undefined,
                  productSapMaterialId: product?.sapMaterialId ?? undefined,
                  productUpc: product?.upc ?? undefined,
                  productWidth: product.width ?? undefined,
                  productLayersPerPallet: product.layersPerPallet ?? undefined,
                  productCasesPerLayer: product.casesPerLayer ?? undefined,
                  productShipsInOwnContainer: product.shipsInOwnContainer ?? undefined,
                  productOracleInvenId: product.oracleInvenId ?? undefined,
                  productProductType: product.productType ?? undefined,
                  productGtin14: product.gtin14 ?? undefined,
                  key: uuidv4()
                };
              }) || [{ key: uuidv4(), productName: '' }]
            } as EditFormValues
          }
          onSubmit={submitForm}
          mutators={{
            ...arrayMutators,
            setShipTos: (args, state, utils) => {
              utils.changeValue(state, 'shipTo', () => args[0]);
            },
            setValueByFillingForm: (args, state, utils) => {
              const fieldName = args[1];
              utils.changeValue(state, fieldName, () => args[0]);
              utils.resetFieldState(fieldName);
            },
            updateMultipleUpcs: (args, state, utils) => {
              utils.changeValue(state, 'substitutions', () => args[0]);
            },
            setDistributionCenterIds: (args, state, utils) => {
              utils.changeValue(state, 'distributionCenterIds', () => args[0]);
            }
          }}
          render={({ handleSubmit, form }) => (
            <form id="edit-retailer-product-modal-form" onSubmit={(event) => handleSubmit(event)}>
              <AlloyRow>{fields(form)}</AlloyRow>
            </form>
          )}
        />
      </AlloySpin>
    </FullScreenEditingModal>
  );
};
