import React, { forwardRef } from 'react';
import { Input as AntInput, InputProps, InputRef } from 'ant5';
import { TextAreaProps } from 'ant5/es/input';

export const AlloyInput = forwardRef<InputRef, InputProps>(({ ...otherProps }, ref) => {
  return <AntInput {...otherProps} ref={ref} />;
});

export function AlloyTextArea({ ...otherProps }: TextAreaProps) {
  const { TextArea: AntTextArea } = AntInput;
  return <AntTextArea {...otherProps} />;
}

AlloyInput.displayName = 'AlloyInput';

// Re-exporting types
export type { InputProps, InputRef, TextAreaProps };
