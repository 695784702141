import React, { useState, useMemo } from 'react';
import { AlloyTabs } from 'components/ui/AlloyTabs/AlloyTabs';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { EntityDrawer } from 'components/Common/ViewMode/EntityDrawer/EntityDrawer';
import s from './EditEventDrawer.module.scss';
import { EditEventDetails } from 'pages/EventTracker/components/EditEventDetails/EditEventDetails';
import { EditEventWatchlist } from 'pages/EventTracker/components/EditEventWatchlist/EditEventWatchlist';
import { Form, useForm, useFormState } from 'react-final-form';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { SpecialEventDetailsForEditDocument } from './gql/__generated__/specialEventDetails.query';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { decode } from 'js-base64';
import { InferNodeType } from 'common/helpers/mappingHelper';
import { SpecialEventDetailsForEditQuery } from './gql/__generated__/specialEventDetails.query';
import { UpsertSpecialEventDocument } from 'pages/EventTracker/gql/__generated__/upsertSpecialEvent.mutation';
import { App } from 'ant5';
import { parseWatchlistData } from 'pages/EventTracker/components/EditEventWatchlist/EditEventWatchlist';

type SpecialEvent = InferNodeType<SpecialEventDetailsForEditQuery, 'specialEvents'>;

interface EditEventDrawerProps {
  show: boolean;
  onClose: () => void;
  eventId: string;
}

interface EditEventDrawerDetailsProps extends Omit<EditEventDrawerProps, 'eventId'> {
  event?: SpecialEvent;
  loading: boolean;
}

export const EditEventDrawer = (props: EditEventDrawerProps) => {
  if (!props.eventId) return null;

  const { data, loading } = useQuery(SpecialEventDetailsForEditDocument, {
    variables: {
      ids: [props.eventId]
    }
  });

  const event = useMemo(() => data?.specialEvents[0], [data]);

  return (
    <Form
      initialValues={{
        id: event?.id,
        name: event?.name,
        isPrivate: event?.private,
        startDate: event?.eventDateStart ? moment(event?.eventDateStart) : undefined,
        endDate: event?.eventDateEnd ? moment(event?.eventDateEnd) : undefined,
        businessUnits: event?.businessUnit,
        watchlistFile: {
          filename: event?.watchlistFile?.filename,
          contents: event?.watchlistFile?.contents,
          lastUpdated: event?.watchlistFile?.lastUpdated,
          updated: false
        }
      }}
      onSubmit={() => {}}
    >
      {() => <Drawer {...props} event={event} loading={loading} />}
    </Form>
  );
};

const Drawer = ({ show, onClose, event, loading }: EditEventDrawerDetailsProps) => {
  if (!event) return null;

  const [editMode, setEditMode] = useState(false);
  const [currentTab, setCurrentTab] = useState('details');
  const form = useFormState();

  const { message, notification } = App.useApp();
  const [createSpecialEvent, { loading: saving }] = useMutation(UpsertSpecialEventDocument, {
    onCompleted: () => {
      message.success(`${form.values.name} Updated`);
      setEditMode(false);
    },
    onError: (error) => {
      notification.error({
        message: 'Error',
        description: error.message
      });
      console.error(error.message);
    },
    refetchQueries: ['specialEvents']
  });

  const save = () => {
    createSpecialEvent({
      variables: {
        input: {
          id: form.values.id,
          name: form.values.name,
          businessUnits: form.values.businessUnits,
          customers: form.values.customers,
          eventDateStart: form.values.startDate.format('YYYY-MM-DD'),
          eventDateEnd: form.values.endDate.format('YYYY-MM-DD'),
          private: form.values.isPrivate,
          retailerId: 'UmV0YWlsZXI6MQ==', //amazon
          watchlistFile: {
            filename: form.values.watchlistFile?.filename as string,
            contents: form.values.watchlistFile?.contents as string
          }
        }
      }
    });
  };

  return (
    <EntityDrawer
      startEditing={() => setEditMode(!editMode)}
      loading={loading}
      viewMode={
        <>
          {!!event && (
            <div className={s.edit}>
              <div className={s.view}>
                <AlloyTabs
                  className={s.tabs}
                  activeKey={currentTab}
                  onChange={(target) => setCurrentTab(target)}
                  items={[
                    {
                      label: 'Event details',
                      key: 'details'
                    },
                    {
                      label: 'Event watchlist',
                      key: 'watchlist'
                    }
                  ]}
                />
                {currentTab === 'details' && (
                  <DetailsTab
                    event={event}
                    edit={editMode}
                    setEditMode={setEditMode}
                    onClose={onClose}
                    save={save}
                    saving={saving}
                  />
                )}
                {currentTab === 'watchlist' && (
                  <WatchlistTab
                    event={event}
                    edit={editMode}
                    setEditMode={setEditMode}
                    save={save}
                    saving={saving}
                  />
                )}
              </div>
            </div>
          )}
        </>
      }
      title={event?.name || ''}
      onClose={() => onClose()}
      open={show}
    />
  );
};

const WatchlistTab = ({ event, edit, setEditMode, save, saving }: any) => {
  if (!event) return null;
  const form = useFormState();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const watchlistFile = form.values?.watchlistFile;

  if (edit) {
    return (
      <div className={s.drawer_display}>
        {!showConfirmation && (
          <>
            <EditEventWatchlist />
            <div className={s.footer}>
              <AlloyButton
                type="primary"
                onClick={() => setShowConfirmation(true)}
                disabled={!form.values.watchlistFile.updated}
              >
                Next
              </AlloyButton>
              <AlloyButton type="secondary" onClick={() => setEditMode(false)}>
                Cancel
              </AlloyButton>
            </div>
          </>
        )}
        {showConfirmation && (
          <>
            <div className={s.confirm_details}>
              <div>Found orders and external IDs to be added:</div>
              <ul>
                {!!watchlistFile?.purchaseOrders?.length && (
                  <li>
                    {watchlistFile.purchaseOrders.length} purchase order
                    {watchlistFile.purchaseOrders.length > 0 ? 's' : ''}
                  </li>
                )}
                {!!watchlistFile?.externalIds?.length && (
                  <li>
                    {watchlistFile.externalIds.length} external ID
                    {watchlistFile.externalIds.length > 0 ? 's' : ''}
                  </li>
                )}
              </ul>
            </div>
            <div className={s.footer}>
              <AlloyButton type="primary" onClick={() => save()} disabled={saving}>
                Confirm and Add
              </AlloyButton>
              <AlloyButton type="secondary" onClick={() => setShowConfirmation(false)}>
                Back
              </AlloyButton>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className={s.filelist}>
      <div className={s.header}>
        <div>File name</div>
      </div>

      <div className={s.file}>
        <div className={s.left}>
          <div className={s.icon}>
            <FileOutlined />
          </div>
          <div className={s.name_group}>
            <div className={s.name}>{event?.watchlistFile?.filename}</div>
            {!!event?.watchlistFile?.lastUpdated && (
              <div className={s.date}>
                Updated: {moment(event?.watchlistFile?.lastUpdated).format('MM/DD/YY')}
              </div>
            )}
          </div>
        </div>
        <div className={s.right}>
          {!!event?.watchlistFile && (
            <div className={s.download_link}>
              <CSVLink
                filename={event.watchlistFile.filename}
                data={decode(event.watchlistFile.contents)}
              >
                Download
              </CSVLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DetailsTab = ({ event, edit, setEditMode, onClose, save, saving }: any) => {
  if (!event) return null;

  const form = useFormState();

  if (edit) {
    return (
      <div className={s.drawer_display}>
        <EditEventDetails event={event} />
        <div className={s.footer}>
          <AlloyButton type="primary" onClick={() => save()} disabled={saving}>
            Save
          </AlloyButton>
          <AlloyButton type="secondary" onClick={() => setEditMode(false)}>
            Cancel
          </AlloyButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={s.value_group}>
        <div className={s.label}>Event name</div>
        <div className={s.value}>{event.name}</div>
      </div>
      <div className={s.value_group}>
        <div className={s.label}>Date range of hypercare</div>
        <div className={s.value}>
          {moment(event.eventDateStart).format('MM/DD/YY')} -{' '}
          {moment(event.eventDateEnd).format('MM/DD/YY')}
        </div>
      </div>
      <div className={s.value_group}>
        <div className={s.label}>Customers</div>
        <div className={s.value}></div>
      </div>
      <div className={s.value_group}>
        <div className={s.label}>Business Units</div>
        <div className={s.value}>{event?.businessUnit?.join(',')}</div>
      </div>
    </>
  );
};
