import { useQueryParam, withDefault, JsonParam } from 'use-query-params';
import { Dimension, createInitialFilterObject, filters } from './helpers';

export const useChargebacksFiltersFromQueryParam = () => {
  const [selectedFieldDimensions, setSelectedFieldDimensions] = useQueryParam<{
    enabled: Dimension[];
    filters: { [K in Dimension]?: string[] };
  }>(
    'chargeback_filter',
    withDefault(JsonParam, {
      enabled: ['businessUnit', 'issueType'],
      filters: createInitialFilterObject(filters)
    })
  );

  return [selectedFieldDimensions, setSelectedFieldDimensions] as const;
};
