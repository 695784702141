import React from 'react';
import s from './NumberBadge.module.scss';
import clsx from 'clsx';

export const NumberBadge = ({ count }: { count: number }) => {
  const isLeaningRight = count === 4 || count === 5;
  const isLeaningLeft = count === 1 || count === 7 || count === 9;

  return (
    <div
      className={clsx(s.number_badge, {
        [s.compensate_right_lean]: isLeaningRight,
        [s.compensate_left_lean]: isLeaningLeft
      })}
    >
      {count}
    </div>
  );
};
