import React, { Dispatch, useEffect, useState } from 'react';
import s from './EventsTable.module.scss';
import { App } from 'ant5';
import { DashOutlined, InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { EditEventDrawer } from '../EditEventDrawer/EditEventDrawer';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { useUrlParams } from 'common/helpers/urlParams';
import { Link } from 'react-router-dom';
import { SpecialEventsQuery } from 'pages/EventTracker/gql/__generated__/specialEvents.query';
import { InferNodeType } from 'common/helpers/mappingHelper';
import { HighlightedText } from 'components/Common/HighlightedText/HighlightedText';

type SpecialEvent = InferNodeType<SpecialEventsQuery, 'specialEvents'>;

type SortBy = 'name' | 'createdBy' | 'lastUpdated';
type SortDirection = 'ASC' | 'DESC';

interface EventsTableFilters {
  search?: string;
  sort_by?: SortBy;
  direction?: SortDirection;
}

interface EventsTableProps {
  newEvent: Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  events: SpecialEvent[];
}

interface ModalState {
  clone: {
    show: boolean;
    target: any;
  };
  edit: {
    show: boolean;
    target: any;
  };
  delete: {
    show: boolean;
    target: any;
  };
}

export const EventsTable = ({ newEvent, loading, events }: EventsTableProps) => {
  const { message } = App.useApp();
  const [filters, setFilters] = useState<EventsTableFilters>({});
  const [urlParams, setUrlParams] = useUrlParams();
  //TODO I could add something to urlParams that has allowed string values for columns and sort directions to prevent a weird URL from being used
  const { search } = urlParams;

  const [modalState, setModalState] = useState<ModalState>({
    clone: {
      show: false,
      target: null
    },
    edit: {
      show: false,
      target: null
    },
    delete: {
      show: false,
      target: null
    }
  });

  useEffect(() => {
    setFilters({
      search: urlParams.search as string,
      sort_by: urlParams.sort_by as SortBy,
      direction: urlParams.direction as SortDirection
    });
  }, [urlParams]);

  const columns: ColumnsType<SpecialEvent> = [
    {
      title: 'NAME',
      render: (event) => {
        return (
          <Link className={s.link} to={`event/${event.id}`}>
            <HighlightedText highlight={(search as string) || ''} text={event.name} />
          </Link>
        );
      }
    },
    {
      title: 'CREATED BY',
      dataIndex: 'user',
      key: 'user',
      width: '150px',
      render: (user) => <HighlightedText highlight={(search as string) || ''} text={user?.name} />
    },
    {
      title: 'LAST UPDATED',
      dataIndex: 'reportingLastUpdated',
      key: 'reportingLastUpdated',
      width: '150px',
      render: (reportingLastUpdated) =>
        reportingLastUpdated ? (
          moment(reportingLastUpdated).format('MM/DD/YY')
        ) : (
          <div className={s.pending}>PENDING</div>
        )
    },
    {
      title: '',
      width: '30px',
      render: (event) => {
        return (
          <AlloyDropdown
            trigger={['click']}
            menu={{
              onClick: (e) => {
                const modalStateUpdate = { ...modalState };
                modalState[e.key as keyof ModalState].show = true;
                modalState[e.key as keyof ModalState].target = event;
                setModalState({ ...modalState });
              },
              items: [
                {
                  label: <div className={s.dropdown}>Edit</div>,
                  key: 'edit'
                },
                {
                  label: 'Clone',
                  key: 'clone'
                },
                {
                  label: <span className={s.red}>Delete</span>,
                  key: 'delete'
                }
              ]
            }}
          >
            <DashOutlined
              data-testid={`po-state-dropdown-${event.id}`}
              rotate={90}
              className={s.menu_icon}
            />
          </AlloyDropdown>
        );
      }
    }
  ];

  return (
    <div className={s.events_table}>
      <div className={s.controls}>
        <div className={s.results}>
          {events.length} Events {urlParams.search}
        </div>
        <div className={s.actions}>
          <AlloyButton type="primary" onClick={() => newEvent(true)}>
            New event
          </AlloyButton>
          <AlloyButton>Clone</AlloyButton>
          <AlloyButton>Delete</AlloyButton>
        </div>
      </div>
      <AlloyTable
        loading={loading}
        columns={columns}
        dataSource={events}
        pagination={false}
        rowKey={(row) => row.id}
        rowSelection={{
          onChange: (selection) => console.log(selection)
        }}
      />
      <AlloyModal
        className={s.modal}
        title={<div className={s.title}>Delete {modalState.delete.target?.name}</div>}
        open={modalState.delete.show}
        onCancel={() => setModalState({ ...modalState, delete: { show: false, target: null } })}
        width={433}
        okButtonProps={{
          danger: true
        }}
        okText="Delete"
        onOk={() => {
          console.log('delete event');
          setModalState({ ...modalState, delete: { ...modalState.delete, show: false } });
          message.success('Deleted');
        }}
      >
        <div className={s.content}>Are you sure you want to delete this event?</div>

        <div className={s.divider_top}></div>
        <div className={s.divider_bottom}></div>
      </AlloyModal>
      <EditEventDrawer
        show={modalState.edit.show}
        eventId={modalState.edit?.target?.id}
        onClose={() => {
          setModalState({ ...modalState, edit: { ...modalState.edit, show: false, target: null } });
        }}
      />
    </div>
  );
};
