import { validateRequired } from 'common/helpers/validationHelper';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { RetailerDeliveryDestination } from 'pages/AssortmentPage/AssortmentPage';
import React from 'react';

export const TradingPartnerForCatalogProductField = ({
  tradingPartner
}: {
  tradingPartner: {
    id: string;
    name?: string | null;
    deliveryType?: string | null;
    retailerDeliveryDestinations: RetailerDeliveryDestination[];
  };
}) => (
  <AlloyFormField
    data-testid="aa-edit-modal-trading-partner-select"
    component="select"
    name="tradingPartnerId"
    validate={validateRequired}
    fieldProps={{
      title: 'Trading Partner',
      options: [{ value: tradingPartner.id, label: tradingPartner.name || '' }],
      disabled: true
    }}
  />
);
