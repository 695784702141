import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Field, Form } from 'react-final-form';
import s from './AdvancedFilterComponent.module.scss';
import { AlloyInput, AlloyTextArea } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';

interface SaveFilterModalProps {
  onSave: (name: string, description: string) => void;
  onCancel: () => void;
  existingNames: string[];
}

export const SaveFilterModal = ({ onSave, onCancel, existingNames }: SaveFilterModalProps) => {
  const onSubmit = (values: any) => {
    const { name, description } = values;
    onSave(name, description);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => (
        <AlloyModal
          classNames={{
            body: s.save_filter_modal_body,
            header: s.save_filter_modal_header,
            footer: s.save_filter_modal_footer,
            content: s.save_filter_modal_content
          }}
          data-testid="save-filter-modal"
          title={<span data-testid="save-filter-modal-title">Save Filter</span>}
          open
          maskClosable={false}
          closeIcon={<CloseOutlined />}
          closable={true}
          onCancel={onCancel}
          onOk={() => form.submit()}
          okType="primary"
          okText="Save"
          okButtonProps={{
            // @ts-ignore
            'data-testid': 'save-filter-popup-confirm'
          }}
          cancelButtonProps={{
            // @ts-ignore
            'data-testid': 'save-filter-popup-cancel'
          }}
        >
          <form onSubmit={handleSubmit}>
            <AlloyFormField
              component="input"
              name="name"
              validate={(value) =>
                value && value.length > 0
                  ? existingNames.includes(value)
                    ? 'A filter with the same name already exists'
                    : undefined
                  : 'Required'
              }
              fieldProps={{
                title: 'Filter Title',
                autoComplete: 'off'
              }}
            />
            <AlloyFormField
              component="textarea"
              name="description"
              fieldProps={{
                title: 'Description',
                autoComplete: 'off',
                autoSize: { minRows: 3, maxRows: 3 }
              }}
            />
          </form>
        </AlloyModal>
      )}
    />
  );
};
