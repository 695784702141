import React, { useState, useContext } from 'react';
import s from './NewEventModal.module.scss';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloySteps } from 'components/ui/AlloySteps/AlloySteps';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { Form, useFormState } from 'react-final-form';
import { EditEventDetails } from '../EditEventDetails/EditEventDetails';
import { EditEventWatchlist } from '../EditEventWatchlist/EditEventWatchlist';
import { UpsertSpecialEventDocument } from 'pages/EventTracker/gql/__generated__/upsertSpecialEvent.mutation';
import { useMutation } from '@apollo/client';
import { App } from 'ant5';

interface NewEventModal {
  close: () => void;
}

export const NewEventModal = (props: NewEventModal) => {
  return <Form onSubmit={() => {}}>{() => <Modal {...props} />}</Form>;
};

const Modal = ({ close }: NewEventModal) => {
  const { message, notification } = App.useApp();
  const [currentStep, setCurrentStep] = useState(0);

  const form = useFormState();

  const [createSpecialEvent, { loading }] = useMutation(UpsertSpecialEventDocument, {
    onCompleted: () => {
      message.success(`${form.values.name} Created`);
      onClose();
    },
    onError: (error) => {
      notification.error({
        message: 'Error',
        description: error.message
      });
      console.error(error.message);
    },
    refetchQueries: ['specialEvents']
  });

  const onClose = () => {
    setCurrentStep(0);
    close();
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const disableNext = () => {
    if (currentStep === 0 && form.valid) return false;
    else if (
      currentStep === 1 &&
      (form.values.watchlistFile?.purchaseOrders.length ||
        form.values.watchlistFile?.purchaseOrders.length)
    )
      return false;
    else return true;
  };

  const EventDetails = <EditEventDetails />;

  const EventWatchlist = <EditEventWatchlist />;

  const ConfirmDetails = (
    <div className={s.confirm_details}>
      Found orders and external IDs to be added:
      <ul>
        {!!form.values.watchlistFile?.purchaseOrders?.length && (
          <li>
            {form.values.watchlistFile?.purchaseOrders?.length} purchase order
            {form.values.watchlistFile?.purchaseOrders?.length > 1 ? 's' : ''}
          </li>
        )}
        {!!form.values.watchlistFile?.externalIds?.length && (
          <li>
            {form.values.watchlistFile?.externalIds?.length} external ID
            {form.values.watchlistFile?.externalIds?.length > 1 ? 's' : ''}
          </li>
        )}
      </ul>
    </div>
  );

  const submit = () => {
    createSpecialEvent({
      variables: {
        input: {
          name: form.values.name,
          businessUnits: form.values.businessUnits,
          customers: form.values.customers,
          eventDateStart: form.values.startDate.format('YYYY-MM-DD'),
          eventDateEnd: form.values.endDate.format('YYYY-MM-DD'),
          private: form.values.isPrivate,
          retailerId: 'UmV0YWlsZXI6MQ==', //amazon
          watchlistFile: {
            filename: form.values.watchlistFile?.filename as string,
            contents: form.values.watchlistFile?.contents as string
          }
        }
      }
    });
  };

  return (
    <AlloyModal
      className={s.new_event_modal}
      open={true}
      title="Create new event"
      width={600}
      onCancel={onClose}
      footer={false}
    >
      <div className={s.header_border}></div>
      <AlloySteps
        className={s.steps}
        size="small"
        current={currentStep}
        items={[
          {
            title: 'Add Event details'
          },
          {
            title: 'Add Event watchlist'
          },
          {
            title: 'Confirm Products'
          }
        ]}
      />

      {currentStep === 0 ? EventDetails : currentStep === 1 ? EventWatchlist : ConfirmDetails}

      <div className={s.footer_border}></div>
      <div className={s.footer}>
        <AlloyButton type="link" onClick={onClose}>
          Cancel
        </AlloyButton>
        <div className={s.right}>
          {currentStep !== 0 && <AlloyButton onClick={previousStep}>Back</AlloyButton>}
          {currentStep < 2 && (
            <AlloyButton type="primary" onClick={nextStep} disabled={disableNext()}>
              Next
            </AlloyButton>
          )}
          {currentStep == 2 && (
            <AlloyButton
              type="primary"
              disabled={(() => {
                if (form.error) return true;
                else return false;
              })()}
              loading={loading}
              onClick={submit}
            >
              Add
            </AlloyButton>
          )}
        </div>
      </div>
    </AlloyModal>
  );
};
