import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { ModalAction } from 'common/constants';
import { getShowCountData } from 'pages/AssortmentPage/fieldsHelper';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { composeValidators } from 'common/helpers/validationHelper';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { getValidators } from '../../../fieldsHelper';
import { spaceRemover } from '../../../fieldsHelper';
import {
  AssortmentConfig,
  ExtractedFormField,
  FormField
} from 'pages/AssortmentPage/AssortmentPage';
import {
  FindRetailerProductsDocument,
  FindRetailerProductsQuery
} from 'pages/AssortmentPage/gql/__generated__/findRetailerProduct';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { RetailerProductExternalIdType } from 'graphql/__generated__/types';
import { FoundItemLink } from '../../EditTradingPartnerAssortment/components/FoundItemLink/FoundItemLink';
import { FindCatalogProductsDocument } from 'pages/AssortmentPage/gql/__generated__/findCatalogProducts.query';
import { fillFormWithCatalogProduct } from '../EditAssortmentModal';

export type VendorProduct = InferNodeType<FindRetailerProductsQuery, 'retailerProducts'>;

interface ExternalIdForCatalogProductFieldProps {
  vendorId: string | undefined;
  retailerProductExternalIdType?: RetailerProductExternalIdType;
  changeToUpdate: (vendorProduct: VendorProduct) => void;
  action: ModalAction;
  formField: ExtractedFormField | FormField;
  assortmentConfig: AssortmentConfig;
}

export const ExternalIdForCatalogProductField = ({
  vendorId,
  retailerProductExternalIdType,
  changeToUpdate,
  action,
  formField,
  assortmentConfig
}: ExternalIdForCatalogProductFieldProps) => {
  const form = useForm();

  const [findAssortmentItem, { data, loading }] = useLazyQuery(FindRetailerProductsDocument, {});

  const [findCatalogProducts, { data: catalogProductsData, loading: catalogProductsLoading }] =
    useLazyQuery(FindCatalogProductsDocument);

  const foundItems = useMemo(
    () =>
      getNodesFromEdges(data?.retailerProducts).sort((i1, i2) =>
        i1.externalId.localeCompare(i2.externalId)
      ),
    [data]
  );

  const foundCatalogItems = useMemo(
    () =>
      getNodesFromEdges(catalogProductsData?.catalogProducts).sort((i1, i2) =>
        (i1.externalId || '').localeCompare(i2.externalId || '')
      ),
    [catalogProductsData]
  );

  const [showFoundItems, setShowFoundItems] = useState<boolean>(false);

  useEffect(() => {
    if (!!vendorId && action === ModalAction.ADD) {
      const value = form.getFieldState('vendorProductExternalId')?.value as string;
      if (value && value.length > 2 && form.getFieldState('vendorProductExternalId')?.modified) {
        findAssortmentItem({
          variables: {
            vendorId: vendorId,
            searchLike: value
          }
        });
        if (retailerProductExternalIdType) {
          findCatalogProducts({
            variables: {
              filter: {
                externalIdLike: value,
                externalIdType: retailerProductExternalIdType,
                catalogItemType: assortmentConfig.catalogItemType
              }
            }
          });
        }
        setShowFoundItems(true);
      }
    }
  }, [
    findAssortmentItem,
    findCatalogProducts,
    vendorId,
    form,
    action,
    formField.validation,
    retailerProductExternalIdType,
    assortmentConfig.catalogItemType
  ]);

  return (
    <>
      <AlloySpin spinning={loading || catalogProductsLoading}>
        <AlloyFormField
          data-testid={'aa-edit-modal-externalId-field'}
          component="input"
          name="vendorProductExternalId"
          required={formField.required}
          validate={
            action === ModalAction.ADD ? composeValidators(getValidators(formField)) : undefined
          }
          fieldProps={{
            title: formField.name,
            disabled: action === ModalAction.EDIT
          }}
          parse={spaceRemover}
          countRule={getShowCountData(formField)}
        />
        {action === ModalAction.ADD &&
          showFoundItems &&
          (!!foundItems.length || !!foundCatalogItems.length) && (
            <FoundItemLink
              foundItems={[...foundItems, ...foundCatalogItems]}
              setShowFoundItems={setShowFoundItems}
              changeToUpdate={changeToUpdate}
              fillFormWithCatalogProduct={(catalogProduct) =>
                fillFormWithCatalogProduct(
                  catalogProduct,
                  form,
                  'substitutions[0]',
                  true,
                  assortmentConfig
                )
              }
              tooltip="any"
              assortmentConfig={assortmentConfig}
              findPropertyName="externalId"
            />
          )}
      </AlloySpin>
      <OnChange name="vendorProductExternalId">
        {(value: string) => {
          if (
            action === ModalAction.ADD &&
            !!vendorId &&
            value &&
            value.length > 2 &&
            form.getFieldState('vendorProductExternalId')?.modified
          ) {
            findAssortmentItem({
              variables: {
                searchLike: value,
                vendorId: vendorId
              }
            });
            if (retailerProductExternalIdType) {
              findCatalogProducts({
                variables: {
                  filter: {
                    externalIdLike: value,
                    externalIdType: retailerProductExternalIdType,
                    catalogItemType: assortmentConfig.catalogItemType
                  }
                }
              });
            }
            setShowFoundItems(true);
          }
        }}
      </OnChange>
    </>
  );
};
