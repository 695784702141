import React, { useEffect, useMemo, useRef, useState } from 'react';
import s from './CutsView.module.scss';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { useQuery } from '@apollo/client';
import {
  WarehouseCutsProcessedDocument,
  WarehouseCutsProcessedQuery
} from './gql/__generated__/warehouseCutsProcessed.query';
import { CutSortColumn, SortOrderDirection } from 'graphql/__generated__/types';
import { StringParam, useQueryParam } from 'use-query-params';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { notEmpty } from 'common/helpers/notEmpty';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { EMPTY } from 'common/constants';
import { dateFormat } from 'common/helpers/date';
import { SearchOutlined } from '@ant-design/icons';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { App, Empty } from 'ant5';

type WarehouseCutsProcessed = InferNodeType<WarehouseCutsProcessedQuery, 'warehouseCutsProcessed'>;

interface CsvCutItem {
  customerPo: string | null | undefined;
  cutReason: string | null | undefined;
  cuts: string | null | undefined;
  materialDescription: string | null | undefined;
  materialNumber: string | null | undefined;
  orderNumber: string;
  reqDeliveryDate: string | null | undefined;
  shipDate: string | null | undefined;
  shipTo: string | null | undefined;
}

interface CsvCutsHeader {
  label: string;
  key: keyof CsvCutItem;
}

const getRowKey = (record: WarehouseCutsProcessed) =>
  `${record.customerPo}-${record.orderNumber}-${record.materialNumber}`;

const TABLE_WIDTH = 1590;

export const CutsView = () => {
  const { message } = App.useApp();
  const [cutsSortColumn, setCutsSortColumn] = useQueryParam('cut_column', StringParam);
  const [cutsSortOrder, setCutsSortOrder] = useQueryParam('cut_order', StringParam);
  //   const [search, setSearch] = useQueryParam('cut_search', StringParam);
  const [csvCutsHeaders, setCsvHeaders] = useState<CsvCutsHeader[]>([]);
  const [csvCutsData, setCsvCutsData] = useState<CsvCutItem[]>([]);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [downloadTimestamp, setDownloadTimestamp] = useState(moment());

  // On page load/render, call the warehouseCutsProcessed query
  const { data, loading, error } = useQuery(WarehouseCutsProcessedDocument, {
    variables: {
      first: 100000,
      sort: {
        column: cutsSortColumn as CutSortColumn,
        direction: cutsSortOrder as SortOrderDirection
      }
    }
  });

  const cutsProcessedList = useMemo(() => getNodesFromEdges(data?.warehouseCutsProcessed), [data]);

  useEffect(() => {
    if (!cutsSortColumn || !cutsSortOrder) {
      setCutsSortColumn('SHIP_TO');
      setCutsSortOrder('DESC');
    }
  }, [cutsSortColumn, cutsSortOrder, setCutsSortColumn, setCutsSortOrder]);

  const handleTableSorting = (column: string, order: string) => {
    setCutsSortColumn(column);
    setCutsSortOrder(order);
  };

  const handleCsvLinkClick = () => {
    if (cutsProcessedList && cutsProcessedList.length) {
      setCsvHeaders([
        { label: 'PO Number', key: 'customerPo' },
        { label: 'Order Number', key: 'orderNumber' },
        { label: 'Material ID', key: 'materialNumber' },
        { label: 'Description', key: 'materialDescription' },
        { label: 'Cut Reason', key: 'cutReason' },
        { label: 'Cut Qty', key: 'cuts' },
        { label: 'Ship To', key: 'shipTo' },
        { label: 'Ship Date', key: 'shipDate' },
        { label: 'Suggested RDD', key: 'reqDeliveryDate' }
      ]);

      const cutsList: CsvCutItem[] = cutsProcessedList.map((cut) => {
        return {
          customerPo: cut.customerPo,
          cutReason: cut.cutReason,
          cuts: cut.cuts,
          materialDescription: cut.materialDescription,
          materialNumber: cut.materialNumber,
          orderNumber: cut.orderNumber,
          reqDeliveryDate: dateFormat(cut.reqDeliveryDate),
          shipDate: dateFormat(cut.shipDate),
          shipTo: cut.shipTo
        };
      });
      setCsvCutsData(cutsList);
      setDownloadTimestamp(moment());
      message.success({ content: 'You have successfully exported the cuts list!', duration: 4.5 });
    } else {
      setCsvCutsData([]);
      setCsvHeaders([]);
      message.error({ content: 'Cuts list failed to export. Please try again.', duration: 4.5 });
    }
  };

  const cutsTableColumns: ColumnsType<WarehouseCutsProcessed> = [
    {
      title: 'PO Number',
      key: 'CUSTOMER_PO',
      width: '125px',
      render: (_, record) => record.customerPo,
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'CUSTOMER_PO'),
      sorter: true
    },
    {
      title: 'Order Number',
      key: 'ORDER_NUMBER',
      width: '148px',
      render: (_, record) => record.orderNumber,
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'ORDER_NUMBER'),
      sorter: true
    },
    {
      title: 'Material ID',
      key: 'MATERIAL_NUMBER',
      width: '175px',
      render: (_, record) => record.materialNumber,
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'MATERIAL_NUMBER'),
      sorter: true
    },
    {
      title: 'Description',
      key: 'MATERIAL_DESCRIPTION',
      render: (_, record) => record.materialDescription,
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'MATERIAL_DESCRIPTION'),
      sorter: true
    },
    {
      title: 'Cut Reason',
      key: 'CUT_REASON',
      width: '248px',
      render: (_, record) => record.cutReason,
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'CUT_REASON'),
      sorter: true
    },
    {
      title: 'Cut Qty',
      key: 'CUTS',
      width: '100px',
      align: 'right',
      render: (_, record) => <span className={s.number_value}>{record.cuts ?? EMPTY}</span>,
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'CUTS'),
      sorter: true
    },
    {
      title: 'Ship To',
      key: 'SHIP_TO',
      width: '216px',
      align: 'right',
      render: (_, record) => record.shipTo,
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'SHIP_TO'),
      sorter: true
    },
    {
      title: 'Ship Date',
      key: 'SHIP_DATE',
      align: 'right',
      width: '128px',
      render: (_, record) => dateFormat(record.shipDate),
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'SHIP_DATE'),
      sorter: true
    },
    {
      title: 'Suggested RDD',
      key: 'REQ_DELIVERY_DATE',
      width: '128px',
      align: 'right',
      render: (_, record) => dateFormat(record.reqDeliveryDate),
      sortOrder: getColumnSortOrder(cutsSortColumn, cutsSortOrder, 'REQ_DELIVERY_DATE'),
      sorter: true
    }
  ];

  const EmptyCutsTableText = <div className={s.cuts_table_empty_state_title}>No cut items</div>;

  if (error) return <ErrorDisplay error={error} />;

  return (
    <>
      <div className={s.table_tools_container}>
        <div
          data-testid="cuts-search-and-filters-container"
          className={s.search_and_filters_container}
        >
          <div className={s.search_bar}>
            <AlloyInput
              placeholder="Search"
              allowClear={true}
              prefix={<SearchOutlined width="14px" height="14px" />}
              disabled={true} //TODO: search to be enabled in a future ticket
            />
          </div>
          <AlloySelect
            className={s.select}
            placeholder="Filter"
            allowClear
            options={[
              {
                key: 'all',
                value: 'all',
                label: 'All'
              }
            ]}
            disabled={true} //TODO: filter to be implemented in a future ticket
          />
        </div>
        <div className={s.table_actions_container}>
          <AlloyButton
            data-testid="export-cuts-button"
            size="large"
            disabled={!(cutsProcessedList.length > 0) || loading}
          >
            <CSVLink
              filename={`order-cuts-${downloadTimestamp?.format('MM-DD-YYYY_hh-mm-ss_A')}.csv`}
              ref={csvLinkRef}
              headers={csvCutsHeaders}
              data={csvCutsData}
              onClick={() => handleCsvLinkClick()}
            >
              Export
            </CSVLink>
          </AlloyButton>
        </div>
      </div>

      <div className={s.quantity_display_and_export_container}>
        <QuantityDisplay
          count={data?.warehouseCutsProcessed?.totalCount || 0}
          titleSingular={'Cut'}
          titleMultiple={'Cuts'}
        />
      </div>
      <AlloyTable
        data-testid="cuts-view-table"
        className={s.cuts_view_table}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              data-testid="cuts-table-empty-state"
              className={s.cuts_table_empty_state}
              description={EmptyCutsTableText}
            />
          )
        }}
        scroll={{ x: TABLE_WIDTH }}
        sticky
        pagination={false}
        columns={cutsTableColumns.filter(notEmpty)}
        dataSource={cutsProcessedList}
        onChange={(_pagination, _filters, sorter) => {
          const column =
            String((sorter as SorterResult<WarehouseCutsProcessed>).columnKey) || 'SHIP_TO';
          const order =
            (sorter as SorterResult<WarehouseCutsProcessed>).order === 'ascend' ||
            !(sorter as SorterResult<WarehouseCutsProcessed>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column, order);
        }}
        rowKey={getRowKey}
      />
    </>
  );
};
