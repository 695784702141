import { DeleteOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useQueryParam, withDefault, JsonParam, UrlUpdateType } from 'use-query-params';
import st from './TableFilters.module.scss';
import { isEqual } from 'lodash-es';
import { notEmpty } from 'common/helpers/notEmpty';
import { AlloyPopover } from 'components/ui/AlloyPopover/AlloyPopover';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyRadio } from 'components/ui/AlloyRadio/AlloyRadio';
import { AlloySpace } from 'components/ui/AlloySpace/AlloySpace';
import { FilterOption } from './types';
import { MultipleSelectWithSearch } from './MultipleSelectWithSearch/MultipleSelectWithSearch';

type FilterType = 'single' | 'multiple';

type FilterBase<T extends string = string> = {
  name: string;
  type: FilterType;
  short?: string;
  field: T;
};

export type Filter<T extends string = string> = FilterBase<T> & {
  options: FilterOption[];
};

export type FilterIncomplete<T extends string = string> = FilterBase<T> & {
  options?: FilterOption[];
};

type FilterDropdownProps = {
  name: string;
  field: string;
  short?: string;
  options: FilterOption[];
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string | string[]>>>;
  type: FilterType;
};

const FilterMenuOption = ({
  options,
  type,
  name,
  setOuterPopover,
  disabled,
  setFilters,
  field
}: FilterDropdownProps & {
  disabled: boolean;
  setOuterPopover: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <AlloyPopover
      open={disabled ? false : open}
      onOpenChange={setOpen}
      arrow={false}
      placement="right"
      overlayClassName={st.filterPopowerWrapper}
      content={
        <FilterDropdownContent
          name={name}
          field={field}
          setFilters={setFilters}
          options={options}
          type={type}
          onCancel={() => {
            setOpen(false);
            setOuterPopover(false);
          }}
        />
      }
      trigger="click"
    >
      <AlloyButton type="text" block disabled={disabled} data-testid={`add-filter-${name}`}>
        {name}
      </AlloyButton>
    </AlloyPopover>
  );
};

const ExistingFilter = ({
  options,
  type,
  name,
  short,
  setFilters,
  filters,
  field,
  loading
}: FilterDropdownProps & { filters: Record<string, string | string[]>; loading: boolean }) => {
  const [open, setOpen] = useState(false);

  const value = filters?.[field];
  const displayValue = Array.isArray(value)
    ? value
        .map((x) => (x ? options.find((option) => option.value === x)?.name || x : '[N/A]'))
        .slice(0, 8)
        .join(', ')
    : options.find((option) => option.value === value)?.name || value;

  return (
    <AlloyPopover
      open={open}
      onOpenChange={(visible) => setOpen(visible)}
      placement="bottomLeft"
      overlayClassName={st.popoverWrapper}
      destroyTooltipOnHide
      arrow={false}
      title={type === 'single' ? name : null}
      content={
        <FilterDropdownContent
          name={name}
          field={field}
          setFilters={setFilters}
          options={options}
          type={type}
          onCancel={() => {
            setOpen(false);
          }}
          existing
          filters={filters}
        />
      }
      trigger="click"
    >
      <AlloyButton
        style={{ height: '40px', display: 'flex', alignItems: 'center', gap: '4px' }}
        data-testid={`edit-filter-${name}`}
        disabled={loading}
      >
        {short || name}: {loading ? '...' : <span className={st.displayValue}>{displayValue}</span>}
        <DownOutlined style={{ fontSize: '8px' }} />
      </AlloyButton>
    </AlloyPopover>
  );
};

const FilterDropdownContent = ({
  options,
  type,
  onCancel,
  setFilters,
  field,
  existing,
  filters
}: FilterDropdownProps & {
  onCancel: () => void;
  existing?: boolean;
  filters?: Record<string, string | string[]>;
}) => {
  const initial =
    filters && existing ? filters[field] : type === 'single' ? options?.[0].value || '' : [];
  const [state, setState] = useState(initial);
  const [error, setError] = useState(false);

  const checkIfArrayIsEmpty = (value: string | string[]): boolean => {
    if (!Array.isArray(value)) {
      return false;
    } else {
      return value.length === 0;
    }
  };

  const applyFilter = () => {
    if (checkIfArrayIsEmpty(state)) {
      setError(true);
      return;
    }
    setError(false);

    setFilters((prev) => ({
      ...prev,
      [field]: state
    }));
    onCancel();
  };

  const deleteFilter = () => {
    setFilters((prev) => {
      const { [field]: _toDelete, ...rest } = prev;
      return { ...rest };
    });
    onCancel();
  };

  return (
    <div className={st.filterDropdown}>
      {type === 'single' ? (
        <>
          <AlloyRadio.Group onChange={(e) => setState(e.target.value)} value={state}>
            <AlloySpace direction="vertical">
              {(options || []).map(({ name, value }) => (
                <AlloyRadio
                  value={value}
                  key={name}
                  data-testid={`option-${value || 'n-a'}`}
                  className={st.radio_option}
                >
                  {name}
                </AlloyRadio>
              ))}
            </AlloySpace>
          </AlloyRadio.Group>
        </>
      ) : (
        <MultipleSelectWithSearch
          values={state as string[]}
          setValues={setState as React.Dispatch<React.SetStateAction<string[]>>}
          options={options}
        />
      )}
      <div className={st.buttons}>
        {existing ? (
          <AlloyButton
            data-testid={`delete-filter`}
            icon={<DeleteOutlined />}
            size="small"
            type="text"
            onClick={() => deleteFilter()}
          />
        ) : (
          <></>
        )}
        <AlloyButton
          size="small"
          onClick={() => onCancel()}
          block={type === 'multiple'}
          data-testid={`cancel-filter-change`}
        >
          Cancel
        </AlloyButton>
        <AlloyButton
          type="primary"
          size="small"
          onClick={() => applyFilter()}
          block={type === 'multiple'}
          data-testid={`apply-filter-change`}
        >
          Apply Filter
        </AlloyButton>
      </div>
      {error && checkIfArrayIsEmpty(state) ? (
        <div className={st.error}>Please select at least one value</div>
      ) : null}
    </div>
  );
};

export const TableFilters = ({
  filters,
  resetSelectedRows = () => {},
  queryParamName = 'filters', // TODO: move outside?
  loading = false
}: {
  filters: Filter[];
  resetSelectedRows?: () => void;
  queryParamName?: string;
  loading?: boolean;
}) => {
  // TODO: rewrite using useQueryParams to increase type safety?
  const [queryFilters, setQueryFilters] = useQueryParam(queryParamName, withDefault(JsonParam, {}));

  // Clever function to reset filters on query change. Anything to avoid useEffects.
  const setQueryFiltersAndResetRows = (newValue: any, updateType?: UrlUpdateType | undefined) => {
    if (!isEqual(newValue, queryFilters)) {
      resetSelectedRows();
    }
    setQueryFilters(newValue, updateType);
  };

  const [addOpen, setAddOpen] = useState(false);
  const displayedFilters = Object.keys(queryFilters)
    .map((x) => filters.find(({ field }) => field === x))
    .filter(notEmpty);

  return (
    <div className={st.filters}>
      {displayedFilters.map(({ name, options, type, short, field }) => (
        <ExistingFilter
          key={name}
          short={short}
          name={name}
          field={field}
          setFilters={setQueryFiltersAndResetRows}
          options={options}
          type={type}
          filters={queryFilters}
          loading={loading}
        />
      ))}
      <AlloyPopover
        open={addOpen}
        onOpenChange={(visible) => setAddOpen(visible)}
        placement="bottomLeft"
        overlayClassName={st.popoverWrapper}
        destroyTooltipOnHide
        arrow={false}
        content={
          <div className={st.menu}>
            {filters && filters.length > 0 ? (
              filters.map(({ name, options, type, field }) => (
                <FilterMenuOption
                  setFilters={setQueryFiltersAndResetRows}
                  disabled={!!queryFilters?.[field]}
                  key={name}
                  name={name}
                  field={field}
                  options={options}
                  type={type}
                  setOuterPopover={setAddOpen}
                />
              ))
            ) : (
              <div className={st.empty}>No filters provided</div>
            )}
          </div>
        }
        trigger="click"
      >
        <AlloyButton
          icon={<PlusOutlined />}
          size="large"
          onClick={() => setAddOpen(true)}
          data-testid="add-filter"
          disabled={loading}
        >
          Add Filter
        </AlloyButton>
      </AlloyPopover>
      {displayedFilters.length > 0 ? (
        <AlloyButton
          type="link"
          style={{ fontWeight: 'bold' }}
          onClick={() => setQueryFiltersAndResetRows({})}
          size="large"
          data-testid="clear-filters"
          disabled={loading}
        >
          Clear
        </AlloyButton>
      ) : (
        <></>
      )}
    </div>
  );
};
