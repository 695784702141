import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import s from './OrdersPage.module.scss';
import { DateRange, LocationUpdate, NumberRange } from 'common/interfaces';
import { SearchOutlined } from '@ant-design/icons';
import { SortingType } from 'common/types';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { parse, ParsedQuery, stringify } from 'query-string';
import OrdersListToolbar from 'pages/OrdersPage/components/OrdersListToolbar/OrdersListToolbar';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import ExportItemsModal, {
  ExportType
} from 'pages/OrdersPage/components/ExportItemsModal/ExportItemsModal';
import ExportPoModal from 'pages/OrdersPage/components/ExportPoModal/ExportPoModal';
import ReleaseOrdersModal from 'pages/OrdersPage/components/ReleaseOrdersModal/ReleaseOrdersModal';
import ExportSoItemsModal from 'pages/OrdersPage/components/ExportSoItemsModal/ExportSoItemsModal';
import BulkDownloadFileModal from 'pages/OrdersPage/components/BulkDownloadFileModal/BulkDownloadFileModal';
import { OrdersTable } from './components/OrdersTable/OrdersTable';
import { Paginator } from 'components/Paginator/Paginator';
import { searchStringToQueryFilter } from './components/AdvancedFilters/AdvancedFilters';
import { isEqual } from 'lodash-es';
import {
  SortOrderDirection,
  PurchaseOrderSort,
  PurchaseOrderSearchSort
} from 'graphql/__generated__/types';
import {
  PurchaseOrdersForPurchaseOrdersPageDocument,
  PurchaseOrdersForPurchaseOrdersPageQuery
} from './gql/__generated__/purchaseOrdersForPurchaseOrdersPage.query';
import { getNodesFromEdges, locationPropertyToStringOrNull } from 'common/helpers/mappingHelper';
import { PurchaseOrder } from './types';
import { ExportSeedDataModal } from 'pages/OrdersPage/components/OrdersListToolbar/components/ExportSeedDataModal';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { App } from 'ant5';
import { dateFormat } from 'common/helpers/date';
import { OrdersSummary } from './components/OrdersSummary/OrdersSummary';
import { PurchaseOrderSort as PurchaseOrderSortEnum } from 'graphql/__generated__/enums';
import { getItem, setItem } from 'common/services/persistentStorageServices';

interface ExportModalState {
  visible: boolean;
  type: ExportType;
}

interface SortConfig {
  columnKey: string;
  order: string;
  limit: string | null;
}

type AllExportTypes = 'PO' | ExportType;

const PAGE_SIZE_LOCAL_STORAGE_FIELD_NAME = 'pageSize';

const OldSortColumnKey: Map<PurchaseOrderSearchSort, PurchaseOrderSort> = new Map([
  ['BUSINESS_UNIT_CODE', 'BUSINESS_UNIT'],
  ['CUSTOMER_PO', 'CUSTOMER_PO'],
  ['PO_DELIVERY_WINDOW_END', 'DELIVERY_WINDOW_END'],
  ['ORDER_DATE', 'ORDER_DATE'],
  ['SHIP_TO_EXTERNAL_ID', 'RETAILER_ADDRESS'],
  ['PRIMARY_OPERATIVE_STATUS_INDEX', 'STATUS'],
  ['TRADING_PARTNER_EXTERNAL_ID', 'TRADING_PARTNER_CODE'],
  ['LAST_UPDATED_AT', 'UPDATED_AT'],
  ['RETAILER_CHANNEL_EXTERNAL_ID', 'VENDOR_MARKET']
]);

export const getSortConfig = (location: ParsedQuery): SortConfig => {
  return Object.assign(
    {
      columnKey: 'ORDER_DATE',
      order: 'DESC',
      limit: null
    },
    location
  );
};
//add new filter name and value here
const defaultFilters = {
  before: null,
  after: null,
  deliveryType: null,
  tradingPartners: null,
  distributionCenters: null,
  customers: null,
  deliveryDestinations: null,
  mustArriveByDate_start: null,
  mustArriveByDate_end: null,
  salesOrderMustArriveByDate_start: null,
  salesOrderMustArriveByDate_end: null,
  businessUnits: null,
  purchaseOrderErrors: null,
  purchaseOrderConfirmation: null,
  dateReceived_start: null,
  dateReceived_end: null,
  deliveryWindow_start: null,
  deliveryWindow_end: null,
  labels: null,
  customerPos: null,
  withCuts: null,
  shipmentsRange_min: null,
  shipmentsRange_max: null,
  // for new search filters
  retailerChannelIds: null,
  tradingPartnerIds: null,
  businessUnitIds: null,
  distributionCenterIds: null,
  deliveryDestinationIds: null
};

const OrdersPage = () => {
  const history = useHistory();
  const routerLocation = useLocation();
  const location = parse(history.location.search);
  const sortConfig = getSortConfig(location);

  const { message } = App.useApp();

  const [limit] = useQueryParam(
    'limit',
    withDefault(
      NumberParam,
      getItem(PAGE_SIZE_LOCAL_STORAGE_FIELD_NAME)?.OrdersPage ?? DEFAULT_PAGE_SIZE
    )
  );

  const [exportModalState, setExportModalState] = useState<ExportModalState>({
    visible: false,
    type: 'FULL'
  });
  const [shareEmailModalVisibility, setShareEmailModalVisibility] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<PurchaseOrder[]>([]);
  const [exportPoModalVisible, setExportPoModalVisible] = useState(false);
  const [exportSoItemsModalVisible, setExportSoItemsModalVisible] = useState(false);
  const [showBulkDownloadFileModal, setShowBulkDownloadFileModal] = useState(false);
  const [exportSeedDataModalVisible, setExportSeedDataModalVisible] = useState(false);

  const clearSelectedOrders = () => {
    setSelectedOrders([]);
  };

  // TODO: remove this, it doesn't seem to be necessary, or replace with actual cache merging.
  const updatePurchaseOrderQuery = (
    previousResult: PurchaseOrdersForPurchaseOrdersPageQuery,
    { fetchMoreResult }: { fetchMoreResult: PurchaseOrdersForPurchaseOrdersPageQuery }
  ): PurchaseOrdersForPurchaseOrdersPageQuery => {
    if (!fetchMoreResult?.purchaseOrders) return previousResult;

    const { edges: newEdges, pageInfo, totalCount } = fetchMoreResult.purchaseOrders;
    return newEdges?.length
      ? {
          purchaseOrders: {
            __typename: previousResult?.purchaseOrders?.__typename || 'PurchaseOrderConnection',
            edges: [...newEdges],
            pageInfo,
            totalCount
          }
        }
      : previousResult;
  };

  const { error, data, fetchMore, loading } = useQuery(
    PurchaseOrdersForPurchaseOrdersPageDocument,
    {
      variables: {
        first: location?.after || !location?.before ? limit : null,
        last: !location?.after && location?.before ? limit : null,
        after: locationPropertyToStringOrNull(location?.after),
        before:
          location?.before && !location?.after
            ? locationPropertyToStringOrNull(location?.before)
            : null,
        sort: {
          column: sortConfig.columnKey as PurchaseOrderSort,
          direction: sortConfig.order as SortOrderDirection
        },
        filter: searchStringToQueryFilter(history.location.search).filterData,
        advancedFilter: searchStringToQueryFilter(history.location.search).advancedFilterData,
        includeCancelled: true
      },
      skip: isQueryParamsHasWrongSortOrFilters(history.location.search)
    }
  );

  const purchaseOrders = useMemo(() => getNodesFromEdges(data?.purchaseOrders), [data]);

  const toggleBulkDownloadFileModal = () =>
    setShowBulkDownloadFileModal(!showBulkDownloadFileModal);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const toggleExportItemsModal = (type: AllExportTypes) => {
    if (type === 'PO') setExportPoModalVisible(true);
    else setExportModalState({ visible: !exportModalState.visible, type });
  };

  const toggleExportSoItemsModal = () => {
    setExportSoItemsModalVisible(!exportSoItemsModalVisible);
  };

  const toggleShareEmailModal = () => {
    setShareEmailModalVisibility(!shareEmailModalVisibility);
  }; // can we rename this to toggleReleaseOrdersModal? Where did this name come from? Is it necessary?

  const onSelectionAction = (selectedRows: PurchaseOrder[]) => {
    setSelectedOrders(selectedRows);
  };

  const handlePageSizeChange = async (value: number) => {
    saveLastPagingToLocalStorage(value);
    updatePaging({ limit: value, after: null, before: null });
  };

  const toggleExportSeedDataModal = () => {
    setExportSeedDataModalVisible(!exportSeedDataModalVisible);
  };

  const updatePaging = useCallback(
    (update?: LocationUpdate, clearState: boolean = true, replace: boolean = false) => {
      const location = parse(history.location.search);
      if (update) {
        Object.keys(update).forEach((key) => {
          const t = update[key];
          location[key] = t === undefined ? null : typeof t === 'number' ? `${t}` : t;

          // w/o !== 0 we would remove 0 from numeric queryparams, and we need them.
          // not sure if we want to preserve '' values, so let's keep it like that;
          if (!update[key] && update[key] !== 0) {
            delete location[key];
          }
        });
      }

      const locationNew = {
        ...routerLocation,
        search: stringify(location),
        state: clearState ? undefined : routerLocation.state
      };
      if (replace) {
        history.replace(locationNew);
      } else {
        history.push(locationNew);
      }
    },
    [history, routerLocation]
  );

  //used to reset the table when the user clicks on PO Management from the hamburger menu
  useEffect(() => {
    // This redirect needs because we have different sort column names under feature flag
    // Remove it with schip_po_page_new_search feature flag (should be `if (history.location.search) return`)
    if (history.location.search) {
      const updatePagingLocation = parse(history.location.search);
      const sortColumnKey = locationPropertyToStringOrNull(updatePagingLocation.columnKey);
      if (isQueryParamsHasWrongSortOrFilters(history.location.search)) {
        updatePaging(
          {
            ...updatePagingLocation,
            columnKey: OldSortColumnKey.get(sortColumnKey as PurchaseOrderSearchSort),
            customerPos: updatePagingLocation.customerPos ?? updatePagingLocation.searchIds,
            searchIds: undefined
          },
          false,
          true
        );
      } else {
        return;
      }
    } else {
      updatePaging(
        {
          columnKey: 'ORDER_DATE',
          order: 'DESC',
          searchText: '',
          cursor: null,
          limit: limit,
          before: null,
          after: null
        },
        false,
        true
      );
    }
  }, [history.location.search, limit, updatePaging]);

  useEffect(() => {
    if (history.location.search) {
      const reloadDataBySearchString = async () => {
        const location = parse(history.location.search);
        const sortConfig = getSortConfig(location);

        try {
          await fetchMore({
            variables: {
              first: location.after || !location.before ? limit : null,
              last: !location.after && location.before ? limit : null,
              after: location.after ? location.after : null,
              before: location.before && !location.after ? location.before : null,
              sort: {
                column: sortConfig.columnKey,
                direction: sortConfig.order
              },
              filter: searchStringToQueryFilter(history.location.search).filterData,
              advancedFilter: searchStringToQueryFilter(history.location.search).advancedFilterData
            },
            updateQuery: updatePurchaseOrderQuery
          });
          if (!routerLocation.state) {
            window.scrollTo(0, 0);
          }
        } catch (error) {
          if (mounted.current) throw error;
        }
      };

      reloadDataBySearchString();
    }
  }, [history.location.search, fetchMore, history, routerLocation, limit]);

  if (error)
    return (
      <div className="permissions">
        <ErrorDisplay error={error} />
      </div>
    );

  const nextPage = async () => {
    updatePaging({ after: data?.purchaseOrders?.pageInfo.endCursor, before: null });
  };

  const prevPage = async () => {
    updatePaging({ before: data?.purchaseOrders?.pageInfo.startCursor, after: null });
  };

  const handleOrdersFilter = (
    filterName: string,
    filterValue: string | string[] | DateRange | NumberRange
  ) => {
    let newFilter;
    if (
      (filterValue as { start: string; end: string })?.start ||
      (filterValue as { start: string; end: string })?.start === ''
    ) {
      newFilter = {
        ...parse(history.location.search),
        [`${filterName}_start`]: (filterValue as { start: string; end: string }).start,
        [`${filterName}_end`]: (filterValue as { start: string; end: string }).end
      };
    } else if (
      ((filterValue as NumberRange).min !== null &&
        (filterValue as NumberRange).min !== undefined) ||
      ((filterValue as NumberRange).max !== null && (filterValue as NumberRange).max !== undefined)
    ) {
      newFilter = {
        ...parse(history.location.search),
        [`${filterName}_min`]: (filterValue as NumberRange).min,
        [`${filterName}_max`]: (filterValue as NumberRange).max
      };
    } else {
      newFilter = {
        ...parse(history.location.search),
        [filterName]: filterValue as string | string[]
      };
    }
    delete newFilter.after;
    delete newFilter.before;
    // clear statuses when update PO search not to hide found POs in not selected statuses
    if (filterName === 'customerPos') {
      delete newFilter.status;
    }
    history.push({
      search: stringify(newFilter)
    });
    clearSelectedOrders();
  };

  const handleTableSorting = (columnKey: string, order: string) => {
    updatePaging({
      columnKey,
      order
    });
  };

  const getSortOrder = (columnKey: string) =>
    sortConfig.columnKey === columnKey
      ? ((sortConfig.order === 'ASC' ? 'ascend' : 'descend') as SortingType)
      : undefined;

  const onApplyAdvancedFilter = (update: LocationUpdate, name?: string) => {
    const currentLocationSearch = parse(history.location.search);
    const previousSearch = {
      ...defaultFilters,
      ...currentLocationSearch
    };
    const newLocationSearch = {
      status: null,
      ...defaultFilters,
      ...update
    };
    if (!isEqual(previousSearch, newLocationSearch)) {
      clearSelectedOrders();
      updatePaging(newLocationSearch);
    }

    if (name) {
      message.success(
        <span data-testid="advanced-filter-applied-message">Filter {name} has been applied</span>
      );
    }
  };

  const { customerPos } = parse(history.location.search);

  return (
    <>
      <OrdersListToolbar
        isUseNewSearch={false}
        clearSelectedOrders={clearSelectedOrders}
        toggleExportSoItemsModal={toggleExportSoItemsModal}
        toggleExportModal={toggleExportItemsModal}
        toggleShareEmailModal={toggleShareEmailModal}
        toggleBulkDownloadFileModal={toggleBulkDownloadFileModal}
        selectedRows={selectedOrders
          .map((selectedPo) => purchaseOrders.find((po) => po.id === selectedPo.id) || selectedPo)
          .map((po) => ({
            id: po.id,
            deliveryType: po.tradingPartner?.deliveryType || '',
            customerPo: po.customerPo || '',
            availableActions: po.availableActions,
            primaryStatus: (po.statuses?.primary || '').toUpperCase(),
            shipTo: po.retailerDeliveryDestination
              ? {
                  id: po.retailerDeliveryDestination.id,
                  name: po.retailerDeliveryDestination?.name || '',
                  externalId: po.retailerDeliveryDestination?.externalId || ''
                }
              : undefined,
            shipToExternalId: po.retailerDeliveryDestinationExternalId || '',
            salesOrderIds: po.salesOrders.map((so) => so.id),
            labelIds: po.labels.map((label) => label.id)
          }))}
        handleOrdersFilter={handleOrdersFilter}
        onApplyAdvancedFilter={onApplyAdvancedFilter}
        toggleExportSeedDataModal={toggleExportSeedDataModal}
        advancedFiltersLoading={false}
      />
      <AlloyRow>
        <OrdersSummary handleOrdersFilter={handleOrdersFilter} />
      </AlloyRow>
      <div data-testid="po-search-bar" className={s.po_search_bar}>
        <MultipleValuesInput
          loading={loading}
          placeholder="Search by PO Number"
          value={customerPos ? (typeof customerPos === 'string' ? [customerPos] : customerPos) : []}
          onChange={(value) => {
            handleOrdersFilter('customerPos', value);
          }}
          allowClear={true}
          prefix={<SearchOutlined width="14px" height="14px" />}
          splitInputValue={/[^0-9a-zA-Z-]+/g}
        />
      </div>
      <div className={s.po_list_quantity_and_paginator}>
        <div className={s.po_list_quantity}>
          <QuantityDisplay
            count={
              selectedOrders.length > 0 ? selectedOrders.length : data?.purchaseOrders?.totalCount
            }
            titleSingular={`${selectedOrders.length > 0 ? 'Selected ' : ''} Order`}
            titleMultiple={`${selectedOrders.length > 0 ? 'Selected ' : ''} Orders`}
          />
          {selectedOrders.length > 0 && (
            <AlloyButton
              data-testid="clear-selection-button"
              onClick={() => onSelectionAction([])}
              className={s.clear_selection_button}
            >
              Clear selection
            </AlloyButton>
          )}
        </div>
        <Paginator
          pageSize={limit}
          hasNextPage={!!data?.purchaseOrders?.pageInfo.hasNextPage}
          hasPreviousPage={!!data?.purchaseOrders?.pageInfo.hasPreviousPage}
          handlePageSizeChange={handlePageSizeChange}
          prevPage={prevPage}
          nextPage={nextPage}
          onlyButtons={false}
        />
      </div>
      <OrdersTable
        loading={loading}
        purchaseOrders={purchaseOrders}
        handleTableSorting={handleTableSorting}
        selectedOrders={selectedOrders.map(
          (selectedPo) => purchaseOrders.find((po) => po.id === selectedPo.id) || selectedPo
        )}
        getSortOrder={getSortOrder}
        onSelectionAction={onSelectionAction}
      />
      <AlloyRow className={s.po_list_bottom_paginator}>
        <Paginator
          pageSize={limit}
          hasNextPage={!!data?.purchaseOrders?.pageInfo.hasNextPage}
          hasPreviousPage={!!data?.purchaseOrders?.pageInfo.hasPreviousPage}
          handlePageSizeChange={handlePageSizeChange}
          prevPage={prevPage}
          nextPage={nextPage}
          onlyButtons={true}
        />
      </AlloyRow>
      <BulkDownloadFileModal
        visibility={showBulkDownloadFileModal}
        toggleModal={toggleBulkDownloadFileModal}
        selectedRowIds={selectedOrders.map((selectedOrder) => selectedOrder.id)}
      />
      <ExportItemsModal
        visibility={exportModalState.visible}
        type={exportModalState.type}
        toggleModal={toggleExportItemsModal}
        selectedPurchaseOrderIds={selectedOrders
          .map((selectedPo) => purchaseOrders.find((po) => po?.id === selectedPo?.id) ?? selectedPo)
          .map((po) => po.id)}
      />
      <ExportPoModal
        visibility={exportPoModalVisible}
        toggleModal={() => setExportPoModalVisible(false)}
        selectedPurchaseOrders={selectedOrders
          .map((selectedPo) => purchaseOrders.find((po) => po?.id === selectedPo?.id) ?? selectedPo)
          .map((po) => ({
            id: po.id,
            customerPo: po.customerPo || '',
            customer: po.retailerDeliveryDestination?.vendorMarket?.name || '',
            shipTo:
              po.retailerDeliveryDestination?.externalId ||
              po.retailerDeliveryDestinationExternalId ||
              '',
            orderDate: dateFormat(po.orderDate),
            deliveryWindowEnd: dateFormat(po.deliveryWindowEnd),
            bu: po.businessUnit?.code || ''
          }))}
      />
      <ReleaseOrdersModal
        visibility={shareEmailModalVisibility}
        toggleModal={toggleShareEmailModal}
        selectedRowIds={selectedOrders.map((row) => row.id)}
        clearSelection={clearSelectedOrders}
      />
      <ExportSoItemsModal
        visibility={exportSoItemsModalVisible}
        toggleModal={() => setExportSoItemsModalVisible(false)}
        selectedRowIds={selectedOrders.map((po) => po.id)}
      />
      <ExportSeedDataModal
        open={exportSeedDataModalVisible}
        toggleModal={toggleExportSeedDataModal}
        selectedRowIds={selectedOrders.map((po) => po.id)}
      />
    </>
  );
};

export default OrdersPage;

const isQueryParamsHasWrongSortOrFilters = (search: string) => {
  const updatePagingLocation = parse(search);
  const sortColumnKey = locationPropertyToStringOrNull(updatePagingLocation.columnKey);
  const result =
    (!!sortColumnKey &&
      !Object.values(PurchaseOrderSortEnum).includes(sortColumnKey as PurchaseOrderSortEnum)) ||
    !!updatePagingLocation.searchIds;
  return result;
};

const saveLastPagingToLocalStorage = (value: number) => {
  setItem(PAGE_SIZE_LOCAL_STORAGE_FIELD_NAME, {
    OrdersPage: value
  });
};
