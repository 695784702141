import { useLazyQuery } from '@apollo/client';
import { capitalizeFirstLetter, fieldNameToCapitalize } from 'common/helpers/stringsConverter';
import { UserContext } from 'context/userContext';
import moment from 'moment';
import { parse } from 'query-string';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AssortmentConfig,
  DistributionCenterForAA,
  getVendorMarketExternalId,
  RetailerChannel,
  RetailerDeliveryDestination,
  TradingPartner,
  TradingPartnerAssortment
} from 'pages/AssortmentPage/AssortmentPage';
import {
  AllTpAssortmentsForExportDocument,
  AllTpAssortmentsForExportQuery
} from 'pages/AssortmentPage/gql/__generated__/allTpAssortmentsForExportDocument.query';
import {
  getExcelHeaders,
  ExportHeader,
  ExtendedAssortmentConfigFieldSlug
} from 'pages/AssortmentPage/fieldsHelper';
import {
  locationPropertyToStringArrayOrNull,
  locationPropertyToStringOrNull,
  getNodesFromEdges,
  InferNodeType
} from 'common/helpers/mappingHelper';
import {
  TradingPartnerAssortmentDocument,
  TradingPartnerAssortmentQuery
} from '../../gql/__generated__/tradingPartnerAssortment.query';
import { ConfigNotfication } from '../ConfigNotfication/ConfigNotfication';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { App } from 'ant5';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import {
  AllTpAssortmentsWithInventoryForExportDocument,
  AllTpAssortmentsWithInventoryForExportQuery
} from 'pages/AssortmentPage/gql/__generated__/allTpAssortmentsWithInventoryForExportDocument.query';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { exportDataToXlsx } from 'common/helpers/exportToXlsx';

interface ExportActiveAssortmentByConfigProps {
  selectedRows: TradingPartnerAssortment[];
  retailersList: RetailerChannel[];
  assortmentConfig?: AssortmentConfig | null;
  tradingPartner: TradingPartner;
  disabled: boolean;
  retailerDeliveryDestinations: RetailerDeliveryDestination[];
  distributionCenters: DistributionCenterForAA[] | undefined;
}

type FullTradingPartnerAssortment = InferNodeType<
  AllTpAssortmentsForExportQuery,
  'tradingPartnerAssortments'
>;

type FullTradingPartnerAssortmentWithInventory = InferNodeType<
  AllTpAssortmentsWithInventoryForExportQuery,
  'tradingPartnerAssortments'
>;

type FullSelectedTradingPartnerAssortment =
  TradingPartnerAssortmentQuery['tradingPartnerAssortmentsByRpIds'][number];

export type AllTpAssortmentsForExportSubstitution =
  FullTradingPartnerAssortment['substitutions'][number];

export const ExportActiveAssortmentByConfig = ({
  selectedRows,
  retailersList,
  assortmentConfig,
  tradingPartner,
  disabled,
  retailerDeliveryDestinations,
  distributionCenters
}: ExportActiveAssortmentByConfigProps) => {
  const [showExportModal, setShowExportModal] = useState(false);

  const { message } = App.useApp();

  const history = useHistory();
  const location = parse(history.location.search);
  const [active] = useQueryParam('active', BooleanParam);
  const [vendorMarketId] = useQueryParam('vendorMarketId', StringParam);

  const [withInventory, setWithInventory] = useState(false);

  const { isAdmin } = useContext(UserContext);

  const [getAllProducts, { loading: loadingAllProducts }] = useLazyQuery(
    AllTpAssortmentsForExportDocument,
    {
      // it needs because we dont have uniq id for inventory (only within one upc)
      fetchPolicy: 'no-cache'
    }
  );
  const [getAllProductsWithInventory, { loading: loadingAllProductsWithInventory }] = useLazyQuery(
    AllTpAssortmentsWithInventoryForExportDocument,
    {
      // it needs because we dont have uniq id for inventory (only within one upc)
      fetchPolicy: 'no-cache'
    }
  );
  const [getFullSelectedProducts, { loading: loadingSelectedProducts }] = useLazyQuery(
    TradingPartnerAssortmentDocument,
    {
      // it needs because we dont have uniq id for inventory (only within one upc)
      fetchPolicy: 'no-cache'
    }
  );

  const loading = useMemo(
    () => loadingAllProducts || loadingSelectedProducts || loadingAllProductsWithInventory,
    [loadingAllProducts, loadingSelectedProducts, loadingAllProductsWithInventory]
  );

  const headers = useMemo(() => {
    return assortmentConfig ? getExcelHeaders(assortmentConfig, true) : [];
  }, [assortmentConfig]);

  const exportSelectedItems = useCallback(async () => {
    const data = await getFullSelectedProducts({
      variables: {
        tradingPartnerId: locationPropertyToStringOrNull(location.tradingPartnerIds) || '',
        active: active === null || active === undefined ? true : active,
        retailerProductIds: selectedRows.map((row) => row.retailerProductId)
      }
    });
    if (data.data) {
      const fileName = `${tradingPartner?.name}-selected-active-assortment-${moment().format(
        'MM-DD-YYYY_hh:mm:ssA'
      )}.xlsx`;
      exportDataToXlsx(fileName, [
        {
          tabName: 'Assortment',
          itemsToExport: data.data?.tradingPartnerAssortmentsByRpIds.flatMap((tpa) =>
            mapTradingPartnerAssortmentToExcelObject(
              tpa,
              headers,
              withInventory,
              tradingPartner,
              distributionCenters ?? [],
              retailerDeliveryDestinations,
              location.retailerDeliveryDestinationIds,
              !!(assortmentConfig?.multipleUpc || assortmentConfig?.useMultipleSubstitutions)
            )
          ),
          headers: headers
        }
      ]);
    }
  }, [
    active,
    distributionCenters,
    getFullSelectedProducts,
    headers,
    location.retailerDeliveryDestinationIds,
    location.tradingPartnerIds,
    retailerDeliveryDestinations,
    selectedRows,
    tradingPartner,
    withInventory
  ]);

  const exportAllItems = useCallback(async () => {
    if (withInventory) {
      const data = await getAllProductsWithInventory({
        variables: {
          first: 10000000,
          tradingPartnerId: locationPropertyToStringOrNull(location.tradingPartnerIds) || '',
          filters: {
            active: active === null || active === undefined ? true : active,
            productSapMaterialIdLike: locationPropertyToStringOrNull(
              location.productSapMaterialIdLike
            ),
            productUpcLike: locationPropertyToStringOrNull(location.productUpcLike),
            vendorProductExternalIdLike: locationPropertyToStringOrNull(
              location.vendorProductExternalIdLike
            ),
            productNameLike: locationPropertyToStringOrNull(location.productNameLike),
            retailerDeliveryDestinationIds: !location.retailerDeliveryDestinationIds?.includes(
              'all'
            )
              ? locationPropertyToStringArrayOrNull(location.retailerDeliveryDestinationIds)
              : null,
            vendorMarketId:
              retailersList.find(
                (item) => item.externalId === getVendorMarketExternalId(vendorMarketId)
              )?.id || 'VmVuZG9yTWFya2V0OjI='
          }
        }
      });
      if (data.data) {
        const fileName = `${tradingPartner?.name}-all-active-assortment-${moment().format(
          'MM-DD-YYYY_hh:mm:ssA'
        )}.xlsx`;
        exportDataToXlsx(fileName, [
          {
            tabName: 'Assortment',
            itemsToExport: getNodesFromEdges(data.data?.tradingPartnerAssortments).flatMap((item) =>
              mapTradingPartnerAssortmentToExcelObject(
                item,
                headers,
                withInventory,
                tradingPartner,
                distributionCenters ?? [],
                retailerDeliveryDestinations,
                location.retailerDeliveryDestinationIds,
                !!(assortmentConfig?.multipleUpc || assortmentConfig?.useMultipleSubstitutions)
              )
            ),
            headers: headers
          }
        ]);
      } else {
        message.error('Failed to export items');
      }
    } else {
      const data = await getAllProducts({
        variables: {
          first: 10000000,
          tradingPartnerId: locationPropertyToStringOrNull(location.tradingPartnerIds) || '',
          filters: {
            active: active === null || active === undefined ? true : active,
            productSapMaterialIdLike: locationPropertyToStringOrNull(
              location.productSapMaterialIdLike
            ),
            productUpcLike: locationPropertyToStringOrNull(location.productUpcLike),
            vendorProductExternalIdLike: locationPropertyToStringOrNull(
              location.vendorProductExternalIdLike
            ),
            productNameLike: locationPropertyToStringOrNull(location.productNameLike),
            retailerDeliveryDestinationIds: !location.retailerDeliveryDestinationIds?.includes(
              'all'
            )
              ? locationPropertyToStringArrayOrNull(location.retailerDeliveryDestinationIds)
              : null,
            vendorMarketId:
              retailersList.find(
                (item) => item.externalId === getVendorMarketExternalId(vendorMarketId)
              )?.id || 'VmVuZG9yTWFya2V0OjI='
          }
        }
      });
      if (data.data) {
        const fileName = `${tradingPartner?.name}-all-active-assortment-${moment().format(
          'MM-DD-YYYY_hh:mm:ssA'
        )}.xlsx`;
        exportDataToXlsx(fileName, [
          {
            tabName: 'Assortment',
            itemsToExport: getNodesFromEdges(data.data?.tradingPartnerAssortments).flatMap((item) =>
              mapTradingPartnerAssortmentToExcelObject(
                item,
                headers,
                withInventory,
                tradingPartner,
                distributionCenters ?? [],
                retailerDeliveryDestinations,
                location.retailerDeliveryDestinationIds,
                !!(assortmentConfig?.multipleUpc || assortmentConfig?.useMultipleSubstitutions)
              )
            ),
            headers: headers
          }
        ]);
      } else {
        message.error('Failed to export items');
      }
    }
  }, [
    active,
    distributionCenters,
    getAllProducts,
    getAllProductsWithInventory,
    headers,
    location.productNameLike,
    location.productSapMaterialIdLike,
    location.productUpcLike,
    location.retailerDeliveryDestinationIds,
    location.tradingPartnerIds,
    location.vendorProductExternalIdLike,
    message,
    retailerDeliveryDestinations,
    retailersList,
    tradingPartner,
    vendorMarketId,
    withInventory
  ]);

  if (!assortmentConfig) {
    return (
      <AlloyModal title="Active Assortment configuration is not set">
        <ConfigNotfication forAdmin={isAdmin()} tradingPartnerId={tradingPartner?.id || ''} />
      </AlloyModal>
    );
  }

  return (
    <>
      {selectedRows.length < 1 ? (
        <>
          <AlloyButton
            data-testid="export-aa-all-item-btn"
            onClick={async () => {
              setShowExportModal(true);
            }}
            disabled={disabled}
          >
            Export All
          </AlloyButton>
        </>
      ) : (
        <>
          <AlloyButton
            data-testid="export-aa-selected-item-btn"
            disabled={disabled}
            onClick={async () => {
              setShowExportModal(true);
            }}
          >
            Export Selected
          </AlloyButton>
        </>
      )}
      <AlloyModal
        title={selectedRows.length < 1 ? 'Export items' : 'Export selected items'}
        open={showExportModal}
        destroyOnClose
        okText="Export"
        onOk={async () => {
          if (selectedRows.length < 1) {
            await exportAllItems();
          } else {
            await exportSelectedItems();
          }
          setShowExportModal(false);
          setWithInventory(false);
        }}
        onCancel={() => {
          setShowExportModal(false);
          setWithInventory(false);
        }}
        okButtonProps={{
          disabled: loading,
          'data-testid': 'aa-page-export-confirm-button'
        }}
        cancelButtonProps={{
          disabled: loading
        }}
      >
        <AlloySpin spinning={loading}>
          <AlloyCheckbox
            checked={withInventory}
            onChange={(e) => {
              setWithInventory(e.target.checked);
            }}
          >
            Include Inventory
          </AlloyCheckbox>
        </AlloySpin>
      </AlloyModal>
    </>
  );
};

const mapTradingPartnerAssortmentToExcelObject = (
  item:
    | FullTradingPartnerAssortment
    | FullTradingPartnerAssortmentWithInventory
    | FullSelectedTradingPartnerAssortment,
  headers: ExportHeader[],
  withInventory: boolean,
  tradingPartner: TradingPartner,
  distributionCenters: DistributionCenterForAA[],
  retailerDeliveryDestinations: RetailerDeliveryDestination[],
  selectedRdds: string[] | string | null,
  isMultipleUpc: boolean
) => {
  const resultItems: { [name: string]: string | number | boolean | string[] }[] = [];
  if (item.substitutions && item.substitutions.length > 0) {
    item.substitutions.forEach((_, index) => {
      // for TPs without multiple UPC add only first substitution
      if (!isMultipleUpc && index > 0) return;

      const result: { [name: string]: string | number | boolean | string[] } = {};
      headers.forEach((header) => {
        result[header.name] = getTradingPartnerAssortmentFieldValueBySlug(
          item,
          index,
          header.slug,
          tradingPartner,
          distributionCenters,
          retailerDeliveryDestinations,
          selectedRdds
        );
      });
      if (withInventory) {
        const inventory = (item as FullTradingPartnerAssortmentWithInventory).substitutions[index]
          .inventory;
        inventory
          // export only active inventory
          .filter((inv) => !!inv.active)
          .forEach((inventoryItem) => {
            const dcCode =
              distributionCenters.find((dc) => inventoryItem.distributionCenterId === dc.id)
                ?.code ?? '';
            const sourceAndDcCodeTitle = `${inventoryItem.source} ${dcCode}`;
            result[sourceAndDcCodeTitle] = `${inventoryItem.inventoryOnHand ?? ''}`;
            result[`${sourceAndDcCodeTitle} updated at`] = inventoryItem.inventoryOnHandUpdatedAt
              ? moment(inventoryItem.inventoryOnHandUpdatedAt).format('MM/DD/YYYY HH:mm:ss')
              : '';
          });
      }
      resultItems.push(result);
    });
  }
  return resultItems;
};

const getTradingPartnerAssortmentFieldValueBySlug = (
  item: FullTradingPartnerAssortment,
  substitutionOrder: number,
  slug: ExtendedAssortmentConfigFieldSlug | undefined,
  tradingPartner: TradingPartner,
  distributionCenters: DistributionCenterForAA[],
  retailerDeliveryDestinations: RetailerDeliveryDestination[],
  selectedRdds: string[] | string | null
) => {
  if (!slug) return '';
  switch (slug) {
    case 'DISTRIBUTION_CENTER_IDS':
      return getDistributionCentersString(
        distributionCenters?.filter((dc) =>
          (item.dcIds ?? item.substitutions[substitutionOrder].activeDcIds).includes(dc.id)
        ) || []
      );
    case 'EXTERNAL_ID':
      return item.retailerProductExternalId;
    case 'SPECIAL_ASSORTMENT':
      return getRetailerDeliveryDestinationsString(
        retailerDeliveryDestinations.filter(
          (rdd) => item.rddIds.includes(rdd.id) && rdd.specialAssortment
        ),
        selectedRdds
      );
    case 'SHIP_TO':
      return getRetailerDeliveryDestinationsString(
        retailerDeliveryDestinations.filter(
          (rdd) => item.rddIds.includes(rdd.id) && !rdd.specialAssortment
        ),
        selectedRdds
      );
    case 'TRADING_PARTNER':
      return tradingPartner.name ?? '';
    case 'UPDATED_AT':
      return moment(item.updatedAt).startOf('minute').fromNow();
    case 'SUBSTITUTION_ORDER':
      return substitutionOrder + 1;
    case 'PRODUCT_TYPE':
      return capitalizeFirstLetter(item.substitutions[substitutionOrder].productType ?? '');
    case 'CASES_PER_LAYER':
    case 'CASES_PER_PALLET':
    case 'CURRENCY':
    case 'DEPTH':
    case 'GROSS_WEIGHT':
    case 'GTIN14':
    case 'HEIGHT':
    case 'INVENTORY_RESERVE':
    case 'LAYERS_PER_PALLET':
    case 'MOQ_MINIMUM':
    case 'MOQ_UNIT_OF_MEASURE':
    case 'NAME':
    case 'ORACLE_INVEN_ID':
    case 'PRICE':
    case 'REQUIRED_ORDER_UNIT_OF_MEASURE':
    case 'SAP_MATERIAL_ID':
    case 'SHIPS_IN_OWN_CONTAINER':
    case 'TO_CASE_QUANTITY':
    case 'UPC':
    case 'WIDTH':
    default:
      return (
        item.substitutions[substitutionOrder] as {
          [name: string]: string | number | boolean | string[];
        }
      )[fieldNameToCapitalize(slug)];
  }
};

const getDistributionCentersString = (distributionCenters: DistributionCenterForAA[]) =>
  distributionCenters.map((dc) => `${dc.name} ${dc.code}`).join(', ');

const getRetailerDeliveryDestinationsString = (
  retailerDeliveryDestinations: RetailerDeliveryDestination[],
  selectedRdds: string[] | string | null
) => {
  if (selectedRdds?.includes('all')) {
    return retailerDeliveryDestinations.map((rdd) => rdd.externalId).join(', ');
  } else {
    return retailerDeliveryDestinations
      .reduce((result, current) => {
        if (selectedRdds?.includes(current.id)) {
          result.push(current.externalId || '');
        }
        return result;
      }, [] as string[])
      .join(', ');
  }
};
