import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useElementSize } from '@reactuses/core';
import { useMutation, useSubscription } from '@apollo/client';
import { JsonParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import './PurchaseOrderDetails.scss'; //TODO: refactor this to use module.scss
import { App } from 'ant5';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { TradingPartnerActiveAssortmentItem } from 'common/interfaces';
import {
  PoDetailsCutSummaryOrderDetailsPage,
  PurchaseOrderItem,
  PurchaseOrderWithOverdue,
  SalesOrderItem
} from 'pages/OrderDetailsPage/types';
import { CutSource, PurchaseOrderItemError } from 'graphql/__generated__/types';

import {
  PO_STATUS_CANCELLED,
  ModalAction,
  DeliveryType,
  PurchaseOrderRefetchQueries,
  EMPTY
} from 'common/constants';

import PurchaseOrderDetailsHeader from '../PurchaseOrderDetailsHeader/PurchaseOrderDetailsHeader';
import PurchaseOrderDetailsInformation from '../PurchaseOrderDetailsInformation/PurchaseOrderDetailsInformation';
import {
  SO_FILTERS_QUERY_PARAM_STRING,
  SalesOrderListToolbar,
  SoFilterKeys
} from '../SalesOrderListToolbar';
import { ValidationError } from 'pages/OrderDetailsPage/OrderDetailsPage';
import { EditTradingPartnerAssortmentByConfig } from 'pages/AssortmentPage/components/EditTradingPartnerAssortment/EditTradingPartnerAssortment';
import { CollapsibleConfirmationTable } from '../CollapsibleConfirmationTable/CollapsibleConfirmationTable';
import { CollapsibleSalesOrderTable } from '../CollapsibleSalesOrderTable/CollapsibleSalesOrderTable';

import DownloadFileModal from 'components/Modals/DownloadFileModal';
import CancelPurchaseOrderModal from '../CancelPurchaseOrderModal/CancelPurchaseOrderModal';
import EditPoTagsModal from 'components/PurchaseOrders/PoLabelsModal/EditPoTagsModal';

import { MoveSalesOrderItemsDocument } from '../gql/__generated__/moveSalesOrderItems.mutation';
import { SplitSalesOrderDocument } from '../gql/__generated__/splitSalesOrder.mutation';
import { UpsertTradingPartnerActiveAssortmentDocument } from '../gql/__generated__/upsertTradingPartnerActiveAssortment.mutation';
import { OrderDetailsUpdatesDocument } from 'pages/OrderDetailsPage/gql/__generated__/orderDetailsUpdates.subscription';

import {
  ParsedShipment,
  SelectedShipmentGroup,
  parsePurchaseOrderWhdData
} from 'common/helpers/purchaseOrderDataHelper';
import { uniq } from 'lodash-es';
import CutSummariesToolbar, {
  CUTS_FILTERS_QUERY_PARAM_STRING,
  CutsFilterKeys
} from '../CutSummariesToolbar/CutSummariesToolbar';
import { CollapsibleCutSummariesTable } from '../CollapsibleCutSummariesTable/CollapsibleCutSummariesTable';
import { TradingPartnerAssortmentFullData } from 'pages/AssortmentPage/components/ProductDetailsModal/ProductDetailsDrawer';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { PurchaseOrderItemsTableWHD } from '../PurchaseOrderItemsTableWHD/PurchaseOrderItemsTableWHD';
import { InvalidItemsTableWHD } from '../InvalidItemsTableWHD/InvalidItemsTableWHD';
import { PurchaseOrderItemsTablesECOMM } from '../PurchaseOrderItemsTablesECOMM/PurchaseOrderItemsTablesECOMM';
import { PurchaseOrderItemsTableDSD } from '../PurchaseOrderItemsTableDSD/PurchaseOrderItemsTableDSD';
import { InvalidItemsTableDSD } from '../InvalidItemsTableDSD/InvalidItemsTableDSD';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';
import {
  CATALOG_PRODUCT_ASSORTMENT,
  PO_DETAILS_DSD_CONDITIONAL_COLUMNS
} from 'common/useFeatureFlags/featureFlags';
import { AlloyAffix } from 'components/ui/AlloyAffix/AlloyAffix';
import { EditAssortmentModal } from 'pages/AssortmentPage/components/EditAssortmentModal/EditAssortmentModal';

interface PurchaseOrderDetailsProps {
  orderId: string;
  purchaseOrderData: PurchaseOrderWithOverdue;
  warnings: ValidationError[];
  cutSummariesList: PoDetailsCutSummaryOrderDetailsPage[];
  cutSummariesDataLoading: boolean;
}

export enum SubscribedAction {
  AUTO_SPLIT,
  ADD_TO_ASSORTMENT,
  REPROCESS,
  RESET_PURCHASE_ORDER
}

const getSalesOrderSource = (orderSource: string) =>
  orderSource === 'SAP' || orderSource === 'ORACLE'
    ? 'Manual'
    : orderSource === 'OREX'
      ? 'SCHIP'
      : EMPTY;

const soSorter = (s1: ParsedShipment, s2: ParsedShipment) => {
  if (getSalesOrderSource(s1.shipmentSource as CutSource) === 'Manual') return 1;
  if (getSalesOrderSource(s2.shipmentSource as CutSource) === 'SCHIP') return -1;
  return 0;
};

const PurchaseOrderDetails = ({
  orderId,
  purchaseOrderData,
  warnings,
  cutSummariesList,
  cutSummariesDataLoading
}: PurchaseOrderDetailsProps) => {
  const { message, notification } = App.useApp();
  const [showDownloadFileModal, setShowDownloadFileModal] = useState(false);
  const [editPoTagsModalVisibility, setEditPoTagsModalVisibility] = useState(false);
  const [showCancelPurchaseOrderModal, setShowCancelPurchaseOrderModal] = useState(false);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState<string>();
  const [newItem, setNewItem] = useState<TradingPartnerAssortmentFullData | undefined>();
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [predefinedValues, setPredefinedValues] = useState<
    Partial<TradingPartnerAssortmentFullData> | undefined
  >(undefined);

  const [subscribedActionInProgress, setSubscribedActionInProgress] = useState<
    SubscribedAction | undefined
  >(undefined);

  const purchaseOrderItemsWithErrors = useMemo<PurchaseOrderItem[]>(() => {
    const invalidItems = purchaseOrderData.purchaseOrderItems.filter(
      (poI) => poI.purchaseOrderItemErrors.length
    );

    //This is to catch an edge case where the backend does not set an item error, but an item error has prevented the creation of a sales order. The user still needs to see the items somehow.
    let poItemsWithMissingSaleOrder: PurchaseOrderItem[] =
      purchaseOrderData.purchaseOrderItems as PurchaseOrderItem[];
    if (!purchaseOrderData.salesOrders.length && !invalidItems.length) {
      poItemsWithMissingSaleOrder = purchaseOrderData.purchaseOrderItems.map((poItem) => {
        return {
          ...poItem,
          purchaseOrderItemErrors: [
            'Unknown Error: Likely Product not found' as PurchaseOrderItemError
          ]
        } as PurchaseOrderItem;
      });
    }
    return poItemsWithMissingSaleOrder;
  }, [purchaseOrderData]);

  const invalidItems = useMemo(
    () => purchaseOrderItemsWithErrors.filter((poItem) => !!poItem.purchaseOrderItemErrors.length),
    [purchaseOrderItemsWithErrors]
  );

  const cutSummariesWithoutInvalidItems = useMemo(
    () =>
      cutSummariesList.filter(
        (item) => !invalidItems.find((invalid) => invalid.externalId === item.product.externalId)
      ),
    [cutSummariesList, invalidItems]
  );

  useSubscription(OrderDetailsUpdatesDocument, {
    variables: { id: orderId },
    onSubscriptionData: (result) => {
      if (result.subscriptionData.data) {
        switch (subscribedActionInProgress) {
          case SubscribedAction.ADD_TO_ASSORTMENT:
            message.success('PO successfully reprocessed after adding item to assortment');
            break;
          case SubscribedAction.AUTO_SPLIT:
            setSelectedSalesOrder(undefined);
            message.success('Sale order successfully split');
            break;
        }
        setSubscribedActionInProgress(undefined);
      }
    }
  });

  const [moveSalesOrderItems] = useMutation(MoveSalesOrderItemsDocument, {
    refetchQueries: PurchaseOrderRefetchQueries
  });
  const [runSplitSalesOrder] = useMutation(SplitSalesOrderDocument, {
    refetchQueries: PurchaseOrderRefetchQueries
  });
  const [upsertTradingPartnerActiveAssortment] = useMutation(
    UpsertTradingPartnerActiveAssortmentDocument
  );

  const [selectedItems, setSelectedItems] = useState<SalesOrderItem[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [selectedShipmentGroup, setSelectedShipmentGroup] = useState<SelectedShipmentGroup>();

  const toggleDownloadFileModal = () => setShowDownloadFileModal(!showDownloadFileModal);
  const toggleCancelPurchaseOrderModal = () =>
    setShowCancelPurchaseOrderModal(!showCancelPurchaseOrderModal);
  const toggleEditPoTagsModal = () => setEditPoTagsModalVisibility(!editPoTagsModalVisibility);

  useEffect(() => {
    if (window) window.scrollTo(0, 0);
  }, []);

  const {
    availableActions,
    customerPo,
    deliveryWindowEnd,
    deliveryWindowStart,
    orderDate,
    overdue,
    retailerDeliveryDestination,
    salesOrders,
    statuses,
    tradingPartner,
    purchaseOrderItems
  } = purchaseOrderData;

  const parsedWhdPurchaseOrderData = parsePurchaseOrderWhdData(purchaseOrderData);

  const poType = purchaseOrderData.tradingPartner?.deliveryType || '';

  const isWHD: () => boolean = () => poType === 'WHD';

  //FF for TP conditional columns
  const { enabled: isConditionalColumnsEnabled } = useFeatureFlag({
    name: PO_DETAILS_DSD_CONDITIONAL_COLUMNS,
    tradingPartnerExternalId: tradingPartner?.externalId
  });

  /*******************************  SALES ORDERS FUNCTIONS *********************************/

  //TODO: Future refactor -- use new SO query for this logic, instead of PO
  const onSelectAction = (selectedRows: SalesOrderItem[]) => {
    const filteredSelectedRows = selectedRows.filter((row) => row?.id);
    setSelectedItems(filteredSelectedRows);
    setSelectedKeys(filteredSelectedRows.map((row) => row.id));
    setSelectedShipmentGroup(
      parsedWhdPurchaseOrderData.find(({ shipments }) =>
        shipments.find(({ id }) => id === filteredSelectedRows[0]?.salesOrder.id)
      )
    );

    setSelectedSalesOrder(filteredSelectedRows[0]?.salesOrder.id);
  };

  const splitSalesOrder = async (id: string) => {
    runSplitSalesOrder({ variables: { id } }).catch((err) => {
      setSubscribedActionInProgress(undefined);
      const message = err.message.split(': ');
      notification.error({ message: `Error: ${message[1] || err.message}` });
    });
  };

  const createNewShipment = async (moveToId = 'new') => {
    try {
      await moveSalesOrderItems({
        variables: {
          sourceId: selectedItems[0].salesOrder.id,
          salesOrderItemIds: selectedKeys,
          destinationId: moveToId !== 'new' ? moveToId : undefined
        }
      });
      setTimeout(() => {
        notification.success({
          message: 'Success',
          description: 'Sales order item moved successfully to new shipment'
        });
      }, 250);
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'An error occurred while moving sales order(s) to a new shipment',
        description: error.message
      });
    }
  };

  /*****************************************************************************************/

  //FF for catalogProduct
  const { enabled: isCatalogProductEnabled } = useFeatureFlag({
    name: CATALOG_PRODUCT_ASSORTMENT,
    tradingPartnerExternalId: tradingPartner?.externalId
  });

  /****************** ADD POI INTO ASSORTMENT FUNCTIONS ******************/

  const openNewItemModal = (
    item?: Partial<TradingPartnerActiveAssortmentItem>,
    predefinedValues?: Partial<TradingPartnerActiveAssortmentItem>
  ) => {
    if (item)
      setNewItem(
        mapTradingPartnerActiveAssortmentItemToTradingPartnerAssortment(
          item as TradingPartnerActiveAssortmentItem
        )
      );
    setPredefinedValues(
      mapTradingPartnerActiveAssortmentItemToTradingPartnerAssortment(predefinedValues)
    );
    setNewItemModalVisible(true);
  };

  const upsertTradingPartnerAssortment = async (action: ModalAction, params: any) => {
    setSubscribedActionInProgress(SubscribedAction.ADD_TO_ASSORTMENT);
    try {
      await upsertTradingPartnerActiveAssortment({
        variables: {
          input: {
            ...params,
            vendorMarketId: purchaseOrderData.tradingPartner?.vendorMarket?.id
          }
        }
      });
      message.success(action === ModalAction.ADD ? 'Active item added' : 'Active item edited');
    } catch (error) {
      console.error(error.message);
      notification.error(error);
      notification.error({ message: 'Something went wrong. Please try again}' });
    } finally {
      setLoading(false);
    }
  };

  const mapTradingPartnerActiveAssortmentItemToTradingPartnerAssortment = (
    newItem:
      | TradingPartnerActiveAssortmentItem
      | Partial<TradingPartnerActiveAssortmentItem>
      | undefined
  ): TradingPartnerAssortmentFullData => ({
    rddIds: newItem?.retailerDeliveryDestinations?.map((rdd) => rdd.id) || [],
    retailerProductId: newItem?.vendorProduct?.id || '',
    retailerProductExternalId: newItem?.vendorProduct?.externalId || '',
    updatedAt: newItem?.updatedAt,
    substitutions:
      newItem?.vendorProduct?.substitutionProducts?.map((sub) => {
        const tpVp = newItem.vendorProduct?.tradingPartnerVendorProducts?.find(
          (tpVp) => tpVp.tradingPartnerId === purchaseOrderData.tradingPartner?.id
        );
        return {
          casesPerLayer: sub.product.casesPerLayer,
          casesPerPallet: sub.product.quantityPerPallet,
          currency: tpVp?.currency,
          depth: sub.product.depth,
          grossWeight: sub.product.weightInPounds,
          height: sub.product.height,
          inventoryReserve: tpVp?.inventoryReserve,
          layersPerPallet: sub.product.layersPerPallet,
          moqMinimum: tpVp?.moqMinimum,
          moqUnitOfMeasure: tpVp?.moqUnitOfMeasure,
          name: sub.product.name,
          oracleInvenId: sub.product.oracleInvenId,
          price: tpVp?.price,
          sapMaterialId: sub.product.sapMaterialId,
          shipsInOwnContainer: sub.product.shipsInOwnContainer,
          toCaseQuantity: newItem.vendorProduct?.toCaseQuantity,
          requiredOrderUnitOfMeasure: tpVp?.requiredOrderUnitOfMeasure,
          upc: sub.product.upc,
          width: sub.product.width,
          inventory:
            sub.product.inventory?.map((inv) => ({
              ...inv,
              active: !!inv.active
            })) || [],
          activeDcIds: sub.product.inventory
            ?.filter((inv) => !!inv.active)
            ?.map((inv) => inv.distributionCenterId)
        };
      }) || []
  });

  /*****************************************************************************************/

  /*************************   PO DETAILS TAB TOGGLE LOGIC   *******************************/

  type DisplayMode = 'orderItems' | 'confirmations' | 'salesOrders' | 'cuts';

  type PODetailsTab = { value: DisplayMode; label: string; disabled?: boolean };
  const PO_ITEMS_TAB: PODetailsTab = {
    value: 'orderItems',
    label: 'PO Items',
    disabled: !purchaseOrderItems.length
  };
  const CONFIRMATIONS: PODetailsTab = {
    value: 'confirmations',
    label: 'Confirmations',
    disabled: !purchaseOrderData.purchaseOrderConfirmations.length
  };
  const SALES_ORDERS: PODetailsTab = {
    value: 'salesOrders',
    label: 'Sales Orders',
    disabled: !purchaseOrderData.salesOrders.length
  };
  const CUTS: PODetailsTab = {
    value: 'cuts',
    label: 'Cuts',
    disabled: !purchaseOrderData.cutSummaries.filter(
      (product) => product.cutType !== 'INVALID_PRODUCT_CUT'
    ).length
  };

  const tabs = [PO_ITEMS_TAB, CONFIRMATIONS, SALES_ORDERS, CUTS];

  const availableTabs = isWHD()
    ? tabs.filter((tab) => !tab.disabled)
    : tabs.filter((tab) => !['confirmations', 'cuts'].includes(tab.value) && !tab.disabled);

  const DEFAULT_TAB: DisplayMode = availableTabs.map((tab) => tab.value).includes('salesOrders')
    ? 'salesOrders'
    : 'orderItems';

  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));

  const modeWithType = mode as DisplayMode;

  /*****************************************************************************************/

  const validItems = purchaseOrderItemsWithErrors.filter(
    (poItem) => !poItem.purchaseOrderItemErrors.length
  );

  return (
    <div>
      <AlloyAffix style={{ zIndex: '99', position: 'relative' }}>
        <div>
          <PurchaseOrderDetailsHeader
            customer={
              retailerDeliveryDestination
                ? `${retailerDeliveryDestination?.name} - ${retailerDeliveryDestination?.externalId}`
                : purchaseOrderData.retailerDeliveryDestinationExternalId || ''
            }
            market={purchaseOrderData.tradingPartner?.vendorMarket?.name || ''}
            orderNumber={customerPo || ''}
            orderStatus={(statuses?.primary || '').toUpperCase()}
            orderType={poType}
            overdue={!!overdue}
            externalId={purchaseOrderData.customerPo || ''}
            vendorMarket={tradingPartner?.vendorMarket?.externalId}
            salesOrders={salesOrders}
            toggleDownloadFileModal={toggleDownloadFileModal}
            purchaseOrder={purchaseOrderData}
            purchaseOrderId={orderId}
            toggleCancelPurchaseOrderModal={toggleCancelPurchaseOrderModal}
            setSubscribedActionInProgress={(value: SubscribedAction | undefined) =>
              setSubscribedActionInProgress(value)
            }
            isReprocess={
              subscribedActionInProgress === SubscribedAction.REPROCESS ||
              subscribedActionInProgress === SubscribedAction.ADD_TO_ASSORTMENT
            }
            poAvailableActions={availableActions || []}
          />
          <PurchaseOrderDetailsInformation
            purchaseOrder={purchaseOrderData}
            address={retailerDeliveryDestination?.address || ''}
            addressZip={retailerDeliveryDestination?.addressZip || ''}
            addressCity={retailerDeliveryDestination?.addressCity || ''}
            addressState={retailerDeliveryDestination?.addressState || ''}
            businessUnit={purchaseOrderData?.businessUnit?.code || ''}
            deliveryType={poType}
            deliveryWindowEnd={deliveryWindowEnd}
            deliveryWindowStart={deliveryWindowStart}
            orderType={poType}
            poDate={orderDate}
            poTradingPartnerCode={purchaseOrderData.tradingPartnerCode || ''}
            shipToNumber={retailerDeliveryDestination?.sapCustomerId || ''}
            toggleEditPoTagsModal={toggleEditPoTagsModal}
          />
          <div className="view_toggle" data-testid="view-toggle">
            <AlloySegmented
              options={availableTabs}
              value={mode}
              onChange={(value) => setMode(value)}
            />
          </div>
        </div>
      </AlloyAffix>

      {modeWithType === 'orderItems' ? (
        <>
          {poType === DeliveryType.WHD && !!invalidItems.length && (
            <InvalidItemsTableWHD
              loading={loading}
              invalidItems={invalidItems}
              purchaseOrder={purchaseOrderData}
              openNewItemModal={openNewItemModal}
            />
          )}

          {poType === DeliveryType.WHD && !!validItems.length && (
            <PurchaseOrderItemsTableWHD loading={loading} validItems={validItems} />
          )}

          {poType === DeliveryType.ECOMM && (
            <PurchaseOrderItemsTablesECOMM
              loading={loading}
              purchaseOrder={purchaseOrderData}
              purchaseOrderItems={purchaseOrderData.purchaseOrderItems}
              salesOrders={purchaseOrderData.salesOrders}
              openNewItemModal={openNewItemModal}
            />
          )}

          {poType === DeliveryType.DSD && !!invalidItems.length && (
            <InvalidItemsTableDSD
              loading={loading}
              invalidItems={invalidItems}
              purchaseOrder={purchaseOrderData}
              isConditionalColumnsEnabled={isConditionalColumnsEnabled}
              openNewItemModal={openNewItemModal}
            />
          )}

          {poType === DeliveryType.DSD && !!validItems.length && (
            <>
              <PurchaseOrderItemsTableDSD
                loading={loading}
                purchaseOrder={purchaseOrderData}
                validItems={validItems}
                isConditionalColumnsEnabled={isConditionalColumnsEnabled}
              />
              {!!warnings.length && (
                <div className="po_warning">
                  <ExclamationCircleFilled className="icon" />
                  <div className="label">Will release only valid items</div>
                </div>
              )}
            </>
          )}
        </>
      ) : null}

      {modeWithType === 'confirmations' && poType === DeliveryType.WHD
        ? !!purchaseOrderData.purchaseOrderConfirmations?.length &&
          (purchaseOrderData.statuses?.primary || '').toUpperCase() !== PO_STATUS_CANCELLED && (
            <CollapsibleConfirmationTable
              confirmations={purchaseOrderData.purchaseOrderConfirmations}
            />
          )
        : null}

      {modeWithType === 'salesOrders' ? (
        <SalesOrdersSection
          /********* SalesOrderListToolbar *********/
          selectedItems={selectedItems}
          onSelectAction={onSelectAction}
          createNewShipment={createNewShipment}
          selectedSalesOrder={selectedSalesOrder}
          splitSalesOrder={splitSalesOrder}
          purchaseOrder={purchaseOrderData} //Shared with table as well //TODO: remove this and pass sales orders down directly if possible
          setSubscribedActionInProgress={(value: SubscribedAction) =>
            setSubscribedActionInProgress(value)
          }
          /********* CollapsibleSalesOrderTable *********/
          parsedPurchaseOrderData={parsedWhdPurchaseOrderData}
          selectedKeys={selectedKeys}
        />
      ) : null}

      {modeWithType === 'cuts' && poType === DeliveryType.WHD ? (
        <CutsSection
          cutSummariesDataLoading={cutSummariesDataLoading}
          cutSummariesList={cutSummariesWithoutInvalidItems}
          purchaseOrder={purchaseOrderData}
        />
      ) : null}

      <DownloadFileModal
        orderType={poType}
        visibility={showDownloadFileModal}
        toggleDownloadFileModal={toggleDownloadFileModal}
        isConditionalColumnsEnabled={isConditionalColumnsEnabled}
        orderNumber={customerPo || ''}
        salesOrders={salesOrders}
        purchaseOrderItems={purchaseOrderItems}
        purchaseOrder={purchaseOrderData}
      />
      <CancelPurchaseOrderModal
        visibility={showCancelPurchaseOrderModal}
        purchaseOrderId={orderId}
        customerPO={purchaseOrderData.customerPo || ''}
        toggleCancelPurchaseOrderModal={toggleCancelPurchaseOrderModal}
      />
      <EditPoTagsModal
        visibility={editPoTagsModalVisibility}
        toggleEditPoTagsModal={toggleEditPoTagsModal}
        poTagsList={purchaseOrderData.labels.map((l) => l.id)}
        orderId={orderId}
      />

      {newItemModalVisible &&
        purchaseOrderData?.tradingPartner &&
        tradingPartner?.assortmentConfig &&
        (isCatalogProductEnabled ? (
          <EditAssortmentModal
            tradingPartner={purchaseOrderData.tradingPartner}
            visible={newItemModalVisible}
            onCancel={() => {
              setNewItemModalVisible(!newItemModalVisible);
              setPredefinedValues(undefined);
              setNewItem(undefined);
            }}
            upsertTradingPartnerAssortment={upsertTradingPartnerAssortment}
            retailerProduct={newItem}
            vendorId={purchaseOrderData.tradingPartner?.vendorMarket?.vendor?.id}
            tradingPartnerDistributionCenters={
              purchaseOrderData.tradingPartner?.distributionCenters || []
            }
            assortmentConfig={tradingPartner?.assortmentConfig}
            vendorMarketId={purchaseOrderData.tradingPartner?.vendorMarket?.id || ''}
            retailerProductExternalIdType={
              purchaseOrderData.tradingPartner?.vendorMarket?.vendor
                ?.retailerProductExternalIdType ?? undefined
            }
            changeAddingFlowToUpdate={(retailerProduct) => {
              setNewItem(retailerProduct);
            }}
            defaultShipTo={['all']}
            predefinedValues={predefinedValues}
          />
        ) : (
          <EditTradingPartnerAssortmentByConfig
            tradingPartner={purchaseOrderData.tradingPartner}
            visible={newItemModalVisible}
            onCancel={() => {
              setNewItemModalVisible(!newItemModalVisible);
              setPredefinedValues(undefined);
              setNewItem(undefined);
            }}
            upsertTradingPartnerAssortment={upsertTradingPartnerAssortment}
            retailerProduct={newItem}
            vendorId={purchaseOrderData.tradingPartner?.vendorMarket?.vendor?.id}
            tradingPartnerDistributionCenters={
              purchaseOrderData.tradingPartner?.distributionCenters || []
            }
            assortmentConfig={tradingPartner?.assortmentConfig}
            vendorMarketId={purchaseOrderData.tradingPartner?.vendorMarket?.id || ''}
            retailerProductExternalIdType={
              purchaseOrderData.tradingPartner?.vendorMarket?.vendor
                ?.retailerProductExternalIdType ?? undefined
            }
            changeAddingFlowToUpdate={(retailerProduct) => {
              setNewItem(retailerProduct);
            }}
            defaultShipTo={['all']}
            predefinedValues={predefinedValues}
          />
        ))}
    </div>
  );
};

const SalesOrdersSection = ({
  selectedItems,
  onSelectAction,
  createNewShipment,
  selectedSalesOrder,
  splitSalesOrder,
  purchaseOrder,
  setSubscribedActionInProgress,
  parsedPurchaseOrderData,
  selectedKeys
}: {
  selectedItems: SalesOrderItem[];
  onSelectAction: (selectedRows: SalesOrderItem[]) => void;
  createNewShipment: (moveToId?: string) => Promise<void>;
  selectedSalesOrder: string | undefined;
  splitSalesOrder: (id: string) => Promise<void>;
  purchaseOrder: PurchaseOrderWithOverdue;
  parsedPurchaseOrderData: SelectedShipmentGroup[];
  setSubscribedActionInProgress: (value: SubscribedAction) => void;
  selectedKeys: string[];
}) => {
  const [search, setSearch] = useState<string[]>([]);

  const [orderSourcesTableFiltersFromQuery] = useQueryParam<Record<SoFilterKeys, string[]>>(
    SO_FILTERS_QUERY_PARAM_STRING,
    withDefault(JsonParam, {})
  );

  const existingOrderSources = useMemo(
    () =>
      uniq(
        parsedPurchaseOrderData.flatMap((item) =>
          item.shipments.map((shipment) =>
            getSalesOrderSource(shipment.shipmentSource as CutSource)
          )
        )
      ).map((value) => ({
        name: value ?? '',
        value: value ?? ''
      })),
    [parsedPurchaseOrderData]
  );

  const fullSalesOrdersList = useMemo(
    () =>
      parsedPurchaseOrderData.map((group) => {
        return {
          ...group,
          shipments: [...group.shipments]
            .sort(soSorter)
            .map((shipment, index) => ({ ...shipment, name: `Shipment ${index + 1}` }))
        };
      }),
    [parsedPurchaseOrderData]
  );

  const filteredSalesOrderList = useMemo(() => {
    return fullSalesOrdersList
      .map((shipmentGroupObject) => {
        return {
          ...shipmentGroupObject,
          shipments: shipmentGroupObject.shipments.filter((shipment) => {
            const matchOnItemUpc =
              search.length === 0 ||
              search.find((text) => {
                return !!shipment.items.find((item) =>
                  item.product?.upc?.toLowerCase().includes(text.toLowerCase())
                );
              });
            const matchOnItemAsin =
              search.length === 0 ||
              search.find((text) => {
                return !!shipment.items.find((item) =>
                  item.externalId?.toLowerCase().includes(text.toLowerCase())
                );
              });

            const matchOnItemName =
              search.length === 0 ||
              search.find((text) => {
                return !!shipment.items.find((item) =>
                  item.product?.name.toLowerCase().includes(text.toLowerCase())
                );
              });

            const matchOnAlternateId =
              search.length === 0 ||
              !!search.find((text) =>
                shipment.alternateOrderId?.toLowerCase().includes(text.toLowerCase())
              );

            const matchOrderSource =
              (orderSourcesTableFiltersFromQuery.orderSource || []).length === 0 ||
              orderSourcesTableFiltersFromQuery.orderSource.includes(
                getSalesOrderSource(shipment.shipmentSource as CutSource)
              );

            return (
              (matchOnItemUpc || matchOnItemAsin || matchOnItemName || matchOnAlternateId) &&
              matchOrderSource
            );
          })
        };
      })
      .filter((group) => group.shipments.length > 0);
  }, [orderSourcesTableFiltersFromQuery.orderSource, fullSalesOrdersList, search]);

  const ref = useRef<HTMLDivElement>(null);
  const [, height] = useElementSize(ref);

  return (
    <>
      <div ref={ref} style={{ position: 'sticky', top: 246, zIndex: 50 }}>
        <SalesOrderListToolbar
          selectedItems={selectedItems}
          onSelectAction={onSelectAction}
          createNewShipment={createNewShipment}
          selectedSalesOrder={selectedSalesOrder}
          splitSalesOrder={splitSalesOrder}
          purchaseOrder={purchaseOrder}
          fullSalesOrdersList={fullSalesOrdersList}
          setSubscribedActionInProgress={setSubscribedActionInProgress}
          search={search}
          setSearch={setSearch}
          existingOrderSources={existingOrderSources}
        />
      </div>

      <CollapsibleSalesOrderTable
        salesOrderShipmentGroupList={filteredSalesOrderList}
        purchaseOrder={purchaseOrder}
        onSelectAction={onSelectAction}
        selectedKeys={selectedKeys}
        toolbarHeight={height}
      />
    </>
  );
};

const CutsSection = ({
  cutSummariesList,
  cutSummariesDataLoading,
  purchaseOrder
}: {
  cutSummariesList: PoDetailsCutSummaryOrderDetailsPage[];
  cutSummariesDataLoading: boolean;
  purchaseOrder: PurchaseOrderWithOverdue;
}) => {
  const [search, setSearch] = useState<string[]>([]);

  const [cutSummariesTableFiltersFromQuery] = useQueryParam<Record<CutsFilterKeys, string[]>>(
    CUTS_FILTERS_QUERY_PARAM_STRING,
    withDefault(JsonParam, {})
  );

  const existingCutReasons = useMemo(
    () =>
      uniq(
        cutSummariesList?.flatMap((cutSummary) => cutSummary.items.map((item) => item.reason))
      ).map((reason) => ({
        name: reason ?? '',
        value: reason ?? ''
      })) || [],
    [cutSummariesList]
  );

  const filteredCutSummaries = useMemo(() => {
    const filterBySearch = (cut: PoDetailsCutSummaryOrderDetailsPage) => {
      if (!search || search.length === 0) return true;
      const lowerCaseSearch = search.map((x) => x.toLowerCase());

      return lowerCaseSearch.some((term) => {
        return (
          cut.product.upc.toLowerCase().includes(term) ||
          cut.product.name.toLowerCase().includes(term) ||
          cut.product.externalId.toLowerCase().includes(term)
        );
      });
    };

    return cutSummariesList.reduce((result, cut) => {
      if (filterBySearch(cut)) {
        if (cutSummariesTableFiltersFromQuery.cutReason?.length) {
          const filteredItems = cut.items.filter((item) =>
            cutSummariesTableFiltersFromQuery.cutReason.includes(item.reason ?? '')
          );
          if (filteredItems.length > 0) {
            result.push({
              ...cut,
              items: filteredItems
            });
          }
        } else {
          result.push(cut);
        }
      }
      return result;
    }, [] as PoDetailsCutSummaryOrderDetailsPage[]);
  }, [cutSummariesList, search, cutSummariesTableFiltersFromQuery]);

  const ref = useRef<HTMLDivElement>(null);
  const [, height] = useElementSize(ref);

  return (
    <>
      <div ref={ref} style={{ position: 'sticky', top: 246, zIndex: 10 }}>
        <CutSummariesToolbar
          cutSummariesList={filteredCutSummaries}
          loading={cutSummariesDataLoading}
          search={search}
          setSearch={setSearch}
          purchaseOrder={purchaseOrder}
          existingCutReasons={existingCutReasons}
        />
      </div>

      <CollapsibleCutSummariesTable
        cutSummariesList={filteredCutSummaries}
        loading={cutSummariesDataLoading}
        height={height}
      />
    </>
  );
};

export default PurchaseOrderDetails;
