import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { ModalAction } from 'common/constants';
import { getShowCountData } from 'pages/AssortmentPage/fieldsHelper';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { composeValidators } from 'common/helpers/validationHelper';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { getValidators } from '../../../fieldsHelper';
import { spaceRemover } from '../../../fieldsHelper';
import {
  AssortmentConfig,
  ExtractedFormField,
  FormField
} from 'pages/AssortmentPage/AssortmentPage';
import {
  FindRetailerProductsDocument,
  FindRetailerProductsQuery
} from 'pages/AssortmentPage/gql/__generated__/findRetailerProduct';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  FindGmProductsDocument,
  FindGmProductsQuery
} from 'pages/AssortmentPage/gql/__generated__/findGmProducts';
import { RetailerProductExternalIdType } from 'graphql/__generated__/types';
import { FoundItemLink } from './FoundItemLink/FoundItemLink';

export type VendorProduct = InferNodeType<FindRetailerProductsQuery, 'retailerProducts'>;
export type GmProduct = InferNodeType<FindGmProductsQuery, 'gmProducts'>;

interface ExternalIdFieldProps {
  vendorId: string | undefined;
  retailerProductExternalIdType?: RetailerProductExternalIdType;
  changeToUpdate: (vendorProduct: VendorProduct) => void;
  action: ModalAction;
  formField: ExtractedFormField | FormField;
  assortmentConfig: AssortmentConfig;
}

export const ExternalIdField = ({
  vendorId,
  retailerProductExternalIdType,
  changeToUpdate,
  action,
  formField,
  assortmentConfig
}: ExternalIdFieldProps) => {
  const form = useForm();

  const [findAssortmentItem, { data, loading }] = useLazyQuery(FindRetailerProductsDocument, {});

  const [findGmProducts, { data: gmProductsData, loading: gmProductsLoading }] = useLazyQuery(
    FindGmProductsDocument,
    {}
  );

  const foundItems = useMemo(
    () =>
      getNodesFromEdges(data?.retailerProducts).sort((i1, i2) =>
        i1.externalId.localeCompare(i2.externalId)
      ),
    [data]
  );

  const foundGmItems = useMemo(
    () =>
      getNodesFromEdges(gmProductsData?.gmProducts).sort((i1, i2) =>
        (i1.externalId || '').localeCompare(i2.externalId || '')
      ),
    [gmProductsData]
  );

  const [showFoundItems, setShowFoundItems] = useState<boolean>(false);

  useEffect(() => {
    if (!!vendorId && action === ModalAction.ADD) {
      const value = form.getFieldState('externalId')?.value as string;
      if (value && value.length > 2) {
        findAssortmentItem({
          variables: {
            vendorId: vendorId,
            searchLike: value
          }
        });
        if (retailerProductExternalIdType) {
          findGmProducts({
            variables: {
              externalId: value,
              externalIdType: retailerProductExternalIdType
            }
          });
        }
        setShowFoundItems(true);
      }
    }
  }, [
    findAssortmentItem,
    findGmProducts,
    vendorId,
    form,
    action,
    formField.validation,
    retailerProductExternalIdType
  ]);

  const fillFormWithGmProduct = (gmProduct: GmProduct) => {
    const tabPrefix = `multipleUpc[0].`;
    form.getFieldState('name')?.change(gmProduct.name);
    form.getFieldState(`${tabPrefix}name`)?.change(gmProduct.name);
    form.getFieldState(`${tabPrefix}depth`)?.change(gmProduct.measurements.depth);
    form.getFieldState(`${tabPrefix}grossWeight`)?.change(gmProduct.measurements.grossWeight);
    form.getFieldState(`${tabPrefix}height`)?.change(gmProduct.measurements.height);
    form.getFieldState(`${tabPrefix}width`)?.change(gmProduct.measurements.width);
  };

  return (
    <>
      <AlloySpin spinning={loading}>
        <AlloyFormField
          data-testid={'aa-edit-modal-externalId-field'}
          component="input"
          name="externalId"
          required={formField.required}
          validate={
            action === ModalAction.ADD ? composeValidators(getValidators(formField)) : undefined
          }
          fieldProps={{
            title: formField.name,
            disabled: action === ModalAction.EDIT
          }}
          parse={spaceRemover}
          countRule={getShowCountData(formField)}
        />
        {action === ModalAction.ADD &&
          showFoundItems &&
          (!!foundItems.length || !!foundGmItems.length) && (
            <FoundItemLink
              foundItems={[...foundItems, ...foundGmItems]}
              setShowFoundItems={setShowFoundItems}
              changeToUpdate={changeToUpdate}
              fillFormWithGmProduct={fillFormWithGmProduct}
              tooltip="any"
              assortmentConfig={assortmentConfig}
            />
          )}
      </AlloySpin>
      <OnChange name="externalId">
        {(value: string) => {
          if (action === ModalAction.ADD && !!vendorId && value && value.length > 2) {
            findAssortmentItem({
              variables: {
                searchLike: value,
                vendorId: vendorId
              }
            });
            if (retailerProductExternalIdType) {
              findGmProducts({
                variables: {
                  externalId: value,
                  externalIdType: retailerProductExternalIdType
                }
              });
            }
            setShowFoundItems(true);
          }
        }}
      </OnChange>
    </>
  );
};
