import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { AuditTrailDiffItemFragmentFragmentDoc } from './retailerAuditVersions.query';
export type DistributionCenterAuditTrailVersionsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DistributionCenterAuditTrailVersionsQuery = { __typename?: 'RootQueryType', distributionCenter?: { __typename?: 'DistributionCenter', id: string, name: string, auditTrailVersions: Array<{ __typename?: 'AuditTrailVersion', action: Types.AuditTrailAction, actor: Types.AuditTrailActor, appliedAt: any, diff: Array<{ __typename: 'AuditTrailAddition', fieldPath: string, value: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } } | { __typename: 'AuditTrailChange', fieldPath: string, previousValue: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string }, newValue: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } } | { __typename: 'AuditTrailRemoval', fieldPath: string, value: { __typename: 'AuditTrailListOfPrimitives', values: Array<string> } | { __typename: 'AuditTrailPrimitive', value: string } }>, user?: { __typename?: 'User', id: string, name: string, email: string } | null }> } | null };


export const DistributionCenterAuditTrailVersionsDocument = {"kind":"Document", "definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"distributionCenterAuditTrailVersions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"distributionCenter"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"ignoreAllowedTradingPartners"},"value":{"kind":"BooleanValue","value":true}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"auditTrailVersions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"action"}},{"kind":"Field","name":{"kind":"Name","value":"actor"}},{"kind":"Field","name":{"kind":"Name","value":"diff"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuditTrailDiffItemFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"appliedAt"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]}},...AuditTrailDiffItemFragmentFragmentDoc.definitions]} as unknown as DocumentNode<DistributionCenterAuditTrailVersionsQuery, DistributionCenterAuditTrailVersionsQueryVariables>;