import { fieldNameToCapitalize } from 'common/helpers/stringsConverter';
import { AssortmentConfigFieldSlug } from 'graphql/__generated__/types';
import { cloneDeep } from 'lodash-es';
import { AssortmentConfig, ExtractedFormField, FormField } from './AssortmentPage';
import { getMatchRegExpValidation, validateRequired } from 'common/helpers/validationHelper';

export const SPECIAL_ASSORTMENT_FORM_FIELD: ExtractedFormField = {
  slug: 'SPECIAL_ASSORTMENT',
  name: 'Vendor Flex',
  required: false
};

export const SHIP_TO_FORM_FIELD: ExtractedFormField = {
  slug: 'SHIP_TO',
  name: 'Ship-To',
  required: false
};

export const TRADING_PARTNER_FORM_FIELD: ExtractedFormField = {
  slug: 'TRADING_PARTNER',
  name: 'Trading Partner',
  required: false
};

export const UPDATED_AT_FORM_FIELD: ExtractedFormField = {
  slug: 'UPDATED_AT',
  name: 'Updated At',
  required: false
};

export const SUBSTITUTION_ORDER_FORM_FIELD: ExtractedFormField = {
  slug: 'SUBSTITUTION_ORDER',
  name: 'UPC Priority',
  required: false
};

export type ExtendedAssortmentConfigFieldSlug =
  | AssortmentConfigFieldSlug
  | 'SPECIAL_ASSORTMENT'
  | 'SHIP_TO'
  | 'TRADING_PARTNER'
  | 'UPDATED_AT'
  | 'SUBSTITUTION_ORDER';

export interface ExportHeader {
  name: string;
  width: number;
  slug?: ExtendedAssortmentConfigFieldSlug;
}

const EXCEL_COLUMN_WIDTH_BY_SLUG = new Map<ExtendedAssortmentConfigFieldSlug, number>([
  ['CASES_PER_LAYER', 85],
  ['CASES_PER_PALLET', 100],
  ['CURRENCY', 100],
  ['DEPTH', 55],
  ['DISTRIBUTION_CENTER_IDS', 100],
  ['EXTERNAL_ID', 90],
  ['GROSS_WEIGHT', 100],
  ['GTIN14', 90],
  ['HEIGHT', 55],
  ['INVENTORY_RESERVE', 100],
  ['LAYERS_PER_PALLET', 100],
  ['MOQ_MINIMUM', 100],
  ['MOQ_UNIT_OF_MEASURE', 100],
  ['NAME', 130],
  ['ORACLE_INVEN_ID', 100],
  ['PRICE', 100],
  ['PRODUCT_TYPE', 100],
  ['REQUIRED_ORDER_UNIT_OF_MEASURE', 100],
  ['SAP_MATERIAL_ID', 120],
  ['SHIPS_IN_OWN_CONTAINER', 100],
  ['TO_CASE_QUANTITY', 100],
  ['UPC', 100],
  ['WIDTH', 55],
  ['SPECIAL_ASSORTMENT', 100],
  ['SUBSTITUTION_ORDER', 65],
  ['TRADING_PARTNER', 100],
  ['SHIP_TO', 100],
  ['UPDATED_AT', 80]
]);

export const DEFAULT_FORM_FIELDS_ORDER_ON_FORM = [
  'TRADING_PARTNER',
  'SHIP_TO',
  'EXTERNAL_ID',
  'DISTRIBUTION_CENTER_IDS',
  'SPECIAL_ASSORTMENT',
  'GTIN14',
  'UPC',
  'SUBSTITUTION_ORDER',
  'SAP_MATERIAL_ID',
  'NAME',
  'TO_CASE_QUANTITY',
  'GROSS_WEIGHT',
  'CASES_PER_PALLET',
  'WIDTH',
  'HEIGHT',
  'DEPTH',
  'INVENTORY_RESERVE',
  'SHIPS_IN_OWN_CONTAINER',
  'ORACLE_INVEN_ID'
];

export const DEFAULT_FORM_FIELDS_ORDER_ON_CATALOG_PRODUCTS_FORM = [
  'TRADING_PARTNER',
  'SHIP_TO',
  'SPECIAL_ASSORTMENT',
  'EXTERNAL_ID',
  'DISTRIBUTION_CENTER_IDS',
  'GTIN14',
  'UPC',
  'SUBSTITUTION_ORDER',
  'SAP_MATERIAL_ID',
  'NAME',
  'TO_CASE_QUANTITY',
  'GROSS_WEIGHT',
  'CASES_PER_PALLET',
  'WIDTH',
  'HEIGHT',
  'DEPTH',
  'INVENTORY_RESERVE',
  'SHIPS_IN_OWN_CONTAINER',
  'ORACLE_INVEN_ID'
];

export const DEFAULT_FORM_FIELDS_ORDER_CONFIG_EDITING = [
  'UPC',
  'GTIN14',
  'SAP_MATERIAL_ID',
  'ORACLE_INVEN_ID',
  'EXTERNAL_ID',
  'DISTRIBUTION_CENTER_IDS',
  'SPECIAL_ASSORTMENT',
  'SUBSTITUTION_ORDER',
  'NAME',
  'TO_CASE_QUANTITY',
  'GROSS_WEIGHT',
  'CASES_PER_PALLET',
  'WIDTH',
  'HEIGHT',
  'DEPTH',
  'INVENTORY_RESERVE',
  'SHIPS_IN_OWN_CONTAINER'
];

export const getFieldNameBySlug = (slug: string) => {
  switch (slug) {
    case 'CASES_PER_PALLET':
      return 'palletCount';
    case 'MOQ_UNIT_OF_MEASURE':
      return 'productMoqUnitOfMeasure';
    case 'PRICE':
      return 'productPrice';
    case 'MOQ_MINIMUM':
      return 'productMoqMinimum';
    case 'REQUIRED_ORDER_UNIT_OF_MEASURE':
      return 'productRequiredOrderUnitOfMeasure';
    case 'CURRENCY':
      return 'productCurrency';
    case 'CASES_PER_LAYER':
      return 'productCasesPerLayer';
    case 'LAYERS_PER_PALLET':
      return 'productLayersPerPallet';
    case 'ORACLE_INVEN_ID':
      return 'productOracleInvenId';
    case 'GTIN14':
      return 'productGtin14';
    default:
      return fieldNameToCapitalize(slug);
  }
};

const SUBSTITUTION_SLUGS: ExtractedFormField['slug'][] = [
  'UPC',
  'SAP_MATERIAL_ID',
  'CASES_PER_PALLET',
  'GROSS_WEIGHT',
  'WIDTH',
  'HEIGHT',
  'DEPTH',
  'ORACLE_INVEN_ID',
  'CASES_PER_LAYER',
  'LAYERS_PER_PALLET',
  'SHIPS_IN_OWN_CONTAINER',
  'PRODUCT_TYPE',
  'GTIN14',
  'NAME',
  'PRICE',
  'CURRENCY',
  'INVENTORY_RESERVE',
  'MOQ_MINIMUM',
  'MOQ_UNIT_OF_MEASURE',
  'REQUIRED_ORDER_UNIT_OF_MEASURE'
];

export const isForCatalogProductMultipleUpc = (slug?: ExtractedFormField['slug'] | null) =>
  slug && SUBSTITUTION_SLUGS.includes(slug);

export const getFieldNameBySlugForCatalogProduct = (slug: ExtractedFormField['slug']) => {
  if (isForCatalogProductMultipleUpc(slug)) {
    return fieldNameToCapitalize(`PRODUCT_${slug}`);
  } else if (slug === 'TO_CASE_QUANTITY') {
    return fieldNameToCapitalize(`VENDOR_PRODUCT_${slug}`);
  }

  return fieldNameToCapitalize(slug);
};

export const spaceRemover = (value: string) => value.trim();

export const getShowCountData = (formField: ExtractedFormField | FormField) => {
  if (formField.validation?.rules && formField.validation.rules.length > 0) {
    const rules = [...formField.validation.rules].sort((rule1, rule2) =>
      !rule1.value ? 1 : !rule2.value ? -1 : 0
    );
    // if has at least on counting rule
    return rules.find((rule) => rule.value) ? getStringRules(rules) : undefined;
  }
};

export const getStringRules = (
  rules: {
    value?: string | null;
    type: string;
  }[]
) =>
  rules
    .map(
      (rule) =>
        `${
          !rule.value
            ? 'unlimited'
            : rule.value.includes(',')
              ? rule.value.split(',')[0] && rule.value.split(',')[1]
                ? rule.value.replaceAll(',', '-')
                : rule.value.split(',')[0]
                  ? `not less than ${rule.value.split(',')[0]}`
                  : `not more than ${rule.value.split(',')[1]}`
              : `${rule.value}`
        } ${rule.type.toLowerCase().replaceAll('_', ' ')} characters${
          rule.value?.includes(',') && rule.value.split(',')[0] ? ' and not 0' : ''
        }`
    )
    .join(' or ');

export const sorterBySlugs =
  (order: string[]) =>
  (
    s1: {
      slug?:
        | 'SPECIAL_ASSORTMENT'
        | AssortmentConfigFieldSlug
        | 'SHIP_TO'
        | 'TRADING_PARTNER'
        | 'UPDATED_AT'
        | 'SUBSTITUTION_ORDER'
        | string
        | null
        | undefined;
    },
    s2: {
      slug?:
        | 'SPECIAL_ASSORTMENT'
        | AssortmentConfigFieldSlug
        | 'SHIP_TO'
        | 'TRADING_PARTNER'
        | 'UPDATED_AT'
        | 'SUBSTITUTION_ORDER'
        | string
        | null
        | undefined;
    }
  ) => {
    if (!s1.slug) return 1;
    if (!s2.slug) return -1;
    if (!order.includes(s1.slug)) return 1;
    if (!order.includes(s2.slug)) return -1;
    return order.indexOf(s1.slug) - order.indexOf(s2.slug);
  };

export const getItemHeaders = (assortmentConfig: AssortmentConfig, extended?: boolean) => {
  const unsortedHeaders = cloneDeep(assortmentConfig.fields) as ExtractedFormField[];
  if (extended) {
    if (assortmentConfig.specialAssortment) {
      unsortedHeaders.push(SPECIAL_ASSORTMENT_FORM_FIELD);
    }
    unsortedHeaders.push(SHIP_TO_FORM_FIELD);
    unsortedHeaders.push(TRADING_PARTNER_FORM_FIELD);
    unsortedHeaders.push(UPDATED_AT_FORM_FIELD);
    if (assortmentConfig.multipleUpc) {
      unsortedHeaders.push(SUBSTITUTION_ORDER_FORM_FIELD);
    }
  }
  const headers = unsortedHeaders
    .sort(sorterBySlugs(DEFAULT_FORM_FIELDS_ORDER_ON_FORM))
    .map((field) => ({
      label: `${field.name}${field.slug === 'SHIPS_IN_OWN_CONTAINER' ? ' (Y/N)' : ''}${
        field.slug === 'DISTRIBUTION_CENTER_IDS' ? ' (Codes)' : ''
      }${
        field.required || field.validation?.regexp
          ? ` - ${field.required ? 'Required' : ''}${
              field.required && field.validation?.regexp ? ', ' : ''
            }${field.validation?.regexp ? getStringRules(field.validation.rules) : ''}`
          : ''
      }`,
      key: fieldNameToCapitalize(field.slug || '')
    }));
  return headers;
};

const getExcelColumnWidth = (slug?: ExtendedAssortmentConfigFieldSlug) => {
  return slug ? (EXCEL_COLUMN_WIDTH_BY_SLUG.get(slug) ?? 100) : 100;
};

export const getExcelHeaders = (
  assortmentConfig: AssortmentConfig,
  extended?: boolean
): ExportHeader[] => {
  const unsortedHeaders = cloneDeep(assortmentConfig.fields) as ExtractedFormField[];
  if (extended) {
    if (assortmentConfig.specialAssortment) {
      unsortedHeaders.push(SPECIAL_ASSORTMENT_FORM_FIELD);
    }
    unsortedHeaders.push(SHIP_TO_FORM_FIELD);
    unsortedHeaders.push(TRADING_PARTNER_FORM_FIELD);
    unsortedHeaders.push(UPDATED_AT_FORM_FIELD);
    if (assortmentConfig.multipleUpc) {
      unsortedHeaders.push(SUBSTITUTION_ORDER_FORM_FIELD);
    }
  }
  const headers = unsortedHeaders
    .sort(sorterBySlugs(DEFAULT_FORM_FIELDS_ORDER_ON_FORM))
    .map((field) => {
      const validationString =
        field.required || field.validation?.regexp
          ? ` - ${field.required ? 'Required' : ''}${field.required && field.validation?.regexp ? ', ' : ''}${field.validation?.regexp ? getStringRules(field.validation.rules) : ''}`
          : '';
      const additionalDescription =
        field.slug === 'SHIPS_IN_OWN_CONTAINER'
          ? ' (Y/N)'
          : field.slug === 'DISTRIBUTION_CENTER_IDS'
            ? ' (Codes)'
            : '';
      return {
        name: `${field.name}${additionalDescription}${validationString}`,
        width: getExcelColumnWidth(field.slug),
        slug: field.slug
      };
    });
  return headers;
};

export const getValidators = (formField: ExtractedFormField | FormField) => {
  const validators = [];
  if (formField.required) {
    validators.push(validateRequired);
  }
  if (formField.validation?.regexp) {
    // Temporary decision. Should be replaced after adding field types on backend
    const lengthRule = formField.validation.rules.find((rule) => rule.value);
    if (lengthRule) {
      validators.push((value: string | number) =>
        value === 0 || value === '0' ? 'Should not be 0' : null
      );
    }
    validators.push(
      getMatchRegExpValidation(
        formField.validation?.regexp,
        `Must be ${getStringRules(formField.validation.rules)}`
      )
    );
  }
  return validators;
};

export const getFieldTitleBySlug = (config: AssortmentConfig, slug: AssortmentConfigFieldSlug) => {
  return (
    config.fields.find((field) => field.slug === slug)?.name ?? fieldNameToCapitalize(slug || '')
  );
};
