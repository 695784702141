import React, { useMemo, useState } from 'react';
import s from './Filters.module.scss';
import { ChargebacksReportDetailsByPeriodForFiltersDocument } from './gql/__generated__/chargebacksReportDetailsByPeriodForFilters.query';
import Icon, { RightOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { setArrayWithAllAndAllowEmptyState, getValueWithAll } from 'common/helpers/selectAll';
import { MultipleSelectWithSearch } from 'components/TableFilters/MultipleSelectWithSearch/MultipleSelectWithSearch';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyPopover } from 'components/ui/AlloyPopover/AlloyPopover';
import { stringifyBackendValue } from 'pages/Chargebacks/helpers';
import { NumberBadge } from '../NumberBadge/NumberBadge';
import {
  Dimension,
  ALL,
  ChargebackReportByPeriodForFilters,
  ALL_AVAILABLE_DIMENSIONS,
  filters
} from './helpers';
import FilterIcon from 'assets/icons/filter.svg?react';
import { FiscalCalendarWeekInputWithWeekOnly } from 'components/PeriodCalendar/helpers';
import { useLazyQuery } from '@apollo/client';
import { useChargebacksFiltersFromQueryParam } from './hooks';

interface FilterItem<T> {
  value: T;
  label: string;
}

interface FilterProps<T> {
  filters: FilterItem<T>[];
  initialSelectedOptions: {
    enabled: T[];
    filters: Record<string, string[]>;
  };
  handleApply: (selectedOptions: { enabled: T[]; filters: Record<string, string[]> }) => void;
  allowEmpty?: boolean;
  loading?: boolean;
  filtersFromTable: Record<Dimension, string[]>;
  loadFilters?: () => void;
}

const getTableFilterValues = (
  data: ChargebackReportByPeriodForFilters[]
): Record<Dimension, string[]> => {
  const result: Record<Dimension, string[]> = {} as Record<Dimension, string[]>;

  ALL_AVAILABLE_DIMENSIONS.forEach((dimension) => {
    const allValues = data.filter((x) => !x.totalValue).map((x) => x[dimension] || '');
    const distinctValues = new Set<string>(allValues);
    result[dimension] = [...distinctValues];
  });

  return result;
};

export const ChargebacksFilters = ({
  fiscalCalendarWeeks
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
}) => {
  const [selectedFieldDimensions, setSelectedFieldDimensions] =
    useChargebacksFiltersFromQueryParam();

  const [getFiltersData, filtersData] = useLazyQuery(
    ChargebacksReportDetailsByPeriodForFiltersDocument,
    {
      variables: {
        filters: {
          fiscalCalendarWeeks,
          countryCode: 'US'
        }
      }
    }
  );

  const tableFilters = useMemo(
    () => getTableFilterValues(filtersData.data?.chargebackDetailsReportsByPeriod || []),
    [filtersData.data?.chargebackDetailsReportsByPeriod]
  );

  return (
    <Filter
      filters={filters}
      initialSelectedOptions={selectedFieldDimensions}
      handleApply={(dimensions) => setSelectedFieldDimensions(dimensions)}
      loading={filtersData.loading}
      filtersFromTable={tableFilters}
      loadFilters={() => {
        // This allow us to call it only when variables change
        if (!filtersData.called) getFiltersData();
      }}
    />
  );
};

const Filter = <T extends string>({
  filters,
  initialSelectedOptions,
  handleApply,
  allowEmpty = false,
  loading,
  filtersFromTable,
  loadFilters
}: FilterProps<T>) => {
  const [open, setOpen] = useState(false);
  // Otherwise it doesn't close automatically.
  // If we use "destroyTooltipOnHide" prop, it kinda jumps
  const [openPopup, setOpenPopup] = useState('');

  const [tempSelectedOptions, setTempSelectedOptions] = useState<T[]>(
    initialSelectedOptions.enabled
  );

  const [tempSelectedFilters, setTempSelectedFilters] = useState<Record<T, string[]>>(() => {
    const filterObject = {} as Record<T, string[]>;
    filters.forEach((filter) => {
      filterObject[filter.value] = [ALL];
    });
    return filterObject;
  });

  const setBranchValue = (branch: string) => (value: string[]) =>
    setTempSelectedFilters({
      ...tempSelectedFilters,
      [branch]: value
    });

  const handleOptionsChange = (value: T, isChecked: boolean) => {
    setTempSelectedOptions((prevState) => {
      const newSelection = isChecked
        ? [...prevState, value]
        : prevState.filter((item) => item !== value);
      return filters.filter((item) => newSelection.includes(item.value)).map((item) => item.value);
    });
    setBranchValue(value)([ALL]);
  };

  const applyChanges = () => {
    if (!allowEmpty && tempSelectedOptions.length === 0) {
      setTempSelectedOptions(initialSelectedOptions.enabled);
      setTempSelectedFilters(initialSelectedOptions.filters);
    } else {
      handleApply({ enabled: tempSelectedOptions, filters: tempSelectedFilters });
    }
    setOpen(false);
  };

  const cancelChanges = () => {
    onOpenChange(false);
  };

  // Reset values if "apply" was not pressed
  const onOpenChange = (open: boolean) => {
    if (open) {
      if (loadFilters) {
        loadFilters();
      }
    }
    setTempSelectedOptions(initialSelectedOptions.enabled);
    setTempSelectedFilters(initialSelectedOptions.filters);
    setOpenPopup('');
    setOpen(open);
  };

  const onFilterChange = (branch: T, filters: string[], allValues: string[]) => {
    setArrayWithAllAndAllowEmptyState(
      filters || [],
      tempSelectedFilters[branch] || [],
      setBranchValue(branch),
      allValues,
      ALL
    );
  };

  return (
    <AlloyPopover
      open={open}
      onOpenChange={onOpenChange}
      trigger={['click']}
      zIndex={3}
      overlayClassName={s.filters_wrapper}
      overlayInnerStyle={{ borderRadius: '8px', padding: '8px 16px' }}
      arrow={false}
      placement="bottomRight"
      content={() => (
        <>
          <div className={s.filters}>
            {filters.map((item) => {
              const onOpenChange = (isOpen: boolean) => {
                if (tempSelectedFilters[item.value]?.length === 0) {
                  setBranchValue(item.value as Dimension)([ALL]);
                }
                if (isOpen) {
                  setOpenPopup(item.value);
                } else {
                  setOpenPopup('');
                }
              };

              const allName = `All ${item.label}${item.label === 'Status' ? 'es' : 's'}`;
              const someName = `Some ${item.label}${item.label === 'Status' ? 'es' : 's'}`;
              const noneName = `None ${item.label}${item.label === 'Status' ? 'es' : 's'}`;

              const areAllSelected = (tempSelectedFilters[item.value] || [])
                .map((x) => x.toLowerCase())
                .includes(ALL.toLowerCase());

              const text = loading
                ? '...'
                : `${areAllSelected ? allName : (tempSelectedFilters[item.value] || []).length === 0 ? noneName : someName} (${areAllSelected ? filtersFromTable[item.value as Dimension]?.length : tempSelectedFilters[item.value]?.length})`;

              return (
                <div key={JSON.stringify(item.value)} className={s.filter_line_item}>
                  <AlloyCheckbox
                    type="checkbox"
                    value={item.value}
                    checked={tempSelectedOptions.includes(item.value)}
                    onChange={(e) => handleOptionsChange(item.value, e.target.checked)}
                  >
                    {item.label}
                  </AlloyCheckbox>
                  <AlloyPopover
                    open={openPopup === item.value}
                    onOpenChange={onOpenChange}
                    trigger={['click']}
                    destroyTooltipOnHide
                    content={() => (
                      <MultipleSelectWithSearch
                        displaySelected={false}
                        values={
                          getValueWithAll(
                            tempSelectedFilters[item.value] || [],
                            filtersFromTable[item.value as Dimension] || [],
                            true,
                            ALL
                          ) || []
                        }
                        setValues={(values) =>
                          onFilterChange(
                            item.value,
                            values,
                            filtersFromTable[item.value as Dimension] || []
                          )
                        }
                        options={filtersFromTable[item.value as Dimension].map((x) => ({
                          name: stringifyBackendValue(x),
                          value: x
                        }))}
                        allValuesOption={{ value: ALL, name: allName }}
                      />
                    )}
                    placement="rightTop"
                    arrow={false}
                    zIndex={4}
                    overlayClassName={clsx(s.filters_wrapper, s.filters_subsection_wrapper)}
                    overlayInnerStyle={{
                      borderRadius: '4px',
                      padding: '4px 4px 0',
                      minWidth: '100px',
                      maxWidth: '320px'
                    }}
                  >
                    <AlloyButton
                      loading={loading}
                      disabled={!tempSelectedOptions.includes(item.value)}
                      style={{
                        width: '175px',
                        justifyContent: 'space-between',
                        paddingLeft: '7px',
                        paddingRight: '7px'
                      }}
                    >
                      {text}
                      <RightOutlined style={{ fontSize: '10px' }} />
                    </AlloyButton>
                  </AlloyPopover>
                </div>
              );
            })}
          </div>
          <div className={s.confirm}>
            <AlloyButton onClick={cancelChanges}>Cancel</AlloyButton>
            <AlloyButton onClick={applyChanges} type={'primary'}>
              Apply
            </AlloyButton>
          </div>
        </>
      )}
    >
      <AlloyButton
        size="large"
        className={s.filters_button}
        icon={<Icon component={() => <FilterIcon />} />}
      >
        <span>Add Filter</span>
        <NumberBadge count={initialSelectedOptions.enabled.length} />
      </AlloyButton>
    </AlloyPopover>
  );
};
