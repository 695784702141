import React, { useContext, useState } from 'react';
import { Redirect, Route, RouteProps, NavLink, useHistory } from 'react-router-dom';
import MasterHeader from '../components/MasterHeader/MasterHeader';
import { isLoggedIn } from '../common/helpers/routerHelper';
import { User } from 'graphql/__generated__/types';
import { GetMeDocument } from 'queries/__generated__/users';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'components/LoaderSpinner';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { getItem, setItem } from 'common/services/persistentStorageServices';
import s from './PrivateRouteWithHeader.module.scss';
import { UserContext } from 'context/userContext';
import { setUser as sentrySetUser } from '@sentry/browser';
import { datadogRum } from '@datadog/browser-rum';
import moment from 'moment';
import clsx from 'clsx';

import orderIcon from 'assets/icons/menu/order.svg';
import orderIconFilled from 'assets/icons/menu/order-filled.svg';
import orderIconActive from 'assets/icons/menu/order-active.svg';
import statsIcon from 'assets/icons/menu/stats.svg';
import statsIconFilled from 'assets/icons/menu/stats-filled.svg';
import statsIconActive from 'assets/icons/menu/stats-active.svg';
import truckIcon from 'assets/icons/menu/truck.svg';
import truckIconFilled from 'assets/icons/menu/truck-filled.svg';
import truckIconActive from 'assets/icons/menu/truck-active.svg';
import internalManagementIcon from 'assets/icons/menu/internal-management.svg';
import internalManagementIconFilled from 'assets/icons/menu/internal-management-filled.svg';
import internalManagementIconActive from 'assets/icons/menu/internal-management-active.svg';
import usersIcon from 'assets/icons/menu/users.svg';
import usersIconFilled from 'assets/icons/menu/users-filled.svg';
import usersIconActive from 'assets/icons/menu/users-active.svg';
import { useFeatureFlags } from 'common/useFeatureFlags/useFeatureFlags';
import { useBuildDetailsShown } from 'components/Common/BuildDetails/useBuildDetailsShown';
import {
  FE_FORECAST_PLANNING_MENU_ENABLED,
  FE_EVENT_TRACKER,
  FE_DEMAND_VISIBILITY,
  FE_STO_DASHBOARD,
  FE_INVENTORY_OPTIMIZATION,
  WHSE_ORDER_PROCESSING_PAGE,
  WHSE_TRUCK_BUILDER_PAGE,
  FE_CHARGEBACKS
} from 'common/useFeatureFlags/featureFlags';
import { AlloyMenu } from 'components/ui/AlloyMenu/AlloyMenu';
import { AlloyLayout } from 'components/ui/AlloyLayout/AlloyLayout';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
}

const ALL_MENU_KEYS = {
  order_management: 'order_management',
  supply_chain_intelligence: 'supply_chain_intelligence',
  customer_management: 'customer_management',
  internal_management: 'internal_management',
  permissions_management: 'permissions_management',
  sku_optimization: 'sku_optimization',
  inventory_optimzation: 'inventory_optimzation'
};

const ORDERS_SUB_MENU_KEYS = {
  orders: '/',
  assortment: '/assortment',
  warehouse: '/warehouse-processing',
  warehouse_truck_builder: '/warehouse-truck-builder'
};

const INTELLIGENCE_SUB_MENU_KEYS = {
  demand_visibility: '/demand-visibility',
  fill_rate: '/fill-rate',
  event_tracker: '/event-tracker',
  order_visibility: '/visibility/order',
  inventory_visibility: '/visibility/inventory',
  sto_delivery: '/sto-delivery',
  inventory_optimization: '/inventory-optimization',
  repack_planning: '/repack-planning',
  forecast_planning: '/forecast-planning',
  otif: '/otif',
  chargebacks: '/chargebacks'
};

const CUSTOMER_MANAGEMENT_SUB_MENU_KEYS = {
  retailers: '/retailers',
  retailer_channels: '/retailer-channels',
  trading_partners: '/trading-partners',
  ship_to: '/ship-to'
};

const INTERNAL_MANAGEMENT_SUB_MENU_KEYS = {
  distribution_centers: '/distribution-centers'
};

const PERMISSIONS_SUB_MENU_KEYS = {
  permissions: '/permissions'
};

const PrivateRouteWithHeader = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const [collapsedSidebar, setCollapsedSidebar] = useState(true);
  const history = useHistory();
  const isBuildDetailsShown = useBuildDetailsShown();

  // We need this hack since Sider controls Menu and automatically closes all items on collapse.
  const [openKeysBackup, setOpenKeysBackup] = useState(Object.values(ALL_MENU_KEYS));
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const { Content, Sider } = AlloyLayout;

  const { setUser } = useContext(UserContext);

  const { data, loading, error } = useQuery(GetMeDocument, {
    onCompleted: (data) => {
      if (data) {
        const websocketToken = data.me?.websocketToken;
        const user = getItem('user');
        setItem('user', { ...user, websocket_token: websocketToken });
        setUser(data.me as User);
        if (data.me) {
          sentrySetUser({ email: data.me.email, name: data.me.name, id: data.me.id });
          datadogRum.setUser({
            email: data.me.email,
            name: data.me.name,
            id: data.me.id,
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight
          });
        }
      }
    }
  });

  const {
    featureFlags: [
      { enabled: isInventoryOptimisationEnabled },
      { enabled: isSTODashboardEnabled },
      { enabled: isEventTrackerEnabled },
      { enabled: isDemandVisibilityEnabled },
      { enabled: isForecastPlanningEnabled },
      { enabled: isWhseOrderProcessingPageEnabled },
      { enabled: isWhseTruckBuilderPageEnabled },
      { enabled: isChargebacksEnabled }
    ]
  } = useFeatureFlags([
    { name: FE_INVENTORY_OPTIMIZATION },
    { name: FE_STO_DASHBOARD },
    { name: FE_EVENT_TRACKER },
    { name: FE_DEMAND_VISIBILITY },
    { name: FE_FORECAST_PLANNING_MENU_ENABLED },
    { name: WHSE_ORDER_PROCESSING_PAGE },
    { name: WHSE_TRUCK_BUILDER_PAGE },
    { name: FE_CHARGEBACKS }
  ]);

  if (loading || !data) return <LoaderSpinner />;
  if (error) return <ErrorDisplay error={error} />;
  // TODO: not sure if me can be empty
  const user = data?.me as User;

  const toggleCollapsedSidebar = () => {
    // We need this hack since Sider controls Menu and automatically closes all items on collapse.
    if (collapsedSidebar) {
      setOpenKeys(openKeysBackup);
    } else {
      setOpenKeysBackup(openKeys);
      setOpenKeys([]);
    }
    setCollapsedSidebar(!collapsedSidebar);
  };

  const getMenuItemContent = (title: string, href: string, testId?: string) => (
    <NavLink
      to={href}
      data-testid={testId}
      onClick={() => !collapsedSidebar && toggleCollapsedSidebar()}
      className={clsx(s.link, { [s.collapsed]: collapsedSidebar })}
      activeClassName={s.active}
      exact
    >
      {title}
    </NavLink>
  );

  const isAdminOrSuperAdmin = user?.roles.includes('ADMIN') || user?.roles.includes('SUPERADMIN');
  const isSupplyChain = user?.roles.includes('SUPPLY_CHAIN');
  const isDataUser = user?.roles.includes('DATA');

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          <>
            <MasterHeader
              userName={user.name}
              toggleCollapsedSidebar={toggleCollapsedSidebar}
              collapsedSidebar={collapsedSidebar}
            />
            <AlloyLayout>
              <Sider
                className={clsx(s.po_sidebar, { [s.with_build_details]: isBuildDetailsShown })}
                collapsedWidth={74}
                trigger={null}
                collapsible
                collapsed={collapsedSidebar}
                width="270px"
              >
                <AlloyMenu
                  mode="inline"
                  className={s.menu}
                  openKeys={openKeys}
                  selectedKeys={[]}
                  onOpenChange={(values) => {
                    setOpenKeys(values);
                  }}
                  data-testid="sidebar-menu"
                  forceSubMenuRender
                  items={[
                    {
                      key: ALL_MENU_KEYS.order_management,
                      label: !collapsedSidebar ? 'Order Management' : 'Order',
                      className: clsx(s.menu_group, {
                        [s.collapsed]: collapsedSidebar,
                        [s.active]:
                          collapsedSidebar &&
                          Object.values(ORDERS_SUB_MENU_KEYS).includes(history.location.pathname)
                      }),
                      popupClassName: clsx({
                        [s.popup_hidden]: !collapsedSidebar,
                        [s.popup_menu]: collapsedSidebar
                      }),
                      children: [
                        {
                          className: s.menu_item,
                          key: 'purchase-orders-list',
                          label: getMenuItemContent(
                            'Purchase Orders',
                            ORDERS_SUB_MENU_KEYS.orders,
                            'sidebar-menu-po-management'
                          )
                        },
                        {
                          className: s.menu_item,
                          key: 'active-assortment',
                          label: getMenuItemContent(
                            'Active Assortment',
                            ORDERS_SUB_MENU_KEYS.assortment,
                            'sidebar-menu-active-assortment'
                          )
                        },
                        isWhseOrderProcessingPageEnabled
                          ? {
                              className: s.menu_item,
                              key: 'warehouse-processing',
                              label: getMenuItemContent(
                                'Warehouse Processing',
                                ORDERS_SUB_MENU_KEYS.warehouse,
                                'sidebar-menu-warehouse-processing'
                              )
                            }
                          : null,
                        isWhseTruckBuilderPageEnabled
                          ? {
                              className: s.menu_item,
                              key: 'warehouse-truck-builder',
                              label: getMenuItemContent(
                                'Warehouse Truck Builder',
                                ORDERS_SUB_MENU_KEYS.warehouse_truck_builder,
                                'sidebar-menu-warehouse-truck-builder'
                              )
                            }
                          : null
                      ],
                      icon: (
                        <img
                          src={
                            Object.values(ORDERS_SUB_MENU_KEYS).includes(
                              history.location.pathname
                            ) && collapsedSidebar
                              ? orderIconActive
                              : openKeys.includes(ALL_MENU_KEYS.order_management)
                                ? orderIconFilled
                                : orderIcon
                          }
                          alt=""
                        />
                      )
                    },
                    isAdminOrSuperAdmin || isSupplyChain || isDataUser
                      ? {
                          key: ALL_MENU_KEYS.supply_chain_intelligence,
                          label: !collapsedSidebar ? 'SC Intelligence' : 'Intelligence',
                          className: clsx(s.menu_group, {
                            [s.collapsed]: collapsedSidebar,
                            [s.active]:
                              collapsedSidebar &&
                              Object.values(INTELLIGENCE_SUB_MENU_KEYS).includes(
                                history.location.pathname
                              )
                          }),
                          popupClassName: clsx({
                            [s.popup_hidden]: !collapsedSidebar,
                            [s.popup_menu]: collapsedSidebar
                          }),
                          children: [
                            isDemandVisibilityEnabled
                              ? {
                                  className: s.menu_item,
                                  key: 'demand-visibility',
                                  label: getMenuItemContent(
                                    'Demand Visibility',
                                    INTELLIGENCE_SUB_MENU_KEYS.demand_visibility
                                  )
                                }
                              : null,
                            isAdminOrSuperAdmin
                              ? {
                                  className: s.menu_item,
                                  key: 'fill-rate',
                                  label: getMenuItemContent(
                                    'Fill rate',
                                    INTELLIGENCE_SUB_MENU_KEYS.fill_rate,
                                    'sidebar-menu-fill-rate'
                                  )
                                }
                              : null,
                            isInventoryOptimisationEnabled
                              ? {
                                  className: s.menu_item,
                                  key: 'inventory-optimization',
                                  label: getMenuItemContent(
                                    'Inventory Optimization',
                                    INTELLIGENCE_SUB_MENU_KEYS.inventory_optimization,
                                    'sidebar-menu-inventory-optimization'
                                  )
                                }
                              : null,
                            {
                              className: s.menu_item,
                              key: 'inventory-visibility',
                              label: getMenuItemContent(
                                'Inventory Visibility',
                                INTELLIGENCE_SUB_MENU_KEYS.inventory_visibility,
                                'sidebar-menu-inventory-visibility'
                              )
                            },
                            {
                              className: s.menu_item,
                              key: 'order-visibility',
                              label: getMenuItemContent(
                                'Order Visibility',
                                INTELLIGENCE_SUB_MENU_KEYS.order_visibility
                              )
                            },
                            isEventTrackerEnabled
                              ? {
                                  className: s.menu_item,
                                  key: 'event-tracker',
                                  label: getMenuItemContent(
                                    'Event Tracker',
                                    INTELLIGENCE_SUB_MENU_KEYS.event_tracker
                                  )
                                }
                              : null,
                            {
                              className: s.menu_item,
                              key: 'otif',
                              label: getMenuItemContent('OTIF', INTELLIGENCE_SUB_MENU_KEYS.otif)
                            },
                            {
                              className: s.menu_item,
                              key: 'repack-planning',
                              label: getMenuItemContent(
                                'Repack Planning',
                                INTELLIGENCE_SUB_MENU_KEYS.repack_planning
                              )
                            },
                            isForecastPlanningEnabled
                              ? {
                                  className: s.menu_item,
                                  key: 'forecast-planning',
                                  label: getMenuItemContent(
                                    'ForecastIQ',
                                    INTELLIGENCE_SUB_MENU_KEYS.forecast_planning
                                  )
                                }
                              : null,
                            isChargebacksEnabled
                              ? {
                                  className: s.menu_item,
                                  key: 'chargebacks',
                                  label: getMenuItemContent(
                                    'Chargebacks',
                                    INTELLIGENCE_SUB_MENU_KEYS.chargebacks
                                  )
                                }
                              : null,
                            isSTODashboardEnabled
                              ? {
                                  className: s.menu_item,
                                  key: 'sto-delivery',
                                  label: getMenuItemContent(
                                    'STO Delivery',
                                    INTELLIGENCE_SUB_MENU_KEYS.sto_delivery
                                  )
                                }
                              : null
                          ],
                          icon: (
                            <img
                              src={
                                Object.values(INTELLIGENCE_SUB_MENU_KEYS).includes(
                                  history.location.pathname
                                ) && collapsedSidebar
                                  ? statsIconActive
                                  : openKeys.includes(ALL_MENU_KEYS.supply_chain_intelligence)
                                    ? statsIconFilled
                                    : statsIcon
                              }
                              alt=""
                            />
                          )
                        }
                      : null,
                    isAdminOrSuperAdmin
                      ? {
                          key: ALL_MENU_KEYS.customer_management,
                          label: 'Customer Management',
                          className: clsx(s.menu_group, {
                            [s.collapsed]: collapsedSidebar,
                            [s.active]:
                              collapsedSidebar &&
                              Object.values(CUSTOMER_MANAGEMENT_SUB_MENU_KEYS).includes(
                                history.location.pathname
                              )
                          }),
                          popupClassName: clsx({
                            [s.popup_hidden]: !collapsedSidebar,
                            [s.popup_menu]: collapsedSidebar
                          }),
                          children: [
                            {
                              className: s.menu_item,
                              key: 'retailers',
                              label: getMenuItemContent(
                                'Retailers',
                                CUSTOMER_MANAGEMENT_SUB_MENU_KEYS.retailers,
                                'sidebar-menu-retailers'
                              )
                            },
                            {
                              className: s.menu_item,
                              key: 'retailer-channels',
                              label: getMenuItemContent(
                                'Retailer Channels',
                                CUSTOMER_MANAGEMENT_SUB_MENU_KEYS.retailer_channels,
                                'sidebar-menu-retailer-channels'
                              )
                            },
                            {
                              className: s.menu_item,
                              key: 'trading-partners',
                              label: getMenuItemContent(
                                'Trading Partners',
                                CUSTOMER_MANAGEMENT_SUB_MENU_KEYS.trading_partners,
                                'sidebar-menu-trading-partners'
                              )
                            },
                            {
                              className: s.menu_item,
                              key: 'ship-to',
                              label: getMenuItemContent(
                                'Ship-Tos',
                                CUSTOMER_MANAGEMENT_SUB_MENU_KEYS.ship_to,
                                'sidebar-menu-ship-tos'
                              )
                            }
                          ],
                          icon: (
                            <img
                              src={
                                Object.values(CUSTOMER_MANAGEMENT_SUB_MENU_KEYS).includes(
                                  history.location.pathname
                                ) && collapsedSidebar
                                  ? truckIconActive
                                  : openKeys.includes(ALL_MENU_KEYS.customer_management)
                                    ? truckIconFilled
                                    : truckIcon
                              }
                              alt=""
                            />
                          )
                        }
                      : null,
                    isAdminOrSuperAdmin
                      ? {
                          key: ALL_MENU_KEYS.internal_management,
                          label: 'Internal Management',
                          className: clsx(s.menu_group, {
                            [s.collapsed]: collapsedSidebar,
                            [s.active]:
                              collapsedSidebar &&
                              Object.values(INTERNAL_MANAGEMENT_SUB_MENU_KEYS).includes(
                                history.location.pathname
                              )
                          }),
                          popupClassName: clsx({
                            [s.popup_hidden]: !collapsedSidebar,
                            [s.popup_menu]: collapsedSidebar
                          }),
                          children: [
                            {
                              className: s.menu_item,
                              key: 'distribution-centers',
                              label: getMenuItemContent(
                                'Distribution Centers',
                                INTERNAL_MANAGEMENT_SUB_MENU_KEYS.distribution_centers,
                                'sidebar-menu-distribution-centers'
                              )
                            }
                          ],
                          icon: (
                            <img
                              src={
                                Object.values(INTERNAL_MANAGEMENT_SUB_MENU_KEYS).includes(
                                  history.location.pathname
                                ) && collapsedSidebar
                                  ? internalManagementIconActive
                                  : openKeys.includes(ALL_MENU_KEYS.internal_management)
                                    ? internalManagementIconFilled
                                    : internalManagementIcon
                              }
                              alt=""
                            />
                          )
                        }
                      : null,
                    isAdminOrSuperAdmin
                      ? {
                          key: ALL_MENU_KEYS.permissions_management,
                          label: 'Permissions & Access',
                          className: clsx(s.menu_group, {
                            [s.collapsed]: collapsedSidebar,
                            [s.active]:
                              collapsedSidebar &&
                              Object.values(PERMISSIONS_SUB_MENU_KEYS).includes(
                                history.location.pathname
                              )
                          }),
                          popupClassName: clsx({
                            [s.popup_hidden]: !collapsedSidebar,
                            [s.popup_menu]: collapsedSidebar
                          }),
                          children: [
                            {
                              className: s.menu_item,
                              key: 'user-management',
                              label: getMenuItemContent(
                                'User Management',
                                PERMISSIONS_SUB_MENU_KEYS.permissions,
                                'sidebar-menu-user-management'
                              )
                            }
                          ],
                          icon: (
                            <img
                              src={
                                Object.values(PERMISSIONS_SUB_MENU_KEYS).includes(
                                  history.location.pathname
                                ) && collapsedSidebar
                                  ? usersIconActive
                                  : openKeys.includes(ALL_MENU_KEYS.permissions_management)
                                    ? usersIconFilled
                                    : usersIcon
                              }
                              alt=""
                            />
                          )
                        }
                      : null
                  ]}
                />
                {!collapsedSidebar && (
                  <div className={s.side_menu_footer}>
                    Pepsico © {moment().format('YYYY')}. All Rights Reserved.
                  </div>
                )}
              </Sider>
              <AlloyLayout>
                <Content className={s.content_layout}>
                  <Component {...props} />
                </Content>
              </AlloyLayout>
            </AlloyLayout>
          </>
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRouteWithHeader;
