import { ExclamationCircleFilled, UploadOutlined, WarningOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  acronymsToUpperCase,
  fieldNameToCapitalize,
  fieldNameToString
} from 'common/helpers/stringsConverter';
import { UserContext } from 'context/userContext';
import {
  UpsertTpAssortmentInput,
  UpsertTradingPartnerAssortmentsPayload
} from 'graphql/__generated__/types';
import Papa from 'papaparse';
import React, { useContext, useState } from 'react';
import { CSVLink } from 'react-csv';
import s from './BulkAddActiveAssortmentModal.module.scss';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  DistributionCentersForAaDocument,
  DistributionCentersForAaQuery
} from 'pages/AssortmentPage/gql/__generated__/distributionCenters.query';
import { getFieldTitleBySlug, getItemHeaders } from 'pages/AssortmentPage/fieldsHelper';
import { UpsertTradingPartnerAssortmentsDocument } from 'pages/AssortmentPage/gql/__generated__/upsertTradingPartnerAssortments.mutation';
import { AssortmentConfig, TradingPartner } from 'pages/AssortmentPage/AssortmentPage';
import { TradingPartnerAssortmentsDocument } from 'pages/AssortmentPage/gql/__generated__/tradingPartnerAssortments.query';
import { ConfigNotfication } from '../ConfigNotfication/ConfigNotfication';
import { App } from 'ant5';
import { capitalize } from 'lodash-es';
import { ProductType } from 'graphql/__generated__/enums';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloySteps } from 'components/ui/AlloySteps/AlloySteps';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyUpload, UploadChangeParam, UploadProps } from 'components/ui/AlloyUpload/AlloyUpload';
import { AlloyAlert } from 'components/ui/AlloyAlert/AlloyAlert';
import { AlloyTable, ColumnsType, ColumnType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';

const { Step } = AlloySteps;

type UpsertAssortmentError = UpsertTradingPartnerAssortmentsPayload['errors'][number];

interface BulkAddActiveAssortmentWithConfigModalProps {
  toggleModal: () => void;
  tradingPartner: TradingPartner;
  assortmentConfig?: AssortmentConfig | null;
}

interface ActiveAssortmentFileItem extends UpsertTpAssortmentInput {
  errors?: string[];
  [key: string]: (string | null)[] | string | number | boolean | null | undefined;
}

type DistributionCenter = InferNodeType<DistributionCentersForAaQuery, 'distributionCenters'>;

const validateItems = (items: ActiveAssortmentFileItem[], assortmentConfig: AssortmentConfig) =>
  items.map((item) => {
    const errors = [] as string[];

    assortmentConfig.fields.forEach((field) => {
      const value = (item as { [name: string]: string })[fieldNameToCapitalize(field.slug || '')];
      if (field.required && !value) {
        errors.push(`${field.name}: Should not be empty`);
      } else if (field.validation?.regexp && !String(value).match(field.validation?.regexp)) {
        errors.push(`${field.name}: ${field.validation.message}`);
      }

      const checkDuplicateItems = items.filter(
        (i) => i.externalId === item.externalId && i.upc === item.upc
      );
      if (field.slug === 'EXTERNAL_ID' && checkDuplicateItems.length > 1) {
        const errorText = `${field.name} and ${getFieldTitleBySlug(assortmentConfig, 'UPC')} is duplicated`;
        if (!errors.includes(errorText)) {
          errors.push(errorText);
        }
      }

      if (field.slug === 'UPC' && checkDuplicateItems.length > 1) {
        const errorText = `${getFieldTitleBySlug(assortmentConfig, 'EXTERNAL_ID')} and ${field.name} is duplicated`;
        if (!errors.includes(errorText)) {
          errors.push(errorText);
        }
      }

      if (
        field.slug === 'PRODUCT_TYPE' &&
        item.productType &&
        !Object.keys(ProductType).includes(item.productType)
      ) {
        errors.push(`${field.name}: Incorrect value`);
      }
    });

    return { ...item, errors };
  });

const rowsToData = (
  row: { [name: string]: string },
  assortmentConfig: AssortmentConfig,
  distributionCenters: DistributionCenter[]
) => {
  const item: { [name: string]: string | string[] | number | boolean | undefined } = {};
  for (const header of getItemHeaders(assortmentConfig)) {
    if (header.key === 'distributionCenterIds') {
      const codes = row[header.label] ? String(row[header.label])?.split(',') : [];
      const ids: string[] = [];
      for (const code of codes) {
        const dc = distributionCenters.find(
          (dc) => dc.code === code.trim() || code.trim() === `${dc.name} ${dc.code}`
        );
        if (dc) {
          ids.push(dc.id);
        }
      }
      item[header.key] = ids;
    } else if (
      [
        'grossWeight',
        'width',
        'height',
        'depth',
        'casesPerLayer',
        'casesPerPallet',
        'inventoryReserve',
        'layersPerPallet',
        'moqMinimum',
        'price',
        'toCaseQuantity'
      ].includes(header.key)
    ) {
      const value = row[header.label] ? Number(row[header.label].trim()) : undefined;
      item[header.key] = value && !Number.isNaN(value) ? value : undefined;
    } else if (header.key === 'shipsInOwnContainer') {
      item[header.key] = !!row[header.label] && row[header.label] === 'Y';
    } else if (header.key === 'productType') {
      item[header.key] = row[header.label]
        ? (row[header.label] as string).trim().toUpperCase()
        : undefined;
    } else {
      item[header.key] = row[header.label] ? row[header.label].trim() : undefined;
    }
  }
  return item as UpsertTpAssortmentInput;
};

export const BulkAddActiveAssortmentWithConfigModal = ({
  toggleModal,
  tradingPartner,
  assortmentConfig
}: BulkAddActiveAssortmentWithConfigModalProps) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const { message } = App.useApp();

  const { isAdmin } = useContext(UserContext);

  const [selectedRdds, setSelectedRdds] = useState<string[]>(['all']);
  const [vendorFlex, setVendorFlex] = useState<boolean>(false);
  const [selectedVendorFlexRdds, setSelectedVendorFlexRdds] = useState<string[]>([]);

  const [parsedActiveAssortmentItems, setParsedActiveAssortmentItems] =
    useState<ActiveAssortmentFileItem[]>();

  const [uploadingResult, setUploadingResult] = useState<{
    successCount: number;
    errorsCount: number;
    errors: UpsertAssortmentError[];
  }>();

  const { loading } = useQuery(DistributionCentersForAaDocument, {
    variables: {
      first: 100000
    },
    onCompleted: (data) => {
      setDistributionCenters(getNodesFromEdges(data.distributionCenters));
    }
  });

  const [distributionCenters, setDistributionCenters] = useState<DistributionCenter[]>([]);

  const [upsertActiveassortments, { loading: uploading }] = useMutation(
    UpsertTradingPartnerAssortmentsDocument,
    {
      refetchQueries: [TradingPartnerAssortmentsDocument]
    }
  );

  const deliveryDestinations = tradingPartner.retailerDeliveryDestinations || [];
  if (!assortmentConfig) {
    return (
      <AlloyModal title="Active Assortment configuration is not set">
        <ConfigNotfication forAdmin={isAdmin()} tradingPartnerId={tradingPartner.id} />
      </AlloyModal>
    );
  }

  const uploadProps: UploadProps = {
    accept: '.csv',
    maxCount: 1,
    name: 'file',
    customRequest: ({ onSuccess }) => {
      if (onSuccess) onSuccess('ok');
    },
    onChange: ({ file }: UploadChangeParam) => {
      if (file.status === 'done') {
        parseData(file.originFileObj as File);
      } else {
        setParsedActiveAssortmentItems(undefined);
      }
    }
  };

  const getColumns = () => {
    const columns: ColumnType<ActiveAssortmentFileItem>[] =
      assortmentConfig?.fields.map((field) => {
        return {
          title: field.name,
          key: field.slug || field.name,
          render: (record: ActiveAssortmentFileItem) => {
            const value = record[fieldNameToCapitalize(field.slug || '')];
            if (field.slug === 'SHIPS_IN_OWN_CONTAINER') {
              return value ? 'Y' : 'N';
            } else if (field.slug === 'DISTRIBUTION_CENTER_IDS') {
              return (value as string[])
                .map((id) => {
                  const distributionCenter = distributionCenters.find((dc) => dc.id === id);
                  return `${distributionCenter?.name} ${distributionCenter?.code}`;
                })
                .join(', ');
            } else if (field.slug === 'PRODUCT_TYPE') {
              return value ? capitalize(value as string) : '';
            }
            return value;
          }
        };
      }) || [];
    if (parsedActiveAssortmentItems?.find((item) => item.errors && item.errors.length > 0)) {
      columns.unshift({
        title: '',
        width: 30,
        key: 'errors',
        render: (record: ActiveAssortmentFileItem) =>
          record.errors && record.errors.length > 0 ? (
            <AlloyTooltip
              title={
                <div>
                  {record.errors.map((error, i) => (
                    <div key={i}>{error}</div>
                  ))}
                </div>
              }
              className={s.error}
            >
              <ExclamationCircleFilled />
            </AlloyTooltip>
          ) : null
      });
    }
    return columns;
  };

  const uploadResultColumns: ColumnsType<UpsertAssortmentError> = [
    {
      title: 'External ID',
      key: 'externalId',
      render: (error: UpsertAssortmentError) => error.externalId
    },
    {
      title: 'UPC',
      key: 'upc',
      render: (error: UpsertAssortmentError) => error.upc
    },
    {
      title: 'Reason',
      key: 'reason',
      render: (error: UpsertAssortmentError) =>
        acronymsToUpperCase(fieldNameToString(error.reason.replaceAll(/[:"]/g, '')))
    }
  ];

  const steps = [
    {
      step: 0,
      title: 'Select data',
      content: (
        <div className={s.modal_content}>
          <div>
            <span>
              Add active assortments for <b>{tradingPartner.name}</b> in bulk via a CSV upload.
              Table data needs to follow{' '}
            </span>
            <CSVLink
              className="download_csv"
              filename={'bulk_add_to_trading_partner_assortment_template.csv'}
              headers={getItemHeaders(assortmentConfig)}
              data={[]}
            >
              this CSV format
            </CSVLink>
            <span>.</span>
          </div>
          <AlloySelect
            placeholder="Select Delivery Destinations"
            value={selectedRdds}
            mode="multiple"
            size="large"
            maxTagCount="responsive"
            onChange={(value) => {
              setSelectedRdds((previous) => {
                const addedValue = (value || []).find((id) => !previous.includes(id));
                if (addedValue) {
                  if (addedValue === 'all') {
                    return ['all'];
                  } else if (previous.includes('all')) {
                    return (value || []).filter((id) => id !== 'all');
                  }
                }
                return value || [];
              });
            }}
            options={[
              {
                label: 'All Ship-Tos',
                value: 'all'
              },
              ...deliveryDestinations
                .filter((rdd) => !rdd?.specialAssortment)
                .map((rdd) => ({
                  label: rdd?.name ?? '',
                  value: rdd?.id
                }))
            ]}
          />
          {assortmentConfig.specialAssortment && (
            <div className={s.vendor_flex_container}>
              <AlloyCheckbox
                checked={vendorFlex}
                onChange={(value) => {
                  setVendorFlex(value.target.checked);
                }}
              >
                Vendor Flex
              </AlloyCheckbox>
              {vendorFlex && (
                <AlloySelect
                  placeholder="Vendor Flex Destinations"
                  value={selectedVendorFlexRdds}
                  size="large"
                  mode="multiple"
                  onChange={(value) => {
                    setSelectedVendorFlexRdds(value || []);
                  }}
                  options={deliveryDestinations
                    .filter((rdd) => rdd?.specialAssortment)
                    .map((rdd) => ({
                      value: rdd?.id,
                      label: rdd?.name || ''
                    }))}
                />
              )}
            </div>
          )}
          <div>
            <AlloyUpload {...uploadProps}>
              <AlloyButton type="primary" size="large" icon={<UploadOutlined />}>
                Click to Upload
              </AlloyButton>
            </AlloyUpload>
          </div>
        </div>
      )
    },
    {
      step: 1,
      title: 'Confirm',
      content: (
        <div className={s.modal_content}>
          <AlloyAlert
            message={
              <div>
                <span style={{ fontWeight: '700' }}>Found: </span>
                {parsedActiveAssortmentItems?.length} item
                {(parsedActiveAssortmentItems || []).length !== 1 ? 's' : ''}
                <br />
                <span style={{ fontWeight: '700' }}>Will be added: </span>
                {
                  parsedActiveAssortmentItems?.filter((item) => item.errors?.length === 0).length
                }{' '}
                item
                {(parsedActiveAssortmentItems || []).filter((item) => item.errors?.length === 0)
                  .length !== 1
                  ? 's'
                  : ''}
              </div>
            }
            type={
              parsedActiveAssortmentItems?.find((item) => (item.errors || []).length > 0)
                ? 'warning'
                : 'success'
            }
          />
          <div className={s.table_wrapper}>
            <AlloyTable
              rowKey={(record) => record.externalId || record.upc || ''}
              columns={getColumns()}
              dataSource={parsedActiveAssortmentItems}
              pagination={false}
              scroll={{
                y: `calc(${window.innerHeight}px - 480px)`,
                x: assortmentConfig.fields.length * 150
              }}
            />
          </div>
          {parsedActiveAssortmentItems?.find((item) => item.errors && item.errors.length > 0) && (
            <div className={s.warning}>
              <WarningOutlined />
              Only entries without errors will be added
            </div>
          )}
        </div>
      )
    },
    {
      step: 2,
      title: 'Uploading Result',
      content: (
        <div className={s.modal_content}>
          <AlloyAlert
            message={
              <div>
                <span style={{ fontWeight: '700' }}>Successfuly uploaded: </span>
                {uploadingResult?.successCount} item
                {uploadingResult?.successCount !== 1 ? 's' : ''}
                <br />
                <span style={{ fontWeight: '700' }}>Not uploaded: </span>
                {uploadingResult?.errorsCount} item
                {uploadingResult?.errorsCount !== 1 ? 's' : ''}
              </div>
            }
            type={(uploadingResult?.errorsCount || 0) > 0 ? 'warning' : 'success'}
          />
          <div className={s.table_name}>Error reasons</div>
          <div className={s.table_wrapper}>
            <AlloyTable<UpsertAssortmentError>
              rowKey={(record) => `${record.externalId}_${record.upc}`}
              columns={uploadResultColumns}
              dataSource={
                uploadingResult?.errors.filter(
                  (error): error is UpsertAssortmentError => !!error
                ) || []
              }
              pagination={false}
              scroll={{ y: `calc(${window.innerHeight}px - 420px)` }}
            />
          </div>
        </div>
      )
    }
  ];

  const parseData = (file: File) => {
    if (assortmentConfig) {
      Papa.parse<{ [name: string]: string }>(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const { data, errors } = results;
          if (errors.length) {
            console.log(errors);
          }
          const fileData = data.map((row) =>
            rowsToData(row, assortmentConfig, distributionCenters)
          ) as ActiveAssortmentFileItem[];
          setParsedActiveAssortmentItems(validateItems(fileData, assortmentConfig));
        }
      });
    }
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const upload = async () => {
    try {
      const itemsToUpload =
        parsedActiveAssortmentItems?.filter((item) => !item.errors || item.errors.length === 0) ||
        [];
      if (itemsToUpload.length === 0) {
        message.success('0 items was successfully added');
      } else {
        const uploadInput = {
          tradingPartnerId: tradingPartner.id,
          retailerDeliveryDestinationIds: (selectedRdds.includes('all')
            ? deliveryDestinations
                .filter((rdd) => !rdd?.specialAssortment)
                .map((rdd) => rdd?.id || '')
            : selectedRdds
          ).concat(vendorFlex ? selectedVendorFlexRdds : []),
          tpAssortments: itemsToUpload.map((item) => {
            delete item.errors;
            return {
              ...item,
              externalId: item.externalId ?? item.upc
            } as UpsertTpAssortmentInput;
          })
        };
        const result = await upsertActiveassortments({
          variables: {
            input: uploadInput
          }
        });
        if (result.data?.upsertTradingPartnerAssortments) {
          const { successCount, errorsCount, errors } = result.data.upsertTradingPartnerAssortments;
          if (errorsCount > 0) {
            setUploadingResult({
              successCount,
              errorsCount,
              errors: errors.filter((error): error is UpsertAssortmentError => !!error)
            });
            next();
          } else {
            message.success(
              `${result.data?.upsertTradingPartnerAssortments?.successCount} item${
                result.data?.upsertTradingPartnerAssortments?.successCount === 1 ? '' : 's'
              } was successfully added`
            );
            toggleModal();
          }
        } else {
          message.error(`Items were not added`);
        }
      }
    } catch (e) {
      console.log({ e });
      const errors = (e.message as string).split('\n').slice(0, 37);
      message.error(
        <>
          <span>Error happened during Trading Partner Active Assortments saving.</span>
          <div>
            {errors.map((x, index) => (
              <div key={index} className={s.truncated_error}>
                {x}
              </div>
            ))}
          </div>
        </>,
        5
      );
    }
  };

  return (
    <AlloyModal
      width={currentStep === 0 ? undefined : 'fit-content'}
      open={true}
      onCancel={toggleModal}
      classNames={{
        content: s.bulk_add_modal,
        body: s.bulk_add_modal_body,
        footer: s.bulk_add_modal_footer,
        header: s.bulk_add_modal_header
      }}
      footer={
        <div>
          {currentStep !== steps.length - 1 && (
            <AlloyButton key="cancel" size="large" onClick={toggleModal} type="secondary">
              Cancel
            </AlloyButton>
          )}
          {currentStep > 0 && currentStep !== steps.length - 1 && (
            <AlloyButton key="previous" size="large" onClick={() => prev()} type="secondary">
              Previous
            </AlloyButton>
          )}
          {currentStep < steps.length - 2 && (
            <AlloyButton
              key="next"
              size="large"
              type="primary"
              onClick={() => next()}
              disabled={selectedRdds.length < 1 || !parsedActiveAssortmentItems}
            >
              Next
            </AlloyButton>
          )}
          {currentStep === steps.length - 2 && (
            <AlloyButton
              key="done"
              size="large"
              type="primary"
              htmlType="submit"
              disabled={uploading}
              onClick={() => {
                upload();
              }}
            >
              Add
            </AlloyButton>
          )}
        </div>
      }
    >
      <AlloySpin spinning={loading || uploading}>
        <AlloySteps current={currentStep} size="small" className={s.steps}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </AlloySteps>
        {steps[currentStep].content}
      </AlloySpin>
    </AlloyModal>
  );
};
