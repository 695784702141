/*
This component contains source code that will create UI for holding values like Transportation Cost, Stressed Inventory Value, Holding Cost
*/
import React, { useMemo } from 'react';
import s from './CostInfo.module.scss';
import downArrowIcon from '../../../../assets/icons/inventoryOptimization/down_arrow_icon.png';
import upArrowIcon from '../../../../assets/icons/inventoryOptimization/up_arrow_icon.png';
import { App } from 'ant5';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { GetCostInfoDataDocument } from 'pages/InventoryOptimizationPage/gql/__generated__/scenarioDetails.query';
import currency from 'currency.js';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
export interface CostDetails {
  id: string;
  costType: string;
  cost: number;
  optimalCost: number;
}

const CostInfo: React.FC = () => {
  const { message } = App.useApp();
  const { key } = useParams<{ key: string }>();
  const {
    data: costInfoData,
    error,
    loading
  } = useQuery(GetCostInfoDataDocument, {
    variables: { scenarioId: key }
  });

  const data = useMemo(
    () =>
      costInfoData?.getScenariosList?.map((scenarioInfo) => [
        {
          id: scenarioInfo?.id + '1',
          costType: 'Tranportation Cost',
          cost: scenarioInfo?.scenarioResult?.transportationCost,
          optimalCost: scenarioInfo?.scenarioResult?.optimalTransportationCost
        },
        {
          id: scenarioInfo?.id + '2',
          costType: 'Stressed Inventory Value',
          cost: scenarioInfo?.scenarioResult?.wastageCost,
          optimalCost: scenarioInfo?.scenarioResult?.optimalWastageCost
        },
        {
          id: scenarioInfo?.id + '3',
          costType: 'Holding Cost',
          cost: scenarioInfo?.scenarioResult?.holdingCost,
          optimalCost: scenarioInfo?.scenarioResult?.optimalHoldingCost
        }
      ]),
    [costInfoData]
  );
  const costData = data && data[0];

  return (
    <div className={s.mainCostContainer}>
      {error && message.error(error.message)}
      {<AlloySpin spinning={loading} />}
      {costData?.map((cost) => {
        return (
          <div className={s.subMainCostContainer} key={cost.id}>
            <p data-testid="inv-allInfoPage-costInfoCostName" className={s.costType}>
              {cost.costType}
            </p>

            <div className={s.costInfoBox}>
              <div className={s.costInfoBaseline}>
                <div>$ {cost.cost?.toLocaleString('en-US')}</div>
                <p>Baseline</p>
              </div>
              <div className={s.optimalCost}>
                <div>$ {cost.optimalCost?.toLocaleString('en-US')}</div>
                <p>
                  Optimal
                  <span
                    className={
                      cost.cost && cost.optimalCost && cost.cost > cost.optimalCost
                        ? s.optimalPositiveValue
                        : s.optimalNegativeValue
                    }
                  >
                    <img
                      src={
                        cost.cost && cost.optimalCost && cost.cost > cost.optimalCost
                          ? downArrowIcon
                          : upArrowIcon
                      }
                      alt="arrowIcon"
                      style={{ height: '13px', width: '14px' }}
                    />
                    {cost.cost && cost.optimalCost
                      ? `${currency(cost.cost)
                          .subtract(cost.optimalCost)
                          .divide(cost.cost)
                          .multiply(100)
                          .toString()}% ($ ${currency(
                          currency(cost.cost).subtract(cost.optimalCost)
                        )
                          .dollars()
                          .toLocaleString('en-US')})`
                      : ''}
                  </span>
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CostInfo;
