import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type MoveToTruckBuildMutationVariables = Types.Exact<{
  input: Types.MoveToTruckBuildInput;
}>;


export type MoveToTruckBuildMutation = { __typename?: 'RootMutationType', moveToTruckBuild?: { __typename?: 'MoveToTruckBuildPayload', message: string, ordersUpdated: number } | null };


export const MoveToTruckBuildDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"moveToTruckBuild"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"MoveToTruckBuildInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"moveToTruckBuild"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"ordersUpdated"}}]}}]}}]} as unknown as DocumentNode<MoveToTruckBuildMutation, MoveToTruckBuildMutationVariables>;