import * as Types from '../../../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GeographicDistributionsQueryVariables = Types.Exact<{
  purchaseOrderNumbers: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type GeographicDistributionsQuery = { __typename?: 'RootQueryType', geographicDistributions: Array<{ __typename?: 'GeographicDistribution', actual: number, distributionCenterId: number, distributionCenterName: string, targetHigh: number, targetLow: number }> };


export const GeographicDistributionsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"geographicDistributions"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"purchaseOrderNumbers"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"geographicDistributions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"purchaseOrderNumbers"},"value":{"kind":"Variable","name":{"kind":"Name","value":"purchaseOrderNumbers"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"actual"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenterId"}},{"kind":"Field","name":{"kind":"Name","value":"distributionCenterName"}},{"kind":"Field","name":{"kind":"Name","value":"targetHigh"}},{"kind":"Field","name":{"kind":"Name","value":"targetLow"}}]}}]}}]} as unknown as DocumentNode<GeographicDistributionsQuery, GeographicDistributionsQueryVariables>;