import { useLazyQuery } from '@apollo/client';
import { ModalAction } from 'common/constants';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import {
  AssortmentConfig,
  ExtractedFormField,
  FormField
} from 'pages/AssortmentPage/AssortmentPage';
import {
  FindCatalogProductsDocument,
  FindCatalogProductsQuery
} from 'pages/AssortmentPage/gql/__generated__/findCatalogProducts.query';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { FoundItemLink } from '../../EditTradingPartnerAssortment/components/FoundItemLink/FoundItemLink';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import React from 'react';
import { getShowCountData, getValidators, spaceRemover } from 'pages/AssortmentPage/fieldsHelper';
import { composeValidators } from 'common/helpers/validationHelper';
import { FieldValidator } from 'final-form';

export type CatalogProduct = InferNodeType<FindCatalogProductsQuery, 'catalogProducts'>;

interface CatalogProductIdentifierFieldProps {
  formFieldName: string;
  propertyName: 'gtin12' | 'gtin14' | 'sapMaterialId';
  fillFormWithCatalogProduct: (product: CatalogProduct) => void;
  action: ModalAction;
  formField: ExtractedFormField | FormField;
  assortmentConfig: AssortmentConfig;
  additionalValidation?: FieldValidator<string>;
}

export const CatalogProductIdentifierField = ({
  formFieldName,
  propertyName,
  fillFormWithCatalogProduct,
  action,
  formField,
  assortmentConfig,
  additionalValidation
}: CatalogProductIdentifierFieldProps) => {
  const form = useForm();

  const [showFoundItems, setShowFoundItems] = useState(false);

  const [findCatalogProductItem, { data, loading }] = useLazyQuery(FindCatalogProductsDocument, {
    fetchPolicy: 'no-cache'
  });

  const foundItems = useMemo(() => {
    if (data && data.catalogProducts && getNodesFromEdges(data.catalogProducts).length > 0) {
      return getNodesFromEdges(data.catalogProducts).sort((i1, i2) =>
        safeLocaleCompare(i1[propertyName], i2[propertyName])
      );
    } else {
      return undefined;
    }
  }, [data, propertyName]);

  useEffect(() => {
    if (
      form.getFieldState(formFieldName)?.value?.length > 3 &&
      form.getFieldState(formFieldName)?.modified
    ) {
      findCatalogProductItem({
        variables: {
          filter: {
            [`${propertyName}Like`]: form.getFieldState(formFieldName)?.value,
            catalogItemType: assortmentConfig.catalogItemType
          }
        }
      });
      setShowFoundItems(true);
    }
  }, [
    findCatalogProductItem,
    form,
    action,
    formFieldName,
    assortmentConfig.catalogItemType,
    propertyName
  ]);

  return (
    <>
      <AlloySpin spinning={loading}>
        <AlloyFormField
          data-testid={`aa-edit-modal-${formFieldName}-field`}
          component="input"
          name={formFieldName}
          required={formField.required}
          validate={composeValidators(
            additionalValidation
              ? [additionalValidation, ...getValidators(formField)]
              : getValidators(formField)
          )}
          fieldProps={{
            title: formField.name
          }}
          parse={spaceRemover}
          countRule={getShowCountData(formField)}
        />
        {foundItems && showFoundItems && !!foundItems.length && (
          <FoundItemLink
            foundItems={foundItems}
            setShowFoundItems={setShowFoundItems}
            fillFormWithCatalogProduct={fillFormWithCatalogProduct}
            assortmentConfig={assortmentConfig}
            tooltip="bottom"
            findPropertyName={propertyName}
          />
        )}
      </AlloySpin>
      <OnChange name={formFieldName}>
        {(value: string) => {
          if (value && value.length > 3) {
            if (form.getFieldState(formFieldName)?.modified) {
              findCatalogProductItem({
                variables: {
                  filter: {
                    [`${propertyName}Like`]: value,
                    catalogItemType: assortmentConfig.catalogItemType
                  }
                }
              });
              setShowFoundItems(true);
            }
          }
        }}
      </OnChange>
    </>
  );
};
