import React from 'react';
import s from './ProductDetails.module.scss';
import { EMPTY } from 'common/constants';
import { ForecastProduct } from 'pages/ForecastPlanning/types';
import { interpolateRgb } from 'd3-interpolate';

const formatPercentage = (value: string) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `${(parsedValue * 100).toFixed(0)}%`;
};

const formatNumber = (value?: string | number | null) => {
  if (value === undefined || value === null) return EMPTY;

  const parsedValue = typeof value === 'number' ? value : parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return parsedValue.toLocaleString();
};

interface ProductDetailsInterface {
  product: ForecastProduct;
}

export const ProductDetails = ({ product }: ProductDetailsInterface) => {
  return (
    <div>
      <div className={s.top}>
        <div className={s.info}>
          <div>Material ID: {product.sapMaterialId}</div>
          <div>ASIN: {product.asin.join(', ') || EMPTY}</div>
          <div>Description: {product.productDescription}</div>
        </div>
        <div className={s.week_stats_wrapper}>
          <div className={s.week_stats}>
            <h2 className={s.week_stats_title}>Order Trend / FCST</h2>
            <div className={s.wrapper}>
              <div className={s.colored_stat}>
                <div className={s.title}>4WK</div>
                <div>{formatPercentage(product.orderTrendForecastFourWeek)}</div>
              </div>
              <div className={s.colored_stat}>
                <div className={s.title}>8WK</div>
                <div>{formatPercentage(product.orderTrendForecastEightWeek)}</div>
              </div>
            </div>
          </div>
          <div className={s.week_stats}>
            <h2 className={s.week_stats_title}>Scan Trend / FCST</h2>
            <div className={s.wrapper}>
              <div className={s.colored_stat}>
                <div className={s.title}>4WK</div>
                <div>{formatPercentage(product.scanTrendForecastFourWeek)}</div>
              </div>
              <div className={s.colored_stat}>
                <div className={s.title}>8WK</div>
                <div>{formatPercentage(product.scanTrendForecastEightWeek)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.stats}>
        <div className={s.stat}>
          <div className={s.title}>5wk Fill Rate Trend</div>
          <div className={s.value}>{formatPercentage(product.fillRateTrendFiveWeek)}</div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>BW Inv</div>
          <div className={s.value}>{formatNumber(product.bwInventory)}</div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>EW Inv</div>
          <div className={s.value}>{formatNumber(product.ewInventory)}</div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>Ending WOC</div>
          <div
            data-testid="ending-woc-value"
            className={s.value}
            style={{
              backgroundColor: getEndOfWeekColor(product.endingWoc),
              fontWeight: 'bold',
              color: 'black'
            }}
          >
            {formatNumber(product.endingWoc)}
          </div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>Order Trend</div>
          <div className={s.value}>{formatNumber(product.orderTrend)}</div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>Scan Trend</div>
          <div className={s.value}>{formatNumber(product.scanTrend)}</div>
        </div>
        {
          // Hidden for now
          /* <div className={s.stat}>
          <div className={s.title}>Sales FCST</div>
          <div className={s.value}>{formatPercentage(product.salesForecast)}</div>
        </div> */
        }
      </div>
    </div>
  );
};

const getEndOfWeekColor = (rawValue: string): string => {
  const normalizeValue = (value: number, min: number, max: number) => {
    return (value - min) / (max - min);
  };

  const COLOR_RED = '#E76F6A';
  const COLOR_GREEN = '#D5E9CA';
  const COLOR_ORANGE = '#fed7aa';
  const COLOR_WHITE = 'white';

  const value = parseFloat(rawValue);

  switch (true) {
    case value >= 0 && value < 3: {
      const colorScale = interpolateRgb(COLOR_RED, COLOR_ORANGE);
      const normValue = normalizeValue(value, 0, 3);
      return colorScale(normValue);
    }

    case value >= 3 && value <= 5:
      return COLOR_GREEN;

    case value > 5 && value < 10: {
      const colorScale = interpolateRgb(COLOR_ORANGE, COLOR_RED);
      const normValue = normalizeValue(value, 5, 10);
      return colorScale(normValue);
    }

    case value >= 10:
      return COLOR_RED;

    default:
      return COLOR_WHITE;
  }
};
