import React, { useState, useMemo } from 'react';
import s from './EventTracker.module.scss';
import { NoEvents } from './components/NoEvents/NoEvents';
import { EventsTable } from './components/EventsTable/EventsTable';
import { SearchField } from 'components/Common/SearchField/SearchField';
import { SearchOutlined } from '@ant-design/icons';
import { useUrlParams } from 'common/helpers/urlParams';
import { NewEventModal } from './components/NewEventModal/NewEventModal';
import { EventTrackerProvider } from 'pages/EventTracker/EventTracker.context';
import { useQuery } from '@apollo/client';
import { SpecialEventsDocument } from './gql/__generated__/specialEvents.query';

export const EventTrackerPage = () => {
  return (
    <EventTrackerProvider>
      <EventTracker />
    </EventTrackerProvider>
  );
};

const EventTracker = () => {
  const [urlParams, setUrlParams] = useUrlParams();
  const [showNewEventModal, setShowNewEventModal] = useState(false);

  const { loading, data } = useQuery(SpecialEventsDocument, {
    variables: { ids: [] },
    errorPolicy: 'ignore', // non-nullable field throws an error blocking data
    onError: (error) => {
      console.error(error.message);
    }
  });

  const search = useMemo(() => {
    return ((urlParams.search as string) || '').toLowerCase();
  }, [urlParams]);

  const events = search
    ? (data?.specialEvents || []).filter((event) => {
        if (!urlParams.search) return true;
        if (
          event.name.toLowerCase().includes(search) ||
          event.user?.name.toLowerCase().includes(search)
        )
          return true;
      })
    : data?.specialEvents || [];

  return (
    <div className={s.events_tracker}>
      <div className={s.events_tracker_title}>Event Tracker</div>
      {!loading && !!data && !events.length ? (
        <NoEvents newEvent={setShowNewEventModal} />
      ) : (
        <>
          <div className={s.search}>
            <SearchField
              loading={false}
              placeholder="Search"
              value={search || ''}
              onClear={() => setUrlParams({ search: null })}
              handleSearch={(value) => setUrlParams({ search: value })}
            />
          </div>
          <EventsTable newEvent={setShowNewEventModal} loading={loading} events={events} />
        </>
      )}

      {showNewEventModal && (
        <NewEventModal
          close={() => {
            setShowNewEventModal(false);
          }}
        />
      )}
    </div>
  );
};
