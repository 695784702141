import React, { ReactNode } from 'react';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { ModalAction } from 'common/constants';
import { useEnumValues } from 'common/useEnumValue';
import {
  getFieldNameBySlugForCatalogProduct,
  getShowCountData,
  getValidators
} from 'pages/AssortmentPage/fieldsHelper';
import { composeValidators } from 'common/helpers/validationHelper';
import { Field, useForm } from 'react-final-form'; //TODO: check to see if we can/should replace Field with AlloyFormField
import {
  ExternalIdForCatalogProductField,
  VendorProduct
} from './ExternalIdForCatalogProductField';
import {
  AssortmentConfig,
  DistributionCenter,
  ExtractedFormField,
  FormField
} from 'pages/AssortmentPage/AssortmentPage';
import { DeliveryDestination } from '../../EditTradingPartnerAssortment/EditTradingPartnerAssortment';
import { FormApi } from 'final-form';
import { RetailerProductExternalIdType } from 'graphql/__generated__/types';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { ProductTypeForCatalogProductField } from './ProductTypeForCatalogProductField';
import { DistributionCentedForCatalogProductField } from './DistributionCentedForCatalogProductField';
import { CatalogProduct, CatalogProductIdentifierField } from './CatalogProductIdentifierField';
import { EditFormValues, ShipToWithAlloyDcs } from '../EditAssortmentModal';

interface ConditionProps {
  when: string;
  is: boolean;
  children: ReactNode;
}

export const Condition = ({ when, is, children }: ConditionProps) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const getSpecialAssortmentField = (deliveryDestinations: DeliveryDestination[]) => (
  <>
    <AlloyFormField
      data-testid="aa-edit-modal-vendor-flex-checkbox"
      component="checkbox"
      name="vendorFlex"
      fieldProps={{
        title: 'Vendor Flex'
      }}
      inline="after"
    />
    <Condition when="vendorFlex" is={true}>
      <AlloyFormField
        data-testid="aa-edit-modal-vendor-flex-locations-select"
        component="select"
        name="vendorFlexLocations"
        fieldProps={{
          title: 'Vendor Flex Destinations',
          placeholder: 'Flex locations',
          options: deliveryDestinations
            .filter((rdd) => rdd.specialAssortment)
            ?.map(({ id, name }) => ({
              label: name || '',
              value: id
            })),
          mode: 'multiple'
        }}
      />
    </Condition>
  </>
);

const getSelectField = (
  formField: ExtractedFormField | FormField,
  values: string[],
  tabKey?: string
) => {
  const { slug } = formField;

  if (!slug) return null;

  const formFieldName = getFieldNameBySlugForCatalogProduct(slug);
  return (
    <AlloyFormField
      component="select"
      data-testid={`aa-edit-modal-${`${tabKey}.${formFieldName}`}-select`}
      name={`${tabKey}.${formFieldName}`}
      required={formField.required}
      fieldProps={{
        title: formField.name,
        options: values.map((value) => ({
          value: value,
          label: value
        }))
      }}
    />
  );
};

const getNumberField = (formField: ExtractedFormField | FormField, tabKey?: string) => {
  const { slug } = formField;

  if (!slug) return null;

  const formFieldName = getFieldNameBySlugForCatalogProduct(slug);
  return (
    <AlloyFormField
      component="inputNumber"
      data-testid={`aa-edit-modal-${`${tabKey}.${formFieldName}`}-field`}
      name={`${tabKey}.${formFieldName}`}
      required={formField.required}
      validate={composeValidators(getValidators(formField))}
      fieldProps={{
        title: formField.name,
        controls: false
      }}
      countRule={getShowCountData(formField)}
    />
  );
};

const getRowOfFields = (
  formField: ExtractedFormField | FormField,
  associatedFields: string[],
  assortmentConfig: AssortmentConfig,
  tabKey?: string
) => {
  const firstFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[0]);
  const secondFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[1]);
  const thirdFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[2]);
  if (formField.slug === associatedFields[0]) {
    return !secondFormField && !thirdFormField ? (
      getNumberField(formField, tabKey)
    ) : (
      <AlloyRow gutter={16}>
        <AlloyCol span={secondFormField && thirdFormField ? 8 : 12}>
          {getNumberField(formField, tabKey)}
        </AlloyCol>
        {secondFormField && (
          <AlloyCol span={secondFormField && thirdFormField ? 8 : 12}>
            {getNumberField(secondFormField, tabKey)}
          </AlloyCol>
        )}
        {thirdFormField && (
          <AlloyCol span={secondFormField && thirdFormField ? 8 : 12}>
            {getNumberField(thirdFormField, tabKey)}
          </AlloyCol>
        )}
      </AlloyRow>
    );
  } else if (formField.slug === associatedFields[1]) {
    return !firstFormField ? (
      thirdFormField ? (
        <AlloyRow gutter={16}>
          <AlloyCol span={12}>{getNumberField(formField, tabKey)}</AlloyCol>
          <AlloyCol span={12}>{getNumberField(thirdFormField, tabKey)}</AlloyCol>
        </AlloyRow>
      ) : (
        getNumberField(formField, tabKey)
      )
    ) : null;
  } else {
    return !firstFormField && !secondFormField ? getNumberField(formField, tabKey) : null;
  }
};

interface FormFieldComponentProps {
  formField: ExtractedFormField | FormField;
  assortmentConfig: AssortmentConfig;
  distributionCenters: DistributionCenter[];
  deliveryDestinations: DeliveryDestination[];
  vendorId: string;
  tabKey: string;
  changeToUpdate: (product: VendorProduct) => void;
  action: ModalAction;
  fillFormWithCatalogProduct: (
    product: CatalogProduct,
    form: FormApi,
    tabKey: string,
    fillExternalId: boolean
  ) => void;
  retailerProductExternalIdType?: RetailerProductExternalIdType;
  shipTosWithAlloyDcs: ShipToWithAlloyDcs[];
}

export const FormFieldComponentForCatalogProduct = ({
  formField,
  assortmentConfig,
  distributionCenters,
  deliveryDestinations,
  vendorId,
  action,
  changeToUpdate,
  tabKey,
  fillFormWithCatalogProduct,
  retailerProductExternalIdType,
  shipTosWithAlloyDcs
}: FormFieldComponentProps) => {
  const form = useForm();
  const { enumValues } = useEnumValues([
    'TradingPartnerActiveAssortmentMoqUnitOfMeasure',
    'TradingPartnerActiveAssortmentRequiredOrderUnitOfMeasure'
  ]);

  if (!formField.slug) return null;

  switch (formField.slug) {
    case 'DISTRIBUTION_CENTER_IDS':
      return (
        <DistributionCentedForCatalogProductField
          formField={formField}
          distributionCenters={distributionCenters}
          shipTosWithAlloyDcs={shipTosWithAlloyDcs}
        />
      );
    case 'SPECIAL_ASSORTMENT':
      return getSpecialAssortmentField(deliveryDestinations);
    case 'CASES_PER_PALLET':
    case 'GROSS_WEIGHT':
      return getRowOfFields(
        formField,
        ['CASES_PER_PALLET', 'GROSS_WEIGHT'],
        assortmentConfig,
        tabKey
      );
    case 'WIDTH':
    case 'HEIGHT':
    case 'DEPTH':
      return getRowOfFields(formField, ['WIDTH', 'HEIGHT', 'DEPTH'], assortmentConfig, tabKey);
    case 'PRICE':
    case 'MOQ_MINIMUM':
    case 'CASES_PER_LAYER':
    case 'LAYERS_PER_PALLET':
    case 'TO_CASE_QUANTITY':
    case 'INVENTORY_RESERVE':
      return getNumberField(formField, tabKey);
    case 'MOQ_UNIT_OF_MEASURE':
      return getSelectField(
        formField,
        enumValues.TradingPartnerActiveAssortmentMoqUnitOfMeasure || [],
        tabKey
      );
    case 'REQUIRED_ORDER_UNIT_OF_MEASURE':
      return getSelectField(
        formField,
        enumValues.TradingPartnerActiveAssortmentRequiredOrderUnitOfMeasure || [],
        tabKey
      );
    case 'NAME': {
      const { slug } = formField;

      if (!slug) return null;

      const formFieldName = getFieldNameBySlugForCatalogProduct(slug);
      return (
        <AlloyFormField
          data-testid={`aa-edit-modal-${`${tabKey}.${formFieldName}`}-field`}
          component="textarea"
          name={`${tabKey}.${formFieldName}`}
          validate={composeValidators(getValidators(formField))}
          required={formField.required}
          fieldProps={{
            title: formField.name
          }}
          countRule={getShowCountData(formField)}
        />
      );
    }
    case 'SHIPS_IN_OWN_CONTAINER': {
      const { slug } = formField;

      if (!slug) return null;

      const formFieldName = getFieldNameBySlugForCatalogProduct(slug);
      return (
        <AlloyFormField
          data-testid={`aa-edit-modal-${`${tabKey}.${formFieldName}`}-checkbox`}
          component="checkbox"
          name={`${tabKey}.${formFieldName}`}
          fieldProps={{
            title: 'Ships In Own Container'
          }}
          inline="after"
        />
      );
    }
    case 'UPC':
      return (
        <CatalogProductIdentifierField
          action={action}
          formField={formField}
          formFieldName={`${tabKey}.productUpc`}
          propertyName="gtin12"
          fillFormWithCatalogProduct={(product) =>
            fillFormWithCatalogProduct(product, form, tabKey, false)
          }
          assortmentConfig={assortmentConfig}
          additionalValidation={(value, allValues, meta) => {
            const formValues: EditFormValues = allValues as EditFormValues;
            const checkDuplicates =
              meta?.active &&
              formValues.substitutions.filter(({ productUpc }) => value === productUpc)?.length > 1;

            return checkDuplicates ? 'Found duplicated UPC' : undefined;
          }}
        />
      );
    case 'PRODUCT_TYPE':
      return <ProductTypeForCatalogProductField formField={formField} tabKey={tabKey} />;
    case 'EXTERNAL_ID':
      return (
        <ExternalIdForCatalogProductField
          vendorId={vendorId}
          changeToUpdate={changeToUpdate}
          action={action}
          formField={formField}
          retailerProductExternalIdType={retailerProductExternalIdType}
          assortmentConfig={assortmentConfig}
        />
      );
    case 'GTIN14':
      return (
        <CatalogProductIdentifierField
          action={action}
          formField={formField}
          formFieldName={`${tabKey}.productGtin14`}
          propertyName="gtin14"
          assortmentConfig={assortmentConfig}
          fillFormWithCatalogProduct={(product) =>
            fillFormWithCatalogProduct(product, form, tabKey, false)
          }
        />
      );
    case 'SAP_MATERIAL_ID':
      return (
        <CatalogProductIdentifierField
          action={action}
          formField={formField}
          formFieldName={`${tabKey}.productSapMaterialId`}
          propertyName="sapMaterialId"
          fillFormWithCatalogProduct={(product) =>
            fillFormWithCatalogProduct(product, form, tabKey, false)
          }
          assortmentConfig={assortmentConfig}
        />
      );
    default: {
      const { slug } = formField;

      if (!slug) return null;

      const formFieldName = getFieldNameBySlugForCatalogProduct(slug);
      return (
        <AlloyFormField
          component="input"
          data-testid={`aa-edit-modal-${`${tabKey}.${formFieldName}`}-field`}
          name={`${tabKey}.${formFieldName}`}
          validate={composeValidators(getValidators(formField))}
          required={formField.required}
          fieldProps={{
            title: formField.name
          }}
          countRule={getShowCountData(formField)}
        />
      );
    }
  }
};
