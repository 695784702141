import React from 'react';
import s from './ExecutiveIntelligence.module.scss';
import { ArrayParam, JsonParam, useQueryParam, withDefault } from 'use-query-params';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { range } from 'lodash-es';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { Chargebacks } from './components/Chargebacks/Chargebacks';
import {
  getCurrentPeriod,
  transformCalendarValueToPeriodWeekYear
} from 'components/PeriodCalendar/helpers';
import { useDeepCompareMemo } from 'use-deep-compare';
import { CaretRightOutlined, SettingOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { useQuery } from '@apollo/client';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { Summary } from './components/Summary/Summary';
import { isEqual } from 'lodash-es';
import { ExecutiveIntelligenceOtifAvailableFiltersDocument } from './components/Summary/gql/__generated__/executiveIntelligenceOtifAvailableFilters.query';
import { ExecutiveReportingFilters } from 'graphql/__generated__/types';
import { Service } from './components/Service/Service';
import { Link } from 'react-router-dom';

const CURRENT_YEAR = new Date().getFullYear();
const STARTING_YEAR = 2024;

const getDefaultPeriod = () => {
  const { week } = getCurrentPeriod(true);
  const end = week;
  return { start: 1, end };
};
const defaultPeriod = getDefaultPeriod();

const DEFAULT_PERIOD_STATE: CalendarValue = {
  mode: Mode.PERIOD_WEEK,
  isRange: true,
  years: [CURRENT_YEAR],
  ...defaultPeriod
};

export const ExecutiveIntelligence = () => {
  const [period, setPeriod] = useQueryParam<CalendarValue>(
    'period',
    withDefault(JsonParam, DEFAULT_PERIOD_STATE)
  );

  // We want to show comparison only if selected calendar value = default calendar value
  // So sort + compare it
  const isPeriodEqualsToDefault = useDeepCompareMemo(
    () =>
      isEqual(
        transformCalendarValueToPeriodWeekYear(period, true).sort((a, b) => a.week - b.week),
        transformCalendarValueToPeriodWeekYear(DEFAULT_PERIOD_STATE, true).sort(
          (a, b) => a.week - b.week
        )
      ),
    [period]
  );

  const fiscalCalendarWeeks = useDeepCompareMemo(() => {
    return transformCalendarValueToPeriodWeekYear(period, true);
  }, [period]);

  // We rely on otif filters for now
  const filtersQuery = useQuery(ExecutiveIntelligenceOtifAvailableFiltersDocument);

  const [bus, setBus] = useQueryParam('bus', withDefault(ArrayParam, ['all']));
  const [customers, setCustomers] = useQueryParam('customers', withDefault(ArrayParam, ['all']));

  const customersOptions = useDeepCompareMemo(
    () =>
      [...(filtersQuery.data?.otifAvailableFilters?.vendorMarkets || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filtersQuery]
  );
  const businessUnitsOptions = useDeepCompareMemo(
    () =>
      [...(filtersQuery.data?.otifAvailableFilters?.businessUnits || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filtersQuery]
  );

  const businessUnitCodes = useDeepCompareMemo(
    () =>
      getValueWithAll(
        bus as string[],
        businessUnitsOptions.map(
          (x) => x.code || "Well actually code can't be null but there's an error in GQL for now"
        )
      ),
    [bus, businessUnitsOptions]
  );

  const retailerChannelExternalIds = useDeepCompareMemo(
    () =>
      getValueWithAll(
        customers as string[],
        customersOptions.map((x) => x.externalId)
      ),
    [customers, customersOptions]
  );

  const executiveReportingFilters: ExecutiveReportingFilters = useDeepCompareMemo(
    () => ({
      fiscalCalendarWeeks: transformCalendarValueToPeriodWeekYear(period, true),
      businessUnitCodes,
      retailerChannelExternalIds,
      // Hardcoding for now
      countryCode: 'US'
    }),
    [businessUnitCodes, period, retailerChannelExternalIds]
  );

  // TODO: make sticky header configurable?
  return (
    <div className={s.wrapper}>
      <div className={s.sticky}>
        <div className={s.header}>
          <div className={s.page_title}>
            <PageHeader className={s.title}>Executive Intelligence</PageHeader>
          </div>
          <div className={s.page_filters}>
            <AlloySelect
              loading={filtersQuery.loading}
              className={clsx(s.select, s.select_repack_centers, bus.length > 1 && s.moreThanOne)}
              showSearch={false}
              value={customers}
              mode="multiple"
              onChange={(value) => {
                setArrayWithAll(value as string[], customers as string[], setCustomers);
              }}
              popupMatchSelectWidth
              maxTagCount={1}
              options={[
                { label: 'All Customers', value: 'all' },
                ...customersOptions.map((customer) => ({
                  label: customer.name,
                  value: customer.externalId
                }))
              ]}
            />
            <AlloySelect
              loading={filtersQuery.loading}
              className={clsx(s.select, bus.length > 1 && s.moreThanOne)}
              showSearch={false}
              value={bus}
              mode="multiple"
              onChange={(value) => {
                setArrayWithAll(value as string[], bus as string[], setBus);
              }}
              popupMatchSelectWidth
              maxTagCount={1}
              options={[
                { label: 'ALL BUs', value: 'all' },
                ...businessUnitsOptions.map((bu) => ({
                  label: bu.name,
                  value: bu.code
                }))
              ]}
            />
            <PeriodCalendar
              value={period}
              onChange={setPeriod}
              years={
                STARTING_YEAR === CURRENT_YEAR ? undefined : range(STARTING_YEAR, CURRENT_YEAR + 1)
              }
              // If we have more years, we want to allow to select prev periods, else nope
              pastOnly={STARTING_YEAR === CURRENT_YEAR}
            />
            <AlloyButton size="large" type="primary" icon={<SettingOutlined />} />
          </div>
        </div>
        <Summary
          executiveReportingFilters={executiveReportingFilters}
          shouldShowComparison={isPeriodEqualsToDefault}
        />
      </div>
      <div className={s.sections}>
        <div className={s.section_wrapper}>
          <div className={s.section_title_wrapper}>
            <h2 className={s.section_title}>Service</h2>
          </div>
          <div className={s.section}>
            <Service
              fiscalCalendarWeeks={fiscalCalendarWeeks}
              executiveReportingFilters={executiveReportingFilters}
            />
          </div>
        </div>
        <div className={s.section_wrapper}>
          <div className={s.section_title_wrapper}>
            <h2 className={s.section_title}>Chargebacks</h2>
            <Link href="/chargebacks" to="/chargebacks" className={s.link}>
              See more <CaretRightOutlined height={8} />
            </Link>
          </div>
          <div className={s.section}>
            <Chargebacks fiscalCalendarWeeks={fiscalCalendarWeeks} />
          </div>
        </div>
      </div>
    </div>
  );
};
