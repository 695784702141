import React, { useContext } from 'react';
import s from './EditEventDetails.module.scss';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { InfoCircleFilled } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { EventTrackerContext } from 'pages/EventTracker/EventTracker.context';
import { useFormState } from 'react-final-form';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { SpecialEventsDocument } from 'pages/EventTracker/gql/__generated__/specialEvents.query';

export const EditEventDetails = ({ event }: { event?: any }) => {
  const { vendorMarkets, businessUnits } = useContext(EventTrackerContext);

  const { data } = useQuery(SpecialEventsDocument, {
    variables: { ids: [] },
    errorPolicy: 'ignore', // non-nullable field throws an error blocking data
    onError: (error) => {
      console.error(error.message);
    }
  });

  const events = data?.specialEvents || [];

  const form = useFormState();

  return (
    <div className={s.event_details}>
      <div className={s.input_group}>
        <label>Event name</label>
        <AlloyFormField
          name="name"
          component="input"
          fieldProps={{
            placeholder: 'Choose a unique name (eg. Prime day 2024)'
          }}
          required={true}
          validate={(value) =>
            events.find(
              (match) =>
                match.name.toLowerCase() === value.toLowerCase() &&
                event?.name.toLowerCase() !== match.name.toLowerCase()
            )
              ? `${value} already exists`
              : undefined
          }
        />

        <div className={s.sub_option}>
          <AlloyFormField name="isPrivate" component="checkbox" />

          <div className={s.label}>Make event private</div>
          <AlloyTooltip title="Private events are only visible to you. Public events are visible to any individual with access to the same Trading Partners in Schip.">
            <div>
              <InfoCircleFilled className={s.icon} />
            </div>
          </AlloyTooltip>
        </div>
      </div>
      <div className={s.input_group}>
        <label>Date range of event hypercare</label>
        <div className={s.daterange}>
          <div className={s.date}>
            <AlloyFormField
              name="startDate"
              component="datepicker"
              required={true}
              fieldProps={{
                placeholder: 'Start Date',
                format: 'MM/DD/YYYY'
              }}
            />
          </div>
          <div className={s.date}>
            <AlloyFormField
              name="endDate"
              component="datepicker"
              required={true}
              fieldProps={{
                placeholder: 'End Date',
                format: 'MM/DD/YYYY'
              }}
              validate={(endDate, values) =>
                !!values.startDate && endDate.isBefore(values.startDate)
                  ? `Please choose an ending date after ${values.startDate?.format('MM/DD/YYYY')}`
                  : undefined
              }
            />
          </div>
        </div>
      </div>
      <div className={s.input_group}>
        <label>Customers</label>
        <AlloyFormField
          name="customers"
          component="select"
          required={true}
          fieldProps={{
            placeholder: 'Choose a customer',
            mode: 'multiple',
            value: form.values.customers || [],
            options: vendorMarkets.map((vendorMarket) => {
              return {
                value: vendorMarket.id,
                label: vendorMarket.name
              };
            })
          }}
        />
      </div>
      <div className={s.input_group}>
        <label>Business Unit</label>
        <AlloyFormField
          name="businessUnits"
          component="select"
          required={true}
          fieldProps={{
            placeholder: 'Choose a BU',
            mode: 'multiple',
            value: form.values.businessUnits || [],
            options: businessUnits.map((businessUnit) => {
              return {
                value: businessUnit.id,
                label: businessUnit.name
              };
            })
          }}
        />
      </div>
    </div>
  );
};
