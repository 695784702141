import { interpolateRgb } from 'd3-interpolate';

const colors = [
  {
    color: '#173181'
  },
  {
    color: '#325FB6'
  },
  {
    color: '#5D99EB'
  },
  {
    color: '#77E3EC',
    text: 'black'
  },
  {
    color: '#96F9F3',
    text: 'black'
  },
  {
    color: '#F7F48A',
    text: 'black'
  },
  {
    color: '#FED968',
    text: 'black'
  },
  {
    color: '#FFBD57'
  },
  {
    color: '#CD8917'
  },
  {
    color: '#9B601D'
  },
  {
    color: '#724900'
  }
];

/**
 * Very naive implementation of getting a "next" color.
 * If there's no enough colors, get last color again
 */
export const getColor = (idx: number) => {
  if (idx >= colors.length) return colors[colors.length - 1];
  return colors[idx];
};

/**
 * A slightly better function which interpolates colors
 * @param idx number in palette
 * @param totalColors total colors
 * @returns color from our pallette or interpolated color
 */
export const getColorWithApproximation = (idx: number, totalColors: number) => {
  // Fix for division by 0, oopsie.
  if (totalColors === 1) return colors[0];

  const numColors = colors.length;
  const segment = (numColors - 1) / (totalColors - 1);
  const lowerIdx = Math.floor(idx * segment);
  const upperIdx = Math.ceil(idx * segment);

  if (lowerIdx === upperIdx) {
    return colors[lowerIdx];
  }

  const factor = idx * segment - lowerIdx;
  const color1 = colors[lowerIdx].color;
  const color2 = colors[upperIdx].color;

  // Interpolate between the two colors using d3-interpolate
  const interpolatedColor = interpolateRgb(color1, color2)(factor);

  // Determine text color based on segments
  const lowerTextColor = colors[lowerIdx].text;
  const upperTextColor = colors[upperIdx].text;
  const text = lowerTextColor === 'black' || upperTextColor === 'black' ? 'black' : undefined;

  return {
    color: interpolatedColor,
    text
  };
};
