import { useEnumValue } from 'common/useEnumValue';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { capitalize } from 'lodash-es';
import { ExtractedFormField, FormField } from 'pages/AssortmentPage/AssortmentPage';
import React from 'react';

export const ProductTypeForCatalogProductField = ({
  formField,
  tabKey
}: {
  formField: ExtractedFormField | FormField;
  tabKey?: string;
}) => {
  const { enumValues: productTypeEnums } = useEnumValue('ProductType');

  return (
    <AlloyFormField
      component="select"
      data-testid={`aa-edit-modal-${tabKey ? `${tabKey}.` : ''}product-type-field`}
      name={tabKey ? `${tabKey}.productProductType` : 'productProductType'}
      required={formField.required}
      fieldProps={{
        title: formField.name ?? 'Product Type',
        options: productTypeEnums.map((pt) => ({
          label: capitalize(pt),
          value: pt
        }))
      }}
    />
  );
};
