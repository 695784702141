/* 
This component contains source code for adding Filters in the Sceanrio Details Page.
It contains functions to create Multiselect element. 
*/
import { DeleteOutlined, DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useQueryParam, withDefault, JsonParam, UrlUpdateType } from 'use-query-params';
import s from './AllSKUsFilters.module.scss';
import LoaderSpinner from 'components/LoaderSpinner';
import { isEqual, unionBy } from 'lodash-es';
import { notEmpty } from 'common/helpers/notEmpty';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyCheckbox } from 'components/ui/AlloyCheckbox/AlloyCheckbox';
import { AlloyRadio } from 'components/ui/AlloyRadio/AlloyRadio';
import { AlloySpace } from 'components/ui/AlloySpace/AlloySpace';
import { AlloyPopover } from 'components/ui/AlloyPopover/AlloyPopover';

const MultipleSelect = ({
  values,
  setValues,
  options
}: {
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  options: FilterOption[];
}) => {
  const [search, setSearch] = useState('');

  const valuesAsOptions = values.map((value) => ({ name: value, value: value }));
  const optionsNoDuplicates = unionBy(options, valuesAsOptions, 'value').sort((a, b) =>
    safeLocaleCompare(a.name, b.name)
  );

  const filteredOptions = optionsNoDuplicates.filter((x) =>
    (x?.name || '').toString().toLowerCase().includes(search.toLowerCase())
  );

  const removeSelected = (x: string) =>
    setValues((values) => values.filter((v) => v.toLowerCase() !== x.toLowerCase()));

  return (
    <div>
      <div className={s.searchInput}>
        <AlloyInput
          data-testid="inv-allInfoPage-addFilterBtnSearchBox"
          size="large"
          placeholder="Search"
          prefix={<SearchOutlined />}
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={s.multipleSelectedValues}>
        {values.map((x) => (
          <AlloyButton
            data-testid="inv-allInfoPage-addFilterBtn"
            key={x}
            size="small"
            onClick={() => removeSelected(x)}
            style={{
              background: '#2A2A2A',
              color: 'white',
              borderRadius: '4px'
            }}
          >
            {options.find((option) => option.value === x)?.name || x || '[N/A]'}
          </AlloyButton>
        ))}
      </div>
      <div className={s.multipleSelect}>
        <AlloyCheckbox.Group onChange={(values) => setValues(values as string[])} value={values}>
          <div className={s.options}>
            {filteredOptions.map((x) => (
              <AlloyCheckbox
                value={x.value}
                key={x.value}
                data-testid="inv-allInfoPage-filterCheckBox"
              >
                {x.name || '[N/A]'}
              </AlloyCheckbox>
            ))}
          </div>
        </AlloyCheckbox.Group>
      </div>
    </div>
  );
};

const FilterDropdownContent = ({
  options,
  type,
  onCancel,
  setFilters,
  field,
  existing,
  filters
}: FilterDropdownProps & {
  onCancel: () => void;
  existing?: boolean;
  filters?: Record<string, string | string[]>;
}) => {
  const initial =
    filters && existing ? filters[field] : type === 'single' ? options?.[0].value || '' : [];
  const [state, setState] = useState(initial);
  const [error, setError] = useState(false);

  const checkIfArrayIsEmpty = (value: string | string[]): boolean => {
    if (!Array.isArray(value)) {
      return false;
    } else {
      return value.length === 0;
    }
  };

  const applyFilter = () => {
    if (checkIfArrayIsEmpty(state)) {
      setError(true);
      return;
    }
    setError(false);

    setFilters((prev) => ({
      ...prev,
      [field]: state
    }));
    onCancel();
  };

  const deleteFilter = () => {
    setFilters((prev) => {
      const { [field]: toDelete, ...rest } = prev;
      return { ...rest };
    });
    onCancel();
  };

  return (
    <div className={s.filterDropdown}>
      {type === 'single' ? (
        <>
          <AlloyRadio.Group onChange={(e) => setState(e.target.value)} value={state}>
            <AlloySpace direction="vertical">
              {(options || []).map(({ name, value }) => (
                <AlloyRadio value={value} key={name} data-testid="inv-allInfoPage-radioBtn">
                  {name}
                </AlloyRadio>
              ))}
            </AlloySpace>
          </AlloyRadio.Group>
        </>
      ) : (
        <MultipleSelect
          values={state as string[]}
          setValues={setState as React.Dispatch<React.SetStateAction<string[]>>}
          options={options}
        />
      )}
      <div className={s.buttons}>
        {existing ? (
          <AlloyButton
            data-testid="inv-allInfoPage-deleterBtn"
            icon={<DeleteOutlined />}
            size="small"
            type="text"
            onClick={() => deleteFilter()}
          />
        ) : (
          <></>
        )}
        <AlloyButton
          size="small"
          onClick={() => onCancel()}
          block={type === 'multiple'}
          data-testid="inv-allInfoPage-cancelBtn"
        >
          Cancel
        </AlloyButton>
        <AlloyButton
          type="primary"
          size="small"
          onClick={() => applyFilter()}
          block={type === 'multiple'}
          data-testid="inv-allInfoPage-applyFilterSubBtn"
        >
          Apply Filter
        </AlloyButton>
      </div>
      {error && checkIfArrayIsEmpty(state) ? (
        <div className={s.error}>Please select at least one value</div>
      ) : null}
    </div>
  );
};

const ExistingFilter = ({
  options,
  type,
  name,
  short,
  setFilters,
  filters,
  field,
  loading
}: FilterDropdownProps & { filters: Record<string, string | string[]>; loading: boolean }) => {
  const [open, setOpen] = useState(false);

  const value = filters?.[field];
  const displayValue = Array.isArray(value)
    ? value
        .map((x) => (x ? options.find((option) => option.value === x)?.name || x : '[N/A]'))
        .slice(0, 8)
        .join(', ')
    : options.find((option) => option.value === value)?.name || value;
  return (
    <AlloyPopover
      open={open}
      onOpenChange={(visible) => setOpen(visible)}
      placement="bottomLeft"
      overlayClassName="popoverWrapper"
      destroyTooltipOnHide
      title={type === 'single' ? name : null}
      content={
        <FilterDropdownContent
          name={name}
          field={field}
          setFilters={setFilters}
          options={options}
          type={type}
          onCancel={() => {
            setOpen(false);
          }}
          existing
          filters={filters}
        />
      }
      trigger="click"
    >
      <AlloyButton
        style={{
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          gap: '2px',
          fontSize: '11px',
          flexWrap: 'wrap',
          borderRadius: '4px'
        }}
        data-testid="inv-allInfoPage-loadingBtn"
        disabled={loading}
      >
        {loading ? (
          <LoaderSpinner />
        ) : (
          <span
            className="displayValue"
            style={{
              overflow: 'hidden',
              width: '80px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {displayValue}
          </span>
        )}

        <DownOutlined style={{ fontSize: '8px', margin: '0px' }} />
      </AlloyButton>
    </AlloyPopover>
  );
};

type FilterType = 'single' | 'multiple';

export type FilterOption = {
  name: string | null | undefined;
  value: string | null | undefined;
};

type FilterBase<T extends string = string> = {
  name: string;
  type: FilterType;
  short?: string;
  field: T;
};

export type Filter<T extends string = string> = FilterBase<T> & {
  options: FilterOption[];
};

export type FilterIncomplete<T extends string = string> = FilterBase<T> & {
  options?: FilterOption[];
};

export const FilterMenuOption = ({
  options,
  type,
  name,
  setOuterPopover,
  disabled,
  setFilters,
  field
}: FilterDropdownProps & {
  disabled: boolean;
  setOuterPopover: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <AlloyPopover
      open={disabled ? false : open}
      onOpenChange={setOpen}
      placement="right"
      overlayClassName="filterPopowerWrapper"
      content={
        <FilterDropdownContent
          name={name}
          field={field}
          setFilters={setFilters}
          options={options}
          type={type}
          onCancel={() => {
            setOpen(false);
            setOuterPopover(false);
          }}
        />
      }
      trigger="click"
    >
      <AlloyButton type="text" block disabled={disabled} data-testid="inv-allInfoPage-filter">
        {name}
      </AlloyButton>
    </AlloyPopover>
  );
};

type FilterDropdownProps = {
  name: string;
  field: string;
  short?: string;
  options: FilterOption[];
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string | string[]>>>;
  type: FilterType;
};
export type filterValues = {
  businessUnit: string[];
  brand: string[];
  category?: string[];
  tradingPartner: string[];
};

export const AllSKUsFilters = ({
  filters,
  resetSelectedRows = () => {},
  onFilter = () => {},
  queryParamName = 'filters',
  loading = false
}: {
  filters: Filter[];
  resetSelectedRows?: () => void;
  onFilter: (param: filterValues) => void;
  queryParamName?: string;
  loading?: boolean;
}) => {
  const [queryFilters, setQueryFilters] = useQueryParam(queryParamName, withDefault(JsonParam, {}));

  const setQueryFiltersAndResetRows = (newValue: any, updateType?: UrlUpdateType | undefined) => {
    if (!isEqual(newValue, queryFilters)) {
      resetSelectedRows();
    }
    setQueryFilters(newValue, updateType);
    setFilterCheck(!filtercheck);
  };

  const [addOpen, setAddOpen] = useState(false);
  const [filtercheck, setFilterCheck] = useState(false);
  const displayedFilters = Object.keys(queryFilters)
    .map((x) => filters.find(({ field }) => field === x))
    .filter(notEmpty);

  useEffect(() => {
    onFilter(queryFilters);
  }, [filtercheck]);

  return (
    <div>
      <div className={s.filters}>
        <AlloyPopover
          open={addOpen}
          onOpenChange={(visible) => setAddOpen(visible)}
          placement="bottomLeft"
          overlayClassName="popoverWrapper"
          destroyTooltipOnHide
          content={
            <div className={s.menu}>
              {filters && filters.length > 0 ? (
                filters.map(({ name, options, type, field }) => (
                  <FilterMenuOption
                    setFilters={setQueryFiltersAndResetRows}
                    disabled={!!queryFilters?.[field]}
                    key={name}
                    name={name}
                    field={field}
                    options={options}
                    type={type}
                    setOuterPopover={setAddOpen}
                  />
                ))
              ) : (
                <div className={s.empty}>No filters provided</div>
              )}
            </div>
          }
          trigger="click"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <AlloyButton
              style={{
                color: '#ffffff',
                background: ' #0597F2 0% 0% no-repeat padding-box',
                font: 'normal normal 600 10.5px/14px Roboto',
                height: '32px'
              }}
              icon={<PlusOutlined />}
              size="large"
              onClick={() => setAddOpen(true)}
              data-testid="inv-allInfoPage-addFilter"
              disabled={loading}
            >
              Add Filter
            </AlloyButton>

            {displayedFilters.length > 0 ? (
              <AlloyButton
                type="link"
                style={{ fontWeight: 'bold', padding: '0px' }}
                onClick={() => setQueryFiltersAndResetRows({})}
                size="large"
                data-testid="inv-allInfoPage-clearFilters"
                disabled={loading}
              >
                Clear All
              </AlloyButton>
            ) : (
              <></>
            )}
          </div>
        </AlloyPopover>
      </div>

      <div className={s.displayFiltersMenu}>
        {displayedFilters.map(({ name, options, type, short, field }) => (
          <ExistingFilter
            key={name}
            short={short}
            name={name}
            field={field}
            setFilters={setQueryFiltersAndResetRows}
            options={options}
            type={type}
            filters={queryFilters}
            loading={loading}
          />
        ))}
      </div>
    </div>
  );
};
