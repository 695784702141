import React from 'react';
import { Modal as AntModal, ModalProps } from 'ant5';

export function AlloyModal({ ...otherProps }: ModalProps) {
  return <AntModal {...otherProps} />;
}

AlloyModal.confirm = AntModal.confirm;

// Re-exporting types
export type { ModalProps };
