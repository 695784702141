/*
This component contains source code for displaying the sceanrio constraints applied while creating scenario
in the scenario creation page.
User can Delete the applied constraints
User can search contraints applied on Trading partner, GTIN number and description
*/
import React, { useState } from 'react';
import s from './SKUScenariosConstraints.module.scss';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { ColumnSplitTooltip } from './ColumnSplitTooltip';
import { DEFAULT_PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE } from '../../../../../src/common/constants';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloySpace } from 'components/ui/AlloySpace/AlloySpace';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloyPagination } from 'components/ui/AlloyPagination/AlloyPagination';

export interface ScenarioConstraintsData {
  key: React.Key;
  skuNumber: string | null | undefined;
  gtin14ProductId: number | null | undefined;
  tpExternalId: string;
  productInfo: string | null | undefined;
  selected: boolean;
  scenarioConstraintDcDetails: (number | null | undefined)[] | null | undefined;
  scenarioConstraintExistingDcDetails?: (number | null | undefined)[] | null | undefined;
  currentDC?: (string | null | undefined)[] | null | undefined;
  scenarioDC?: (string | null | undefined)[] | undefined;
  defaultDC?: (string | null | undefined)[] | null | undefined;
  websiteName: string | null;
}
interface SKUScenariosConstraintsProps {
  sharedGroupSkusList: ScenarioConstraintsData[];
  onDelete: (key: React.Key[]) => void;
}

const SKUScenariosConstraints: React.FC<SKUScenariosConstraintsProps> = ({
  sharedGroupSkusList,
  onDelete
}) => {
  const [selectedRowsKeys, setSelectedRowsKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  // Function to provide confirmation box and delete the constraints added.
  const handleDelete = (key: React.Key[]) => {
    AlloyModal.confirm({
      title: 'Are you sure you want to delete constraints ?',
      content: "This action can't be undone.",
      onOk() {
        if (key) {
          onDelete(key);
          setSelectedRowsKeys([]);
        } else {
          return;
        }
      },
      onCancel() {}
    });
  };
  // Define, set table columns and  map data to display scenarios constraints added by users
  const columns: ColumnsType<ScenarioConstraintsData> = [
    {
      title: 'TRADING PARTNER',
      dataIndex: 'websiteName',
      key: 'websiteName',
      width: 100,
      fixed: 'left'
    },
    {
      title: 'GTIN',
      dataIndex: 'skuNumber',
      width: 80,
      key: 'skuNumber'
    },
    { title: 'DESCRIPTION', width: 140, dataIndex: 'productInfo', key: 'productInfo' },
    {
      title: 'CURRENT DC',
      width: 120,
      dataIndex: 'currentDC',
      key: 'currentDC',
      render: (_, record) => {
        return (
          <span>
            <ColumnSplitTooltip items={record?.currentDC?.map((dc) => dc + ' ')} splitToLines />
          </span>
        );
      }
    },
    {
      title: 'SCENARIO DC',
      dataIndex: 'scenarioDC',
      width: 140,
      key: 'scenarioDC',
      render: (_, record) => {
        return (
          <div className={s.scenarioDC}>
            <span>
              <ColumnSplitTooltip items={record?.scenarioDC?.map((dc) => dc + '')} splitToLines />
            </span>
            <AlloySpace size="small" style={{ alignItems: 'flex-end' }}>
              <AlloyButton type="text" onClick={() => handleDelete([record.key])}>
                <DeleteOutlined />
              </AlloyButton>
            </AlloySpace>
          </div>
        );
      }
    }
  ];

  // function to set the search text to state and set the current page of Pagination to 1.
  const fnSearchScenarios = (searchStr: string) => {
    setSearchText(searchStr);
    setCurrentPage(1);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowsKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowsKeys,
    onChange: onSelectChange
  };

  // Fucntion search the contraints by the search text input by user
  const filteredData = sharedGroupSkusList?.filter(
    (item: ScenarioConstraintsData) =>
      item?.websiteName?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      item?.skuNumber?.includes(searchText) ||
      item?.productInfo?.toString().toLowerCase().includes(searchText.toLowerCase())
  );

  const totalItems = filteredData?.length;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const paginatedData = filteredData.slice(startIndex, endIndex);

  // function to set the page size and current page number for Pagination when user changes Page size in Pagination Dropdown
  const onShowSizeChange = (current: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };
  // function to set current page number for Pagination when user clicks arrow buttons in Pagination.
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className={s.customTableMainContainer}>
      <div className={s.listSceneriosHeaders}>
        <div className={s.listSceneriosSearching}>
          <div className={s.allScenarios} data-testid="inv-scenarioConstraint-heading">
            SKU Positioning Constraints
          </div>
          <AlloyInput
            data-testid="inv-scenarioConstraints-search"
            placeholder="Search"
            className={s.contraintsSearch}
            prefix={<SearchOutlined width="14px" height="14px" />}
            onChange={(e) => fnSearchScenarios(e.target.value)}
          />
        </div>
        <div className={s.scenariosPagination}>
          <div className={s.allScenarioDelete}>
            <AlloyButton
              onClick={() => handleDelete(selectedRowsKeys)}
              className={selectedRowsKeys.length > 1 ? s.multiDelete : s.deleteInactive}
            >
              <DeleteOutlined /> Delete
            </AlloyButton>
          </div>
          <AlloyPagination
            data-testid="inv-scenarioConstraint-pagination"
            className={s.allPagination}
            current={currentPage}
            total={totalItems}
            pageSize={pageSize}
            showLessItems={true}
            itemRender={(page, type, element) => (type === 'page' ? null : element)}
            onChange={handlePageChange}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
          />
        </div>
      </div>
      <AlloyTable
        style={{ border: '2px solid #E8E8E8' }}
        columns={columns}
        rowSelection={{ ...rowSelection, selectedRowKeys: selectedRowsKeys }}
        dataSource={paginatedData}
        scroll={{
          x: 1100,
          y: 752
        }}
        pagination={false}
        sticky={true}
      />
    </div>
  );
};

export default SKUScenariosConstraints;
