import { utils as XLSXutils, writeFileXLSX } from 'non_npm_dependencies/xlsx';

export const exportDataToXlsx = (
  fileName: string,
  tabs: {
    tabName: string;
    itemsToExport: any[];
    headers: {
      name: string;
      width?: number;
    }[];
  }[]
) => {
  const workbook = XLSXutils.book_new();
  tabs.forEach((tab) => {
    const worksheet = XLSXutils.json_to_sheet(tab.itemsToExport, {
      header: tab.headers.map((header) => header.name)
    });
    worksheet['!cols'] = tab.headers.map((header) => ({ wpx: header.width }));
    XLSXutils.book_append_sheet(workbook, worksheet, tab.tabName);
  });
  writeFileXLSX(workbook, fileName, { compression: true });
};
