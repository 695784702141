import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { AdvancedFilter, FilterName, NewFilterName, ShipToFilterName } from 'common/interfaces';
import s from './AdvancedFilterComponent.module.scss';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';

interface ConfirmDeleteFilterModalProps<T extends FilterName | NewFilterName | ShipToFilterName> {
  onConfirm: (confirmed: boolean) => void;
  filter: AdvancedFilter<T>;
}

export const ConfirmDeleteFilterModal = <T extends FilterName | NewFilterName | ShipToFilterName>({
  onConfirm,
  filter
}: ConfirmDeleteFilterModalProps<T>) => {
  return (
    <AlloyModal
      data-testid="po-page-delete-filter-modal"
      classNames={{
        body: s.delete_filter_modal_body,
        header: s.delete_filter_modal_header,
        footer: s.delete_filter_modal_footer,
        content: s.delete_filter_modal_content
      }}
      bodyProps={{
        'data-testid': 'po-page-delete-filter-modal-body'
      }}
      title="Delete Filter"
      open={true}
      maskClosable={false}
      onCancel={() => onConfirm(false)}
      okText="Delete"
      okType="danger"
      okButtonProps={{
        type: 'primary',
        // @ts-ignore
        'data-testid': 'delete-saved-filter-confirm'
      }}
      closeIcon={<CloseOutlined />}
      closable={true}
      onOk={() => onConfirm(true)}
    >
      Are you sure you want to delete filter <b>{filter?.name}</b>? This change is not reversible.
    </AlloyModal>
  );
};
