import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { App } from 'ant5';
import { AlloyInput, InputRef } from 'components/ui/AlloyInput/AlloyInput';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import {
  convertSoItemToModifiedSoItem,
  getAllocatedQty,
  ModifiedSalesOrderItem
} from '../SalesOrderItemsTable';
import { UpdateSalesOrderItemTableAllocatedQtyDocument } from './gql/__generated__/updateSalesOrderItemTableAllocatedQty.mutation';
import { SalesOrderItem } from 'pages/OrderDetailsPage/types';
import { OrderDetailsDocument } from 'pages/OrderDetailsPage/gql/__generated__/orderDetails.query';

interface EditingAllocatedQtyProps {
  salesOrderItemId: string;
  productId: string;
  item: ModifiedSalesOrderItem;
  measurementSystem: string | null | undefined;
}

export const EditingAllocatedQty = ({
  salesOrderItemId,
  productId,
  item,
  measurementSystem
}: EditingAllocatedQtyProps) => {
  const { message } = App.useApp();

  const ref = useRef<InputRef>(null);
  const [value, setValue] = useState(`${getAllocatedQty(item, false)}`);
  const [updateSalesOrderItem, { loading }] = useMutation(
    UpdateSalesOrderItemTableAllocatedQtyDocument,
    {
      refetchQueries: [OrderDetailsDocument]
    }
  );
  const [updating, setUpdating] = useState(false);

  const [editingMode, setEditingMode] = useState(false);

  useEffect(() => {
    if (!loading && ref && ref.current) {
      ref.current.blur();
    }
  }, [loading]);

  useEffect(() => {
    setValue(getAllocatedQty(item, false));
    setUpdating(false);
  }, [item]);

  const saveAllocatedQty = async () => {
    const currentValue = Number(value);
    if (
      !Number.isNaN(currentValue) &&
      currentValue !== Number(getAllocatedQty(item, true)) &&
      currentValue >= 0
    ) {
      setUpdating(true);
      try {
        const result = await updateSalesOrderItem({
          variables: {
            input: {
              acceptedUnits: currentValue,
              id: salesOrderItemId,
              productId
            }
          }
        });

        const newItem = result?.data?.updateSalesOrderItem?.salesOrderItem as SalesOrderItem;
        Object.assign(item, convertSoItemToModifiedSoItem(newItem, measurementSystem));
        message.success('Allocated Qty updated');
      } catch (error) {
        console.error(error.message);
        message.error(error.message);
      }
      setUpdating(false);
      setEditingMode(false);
    }
  };

  return (
    <AlloySpin spinning={updating}>
      {editingMode && (
        <AlloyInput
          ref={ref}
          suffix={item.unitOfMeasure}
          className="editing_allocated_qty"
          value={updating ? '' : value}
          onChange={(event) => setValue(event.currentTarget.value)}
          onBlur={() => {
            setValue(getAllocatedQty(item, false));
            setEditingMode(false);
          }}
          onPressEnter={() => {
            saveAllocatedQty();
          }}
          autoFocus
        />
      )}
      {!editingMode && (
        <span className="view_allocated_qty" onClick={() => setEditingMode(true)}>
          {`${value || '-'} ${item.unitOfMeasure}`}
        </span>
      )}
    </AlloySpin>
  );
};
