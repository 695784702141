import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EditWarehouseOpenOrdersMutationVariables = Types.Exact<{
  input: Types.EditWarehouseOpenOrdersInput;
}>;


export type EditWarehouseOpenOrdersMutation = { __typename?: 'RootMutationType', editWarehouseOpenOrders?: { __typename?: 'EditWarehouseOpenOrdersPayload', message: string } | null };


export const EditWarehouseOpenOrdersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"editWarehouseOpenOrders"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EditWarehouseOpenOrdersInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"editWarehouseOpenOrders"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<EditWarehouseOpenOrdersMutation, EditWarehouseOpenOrdersMutationVariables>;