/*
This component contains source code that will list all the sourcing stragtegy details of the generated Optimzer results
User can view sourcing stragtegy details for a selected scenario, once the optimizer results are generated.
*/
import React from 'react';
import { App } from 'ant5';
import s from './SourcingStrategyTable.module.scss';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import { ApolloError } from '@apollo/client';
import { AlloyTable, AlloyTableProps, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
interface SourcingTableData {
  key: string;
  dc: string | undefined;
  plant: string | undefined;
  currentSourcing: number | undefined;
  optimalSourcing: number | undefined;
  currentPallets: number;
  optimalPallets: number;
  currentLayers: number | undefined;
  optimalLayers: number | undefined;
}

interface SourcingStrategyProps {
  data: SourcingTableData[] | undefined;
  error: ApolloError | undefined;
  loading: boolean;
}

// Define, set table columns and  map data to display sourcing stragtegy details of a scenario
const columns: ColumnsType<SourcingTableData> = [
  {
    title: 'DC',
    dataIndex: 'dc',
    key: 'dc',
    fixed: 'left',
    sorter: (a, b) => safeLocaleCompare(a.dc, b.dc)
  },
  {
    title: 'PLANT',
    dataIndex: 'plant',
    key: 'plant',
    sorter: (a, b) => safeLocaleCompare(a.plant, b.plant)
  },
  {
    title: 'CURRENT SOURCING',
    dataIndex: 'currentSourcing',
    key: 'currentSourcing',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.currentSourcing, b.currentSourcing),
    render: (_, { currentSourcing }) => {
      return currentSourcing?.toLocaleString('en-US');
    }
  },
  {
    title: 'OPTIMAL SOURCING',
    dataIndex: 'optimalSourcing',
    key: 'optimalSourcing',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.optimalSourcing, b.optimalSourcing),
    render: (_, { optimalSourcing }) => {
      return optimalSourcing?.toLocaleString('en-US');
    }
  },
  {
    title: 'CURRENT PALLETS',
    key: 'currentPallets',
    dataIndex: 'currentPallets',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.currentPallets, b.currentPallets),
    render: (_, { currentPallets }) => {
      return currentPallets?.toLocaleString('en-US');
    }
  },
  {
    title: 'OPTIMAL PALLETS',
    dataIndex: 'optimalPallets',
    key: 'optimalPallets',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.optimalPallets, b.optimalPallets),
    render: (_, { optimalPallets }) => {
      return optimalPallets?.toLocaleString('en-US');
    }
  },
  {
    title: 'CURRENT LAYERS',
    dataIndex: 'currentLayers',
    key: 'currentLayers',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.currentLayers, b.currentLayers),
    render: (_, { currentLayers }) => {
      return currentLayers?.toLocaleString('en-US');
    }
  },
  {
    title: 'OPTIMAL LAYERS',
    dataIndex: 'optimalLayers',
    key: 'optimalLayers',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.optimalLayers, b.optimalLayers),
    render: (_, { optimalLayers }) => {
      return optimalLayers?.toLocaleString('en-US');
    }
  }
];

const onChange: AlloyTableProps<SourcingTableData>['onChange'] = (filters, sorter, extra) => {};

const SourcingStrategyTable: React.FC<SourcingStrategyProps> = ({ data, error, loading }) => {
  const { message } = App.useApp();

  return (
    <div className={s.landingPageBrandTable}>
      {error && message.error(error.message)}
      <AlloyTable
        loading={loading}
        columns={columns}
        dataSource={data}
        pagination={false}
        onChange={onChange}
        data-testid="inv-allInfoPage-sourcingStrategyTable"
        sticky
      />
    </div>
  );
};

export default SourcingStrategyTable;
