import React from 'react';
import s from './ProductCard.module.scss';
import clsx from 'clsx';
import { ForecastProduct } from 'pages/ForecastPlanning/types';
import { EMPTY } from 'common/constants';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';

interface ProductCardProps {
  product: ForecastProduct;
  isSelected: boolean;
  setSelected: (asin: string) => void;
}

export const ProductCard = ({ product, isSelected, setSelected }: ProductCardProps) => {
  return (
    <button
      className={clsx(s.wrapper, { [s.selected]: isSelected })}
      onClick={() => setSelected(product.sapMaterialId)}
      data-testid={`forecast-product-card-${product.sapMaterialId}`}
    >
      <div className={s.top}>
        <div className={s.upc}>
          <div>MATERIAL ID</div>
          <div className={clsx(s.bold, s.value)}>{product.sapMaterialId}</div>
        </div>
        <div className={s.asin}>
          <div>ASIN</div>
          <div className={clsx(s.bold, s.value)}>
            {product.asin[0] || EMPTY}{' '}
            <AlloyTooltip
              title={product.asin.slice(1).join(', ')}
              overlayStyle={{ maxWidth: '200px' }}
            >
              {product.asin.length > 1 ? (
                <span className={s.asins}>+{product.asin.length - 1}</span>
              ) : (
                ''
              )}
            </AlloyTooltip>
          </div>
        </div>
      </div>
      <div className={clsx(s.description, s.value)}>{product.productDescription}</div>
    </button>
  );
};
