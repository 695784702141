/*
This component contains source code that will list all the Positioning Constraints the user applied while creating a scenario.
User can view Positioning Constraints the user applied for a scenario.
*/
import { App } from 'ant5';
import React, { useState } from 'react';
import { ColumnSplitTooltip } from '../ScenarioCreate/ColumnSplitTooltip';
import s from '../PositioningConstraintsTable/PositioningConstraintsTable.module.scss';
import { ApolloError } from '@apollo/client';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyPagination } from 'components/ui/AlloyPagination/AlloyPagination';
export interface ConstraintstableData {
  key: string | undefined;
  website: string | null | undefined;
  gtin: string | undefined;
  description: string | null | undefined;
  currentDC: (string | undefined)[] | undefined;
  scenarioDC: (string | undefined)[] | undefined;
}

interface SKUScenariosConstraintsProps {
  sharedGroupSkusList: ConstraintstableData[] | undefined;
  handleHideTable: () => void;
  error: ApolloError | undefined;
  loading: boolean;
}

const PositioningConstraintsTable: React.FC<SKUScenariosConstraintsProps> = ({
  sharedGroupSkusList,
  error,
  loading,
  handleHideTable
}) => {
  const { message } = App.useApp();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Define, set table columns and  map data to display Positioning Constraints of the scenario
  const columns: ColumnsType<ConstraintstableData> = [
    {
      title: 'WEBSITE',
      dataIndex: 'website',
      key: 'websiteName'
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'skuNumber'
    },
    { title: 'DESCRIPTION', dataIndex: 'description', key: 'productInfo' },
    {
      title: 'CURRENT DC',
      dataIndex: 'currentDC',
      key: 'currentDC',
      render: (_, record) => {
        return (
          <span>
            <ColumnSplitTooltip
              items={record?.currentDC?.map((dc, index) =>
                index === 0 ? dc + ' ' : index === 1 ? ', ' + dc + ' ' : dc + ' ' || ''
              )}
              splitToLines
            />
          </span>
        );
      }
    },
    {
      title: 'SCENARIO DC',
      dataIndex: 'scenarioDC',
      key: 'scenarioDC',
      render: (_, record) => {
        return (
          <span>
            <ColumnSplitTooltip
              items={record.scenarioDC?.map((dc, index) =>
                index === 0 ? dc + ' ' : index === 1 ? ', ' + dc + ', ' : dc + ' ' || ''
              )}
              splitToLines
            />
          </span>
        );
      }
    }
  ];

  const totalItems = sharedGroupSkusList?.length;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const paginatedData = sharedGroupSkusList?.slice(startIndex, endIndex);
  // function to set the page size and current page number for Pagination when user changes Page size in Pagination Dropdown
  const onShowSizeChange = (current: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };
  // function to set current page number for Pagination when user clicks arrow buttons in Pagination.
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <AlloyModal
      data-testid="inv-allInfoPage-dcAnalysisTableModal"
      centered
      open={true}
      onOk={() => handleHideTable()}
      onCancel={() => handleHideTable()}
      width={'72.5%'}
      footer={null}
    >
      <div className={s.constraintsTableMainContainer}>
        <div className={s.listScenarioHeader}>
          <div className={s.listScenarioSearching}>
            <div className={s.positioningConstraints} data-testid="inv-scenarioConstraint-heading">
              SKU Positioning Constraints
            </div>
          </div>
          <div className={s.scenarioPagination}>
            <AlloyPagination
              data-testid="inv-scenarioConstraint-pagination"
              className={s.contraintsPagination}
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              itemRender={(page, type, element) => (type === 'page' ? null : element)}
              showLessItems={true}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSizeOptions={['10', '20', '50', '100']}
            />
          </div>
        </div>
        <div className={s.constraintsTable}>
          {error && message.error(error.message)}
          <AlloyTable
            loading={loading}
            style={{ border: '2px solid #E8E8E8' }}
            columns={columns}
            dataSource={paginatedData}
            scroll={{
              y: 400
            }}
            pagination={false}
            sticky
          />
        </div>
      </div>
    </AlloyModal>
  );
};

export default PositioningConstraintsTable;
