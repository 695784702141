import { SearchOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useState } from 'react';
import s from './SearchField.module.scss';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';

export const SearchField = ({
  handleSearch,
  loading,
  width,
  saveToHistory = true,
  placeholder,
  className,
  value,
  onClear
}: {
  handleSearch: (value: string) => void;
  loading: boolean;
  width?: number | string;
  saveToHistory?: boolean;
  placeholder?: string;
  className?: string;
  value: string;
  onClear?: () => void;
}) => {
  const [searchFieldValue, setSearchFieldValue] = useState(saveToHistory ? value || '' : '');

  useEffect(() => {
    setSearchFieldValue(value);
  }, [value]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        handleSearch(searchFieldValue.trim());
      },
      searchFieldValue.length > 0 ? 700 : 0
    );

    return () => clearTimeout(delayDebounceFn);
  }, [searchFieldValue, handleSearch]);

  return (
    <AlloySpin className={s.search_field} spinning={loading}>
      <AlloyInput
        data-testid="search-field"
        className={clsx(s.search_field, className)}
        style={
          width ? (typeof width === 'string' ? { width } : { width: `${width}px` }) : undefined
        }
        prefix={<SearchOutlined className={s.search_icon} />}
        placeholder={placeholder ?? 'Search'}
        value={searchFieldValue}
        allowClear
        onClear={() => {
          if (onClear) onClear();
        }}
        onChange={(event) => setSearchFieldValue(event.currentTarget.value)}
        onPressEnter={() => {
          handleSearch(searchFieldValue.trim());
        }}
      />
    </AlloySpin>
  );
};
