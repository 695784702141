import React from 'react';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import s from './NoEvents.module.scss';
import NewEvent from '@/src/assets/icons/events/new_event_icon.svg';

interface NoEventsProps {
  newEvent: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NoEvents = ({ newEvent }: NoEventsProps) => {
  return (
    <div className={s.no_events}>
      <div className={s.content}>
        <img className={s.icon} src={NewEvent} />
        <div className={s.title}>No events</div>
        <div className={s.description}>Create an event to start monitoring event products</div>
        <AlloyButton type="primary" onClick={() => newEvent(true)}>
          Create an Event
        </AlloyButton>
      </div>
    </div>
  );
};
