import moment, { Moment } from 'moment';
import { DayOfWeek } from 'common/types';
import React, { useCallback, useMemo } from 'react';
import s from './components/AddShipToModal/components/AddShipToStepper/AddShipToStepper.module.scss';
import { StoreUpdates } from './components/BulkAddStoreModal/BulkAddStoreModal';
import { DistributionCenter } from './components/AddShipToModal/AddShipToModal';
import { RddForBulkUpload, TradingPartnerNode } from './components/BulkAddStoreModal/hooks';
import {
  UpsertRetailerDeliveryDestinationsInput,
  StoreType,
  ForecastAlgorithm,
  Rounding,
  ReleaseMethod,
  RetailerDeliveryDestinationInput
} from 'graphql/__generated__/types';
import { DeliveryDestination } from './components/BulkAddStoreModal/components/DownloadCurrentShipTos';
import { FieldArray } from 'react-final-form-arrays';
import { AlloyButton } from '../../components/ui/AlloyButton/AlloyButton';
import { useEnumValues } from 'common/useEnumValue';
import { v4 as uuidv4 } from 'uuid';
import { ValidationMessage } from 'components/Common/fields/ValidationMessage/ValidationMessage';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { isEqual } from 'lodash-es';

type TradingPartnerRetailerDeliveryDestination = NonNullable<
  NonNullable<DeliveryDestination['tradingPartnerRetailerDeliveryDestinations']>[number]
>;
type DeliverySchedule = NonNullable<
  NonNullable<TradingPartnerRetailerDeliveryDestination['deliverySchedule']>[number]
>;

type RetailerDeliveryDestinationForInput = NonNullable<
  UpsertRetailerDeliveryDestinationsInput['stores'][number]
>;

const dayMap: { [index: string]: string } = {
  Mon: 'MONDAY',
  Tue: 'TUESDAY',
  Wed: 'WEDNESDAY',
  Thu: 'THURSDAY',
  Fri: 'FRIDAY',
  Sat: 'SATURDAY',
  Sun: 'SUNDAY'
};

const addIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" fill="white" fillOpacity="0.01" />
    <path d="M8.5 7.5V4H7.5V7.5H4V8.5H7.5V12H8.5V8.5H12V7.5H8.5Z" fill="#161616" />
  </svg>
);

const removeIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" fill="white" fillOpacity="0.01" />
    <path d="M12 7.5H4V8.5H12V7.5Z" fill="#161616" />
  </svg>
);

export enum Titles {
  EXTERNAL_ID = 'External ID',
  NAME_TITLE = 'Name',
  TRADING_PARTNER_TITLE = 'Trading Partner',
  STREET_ADDRESS = 'Street Address',
  ACTIVE = 'Active (y/n)',
  CITY = 'City',
  STATE = 'State',
  ZIP = 'Zipcode',
  METRO_AREA = 'Metro Area',
  OPENING_DATE = 'Opening Date',
  NEW_STORE = 'New Store (y/n)',
  RENNOVATED = 'Rennovated Store (y/n)',
  EXTERNAL_STORE_NUMBER = 'Store Number',
  COF_ID = 'Oracle COF Number',
  CIS_ID = 'CIS Number',
  SAN_CODE = 'SAN Code',
  SAP_CUSTOMER_ID = 'SAP Ship-To Number',
  SOLD_TO_SAP_CUSTOMER_ID = 'SAP Sold To Number',
  DUNS = 'DUNS',
  TIMEZONE = 'Timezone',
  SPECIAL_ASSORTMENT = 'Special Assortment (Vendor Flex) (y/n)',
  FIRST_DELIVERY = 'First Delivery',
  ROUTE_NUMBER = 'Route Number',
  RELEASE_METHOD = 'Release Method',
  FORECAST_ALGORITHM = 'Forecast Algorithm',
  BU_DELIVERY_DESTINATION_CODE = 'Business Unit Delivery Destination Code',
  ROUNDING = 'Rounding',
  STORE_CAPACITY = 'Store Capacity (cft)',
  CAPACITY_CUBIC_FEET_BOH = 'Capacity Cubic Feet Boh',
  FRONT_OF_HOUSE_FILL_PERCENTAGE = 'Front Of House Fill Percentage',
  AUTO_FORECAST = 'Auto Forecast (y/n)',
  AUTO_FULFILL = 'Auto Fulfill (y/n)',
  EXTRA_DAY_OF_COVER = 'Extra Day Of Cover (y/n)',
  OVERRIDE_EXPIRATION_DATE_FROM_SHIPMENT_ITEM = 'Override Expiration Date From Shipment Item (y/n)',
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun',
  DC_CODES = 'DC Code (in order of priority)'
}

export enum Fields {
  EXTERNAL_ID = 'externalId',
  NAME = 'name',
  TRADING_PARTNER = 'tradingPartner',
  STREET_ADDRESS = 'address',
  ACTIVE = 'active',
  CITY = 'addressCity',
  STATE = 'addressState',
  ZIP = 'addressZip',
  METRO_AREA = 'metroArea',
  OPENING_DATE = 'openingDate',
  EXTERNAL_STORE_NUMBER = 'externalStoreNumber',
  COF_ID = 'cofId',
  CIS_ID = 'cisId',
  SAN_CODE = 'sanCode',
  SAP_CUSTOMER_ID = 'sapCustomerId',
  SOLD_TO_SAP_CUSTOMER_ID = 'soldToSapCustomerId',
  DUNS = 'duns',
  TIMEZONE = 'timezone',
  SPECIAL_ASSORTMENT = 'specialAssortment',
  STORE_TYPE = 'storeType',
  DC_CODES = 'distributionCenterCodes'
}

export enum TpRddFields {
  EXTERNAL_ID = 'externalId',
  FIRST_DELIVERY = 'firstDeliveryDate',
  ROUTE_NUMBER = 'routeNumber',
  RELEASE_METHOD = 'releaseMethod',
  FORECAST_ALGORITHM = 'defaultForecastingSource',
  BU_DELIVERY_DESTINATION_CODE = 'businessUnitDeliveryDestinationCode',
  ROUNDING = 'rounding',
  STORE_CAPACITY = 'storeCapacity',
  CAPACITY_CUBIC_FEET_BOH = 'capacityCubicFeetBoh',
  FRONT_OF_HOUSE_FILL_PERCENTAGE = 'frontOfHouseFillPercentage',
  AUTO_FORECAST = 'autoForecast',
  AUTO_FULFILL = 'autoFulfill',
  EXTRA_DAY_OF_COVER = 'extraDayOfCover',
  OVERRIDE_EXPIRATION_DATE_FROM_SHIPMENT_ITEM = 'overrideExpirationDateFromShipmentItem'
}

// export enum RowFields

export const itemHeaders = [
  { label: Titles.EXTERNAL_ID, key: Fields.EXTERNAL_ID },
  { label: Titles.NAME_TITLE, key: Fields.NAME },
  { label: Titles.DC_CODES, key: Fields.DC_CODES },
  { label: Titles.ACTIVE, key: Fields.ACTIVE },
  { label: Titles.STREET_ADDRESS, key: Fields.STREET_ADDRESS },
  { label: Titles.CITY, key: Fields.CITY },
  { label: Titles.STATE, key: Fields.STATE },
  { label: Titles.ZIP, key: Fields.ZIP },
  { label: Titles.METRO_AREA, key: Fields.METRO_AREA },
  { label: Titles.OPENING_DATE, key: Fields.OPENING_DATE },
  { label: Titles.NEW_STORE, key: 'newStore' },
  { label: Titles.RENNOVATED, key: 'rennoStore' },
  { label: Titles.EXTERNAL_STORE_NUMBER, key: Fields.EXTERNAL_STORE_NUMBER },
  { label: Titles.COF_ID, key: Fields.COF_ID },
  { label: Titles.CIS_ID, key: Fields.CIS_ID },
  { label: Titles.SAN_CODE, key: Fields.SAN_CODE },
  { label: Titles.SAP_CUSTOMER_ID, key: Fields.SAP_CUSTOMER_ID },
  { label: Titles.SOLD_TO_SAP_CUSTOMER_ID, key: Fields.SOLD_TO_SAP_CUSTOMER_ID },
  { label: Titles.DUNS, key: Fields.DUNS },
  { label: Titles.TIMEZONE, key: Fields.SAN_CODE },
  { label: Titles.SPECIAL_ASSORTMENT, key: Fields.SPECIAL_ASSORTMENT },
  { label: Titles.TRADING_PARTNER_TITLE, key: Fields.TRADING_PARTNER },
  { label: Titles.FIRST_DELIVERY, key: 'firstDeliveryDate' },
  { label: Titles.ROUTE_NUMBER, key: 'routeNumber' },
  { label: Titles.RELEASE_METHOD, key: 'releaseMethod' },
  { label: Titles.FORECAST_ALGORITHM, key: 'defaultForecastingSource' },
  { label: Titles.BU_DELIVERY_DESTINATION_CODE, key: 'businessUnitDeliveryDestinationCode' },
  { label: Titles.ROUNDING, key: 'rounding' },
  { label: Titles.STORE_CAPACITY, key: TpRddFields.STORE_CAPACITY },
  { label: Titles.CAPACITY_CUBIC_FEET_BOH, key: TpRddFields.CAPACITY_CUBIC_FEET_BOH },
  { label: Titles.FRONT_OF_HOUSE_FILL_PERCENTAGE, key: 'frontOfHouseFillPercentage' },
  { label: Titles.AUTO_FORECAST, key: TpRddFields.AUTO_FORECAST },
  { label: Titles.AUTO_FULFILL, key: TpRddFields.AUTO_FULFILL },
  { label: Titles.EXTRA_DAY_OF_COVER, key: 'extraDayOfCover' },
  {
    label: Titles.OVERRIDE_EXPIRATION_DATE_FROM_SHIPMENT_ITEM,
    key: 'overrideExpirationDateFromShipmentItem'
  },
  { label: Titles.MON, key: 'mon' },
  { label: Titles.TUE, key: 'tue' },
  { label: Titles.WED, key: 'wed' },
  { label: Titles.THU, key: 'thu' },
  { label: Titles.FRI, key: 'fri' },
  { label: Titles.SAT, key: 'sat' },
  { label: Titles.SUN, key: 'sun' }
];

export type RddFromFile = {
  [key in Titles]: string;
};

type MappedTpRdd = {
  tradingPartnerId?: string;
  [TpRddFields.EXTERNAL_ID]: string | null;
  [TpRddFields.FIRST_DELIVERY]: string | null;
  [TpRddFields.ROUTE_NUMBER]: string | null;
  [TpRddFields.RELEASE_METHOD]: string | null;
  [TpRddFields.FORECAST_ALGORITHM]: string | null;
  [TpRddFields.BU_DELIVERY_DESTINATION_CODE]: string | null;
  [TpRddFields.ROUNDING]: string | null;
  [TpRddFields.STORE_CAPACITY]: string | null;
  [TpRddFields.CAPACITY_CUBIC_FEET_BOH]: string | null;
  [TpRddFields.FRONT_OF_HOUSE_FILL_PERCENTAGE]: string | null;
  [TpRddFields.AUTO_FORECAST]: boolean | null;
  [TpRddFields.AUTO_FULFILL]: boolean | null;
  [TpRddFields.EXTRA_DAY_OF_COVER]: boolean | null;
  [TpRddFields.OVERRIDE_EXPIRATION_DATE_FROM_SHIPMENT_ITEM]: boolean | null;
  tradingPartner?: TradingPartnerNode;
  id?: string;
  deliverySchedule: {
    dayOfWeek: string;
    cutoffTime: string | null;
  }[];
};

export type RddMappedFromFile = {
  [Fields.EXTERNAL_ID]: string | null;
  [Fields.NAME]: string | null;
  [Fields.TRADING_PARTNER]: string | null;
  [Fields.STREET_ADDRESS]: string | null;
  [Fields.ACTIVE]: boolean | null;
  [Fields.CITY]: string | null;
  [Fields.STATE]: string | null;
  [Fields.ZIP]: string | null;
  [Fields.METRO_AREA]: string | null;
  [Fields.OPENING_DATE]: string | null;
  [Fields.EXTERNAL_STORE_NUMBER]: string | null;
  [Fields.COF_ID]: string | null;
  [Fields.CIS_ID]: string | null;
  [Fields.SAN_CODE]: string | null;
  [Fields.SAP_CUSTOMER_ID]: string | null;
  [Fields.SOLD_TO_SAP_CUSTOMER_ID]: string | null;
  [Fields.DUNS]: string | null;
  [Fields.TIMEZONE]: string | null;
  [Fields.SPECIAL_ASSORTMENT]: boolean | null;
  [Fields.STORE_TYPE]: string | null;
  [Fields.DC_CODES]: string | null;
  errors?: string[];
  vendorMarketId?: string;
  tradingPartnerRetailerDeliveryDestinations: MappedTpRdd[] | null;
  allowedDistributionCenters?: AllowedDc[];
  allowedDcCodes?: string[];
  id?: string;
};

export const rowsToData = (rows: RddFromFile[]): RddMappedFromFile[] => {
  const rdds: { [key: string]: RddMappedFromFile } = {};

  for (const row of rows) {
    //skip empty table rows used for readability
    if (!row[Titles.EXTERNAL_ID] || row[Titles.EXTERNAL_ID] === 'Required') continue;

    const externalId = row[Titles.EXTERNAL_ID];
    if (!rdds[externalId]) rdds[externalId] = {} as RddMappedFromFile;
    const rdd = rdds[externalId];
    if (!rdd.tradingPartnerRetailerDeliveryDestinations)
      rdd.tradingPartnerRetailerDeliveryDestinations = [];

    if (!rdd.externalId) rdd.externalId = row[Titles.EXTERNAL_ID];
    if (!rdd.name) rdd.name = row[Titles.NAME_TITLE];
    if (!rdd.address) rdd.address = row[Titles.STREET_ADDRESS];
    if (!rdd.addressCity) rdd.addressCity = row[Titles.CITY];
    if (!rdd.addressState) rdd.addressState = row[Titles.STATE];
    if (!rdd.addressZip) rdd.addressZip = row[Titles.ZIP];
    if (!rdd.metroArea) rdd.metroArea = row[Titles.METRO_AREA];
    if (!rdd.active) rdd.active = row[Titles.ACTIVE]?.toUpperCase() === 'Y';
    if (!rdd.openingDate && row[Titles.OPENING_DATE])
      rdd.openingDate = moment(row[Titles.OPENING_DATE]).format('YYYY-MM-DD');
    if (!rdd.storeType && row[Titles.NEW_STORE].toLowerCase() === 'y') rdd.storeType = 'new';
    if (!rdd.storeType && row[Titles.RENNOVATED].toLowerCase() === 'y')
      rdd.storeType = 'rennovated';
    if (!rdd.cisId) rdd.cisId = row[Titles.CIS_ID];
    if (!rdd.cofId) rdd.cofId = row[Titles.COF_ID];
    if (!rdd.sapCustomerId) rdd.sapCustomerId = row[Titles.SAP_CUSTOMER_ID];
    if (!rdd.soldToSapCustomerId) rdd.soldToSapCustomerId = row[Titles.SOLD_TO_SAP_CUSTOMER_ID];
    if (!rdd.sanCode) rdd.sanCode = row[Titles.SAN_CODE];
    if (!rdd.duns) rdd.duns = row[Titles.DUNS];
    if (!rdd.timezone) rdd.timezone = row[Titles.TIMEZONE];
    if (!rdd.specialAssortment)
      rdd.specialAssortment = row[Titles.SPECIAL_ASSORTMENT].toLowerCase() === 'y';
    if (!rdd.externalStoreNumber) rdd.externalStoreNumber = row[Titles.EXTERNAL_STORE_NUMBER];
    if (!rdd.distributionCenterCodes) rdd.distributionCenterCodes = row[Titles.DC_CODES];

    //replace empty strings with nulls to match in comparison with current backend data
    (Object.keys(rdd) as Fields[]).forEach((key) => {
      if (rdd[key] === '') rdd[key] = null;
    });

    if (row[Titles.TRADING_PARTNER_TITLE]) {
      const tpRdd: MappedTpRdd = {
        externalId: row[Titles.TRADING_PARTNER_TITLE],
        routeNumber: row[Titles.ROUTE_NUMBER],
        storeCapacity: row[Titles.STORE_CAPACITY],
        firstDeliveryDate: row[Titles.FIRST_DELIVERY],
        releaseMethod: row[Titles.RELEASE_METHOD],
        defaultForecastingSource: row[Titles.FORECAST_ALGORITHM],
        businessUnitDeliveryDestinationCode: row[Titles.BU_DELIVERY_DESTINATION_CODE],
        rounding: row[Titles.ROUNDING],
        capacityCubicFeetBoh: row[Titles.CAPACITY_CUBIC_FEET_BOH],
        frontOfHouseFillPercentage: row[Titles.FRONT_OF_HOUSE_FILL_PERCENTAGE],
        autoForecast: row[Titles.AUTO_FORECAST].toLowerCase() === 'y',
        autoFulfill: row[Titles.AUTO_FULFILL].toLowerCase() === 'y',
        extraDayOfCover: row[Titles.EXTRA_DAY_OF_COVER]?.toLowerCase() === 'y',
        overrideExpirationDateFromShipmentItem:
          row[Titles.OVERRIDE_EXPIRATION_DATE_FROM_SHIPMENT_ITEM].toLowerCase() === 'y',
        deliverySchedule: []
      };

      (Object.keys(tpRdd) as TpRddFields[]).forEach((key) => {
        if (tpRdd[key] === '') tpRdd[key] = null;
      });

      [Titles.MON, Titles.TUE, Titles.WED, Titles.THU, Titles.FRI, Titles.SAT, Titles.SUN].forEach(
        (day) => {
          if (row[day]) {
            (
              tpRdd.deliverySchedule as {
                dayOfWeek: string;
                cutoffTime: string | null;
              }[]
            ).push({
              dayOfWeek: dayMap[day],
              cutoffTime: row[day] !== 'Y' ? row[day] : null
            });
          }
        }
      );

      (rdd.tradingPartnerRetailerDeliveryDestinations as MappedTpRdd[]).push(tpRdd);
    }
  }

  return Object.keys(rdds).map((key) => rdds[key]);
};

function getDeliveryDay(
  day: DayOfWeek,
  deliverySchedule?: (DeliverySchedule | null | undefined)[] | null
) {
  if (!deliverySchedule) return '';

  const target = deliverySchedule.find((row: any) => row.dayOfWeek === day);
  if (!target) return '';

  return target.cutoffTime ? target.cutoffTime : 'Y';
}

export const dataToRows = (stores: Partial<DeliveryDestination>[]) => {
  const rows: any = [];
  stores.forEach((rdd) => {
    if (!rdd.tradingPartnerRetailerDeliveryDestinations) return;

    const tpRdd = rdd.tradingPartnerRetailerDeliveryDestinations[0];

    rows.push({
      vendorMarket: rdd.vendorMarket?.externalId,
      externalId: rdd.externalId,
      name: rdd.name,
      active: rdd.active ? 'Y' : 'N',
      address: rdd.address,
      addressCity: rdd.addressCity,
      addressState: rdd.addressState,
      addressZip: rdd.addressZip,
      metroArea: rdd.metroArea,
      newStore: rdd.storeType === 'NEW' ? 'Y' : '',
      rennoStore: rdd.storeType === 'RENNOVATED' ? 'Y' : '',
      externalStoreNumber: rdd.externalStoreNumber,
      openingDate: rdd.openingDate ? moment(rdd.openingDate).format('MM/DD/YYYY') : '',
      cofId: rdd.cofId,
      cisId: rdd.cisId,
      sapCustomerId: rdd.sapCustomerId,
      soldToSapCustomerId: rdd.soldToSapCustomerId,
      sanCode: rdd.sanCode,
      duns: rdd.duns,
      timezone: rdd.timezone,
      specialAssortment: rdd.specialAssortment ? 'Y' : 'N',
      tradingPartner: tpRdd?.tradingPartner?.externalId,
      routeNumber: tpRdd?.routeNumber,
      storeCapacity: tpRdd?.storeCapacity,
      firstDeliveryDate: tpRdd?.firstDeliveryDate,
      releaseMethod: tpRdd?.releaseMethod,
      defaultForecastingSource: tpRdd?.defaultForecastingSource,
      businessUnitDeliveryDestinationCode: tpRdd?.businessUnitDeliveryDestinationCode,
      rounding: tpRdd?.rounding,
      capacityCubicFeetBoh: tpRdd?.capacityCubicFeetBoh,
      frontOfHouseFillPercentage: tpRdd?.frontOfHouseFillPercentage,
      autoForecast: tpRdd?.autoForecast ? 'Y' : 'N',
      autoFulfill: tpRdd?.autoFulfill ? 'Y' : 'N',
      extraDayOfCover: tpRdd?.extraDayOfCover ? 'Y' : 'N',
      overrideExpirationDateFromShipmentItem: tpRdd?.overrideExpirationDateFromShipmentItem
        ? 'Y'
        : 'N',
      mon: getDeliveryDay('MONDAY', tpRdd?.deliverySchedule),
      tue: getDeliveryDay('TUESDAY', tpRdd?.deliverySchedule),
      wed: getDeliveryDay('WEDNESDAY', tpRdd?.deliverySchedule),
      thu: getDeliveryDay('THURSDAY', tpRdd?.deliverySchedule),
      fri: getDeliveryDay('FRIDAY', tpRdd?.deliverySchedule),
      sat: getDeliveryDay('SATURDAY', tpRdd?.deliverySchedule),
      sun: getDeliveryDay('SUNDAY', tpRdd?.deliverySchedule),
      distributionCenterCodes: rdd.allowedDistributionCenters
        ?.sort((dc1, dc2) => (dc1?.priority || 0) - (dc2?.priority || 0))
        .map((dc) => dc?.distributionCenter?.code)
        .join(', ')
    });

    for (const [i, tpRdd] of rdd.tradingPartnerRetailerDeliveryDestinations.entries()) {
      if (i === 0) continue;

      rows.push({
        externalId: rdd.externalId,
        tradingPartner: tpRdd?.tradingPartner?.externalId,
        routeNumber: tpRdd?.routeNumber,
        storeCapacity: tpRdd?.storeCapacity,
        firstDeliveryDate: tpRdd?.firstDeliveryDate,
        releaseMethod: tpRdd?.releaseMethod,
        defaultForecastingSource: tpRdd?.defaultForecastingSource,
        businessUnitDeliveryDestinationCode: tpRdd?.businessUnitDeliveryDestinationCode,
        rounding: tpRdd?.rounding,
        capacityCubicFeetBoh: tpRdd?.capacityCubicFeetBoh,
        frontOfHouseFillPercentage: tpRdd?.frontOfHouseFillPercentage,
        autoForecast: tpRdd?.autoForecast ? 'Y' : 'N',
        autoFulfill: tpRdd?.autoFulfill ? 'Y' : 'N',
        extraDayOfCover: tpRdd?.extraDayOfCover ? 'Y' : 'N',
        overrideExpirationDateFromShipmentItem: tpRdd?.overrideExpirationDateFromShipmentItem
          ? 'Y'
          : 'N',
        mon: getDeliveryDay('MONDAY', tpRdd?.deliverySchedule),
        tue: getDeliveryDay('TUESDAY', tpRdd?.deliverySchedule),
        wed: getDeliveryDay('WEDNESDAY', tpRdd?.deliverySchedule),
        thu: getDeliveryDay('THURSDAY', tpRdd?.deliverySchedule),
        fri: getDeliveryDay('FRIDAY', tpRdd?.deliverySchedule),
        sat: getDeliveryDay('SATURDAY', tpRdd?.deliverySchedule),
        sun: getDeliveryDay('SUNDAY', tpRdd?.deliverySchedule)
      });
    }
    rows.push({});
  });

  return rows;
};

export const sanitize = (
  changes: StoreUpdates,
  stores?: RddForBulkUpload[]
): RetailerDeliveryDestinationForInput[] => {
  const rdds = [
    ...changes.new,
    ...changes.updated.map((upd: RddMappedFromFile) => {
      const available = stores ? stores.find((store) => store.id === upd.id) : undefined;
      if (available) {
        const updTpIds: string[] =
          upd.tradingPartnerRetailerDeliveryDestinations?.map(
            (tpRdd) => tpRdd.tradingPartnerId || ''
          ) || [];
        const fullDataUpd = {
          ...available,
          ...upd,
          tradingPartnerRetailerDeliveryDestinations: [
            ...(available.tradingPartnerRetailerDeliveryDestinations?.filter(
              (availableTpRdd) =>
                availableTpRdd?.id &&
                availableTpRdd?.tradingPartner?.id &&
                !updTpIds.includes(availableTpRdd?.tradingPartner?.id)
            ) || []),
            ...(upd.tradingPartnerRetailerDeliveryDestinations || []).map((updTpRdd) => ({
              ...available.tradingPartnerRetailerDeliveryDestinations?.find(
                (availableTpRdd) =>
                  upd.tradingPartnerRetailerDeliveryDestinations &&
                  availableTpRdd?.id === updTpRdd.id
              ),
              ...updTpRdd
            }))
          ] as MappedTpRdd[],
          allowedDistributionCenters: upd.allowedDistributionCenters || []
        };
        return fullDataUpd;
      } else {
        return upd;
      }
    })
  ];
  return rdds.map((rdd) => {
    return {
      active: rdd.active || false,
      name: rdd.name,
      externalStoreNumber: rdd.externalStoreNumber,
      address: rdd.address || '',
      addressCity: rdd.addressCity || '',
      addressState: rdd.addressState || '',
      addressZip: rdd.addressZip || '',
      allowedDcs: rdd.allowedDistributionCenters,
      cisId: rdd.cisId || null,
      cofId: rdd.cofId || null,
      externalId: rdd.externalId || '',
      duns: rdd.duns,
      metroArea: rdd.metroArea,
      timezone: rdd.timezone,
      specialAssortment: rdd.specialAssortment,
      id: rdd.id,
      openingDate: rdd.openingDate ? moment(rdd.openingDate).format('YYYY-MM-DD') : null,
      sanCode: rdd.sanCode || null,
      sapCustomerId: rdd.sapCustomerId || null,
      soldToSapCustomerId: rdd.soldToSapCustomerId || null,
      storeType: rdd.storeType?.toUpperCase() as StoreType,
      vendorMarketId: rdd.vendorMarketId || '',
      tradingPartnerConfiguration: (rdd.tradingPartnerRetailerDeliveryDestinations || []).map(
        (tpRdd) => {
          return {
            businessUnitDeliveryDestinationCode: tpRdd?.businessUnitDeliveryDestinationCode,
            autoForecast: tpRdd?.autoForecast,
            autoFulfill: tpRdd?.autoFulfill,
            releaseMethod: tpRdd?.releaseMethod as ReleaseMethod,
            defaultForecastingSource: tpRdd?.defaultForecastingSource as ForecastAlgorithm,
            extraDayOfCover: tpRdd?.extraDayOfCover,
            firstDeliveryDate: tpRdd?.firstDeliveryDate
              ? moment(tpRdd.firstDeliveryDate).format('YYYY-MM-DD')
              : null,
            frontOfHouseFillPercentage: tpRdd?.frontOfHouseFillPercentage
              ? Number(tpRdd.frontOfHouseFillPercentage)
              : undefined,
            routeNumber: tpRdd?.routeNumber,
            storeCapacity: tpRdd?.storeCapacity ? Number(tpRdd.storeCapacity) : undefined,
            tradingPartnerId: tpRdd?.tradingPartnerId || tpRdd?.tradingPartner?.id || null,
            rounding: (tpRdd?.rounding as Rounding) || null,
            capacityCubicFeetBoh: !Number.isNaN(Number(tpRdd?.capacityCubicFeetBoh))
              ? Number(tpRdd?.capacityCubicFeetBoh)
              : undefined,
            overrideExpirationDateFromShipmentItem: tpRdd?.overrideExpirationDateFromShipmentItem,
            deliverySchedule: tpRdd?.deliverySchedule?.map((deliveryDay: any) => {
              return {
                cutoffTime: deliveryDay.cutoffTime,
                dayOfWeek: deliveryDay.dayOfWeek,
                expectedAsnHoursBeforeDelivery: deliveryDay.expectedAsnHoursBeforeDelivery,
                expectedDeliveryTime: deliveryDay.expectedDeliveryTime,
                expectedInvoiceHoursAfterDelivery: deliveryDay.expectedInvoiceHoursAfterDelivery,
                expectedPoAcknowledgementHoursBeforeDelivery:
                  deliveryDay.expectedPoAcknowledgementHoursBeforeDelivery,
                expectedPurchaseOrderHoursBeforeDelivery:
                  deliveryDay.expectedPurchaseOrderHoursBeforeDelivery,
                expectedSalesOrderHoursBeforeDelivery:
                  deliveryDay.expectedSalesOrderHoursBeforeDelivery
              };
            })
          };
        }
      )
    };
  });
};

export interface AllowedDc {
  distributionCenterId: string;
  externalId?: string;
  priority: number;
}

interface BaseStoreInput extends RetailerDeliveryDestinationInput {}

export interface EditStoreInput extends BaseStoreInput {}

export interface AddStoreInput extends BaseStoreInput {
  deliverySchedule: any[];
  tradingPartnerId: string;
  autoForecast: boolean;
  autoFulfill: boolean;
  businessUnitDeliveryDestinationCode: string;
  capacityCubicFeetBoh: string;
  defaultForecastingSource: string;
  extraDayOfCover: string;
  firstDeliveryDate: string;
  frontOfHouseFillPercentage: number;
  overrideExpirationDateFromShipmentItem: string;
  releaseMethod: string;
  rounding: string;
  routeNumber: string;
  storeCapacity: number;
  tpAssortments?: {
    active: boolean;
    retailerDeliveryDestinationId?: string;
    tradingPartnerId?: string;
    vendorProductId: string;
  }[];
  alternateSoldTo?: string;
  dcSoldTos: {
    dcCode?: string;
    soldTo?: string;
  }[];
}

export const SpecialForTradingPartnersFields = ({
  distributionCenters,
  name
}: {
  distributionCenters: DistributionCenter[];
  name?: string;
}) => {
  return (
    <div>
      <SapSoldToWithDistributionCenter distributionCenters={distributionCenters} name={name} />
      <BaseTradingPartnersFields name={name} />
      <AdditionalFeatures name={name} />
    </div>
  );
};

export const getDistributionCenterTitle = (
  dc: AllowedDc,
  distributionCenters: DistributionCenter[]
) => {
  const found = distributionCenters.find((d) => d.id === dc.distributionCenterId);
  return found ? `${found.name} (${found.code})` : '';
};

export const getDateValue = (date: string | Moment | undefined) =>
  date ? (typeof date === 'string' ? date : date.format('YYYY-MM-DD')) : undefined;

export const getTimeValue = (date: string | Moment | undefined) =>
  date ? (typeof date === 'string' ? date : date.format('HH:mm:ssZ')) : undefined;

const SapSoldToWithDistributionCenter = React.memo(
  ({ name, distributionCenters }: { name?: string; distributionCenters: DistributionCenter[] }) => {
    const mappedDcs = useMemo(
      () =>
        distributionCenters.map((dc) => ({
          value: dc.id,
          label: `${dc.name} ${dc.code}`
        })),
      [distributionCenters]
    );

    const validateFields = useCallback((v: any) => {
      // skip if we have only one row without any data
      if (v?.length === 1 && !v[0].dcCode && !v[0].soldTo) return null;
      if (v?.some((dcSoldTo: any) => !dcSoldTo.soldTo)) return 'SAP Sold To Number is reqiered';
      if (v?.filter((dcSoldTo: any) => !dcSoldTo.dcCode).length > 1)
        return 'Only one alternate SAP Sold To Number could be provided';
      return null;
    }, []);

    return (
      <div className={s.dc_sold_tos}>
        <FieldArray name={`${name ? `${name}.` : ''}dcSoldTos`} validate={validateFields}>
          {({ fields, meta }) => {
            const alreadySelectedDcIds = fields.value
              .map((field) => field.dcCode)
              .filter((field) => !!field);
            return (
              <>
                {fields.map((name, index) => (
                  <SapSoldToWithDistributionCenterItem
                    key={fields.value[index].id}
                    name={name}
                    index={index}
                    fields={fields}
                    distributionCenters={mappedDcs}
                    alreadySelectedDcIds={alreadySelectedDcIds}
                  />
                ))}
                <ValidationMessage meta={meta} />
              </>
            );
          }}
        </FieldArray>
      </div>
    );
  }
);
SapSoldToWithDistributionCenter.displayName = 'SapSoldToWithDistributionCenter';

const BaseTradingPartnersFields = React.memo(({ name }: { name?: string }) => {
  const { enumValues, loading } = useEnumValues(['ForecastAlgorithm', 'Rounding', 'ReleaseMethod']);

  return (
    <AlloyRow gutter={16}>
      <AlloyCol span={12}>
        <AlloyFormField
          component="datepicker"
          name={`${name ? `${name}.` : ''}firstDeliveryDate`}
          fieldProps={{
            title: 'First Delivery Date'
          }}
        />
        <AlloyFormField
          component="select"
          name={`${name ? `${name}.` : ''}releaseMethod`}
          fieldProps={{
            loading: loading,
            title: 'Release Method',
            options: (enumValues.ReleaseMethod || []).map((rm) => ({
              value: rm,
              label: rm
            }))
          }}
        />
        <AlloyFormField
          component="input"
          name={`${name ? `${name}.` : ''}businessUnitDeliveryDestinationCode`}
          fieldProps={{
            title: 'Business Unit Delivery Destination Code'
          }}
        />
        <AlloyFormField
          component="inputNumber"
          name={`${name ? `${name}.` : ''}storeCapacity`}
          fieldProps={{
            title: 'Store Capacity'
          }}
        />
        <AlloyFormField
          component="inputNumber"
          name={`${name ? `${name}.` : ''}frontOfHouseFillPercentage`}
          fieldProps={{
            title: 'Front Of House Fill Percentage'
          }}
        />
      </AlloyCol>
      <AlloyCol span={12}>
        <AlloyFormField
          component="input"
          name={`${name ? `${name}.` : ''}routeNumber`}
          fieldProps={{
            title: 'Route Number'
          }}
        />
        <AlloyFormField
          component="select"
          name={`${name ? `${name}.` : ''}defaultForecastingSource`}
          fieldProps={{
            loading: loading,
            title: 'Forecast Algorithm',
            options: (enumValues.ForecastAlgorithm || []).map((fa) => ({
              value: fa,
              label: fa
            }))
          }}
        />
        <AlloyFormField
          component="select"
          name={`${name ? `${name}.` : ''}rounding`}
          fieldProps={{
            loading: loading,
            title: 'Rounding',
            options: (enumValues.Rounding || []).map((rounding) => ({
              value: rounding,
              label: rounding
            }))
          }}
        />
        <AlloyFormField
          component="inputNumber"
          name={`${name ? `${name}.` : ''}capacityCubicFeetBoh`}
          fieldProps={{
            title: 'Capacity Cubic Feet Boh'
          }}
        />
      </AlloyCol>
    </AlloyRow>
  );
});
BaseTradingPartnersFields.displayName = 'BaseTradingPartnersFields';

const AdditionalFeatures = React.memo(({ name }: { name?: string }) => {
  return (
    <>
      <AlloyRow className={s.title_row}>Additional features</AlloyRow>
      <AlloyRow>
        <AlloyCol>
          <AlloyFormField
            component="switch"
            name={`${name ? `${name}.` : ''}autoForecast`}
            fieldProps={{
              title: 'Auto Forecast'
            }}
            inline="after"
            hideInfo="WITH_MARGING"
          />
          <AlloyFormField
            component="switch"
            name={`${name ? `${name}.` : ''}autoFulfill`}
            fieldProps={{
              title: 'Auto Fulfill'
            }}
            inline="after"
            hideInfo="WITH_MARGING"
          />
          <AlloyFormField
            component="switch"
            name={`${name ? `${name}.` : ''}extraDayOfCover`}
            fieldProps={{
              title: 'Extra Day Of Cover'
            }}
            inline="after"
            hideInfo="WITH_MARGING"
          />
          <AlloyFormField
            component="switch"
            name={`${name ? `${name}.` : ''}overrideExpirationDateFromShipmentItem`}
            fieldProps={{
              title: 'Override Expiration Date From Shipment Item'
            }}
            inline="after"
            hideInfo="WITH_MARGING"
          />
        </AlloyCol>
      </AlloyRow>
    </>
  );
});
AdditionalFeatures.displayName = 'AdditionalFeatures';

const SapSoldToWithDistributionCenterItem = React.memo(
  ({
    name,
    index,
    fields,
    distributionCenters,
    alreadySelectedDcIds
  }: {
    name: string;
    index: number;
    fields: any;
    distributionCenters: {
      value: string;
      label: string;
    }[];
    alreadySelectedDcIds: string[];
  }) => {
    return (
      <AlloyRow gutter={16} key={`${fields.value[index].id}`} id={`${fields.value[index].id}`}>
        <AlloyCol span={12}>
          <AlloyFormField
            component="input"
            fieldProps={{
              title: index === 0 ? 'SAP Sold To Number' : undefined
            }}
            name={`${name}.soldTo`}
            hideInfo="WITH_MARGING"
          />
        </AlloyCol>
        <AlloyCol span={12}>
          <div className={s.dc_code_buttons_container}>
            <AlloyFormField
              component="select"
              name={`${name}.dcCode`}
              fieldProps={{
                title: index === 0 ? 'DC' : undefined,
                options: distributionCenters.filter(
                  (dc) =>
                    !alreadySelectedDcIds.includes(dc.value) ||
                    fields.value[index].dcCode === dc.value
                ),
                virtual: true
              }}
              hideInfo="WITH_MARGING"
            />
            <AlloyButton
              size="middle"
              icon={removeIcon}
              onClick={() => {
                fields.remove(index);
              }}
              disabled={fields.length === 1}
            />
            <AlloyButton
              size="middle"
              icon={addIcon}
              onClick={() => {
                fields.insert(index + 1, { dcCode: '', soldTo: '', id: uuidv4() });
              }}
            />
          </div>
        </AlloyCol>
      </AlloyRow>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.index === nextProps.index &&
      prevProps.name === nextProps.name &&
      isEqual(prevProps.fields.value[prevProps.index], nextProps.fields.value[nextProps.index]) &&
      isEqual(prevProps.distributionCenters, nextProps.distributionCenters) &&
      isEqual(prevProps.alreadySelectedDcIds, nextProps.alreadySelectedDcIds)
    );
  }
);
SapSoldToWithDistributionCenterItem.displayName = 'SapSoldToWithDistributionCenterItem';
