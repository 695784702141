import React, { useMemo } from 'react';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { ModalAction } from 'common/constants';
import { useEnumValue } from 'common/useEnumValue';
import {
  getFieldNameBySlug,
  getShowCountData,
  getValidators
} from 'pages/AssortmentPage/fieldsHelper';
import { composeValidators, validateRequired } from 'common/helpers/validationHelper';
import { Field, useForm } from 'react-final-form'; //TODO: check to see if we can/should replace Field with AlloyFormField
import { ExternalIdField, VendorProduct } from '../ExternalIdField';
import { Product, UpcField } from '../UpcField';
import {
  AssortmentConfig,
  DistributionCenter,
  ExtractedFormField,
  FormField
} from 'pages/AssortmentPage/AssortmentPage';
import { DeliveryDestination } from '../../EditTradingPartnerAssortment';
import { FormApi } from 'final-form';
import { RetailerProductExternalIdType } from 'graphql/__generated__/types';
import { capitalize } from 'lodash-es';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';

export const Condition = ({ when, is, children }: any) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const getSpecialAssortmentField = (deliveryDestinations: DeliveryDestination[]) => (
  <>
    <AlloyFormField
      data-testid="aa-edit-modal-vendor-flex-checkbox"
      component="checkbox"
      name="vendorFlex"
      fieldProps={{
        title: 'Vendor Flex'
      }}
      inline="after"
    />
    <Condition when="vendorFlex" is={true}>
      <AlloyFormField
        data-testid="aa-edit-modal-vendor-flex-locations-select"
        component="select"
        name="vendorFlexLocations"
        fieldProps={{
          title: 'Vendor Flex Destinations',
          placeholder: 'Flex locations',
          options: deliveryDestinations
            .filter((rdd) => rdd.specialAssortment)
            ?.map(({ id, name }) => ({
              label: name || '',
              value: id
            })),
          mode: 'multiple'
        }}
      />
    </Condition>
  </>
);

const getDistributionCentersField = (
  formField: ExtractedFormField | FormField,
  distributionCenters: DistributionCenter[]
) => (
  <AlloyFormField
    component="select"
    data-testid="aa-edit-modal-distribution-centers-select"
    name="distributionCenters"
    required={formField.required}
    validate={formField.required ? validateRequired : undefined}
    fieldProps={{
      title: formField.name,
      options: distributionCenters.map(({ id, name, code }) => ({
        value: id,
        label: `${name} ${code}`
      })),
      mode: 'multiple'
    }}
  />
);

const getSelectField = (formField: ExtractedFormField | FormField, values: string[]) => (
  <AlloyFormField
    component="select"
    data-testid={`aa-edit-modal-${getFieldNameBySlug(formField.slug || '')}-select`}
    name={getFieldNameBySlug(formField.slug || '')}
    required={formField.required}
    fieldProps={{
      title: formField.name,
      options: values.map((value) => ({
        value: value,
        label: value
      }))
    }}
  />
);

const getNumberField = (formField: ExtractedFormField | FormField, tabKey?: string) => (
  <AlloyFormField
    component="inputNumber"
    data-testid={`aa-edit-modal-${
      tabKey
        ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
        : getFieldNameBySlug(formField.slug || '')
    }-field`}
    name={
      tabKey
        ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
        : getFieldNameBySlug(formField.slug || '')
    }
    required={formField.required}
    validate={composeValidators(getValidators(formField))}
    fieldProps={{
      title: formField.name,
      controls: false
    }}
    countRule={getShowCountData(formField)}
  />
);

const getRowOfFields = (
  formField: ExtractedFormField | FormField,
  associatedFields: string[],
  assortmentConfig: AssortmentConfig,
  tabKey?: string
) => {
  const firstFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[0]);
  const secondFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[1]);
  const thirdFormField = assortmentConfig.fields.find((ff) => ff.slug === associatedFields[2]);
  if (formField.slug === associatedFields[0]) {
    return !secondFormField && !thirdFormField ? (
      getNumberField(formField, tabKey)
    ) : (
      <AlloyRow gutter={16}>
        <AlloyCol span={secondFormField && thirdFormField ? 8 : 12}>
          {getNumberField(formField, tabKey)}
        </AlloyCol>
        {secondFormField && (
          <AlloyCol span={secondFormField && thirdFormField ? 8 : 12}>
            {getNumberField(secondFormField, tabKey)}
          </AlloyCol>
        )}
        {thirdFormField && (
          <AlloyCol span={secondFormField && thirdFormField ? 8 : 12}>
            {getNumberField(thirdFormField, tabKey)}
          </AlloyCol>
        )}
      </AlloyRow>
    );
  } else if (formField.slug === associatedFields[1]) {
    return !firstFormField ? (
      thirdFormField ? (
        <AlloyRow gutter={16}>
          <AlloyCol span={12}>{getNumberField(formField, tabKey)}</AlloyCol>
          <AlloyCol span={12}>{getNumberField(thirdFormField, tabKey)}</AlloyCol>
        </AlloyRow>
      ) : (
        getNumberField(formField, tabKey)
      )
    ) : null;
  } else {
    return !firstFormField && !secondFormField ? getNumberField(formField, tabKey) : null;
  }
};

interface FormFieldComponentProps {
  formField: ExtractedFormField | FormField;
  assortmentConfig: AssortmentConfig;
  distributionCenters: DistributionCenter[];
  deliveryDestinations: DeliveryDestination[];
  vendorId: string;
  tabKey?: string;
  changeToUpdate: (product: VendorProduct) => void;
  action: ModalAction;
  fillFormWithProduct: (product: Product, form: FormApi) => void;
  retailerProductExternalIdType?: RetailerProductExternalIdType;
}

export const FormFieldComponent = ({
  formField,
  assortmentConfig,
  distributionCenters,
  deliveryDestinations,
  vendorId,
  action,
  changeToUpdate,
  tabKey,
  fillFormWithProduct,
  retailerProductExternalIdType
}: FormFieldComponentProps) => {
  const form = useForm();
  const { enumValues: moqUnitOfMeasureEnums } = useEnumValue(
    'TradingPartnerActiveAssortmentMoqUnitOfMeasure'
  );
  const { enumValues: requiredOrderUnitOfMeasureEnums } = useEnumValue(
    'TradingPartnerActiveAssortmentRequiredOrderUnitOfMeasure'
  );

  const conditionalUpcField = useMemo(() => {
    return (
      <UpcField
        vendorId={vendorId}
        action={action}
        formField={formField}
        tabKey={tabKey}
        fillFormWithProduct={(product) => fillFormWithProduct(product, form)}
        assortmentConfig={assortmentConfig}
      />
    );
  }, [action, assortmentConfig, fillFormWithProduct, form, formField, tabKey, vendorId]);

  if (!formField.slug) return null;

  switch (formField.slug) {
    case 'DISTRIBUTION_CENTER_IDS':
      return getDistributionCentersField(formField, distributionCenters);
    case 'SPECIAL_ASSORTMENT':
      return getSpecialAssortmentField(deliveryDestinations);
    case 'CASES_PER_PALLET':
    case 'GROSS_WEIGHT':
      return getRowOfFields(
        formField,
        ['CASES_PER_PALLET', 'GROSS_WEIGHT'],
        assortmentConfig,
        tabKey
      );
    case 'WIDTH':
    case 'HEIGHT':
    case 'DEPTH':
      return getRowOfFields(formField, ['WIDTH', 'HEIGHT', 'DEPTH'], assortmentConfig, tabKey);
    case 'PRICE':
    case 'MOQ_MINIMUM':
    case 'CASES_PER_LAYER':
    case 'LAYERS_PER_PALLET':
    case 'TO_CASE_QUANTITY':
    case 'INVENTORY_RESERVE':
      return getNumberField(formField, tabKey);
    case 'MOQ_UNIT_OF_MEASURE':
      return getSelectField(formField, moqUnitOfMeasureEnums || []);
    case 'REQUIRED_ORDER_UNIT_OF_MEASURE':
      return getSelectField(formField, requiredOrderUnitOfMeasureEnums || []);
    case 'NAME':
      return (
        <AlloyFormField
          data-testid={`aa-edit-modal-${
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
              : getFieldNameBySlug(formField.slug || '')
          }-field`}
          component="textarea"
          name={
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug)}`
              : getFieldNameBySlug(formField.slug)
          }
          validate={composeValidators(getValidators(formField))}
          required={formField.required}
          fieldProps={{
            title: formField.name
          }}
          countRule={getShowCountData(formField)}
        />
      );
    case 'SHIPS_IN_OWN_CONTAINER':
      return (
        <AlloyFormField
          data-testid={`aa-edit-modal-${
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
              : getFieldNameBySlug(formField.slug || '')
          }-checkbox`}
          component="checkbox"
          name={
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug)}`
              : getFieldNameBySlug(formField.slug)
          }
          fieldProps={{
            title: 'Ships In Own Container'
          }}
          inline="after"
        />
      );
    case 'UPC':
      return conditionalUpcField;
    case 'PRODUCT_TYPE':
      return <ProductTypeField formField={formField} tabKey={tabKey} />;
    case 'EXTERNAL_ID':
      return (
        <ExternalIdField
          vendorId={vendorId}
          changeToUpdate={changeToUpdate}
          action={action}
          formField={formField}
          retailerProductExternalIdType={retailerProductExternalIdType}
          assortmentConfig={assortmentConfig}
        />
      );
    default:
      return (
        <AlloyFormField
          component="input"
          data-testid={`aa-edit-modal-${
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug || '')}`
              : getFieldNameBySlug(formField.slug || '')
          }-field`}
          name={
            tabKey
              ? `${tabKey}.${getFieldNameBySlug(formField.slug)}`
              : getFieldNameBySlug(formField.slug)
          }
          validate={composeValidators(getValidators(formField))}
          required={formField.required}
          fieldProps={{
            title: formField.name
          }}
          countRule={getShowCountData(formField)}
        />
      );
  }
};

const ProductTypeField = ({
  formField,
  tabKey
}: {
  formField: ExtractedFormField | FormField;
  tabKey?: string;
}) => {
  const { enumValues: productTypeEnums } = useEnumValue('ProductType');

  return (
    <AlloyFormField
      component="select"
      data-testid={`aa-edit-modal-${tabKey ? `${tabKey}.` : ''}product-type-field`}
      name={tabKey ? `${tabKey}.productProductType` : 'productProductType'}
      required={formField.required}
      fieldProps={{
        title: formField.name ?? 'Product Type',
        options: productTypeEnums.map((pt) => ({
          label: capitalize(pt),
          value: pt
        }))
      }}
    />
  );
};
