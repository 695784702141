import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { App } from 'ant5';
import { DEFAULT_PAGE_SIZE } from 'common/constants';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { LocationUpdate } from 'common/interfaces';
import { HighlightedText } from 'components/Common/HighlightedText/HighlightedText';
import { Paginator } from 'components/Paginator/Paginator';
import { AddShipToModal } from './components/AddShipToModal/AddShipToModal';
import s from './ShipToPage.module.scss';
import { SearchField } from 'components/Common/SearchField/SearchField';
import {
  AdvancedFilters,
  searchStringToQueryFilter
} from './components/AdvancedFilters/AdvancedFilters';
import { ListOfItemsColumn } from 'components/Common/Table/ListOfItemsColumn/ListOfItemsColumn';
import { DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import BulkAddStoreModal from 'pages/ShipToPage/components/BulkAddStoreModal/BulkAddStoreModal';
import ConfirmationModal from 'components/Modals/ConfirmationModal/ConfirmationModal';
import * as Sentry from '@sentry/browser';
import { BulkActivateStoreModal } from 'pages/ShipToPage/components/BulkActivateStoreModal/BulkActivateStoreModal';
import {
  RetailerDeliveryDestinationsDocument,
  RetailerDeliveryDestinationsQuery
} from './gql/__generated__/retailerDeliveryDestinations.query';
import { RddSortColumn, SortOrderDirection } from 'graphql/__generated__/types';
import {
  RetailerChannelsForShipToDocument,
  RetailerChannelsForShipToQuery
} from './gql/__generated__/retailerChannels.query';
import { RetailerDeliveryDestinationToCopy } from './components/AddShipToModal/components/SelectShipToModal/SelectShipToModal';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import {
  RetailerDeliveryDestination,
  ShipToViewDrawer
} from './components/ShipToViewDrawer/ShipToViewDrawer';
import { UpdateStatusOfRddsDocument } from './gql/__generated__/updateStatusOfRdds.mutation';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { EditShipToModal } from './components/EditingShipToModal/EditShipToModal';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import { updatePagingCallback } from 'common/helpers/advancedFiltersHelper';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { UpdatedAt } from 'components/ui/UpdatedAt/UpdatedAt';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';

export type RetailerDeliveryDestinationFromList = Omit<
  InferNodeType<RetailerDeliveryDestinationsQuery, 'retailerDeliveryDestinations'>,
  'id'
> & { id: string | undefined };

export type RetailerChannelsForShipTo = InferNodeType<
  RetailerChannelsForShipToQuery,
  'vendorMarkets'
>;

export const ShipToPage = () => {
  const history = useHistory();
  const { message } = App.useApp();

  const [rddId, setRddId] = useQueryParam('shipto', StringParam);
  const [pageSize, setPageSize] = useQueryParam('limit', NumberParam);
  const [sortColumn, setSortColumn] = useQueryParam('column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('order', StringParam);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [after, setAfter] = useQueryParam('after', StringParam);
  const [before, setBefore] = useQueryParam('before', StringParam);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(DEFAULT_PAGE_SIZE);
      setSortColumn('UPDATED_AT');
      setSortOrder('DESC');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  useEffect(() => {
    if (rddId) {
      setRddToChangeActivating(undefined);
      setShowBulkAddModal(false);
      setViewHistoryDeliveryDestination(undefined);
      setShowAdvancedFilters(false);
      setShowBulkActivateModal(undefined);
      setAddDeliveryDestination(undefined);
    }
  }, [rddId]);

  const [addDeliveryDestination, setAddDeliveryDestination] = useState<
    { shipTo: RetailerDeliveryDestinationToCopy; tpId?: string } | undefined
  >();
  const [editingDeliveryDestination, setEditingDeliveryDestination] = useState<
    RetailerDeliveryDestination | undefined
  >();
  const [rddToChangeActivating, setRddToChangeActivating] = useState<
    RetailerDeliveryDestinationFromList | undefined
  >();
  const [viewChangeHistoryDeliveryDestination, setViewHistoryDeliveryDestination] = useState<
    RetailerDeliveryDestinationFromList | undefined
  >();

  const [showBulkAddModal, setShowBulkAddModal] = useState(false);

  const [showBulkActivateModal, setShowBulkActivateModal] = useState<{ active: boolean }>();
  const [selected, setSelected] = useState<RetailerDeliveryDestinationFromList[]>([]);
  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);

  const { filterData, advancedFilterData } = useMemo(
    () => searchStringToQueryFilter(history.location.search),
    [history.location.search]
  );

  const { data, loading, error } = useQuery(RetailerDeliveryDestinationsDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as RddSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      after,
      before,
      searchLike: search,
      filter: filterData,
      advancedFilter: advancedFilterData
    },

    skip: !pageSize || !sortColumn || !sortOrder
  });

  const shipToList = useMemo(() => getNodesFromEdges(data?.retailerDeliveryDestinations), [data]);

  const updatePaging = useCallback(updatePagingCallback, [history]);

  const retailersListQueryData = useQuery(RetailerChannelsForShipToDocument, {
    variables: { first: 10000000 }
  });

  const retailerList = useMemo(
    () => getNodesFromEdges(retailersListQueryData?.data?.vendorMarkets),
    [retailersListQueryData]
  );

  const [updateStatusOfRdds] = useMutation(UpdateStatusOfRddsDocument, {
    refetchQueries: ['retailerDeliveryDestinations']
  });

  const handleSearch = useCallback(
    (value: string) => {
      if (search !== (value || undefined)) {
        setSearch(value || undefined);
        setAfter(undefined);
        setBefore(undefined);
      }
    },
    [search, setAfter, setBefore, setSearch]
  );

  if (error) return <p>Error</p>;

  const columns: ColumnsType<RetailerDeliveryDestinationFromList> = [
    {
      title: 'ID',
      render: (_, record) => (
        <span className={!record.active ? s.inactive : ''}>
          <HighlightedText text={record.externalId || ''} highlight={search ?? ''} />
        </span>
      )
    },
    {
      title: 'SAP Sold-To',
      render: (_, record) => (
        <span className={!record.active ? s.inactive : ''}>
          <HighlightedText text={record.soldToSapCustomerId || ''} highlight={search ?? ''} />
        </span>
      ),
      dataIndex: 'soldToSapCustomerId'
    },
    {
      title: 'Name',
      render: (_, record) => (
        <span className={!record.active ? s.inactive : ''}>
          <HighlightedText text={record.name || ''} highlight={search ?? ''} />
        </span>
      ),
      key: 'NAME',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'NAME'),
      sorter: true
    },
    {
      title: 'Retailer Channel',
      render: (_, record) => (
        <span className={!record.active ? s.inactive : ''}>
          <HighlightedText text={record.vendorMarket?.name || ''} highlight={search ?? ''} />
        </span>
      ),
      key: 'VENDOR_MARKET_NAME',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'VENDOR_MARKET_NAME'),
      sorter: true
    },
    {
      title: 'Trading Partners',
      render: (_, record) => (
        <span className={!record.active ? s.inactive : ''}>
          <ListOfItemsColumn
            items={
              record.tradingPartners?.map((tradingPartner) => tradingPartner?.name || '') || []
            }
            splitToLines
          />
        </span>
      ),
      key: 'TRADING_PARTNER_NAME'
    },
    {
      title: 'Status',
      render: (_, record) => {
        return record.active ? 'Active' : <span className={s.inactive}>Inactive</span>;
      },
      width: 79
    },
    {
      title: 'Last updated',
      render: (_, record) => (
        <UpdatedAt
          onClick={(e) => {
            setViewHistoryDeliveryDestination(record);
            e.stopPropagation();
          }}
          updatedAt={record.updatedAt}
        />
      ),
      key: 'UPDATED_AT',
      sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'UPDATED_AT'),
      sorter: true,
      width: 160
    },
    {
      title: '',
      key: 'ACTIONS',
      width: 46,
      render: (_, record) => {
        return (
          <AlloyDropdown
            menu={{
              items: [
                {
                  label: record.active ? 'Deactivate' : 'Activate',
                  key: 'ACTIVATE',
                  onClick: (e) => {
                    e.domEvent.stopPropagation();
                    setRddToChangeActivating(record);
                  }
                }
              ]
            }}
            placement="bottomRight"
            trigger={['click']}
          >
            <EllipsisOutlined
              rotate={90}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </AlloyDropdown>
        );
      }
    }
  ];

  const nextPage = async () => {
    setAfter(data?.retailerDeliveryDestinations?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.retailerDeliveryDestinations?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const onApplyAdvancedFilter = (update: LocationUpdate) => {
    updatePaging(history, update);
  };

  const deactivateShipTo = async (shipTo: RetailerDeliveryDestinationFromList) => {
    try {
      await updateStatusOfRdds({
        variables: {
          input: { ids: shipTo.id ? [shipTo.id] : [], active: !shipTo.active }
        }
      });

      message.success(`Ship-To was successfully ${shipTo.active ? 'deactivated' : 'activated'}`);
      setRddToChangeActivating(undefined);
    } catch (error) {
      console.log(error);
      Sentry.addBreadcrumb({
        message: `Deactivate rdds: ${JSON.stringify(shipTo)}`
      });
      Sentry.captureException(error);
    }
  };

  const items = [
    {
      label: 'Single add',
      key: '0',
      onClick: () => {
        setAddDeliveryDestination({ shipTo: {} as RetailerDeliveryDestinationToCopy });
      },
      dataTestId: 'add-single-ship-to'
    },
    {
      label: 'Bulk add (CSV)',
      key: '1',
      onClick: () => {
        setShowBulkAddModal(true);
      },
      dataTestId: 'bulk-add-ship-to'
    }
  ];

  const addMenu = items.map(({ label, dataTestId, onClick }) => ({
    key: label + dataTestId,
    label,
    'data-testid': dataTestId,
    onClick
  }));

  const activateItems = [
    {
      label: 'Activate',
      key: '0',
      onClick: () => {
        setShowBulkActivateModal({ active: true });
      },
      show: selected.find((item) => !item.active),
      dataTestId: 'activate-ship-to'
    },
    {
      label: 'Deactivate',
      key: '1',
      onClick: () => {
        setShowBulkActivateModal({ active: false });
      },
      show: selected.find((item) => item.active),
      dataTestId: 'deactivate-ship-to'
    }
  ];

  const activateMenu = activateItems
    .filter((item) => item.show)
    .map(({ label, dataTestId, onClick }) => ({
      key: label + dataTestId,
      label,
      'data-testid': dataTestId,
      onClick
    }));

  return (
    <div className={s.page}>
      <div className={s.header}>
        <PageHeader className={s.ship_to_title}>Ship-To</PageHeader>
        <div>
          <AdvancedFilters
            onApplyAdvancedFilter={onApplyAdvancedFilter}
            showAdvancedFilters={showAdvancedFilters}
            setShowAdvancedFilters={setShowAdvancedFilters}
          />
        </div>
      </div>
      <SearchField
        handleSearch={handleSearch}
        loading={loading}
        value={search || ''}
        placeholder="Search by External ID, SAP Sold-to, or Name"
      />
      <div className={s.pagination_container}>
        <QuantityDisplay
          count={data?.retailerDeliveryDestinations?.totalCount}
          titleSingular="Ship-To"
          titleMultiple="Ship-Tos"
        />
        <div className={s.right_container}>
          <Paginator
            hasNextPage={!!data?.retailerDeliveryDestinations?.pageInfo?.hasNextPage}
            hasPreviousPage={!!data?.retailerDeliveryDestinations?.pageInfo?.hasPreviousPage}
            nextPage={nextPage}
            prevPage={prevPage}
            pageSize={pageSize || DEFAULT_PAGE_SIZE}
            handlePageSizeChange={handlePageSizeChange}
            onlyButtons={false}
          />
          <AlloyDropdown
            menu={{ items: activateMenu }}
            trigger={['click']}
            disabled={!selected.length}
          >
            <AlloyButton data-testid="activate-deactivate-dropdown">
              Change Status
              <DownOutlined />
            </AlloyButton>
          </AlloyDropdown>
          <AlloyDropdown menu={{ items: addMenu }} trigger={['click']} placement="bottomLeft">
            <AlloyButton
              type="primary"
              className={s.add_new_button}
              data-testid="add-ship-to-dropdown"
            >
              Add new Ship-To
              <DownOutlined />
            </AlloyButton>
          </AlloyDropdown>
        </div>
      </div>
      <AlloyTable
        loading={loading}
        sticky
        tableLayout="auto"
        columns={columns}
        dataSource={shipToList}
        rowKey={(record) => record.id || ''}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              setRddId(record.id);
            }
          };
        }}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelected(selectedRows);
            setSelectedRows([...selectedRowKeys]);
          }
        }}
        onChange={(_pagination, _filters, sorter) => {
          const column =
            String((sorter as SorterResult<RetailerDeliveryDestinationFromList>).columnKey) ||
            'VENDOR_MARKET_NAME';
          const order =
            (sorter as SorterResult<RetailerDeliveryDestinationFromList>).order === 'ascend' ||
            !(sorter as SorterResult<RetailerDeliveryDestinationFromList>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column, order);
        }}
      />
      <div className={s.pagination_container_end}>
        <Paginator
          hasNextPage={!!data?.retailerDeliveryDestinations?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.retailerDeliveryDestinations?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={true}
        />
      </div>
      <AddShipToModal
        deliveryDestination={addDeliveryDestination?.shipTo}
        tradingPartnerId={addDeliveryDestination?.tpId}
        onClose={() => setAddDeliveryDestination(undefined)}
        vendorMarkets={retailerList}
        onSelectShipTo={(shipTo, tpId) => {
          setAddDeliveryDestination({
            shipTo: shipTo || ({} as RetailerDeliveryDestinationToCopy),
            tpId
          });
        }}
      />
      <EditShipToModal
        shipTo={editingDeliveryDestination}
        onClose={() => {
          setEditingDeliveryDestination(undefined);
          setRddId(undefined);
        }}
        vendorMarkets={retailerList}
      />
      <ShipToViewDrawer
        shipToId={editingDeliveryDestination || !rddId ? undefined : rddId}
        onClose={() => setRddId(undefined)}
        startEditing={(shipTo) => {
          setEditingDeliveryDestination({ ...shipTo });
        }}
      />
      <BulkAddStoreModal
        visible={showBulkAddModal}
        retailerChannels={retailerList}
        onClose={() => {
          setShowBulkAddModal(false);
        }}
      />
      <ConfirmationModal
        visible={!!rddToChangeActivating}
        message={`Are you sure you want to ${
          rddToChangeActivating?.active ? 'deactivate' : 'activate'
        } Ship-To?`}
        confirmed={(confirmed) => {
          if (rddToChangeActivating && confirmed) {
            deactivateShipTo(rddToChangeActivating);
          } else {
            setRddToChangeActivating(undefined);
          }
        }}
      />
      <BulkActivateStoreModal
        storesToChangeActivating={selected}
        show={!!showBulkActivateModal}
        onClose={(completed) => {
          setShowBulkActivateModal(undefined);
          if (completed) {
            setSelectedRows([]);
            setSelected([]);
          }
        }}
        active={!!showBulkActivateModal?.active}
      />
      <ChangeHistory
        entity={
          viewChangeHistoryDeliveryDestination
            ? {
                id: viewChangeHistoryDeliveryDestination.id || '',
                name: viewChangeHistoryDeliveryDestination.name || '',
                type: 'Ship-To'
              }
            : undefined
        }
        onClose={() => setViewHistoryDeliveryDestination(undefined)}
      />
    </div>
  );
};
