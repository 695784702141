import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  DefaultOptions
} from '@apollo/client';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import { getItem } from 'common/services/persistentStorageServices';
import { logout } from 'common/helpers/logout';
import { GraphQLError } from 'graphql';
import { AUTHORIZATION_ERROR, AUTHENTICATION_ERROR } from 'common/constants';
import * as AbsintheSocket from '@absinthe/socket';
import { Socket as PhoenixSocket } from 'phoenix';
// We can not use App.useApp() outside of react tree, so we use message directly.
import { message } from 'ant5';
import {
  logBuild,
  parseXVersionString,
  getBuildDetails,
  setBEBuildDetails,
  cleanBEBuildDetails,
  cleanIsSentryBEContextSet,
  setSentryBEContextSet,
  isSentryBEContextSet
} from 'common/helpers/buildDetails';
import { createLink } from 'apollo-absinthe-upload-link';
import {
  getFrontendEnvironmentName,
  getSentryRelease,
  isE2ETestSession
} from 'common/helpers/identificateEnvironment';
import { StrictTypedTypePolicies } from 'graphql/__generated__/apollo-helpers';

// We need to clean this before everything else.
cleanBEBuildDetails();
cleanIsSentryBEContextSet();

const shouldInitializeSentry =
  !['localhost', 'shipyard', 'unknown', undefined].includes(getFrontendEnvironmentName()) &&
  !isE2ETestSession();

// Init sentry outside of component
if (shouldInitializeSentry) {
  Sentry.init({
    dsn: 'https://380f298f65c243899e069cbdc70a2c4b@o236104.ingest.sentry.io/5518729',
    release: getSentryRelease(),
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Failed to fetch',
      'NetworkError when attempting to fetch resource.',
      'ResizeObserver loop completed with undelivered notifications'
    ],
    environment: getFrontendEnvironmentName()
  });
}

const authLink = setContext((_, { headers }) => {
  const user = getItem('user');
  const api_token = user?.api_token;
  return {
    headers: {
      ...headers,
      authorization: api_token ? `Bearer ${api_token}` : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (!Array.isArray(graphQLErrors)) {
      console.error(`[GraphQL error]: `, graphQLErrors);
    } else if (
      graphQLErrors?.some(
        (error: GraphQLError) =>
          error.message === AUTHORIZATION_ERROR || error.message === AUTHENTICATION_ERROR
      )
    ) {
      logout();
    } else {
      for (const error of graphQLErrors) {
        const { message: errorMessage, locations, path } = error;
        if (errorMessage?.includes('unauthorized')) {
          // This message doesn't hold the context, so it doesn't have the ant5 theme modifications.
          // TODO: solve it somehow, by emitting event?
          message.error(`[GraphQL error]: Message: ${errorMessage}`);
        }
        console.log(
          `[GraphQL error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
        );
      }
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const phoenixSocket = new PhoenixSocket(
  `${import.meta.env.REACT_APP_API_WEBSOCKET_URL}/supply_chain_socket`,
  {
    params: () => {
      const user = getItem('user');
      const api_token = user?.websocket_token;
      return { token: api_token ? api_token : '' };
    }
  }
);
const absintheSocket = AbsintheSocket.create(phoenixSocket);
const websocketLink = createAbsintheSocketLink(absintheSocket) as unknown as ApolloLink;

const BACKEND_URL = `${import.meta.env.REACT_APP_API_URL}/supply_chain/graphql`;

const uploadLink = createLink({
  uri: BACKEND_URL,
  credentials: 'include'
});

const namingOperationsLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${BACKEND_URL}?${operation.operationName}`
  }));
  return forward ? forward(operation) : null;
});

//used to provide BE git commit sha and the BE environment
//should trigger only once to populate the build details. All subsequent requests will flow through untouched.
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const build = getBuildDetails();

    const context = operation.getContext();
    const { be, beEnv } = parseXVersionString(context.response?.headers?.get('x-version'));

    if (!build.be || !build.beEnv) {
      const updatedBuild = { ...build, be, beEnv };
      setBEBuildDetails(updatedBuild);
      logBuild(updatedBuild);
    } else if (build.be && build.beEnv) {
      // add BE env details to sentry session. Wrapped in try catch so if it fails, it doesn't break the app.
      try {
        if (shouldInitializeSentry && !isSentryBEContextSet()) {
          Sentry.setContext('Backend', {
            release: build.be,
            env: build.beEnv
          });

          if (build.feEnv === 'shipyard') {
            const isFe = window.location?.href?.includes('ecomm-engine-frontend');
            const prEnv = window.location?.href
              ?.match(/pr.+?(?=\.)/g)
              ?.toString()
              .slice(2);

            Sentry.setContext('Shipyard', {
              PR: prEnv,
              app: isFe ? 'ecomm-engine-frontend (frontend)' : 'order-excellence (backend)'
            });

            Sentry.setContext('Github', {
              repo: isFe ? 'ecomm-engine-frontend' : 'ecom_api',
              PR: isFe
                ? `https://github.com/pepsico-ecommerce/ecomm-engine-frontend/pull/${prEnv}`
                : `https://github.com/pepsico-ecommerce/ecom_api/pull/${prEnv}`
            });
          }

          setSentryBEContextSet();
        }
      } catch {
        console.error('Could not set proper Sentry context.');
      }
    }
    return response;
  });
});

// If the query contains a subscription, send it through the
// websocket link. Otherwise, send it through the HTTP link.
const link = ApolloLink.split(
  (operation) => {
    const definition = getMainDefinition(operation.query);
    //console.log('context', operation.getContext()?.request)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  websocketLink,
  ApolloLink.from([authLink, afterwareLink, errorLink, namingOperationsLink, uploadLink])
);

const typePolicies: StrictTypedTypePolicies = {
  // PurchaseOrderDetailsReportMetrics id can be empty, that's why we need externalId
  PurchaseOrderDetailsReportMetrics: {
    keyFields: ['id', 'externalId']
  },
  TradingPartnerAssortment: {
    keyFields: ['retailerProductId']
  },
  TpAssortmentSubstitution: {
    keyFields: ['upc', 'oracleInvenId', 'sapMaterialId']
  },
  TpaInventory: {
    keyFields: ['distributionCenterId', 'source']
  },
  ProductsFillRateReportMetrics: {
    keyFields: ['productId', 'productExternalId', 'sapMaterialId']
  },
  OpportunitiesMetrics: {
    // Should we also have something like "type"?
    keyFields: ['id', 'name']
  },
  DistributionCenterSubset: {
    keyFields: ['id', 'code', 'sapPlantNumber']
  },
  GmProduct: {
    keyFields: ['externalId', 'gtin14']
  },
  DcSoldTo: {
    keyFields: ['dcCode', 'soldTo']
  }
};

const cache = new InMemoryCache({
  possibleTypes: {
    AuditTrailDiffItem: ['AuditTrailAddition', 'AuditTrailChange', 'AuditTrailRemoval'],
    AuditTrailValue: ['AuditTrailPrimitive', 'AuditTrailListOfPrimitives']
  },
  typePolicies
});

/*
 * We use "network-only" almost everywhere anyway.
 * In some places, we have "no-cache," but I think it can be replaced later.
 */
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only'
  },
  query: {
    fetchPolicy: 'network-only'
  },
  mutate: {
    fetchPolicy: 'network-only'
  }
};

const client = new ApolloClient({
  link,
  cache,
  defaultOptions
});

const ApolloContextProvider = (props: { children: ReactNode }) => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default ApolloContextProvider;
