/*
This component contains source code that will display the details of the generated Optimzer result analysis at the DC level
User can view DC level analysis details of the optimizer results for a selected scenario.
*/
import React from 'react';
import { App } from 'ant5';
import { ApolloError } from '@apollo/client';
import s from './DCAnalysisTable.module.scss';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import { AlloyTable, AlloyTableProps, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
interface DcAnalysisData {
  key: string | undefined;
  dc: string | undefined;
  operatingCost: number | undefined;
  wastageCost: number | undefined;
  holdingCost: number | null | undefined;
}
interface DCTableProps {
  handleHideTable: () => void;
  data: DcAnalysisData[] | undefined;
  error: ApolloError | undefined;
  loading: boolean;
}
// Define, set table columns and  map data to display DC level analysis details of the optimizer results
const columns: ColumnsType<DcAnalysisData> = [
  {
    title: 'DC',
    dataIndex: 'dc',
    key: 'dc',
    sorter: (a, b) => safeLocaleCompare(a.dc, b.dc)
  },
  {
    title: 'OPERATING COST',
    dataIndex: 'operatingCost',
    key: 'operatingCost',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.operatingCost, b.operatingCost),
    render: (_, { operatingCost }) => {
      return operatingCost && `$ ${operatingCost.toLocaleString('en-US')}`;
    }
  },
  {
    title: 'STRESSED INVENTORY VALUE',
    key: 'wastageCost',
    dataIndex: 'wastageCost',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.wastageCost, b.wastageCost),
    render: (_, { wastageCost }) => {
      return wastageCost && `$ ${wastageCost.toLocaleString('en-US')}`;
    }
  },
  {
    title: 'HOLDING COST',
    dataIndex: 'holdingCost',
    key: 'holdingCost',
    defaultSortOrder: 'descend',
    sorter: (a, b) => safeNumberComparator(a.holdingCost, b.holdingCost),
    render: (_, { holdingCost }) => {
      return holdingCost && `$ ${holdingCost.toLocaleString('en-US')}`;
    }
  }
];

const onChange: AlloyTableProps<DcAnalysisData>['onChange'] = (filters, sorter, extra) => {};

const DcAnalysisTable: React.FC<DCTableProps> = ({ handleHideTable, data, error, loading }) => {
  const { message } = App.useApp();

  return (
    <AlloyModal
      data-testid="inv-allInfoPage-dcAnalysisTableModal"
      centered
      open={true}
      onOk={() => handleHideTable()}
      onCancel={() => handleHideTable()}
      width={'60%'}
      footer={null}
    >
      {error && message.error(error.message)}
      <div>
        <p className={s.landingPageBrandTableHeader} data-testid="inv-allInfoPage-dcTableTitle">
          DC Overview
        </p>
        <div data-testid="inv-allInfoPage-dcAnalysisTableDiv" className={s.dcAnalysisOverviewTable}>
          <AlloyTable
            loading={loading}
            scroll={{ y: 300 }}
            columns={columns}
            dataSource={data}
            pagination={false}
            onChange={onChange}
            data-testid="inv-allInfoPage-dcAnalysisTable"
          />
        </div>
      </div>
    </AlloyModal>
  );
};

export default DcAnalysisTable;
